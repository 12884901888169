<template>
  <MiFormItem
    :class="[
      'form-select',
      {
        'form-select_in-column': inColumn,
        'form-select_in-line-space-between': !inColumn && !!label && spaceBetween,
      },
    ]"
    :error="errorMessage"
    :label="label"
    :required="required">
    <MiSelect
      class="form-select__select"
      v-model="value"
      :validate-event="false"
      :disabled="disabled"
      :placeholder="placeholder || $t('Base.PleaseSelect')"
      :clearable="clearable"
      @update:model-value="$emit('update:modelValue', $event)"
      @change="selectHandler">
      <ElOption
        class="form-select__option"
        v-for="item in options"
        :key="item[valueKey]"
        :label="item[labelKey]"
        :value="item[valueKey]" />
    </MiSelect>
  </MiFormItem>
</template>

<script lang="ts">
import cloneDeep from 'lodash.clonedeep';
import { useField } from 'vee-validate';
import { PropType } from 'vue';

import { MiSelect, MiFormItem } from '~shared/ui';

export default {
  name: 'FormSelect',
  components: { MiSelect, MiFormItem },
  emits: ['select', 'update:modelValue'],
  props: {
    options: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Array as PropType<{ [key: string]: any }[]>,
      required: true,
    },
    inColumn: { type: Boolean, default: true },
    spaceBetween: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    fieldName: { type: String, required: true },
    fieldObjectName: { type: String, required: true },
    labelKey: { type: String, default: 'title' },
    valueKey: { type: String, default: 'id' },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
  },
  setup(props, { emit }) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const { value, setValue, errorMessage } = useField<string | number | boolean | undefined>(
      props.fieldName
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, vue/no-setup-props-destructure
    const { setValue: setObjectValue } = useField<Record<string, any>>(props.fieldObjectName);

    const selectHandler = (eventValue: string | number | boolean) => {
      const result = props.options.find((f) => f[props.valueKey] === eventValue);
      const cloneResult = cloneDeep(result);

      setValue(eventValue);
      if (cloneResult) {
        setObjectValue(cloneResult);
      }
      emit('select', cloneResult);
    };

    return { value, setValue, errorMessage, selectHandler, setObjectValue, MiSelect };
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
