import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware } from '~shared/lib';

export const PATIENT_MEDICAL_CARD_ROUTE = {
  name: 'PatientMedicalCard',
  path: ROUTE_PATH.patients.medicalCard.rawPath,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'MedicalCard.MedicalCard',
  },
};
