{
  "en": {
    "NotAppointmentsError": "Add entries",
    "Title": {
      "Create": "Sign up for appointments",
      "Edit": "Edit post"
    }
  },
  "ru": {
    "NotAppointmentsError": "Добавьте записи",
    "Title": {
      "Create": "Записать на приёмы",
      "Edit": "Редактировать запись"
    }
  },
  "uz": {
    "NotAppointmentsError": "Xizmatlarni qoshing",
    "Title": {
      "Create": "Xizmatga ro'yxatdan o'tkazish",
      "Edit": "Xabarni ozgartirish"
    }
  }
}