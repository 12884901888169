import axios from 'axios';

import { Analysis } from '@/models/laboratory/Analysis.model';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { Measure } from '@/models/Measure.model';
import { ServiceGroup } from '@/models/ServiceGroup.model';
import SelectBiomaterial from '@/components/laboratory/SelectBiomaterialType/index.vue';
import SelectAnalysisValueType from '@/components/laboratory/analysis/SelectAnalysisValueType/index.vue';

export default {
  name: 'CreateOrEditAnalysisModal',
  components: { SelectBiomaterial, SelectAnalysisValueType },
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    data: [Analysis, Object],
  },
  data() {
    return {
      loading: false,
      /** @type {Analysis|object} analysis */
      analysis: new Analysis(),
    };
  },
  computed: {
    formOptions() {
      return {
        measure: {
          required: !this.analysis.value_type === Analysis.enum.valueTypes.Default,
          disabled: this.analysis.value_type === Analysis.enum.valueTypes.Boolean,
        },
      };
    },
  },

  watch: {
    'modelValue': {
      handler() {
        this.analysis = new Analysis(this.data ?? {});
      },
      immediate: true,
    },

    'analysis.is_boolean': {
      handler(value) {
        if (value) this.analysis.measure = null;
      },
    },
  },

  methods: {
    async submitHandler() {
      this.loading = true;

      try {
        const analysis = this.analysis.id ? await this.editAnalysis() : await this.createAnalysis();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.analysis.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalInstance({
            name: this.analysis.id ? 'edited' : 'created',
            data: { analysis },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    async createAnalysis() {
      const { analysis } = await Analysis.create(this.analysis);
      return analysis;
    },
    async editAnalysis() {
      const { analysis } = await Analysis.update({ id: this.analysis.id, payload: this.analysis });
      return analysis;
    },
  },

  setup: () => ({
    Analysis,
    Measure,
    ServiceGroup,
  }),
};
