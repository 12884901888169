//TODO: выпилить
import { createStore } from 'vuex';

import { loadAndGenerateStoreModules } from '@/utils/store.util';

export const store = createStore({
  state: {},
  getters: {},

  mutations: {},
  actions: {
    // сбрасываем чтобы при смене аккаунта не было старых данных
    resetGlobalDataFromModules({ dispatch }) {
      const modules = [];
      for (const key in store._modulesNamespaceMap) {
        if (store._modulesNamespaceMap[key]._rawModule.isCRUDModule) modules.push(key);
      }

      return Promise.all(modules.map((module) => dispatch(`${module}clearAllData`)));
    },
  },
  modules: loadAndGenerateStoreModules(),
});
