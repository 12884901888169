import { onlyUserIsAuthMiddleware } from '~shared/lib';

export const HOSPITALS_ROUTE = {
  name: 'HOSPITALS',
  path: '/inpatient-care',
  component: 'VHospitals',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Hospital.Hospital',
  },
};

export const HOSPITAL_ROUTE = {
  name: 'HOSPITAL',
  path: '/inpatient-care/:id',
  component: 'VHospital',
  props: true,
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Hospital.Hospital',
  },
};

export const routes = [HOSPITALS_ROUTE, HOSPITAL_ROUTE];
