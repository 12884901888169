import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware } from '~shared/lib';

export const NO_ACCESS_ROUTE = {
  name: 'NoAccess',
  path: ROUTE_PATH.noAccess,
  component: () => import('./ui'),
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: '403',
  },
};
