<template>
  <ElTable
    class="mi-simple-table"
    :data="data"
    :max-height="maxHeight"
    :empty-text="$t('Base.NoData')"
    :default-sort="defaultSort"
    :size="size"
    v-bind="$props"
    v-loading="loading"
    ref="elTable">
    <template v-if="$slots.empty" #empty>
      <slot name="empty"></slot>
    </template>
    <slot></slot>
  </ElTable>
</template>

<script setup lang="ts">
import { Sort, ElTable, TableProps } from 'element-plus';
import { ref } from 'vue';

import { AnyObject } from '~shared/types';
interface Props extends Partial<TableProps<AnyObject>> {
  eslintFix?: string;
  loading: boolean;
  tableClass?: string;
  maxHeight?: number;
  defaultSort?: Sort;
}

const elTable = ref<InstanceType<typeof ElTable> | null>(null);

withDefaults(defineProps<Props>(), {
  fit: true,
  showHeader: true,
  selectOnIndeterminate: true,
});

defineEmits<{
  (e: 'row-click', event: AnyObject): void;
}>();
</script>

<style lang="scss" src="./index.scss"></style>
