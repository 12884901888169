<template>
  <ElSelect
    :multiple="props.multiple"
    collapse-tags
    :clearable="props.clearable"
    :model-value="props.modelValue"
    :placeholder="props.placeholder"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElOption
      v-for="option in props.options"
      :key="option.value"
      :label="option.label"
      :value="option.value" />
  </ElSelect>
</template>

<script lang="ts">
export default {
  name: 'UiSelect',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
type Props = {
  modelValue: string | number | (string | number)[] | undefined;
  options: { label: string; value: string | number }[];
  clearable?: boolean;
  multiple?: boolean;
  placeholder?: string;
};

const props = withDefaults(defineProps<Props>(), {
  clearable: true,
  multiple: false,
  placeholder: '',
});

defineEmits(['update:modelValue']);
</script>

<style lang="scss" src="./index.scss" />
