export default {
  name: 'appointment',
  namespaced: true,
  state: {
    appointment: null,
  },
  mutations: {
    SET_APPOINTMENT(state, appointment) {
      state.appointment = appointment;
    },
  },
  actions: {
    setAppointment({ commit }, appointment) {
      commit('SET_APPOINTMENT', appointment);
    },
  },
};
