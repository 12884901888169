<template>
  <ElTag :type="props.mapStatus[value]" effect="light" :size="size">
    {{ title }}
  </ElTag>
</template>

<script lang="ts">
export default {
  name: 'StatusTag',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { withDefaults } from 'vue';

import { SizeType, MapStatusType } from '@/components/common/StatusTag/types';

type Props = {
  value: string;
  mapStatus?: MapStatusType;
  title: string;
  size?: SizeType;
};

const props = withDefaults(defineProps<Props>(), {
  size: 'small',
  mapStatus: () => ({
    created: 'warning',
    accepted: 'success',
    canceled: 'danger',
  }),
});
</script>

<style lang="scss">
.income-tag {
  padding: 12px;
  width: 100px;
}
</style>
<i18n src="@/locales/warehouse.locales.json" />
