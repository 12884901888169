<template>
  <div class="ui-tabs-wrapper">
    <div v-if="props.title" class="ui-tabs-title">
      <div class="ui-tabs-title__text">
        {{ title }}
      </div>
      <div class="ui-tabs-title__append"><slot name="title-append" /></div>
    </div>
    <ElTabs class="ui-tabs" v-bind="$attrs"> <slot /> </ElTabs>
  </div>
</template>

<script lang="ts">
export default {
  name: 'UiTabs',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { ElTabs } from 'element-plus';

const props = withDefaults(defineProps<{ title: string | null }>(), {
  title: null,
});
</script>

<style lang="scss" src="./index.scss" />
