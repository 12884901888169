{
  "en": {
    "InpatientCare": {
      "AddRecord": "Add record",
      "ArrivalDate": "Arrival Date",
      "AttendingDoctor": "Attending doctor",
      "CancelInInpatientCare": "Cancel in inpatient care",
      "CarryOutProcedure": "Carry out the procedure",
      "CarryingOutProcedure": "Carrying out the procedure",
      "Chamber": "Ward",
      "Department": "Department",
      "DischargedDate": "Departure Date",
      "EnrollInInpatientCare": "Enroll in inpatient care",
      "EditInInpatientCare": "Edit inpatient care",
      "InpatientCare": "InpatientCare",
      "InpatientTreatment": "Inpatient treatment",
      "StartDate": "The date of the beginning",
      "Statistic": {
        "PatientAmount": "Number of patients in the inpatient"
      },
      "Statuses": {
        "canceled": "Canceled",
        "completed": "Completed",
        "in_progress": "In progress"
      },
      "TreatmentDairy": "Treatment dairy"
    }
  },
  "ru": {
    "InpatientCare": {
      "AddRecord": "Добавить запись",
      "ArrivalDate": "Дата заезда",
      "AttendingDoctor": "Лечащий врач",
      "CancelInInpatientCare": "Отменить стационар",
      "CarryOutProcedure": "Провести процедуру",
      "CarryingOutProcedure": "Проведение процедуры",
      "Chamber": "Палата",
      "Department": "Отделение",
      "DischargedDate": "Дата выезда",
      "EnrollInInpatientCare": "Записать в стационар",
      "EditInInpatientCare": "Редактировать стационар",
      "InpatientCare": "Стационар",
      "InpatientTreatment": "Стационарное лечение",
      "StartDate": "Дата начала",
      "Statistic": {
        "PatientAmount": "Количество пациентов в стационаре"
      },
      "Statuses": {
        "canceled": "Отменен",
        "completed": "Завершен",
        "in_progress": "В процессе"
      },
      "TreatmentDairy": "Дневник лечения"
    }
  },
  "uz": {
    "InpatientCare": {
      "AddRecord": "?? Добавить запись",
      "ArrivalDate": "Kirish sanasi",
      "AttendingDoctor": "?? Davolovchi shifokor",
      "CancelInInpatientCare": "?? Отменить стационар",
      "CarryOutProcedure": "?? Провести процедуру",
      "CarryingOutProcedure": "?? Проведение процедуры",
      "Chamber": "Xona",
      "Department": "Bo'lim",
      "DischargedDate": "Chiqish sanasi",
      "EnrollInInpatientCare": "?? Записать в стационар",
      "EditInInpatientCare": "?? Редактировать стационар",
      "InpatientCare": "Kasalxona",
      "InpatientTreatment": "Kasalxonada davolanish",
      "StartDate": "Boshlanish sanasi",
      "Statistic": {
        "PatientAmount": "Statsionarda bemorlar soni"
      },
      "Statuses": {
        "canceled": "Bekor qilingan",
        "completed": "Yakullangan",
        "in_progress": "Jarayonda"
      },
      "TreatmentDairy": "?? Дневник лечения"
    }
  }
}