<template>
  <div class="employee-form">
    <FieldGroupWrapper :title="$t('User.EmployeeInformation')">
      <template #actions>
        <ElButton v-if="isShowEditButton" type="primary" size="small" @click="isDisabledTrue"
          >{{ $t('Base.Edit') }}
        </ElButton>
      </template>
      <div class="employee-form-info">
        <FormTextField
          required
          :disabled="isDisabled"
          :label="$t('User.FIOEmployee')"
          field-name="name"
          :placeholder="$t('User.FIOEmployee')"
          maxlength="255" />
        <FormSelectField
          required
          :disabled="isDisabled"
          :label="$t('User.Role')"
          :items="userRoles"
          :placeholder="$t('User.Role')"
          field-name="role"
          field-object-name="roleObject"
          value-key="value"
          label-key="label" />
      </div>
      <FormSearchSelectField
        v-if="roleDoctor"
        required
        :disabled="isDisabled"
        :label="$t('User.Speciality')"
        :placeholder="$t('User.Speciality')"
        multiple
        field-name="specialty_ids"
        field-object-name="doctor.specialties"
        :model-for-use="Specialty" />
      <div class="employee-form-info">
        <FormDateField
          :disabled="isDisabled"
          field-name="birthdate"
          :label="$t('User.Birthdate')"
          :placeholder="$t('User.FormatDate')" />
        <FormTextField
          required
          :disabled="isDisabled"
          :label="$t('User.PhoneNumber')"
          field-name="phone"
          placeholder="+ 998 ХХ ХХХ - ХХ - ХХ"
          mask-name="int-12" />
        <FormTextField
          :disabled="isDisabled"
          :label="$t('User.Address')"
          field-name="address"
          :placeholder="$t('User.Address')"
          maxlength="255" />
      </div>
      <div class="employee-form-info">
        <FormTextField
          required
          :disabled="isDisabled"
          :label="$t('User.EmployeeSalary')"
          field-name="salary"
          :placeholder="$t('User.EmployeeSalary')"
          mask-name="int-12" />
        <FormTextField
          required
          :disabled="isDisabled"
          :label="$t('User.ConsultationFee')"
          field-name="doctor_recommendation_commission"
          placeholder="%"
          mask-name="int-12" />
        <FormTextField
          required
          :disabled="isDisabled"
          :label="$t('User.AppointmentFee')"
          field-name="appointment_commission"
          placeholder="%"
          mask-name="int-12" />
      </div>
      <ScheduleBlock v-if="props.formData.working_time" :days="props.formData.working_time" />
      <div class="employee-form-footer">
        <div class="employee-form-footer-title">{{ $t('User.EmployeeActivityStatus') }}</div>
        <div class="employee-form-footer-actions">
          <FormSwitchField
            :active-text="$t('User.Statuses.active')"
            :inactive-text="$t('User.Statuses.not_active')"
            active-color="#44D87F"
            inactive-color="#F6505A"
            field-name="is_working" />
        </div>
        <div class="employee-form-footer-text">{{ $t('User.EmployeeActivityStatusTip') }}</div>
      </div>
    </FieldGroupWrapper>

    <FormActionsWrapper align="right">
      <ElButton type="primary" @click="submitForm">
        {{ props.formData.id ? $t('User.UpdateUser') : $t('User.AddUser') }}
      </ElButton>
    </FormActionsWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'EmployeeForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { computed } from 'vue';

import { EmployeeFormData, User } from '@/models/User.model';
import { I18nService } from '~shared/lib';
import { Specialty } from '@/models/Specialty.model';
import { EmployeeDto } from '@/types/api';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { validationSchema } from '@/components/accounting/modals/EmployeeModal/EmployeeForm/validationSchema';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormDateField from '@/components/common/form/ui/FormDateField/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';
import FormSwitchField from '@/components/common/form/ui/FormSwitchField/index.vue';
import ScheduleBlock from '@/components/accounting/modals/EmployeeModal/ScheduleBlock/index.vue';
import FormSelectField from '@/components/common/form/ui/FormSelectField/index.vue';

const emit = defineEmits(['close:modal', 'action', 'isDisabledFalse']);

const props = defineProps<{
  formData: EmployeeFormData;
  isShowEditButton: boolean;
  isDisabled: boolean;
}>();

const isDisabledTrue = () => {
  emit('isDisabledFalse');
};

// eslint-disable-next-line vue/no-setup-props-destructure
const { handleSubmit } = useForm({
  initialValues: props.formData,
  validationSchema,
  validateOnMount: false,
});

const { value: userRole } = useField('role');

const userRoles = computed<{ value: EmployeeFormData['role']; label: string }[]>(() => {
  return [
    { value: 'doctor', label: I18nService.t('User.Roles.doctor') },
    { value: 'accountant', label: I18nService.t('User.Accounting') },
    { value: 'manager', label: I18nService.t('User.Roles.manager') },
    { value: 'director', label: I18nService.t('User.Roles.director') },
    { value: 'laboratory', label: I18nService.t('User.Roles.laboratory') },
    { value: 'warehouse', label: I18nService.t('User.Roles.warehouse') },
    { value: 'nurse', label: I18nService.t('User.Roles.nurse') },
  ];
});

const roleDoctor = computed(() => {
  return userRoles.value.find((item) => item.value === userRole.value)?.value === 'doctor';
});

let responseData: EmployeeDto;

const submitForm = () => {
  handleSubmit(
    async (values) => {
      if (props.formData.id) {
        const response = await User.updateEmployee(values);
        responseData = response?.data ?? ({} as EmployeeDto);
      } else {
        const response = await User.createEmployee(values);
        responseData = response?.data ?? ({} as EmployeeDto);
      }
      emit(
        'action',
        new GlobalModalInstance({
          name: props.formData.id ? 'updated' : 'created',
          data: { ...responseData },
        })
      );
      emit('close:modal');
    },
    (ctx) => {
      // eslint-disable-next-line no-console
      console.log('invalid submit', ctx.errors);
    }
  )();
};
</script>

<style lang="scss" src="./index.scss" scoped />
<i18n src="@/locales/user.locales.json" />
