<!-- TODO: отрефакторить -->
<template>
  <MainLayout content-class="v-statistic-dashboard">
    <LayoutContentHeader>
      <ElDatePicker
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />
    </LayoutContentHeader>

    <div class="v-statistic-dashboard__content v-statistic-dashboard-content">
      <StatisticSimpleCard
        v-for="card in simpleCards"
        :key="card.title"
        :title="card.title"
        :loading="card.loading"
        :value="card.value"
        :percentage="card.percentage"
        :chart="card.chart"
        :value-formatter="card.valueFormatter"
        :chart-color="card.chartColor" />

      <ElCard
        class="v-statistic-dashboard-top-item"
        v-for="card in topCards"
        :key="card.title"
        :header="card.title"
        shadow="never"
        v-loading="card.loading">
        <div
          class="v-statistic-dashboard-top-item-content"
          v-for="item in card.items"
          :key="item.title + item.value">
          <div class="v-statistic-dashboard-top-item-content__title">{{ item.title }}</div>
          <div class="v-statistic-dashboard-top-item-content__subtitle">{{ item.subtitle }}</div>
          <div class="v-statistic-dashboard-top-item-content__value">{{ item.value }}</div>
        </div>
      </ElCard>

      <div class="v-statistic-dashboard__cards-wrapper">
        <AppointmentsOnTimeStatisticCard
          class="v-statistic-dashboard__appointment-by-time-card"
          :loading="appointmentsOnTime.loading"
          :value="appointmentsOnTime.data.value"
          :percentage="appointmentsOnTime.data.percentage" />
        <PatientWaitingTimeStatisticCard
          class="v-statistic-dashboard__patient-waiting-time-card"
          :loading="patientAverageWaiting.loading"
          :time-waiting="patientAverageWaiting.data.timeWaiting"
          :time-appointment="patientAverageWaiting.data.timeAppointment" />
        <CompletedAppointmentsStatisticCard
          class="v-statistic-dashboard__completed-appointments-card"
          :loading="completedAppointments.loading"
          :value="completedAppointments.data.value"
          :percentage="completedAppointments.data.percentage" />
      </div>
      <AppointmentSourcesStatisticCard
        class="v-statistic-dashboard__appointment-sources-card"
        :period="date.value" />
      <ClinicWorkloadStatisticCard
        class="v-statistic-dashboard__clinic-workload-card"
        :period="date.value" />
      <ElCard
        class="v-statistic-dashboard__feedbacks"
        v-loading="feedback.loading"
        :header="$t('Statistic.PatientFeedback')"
        shadow="never">
        <div class="v-statistic-dashboard__feedback">
          <Feedback v-for="item in feedback.data" :item="item" :key="item.id" show-type-feedback />
        </div>
      </ElCard>
    </div>
  </MainLayout>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss" />
