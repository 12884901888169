<template>
  <ElCard
    class="patient-waiting-time-statistic-card"
    v-loading="loading"
    :header="$t('Statistic.PatientWaitingTime')"
    :shadow="shadow">
    <div class="patient-waiting-time-statistic-card__content">
      <div
        class="patient-waiting-time-statistic-card__info patient-waiting-time-statistic-card-info">
        <div class="patient-waiting-time-statistic-card-info__value">{{ valueString }}</div>
        <div class="patient-waiting-time-statistic-card-info__percentage">0</div>
      </div>

      <Vue3Apexcharts
        class="patient-waiting-time-statistic-card__chart"
        type="bar"
        width="150px"
        height="150px"
        :options="chartOptions"
        :series="series"
        ref="chart" />
    </div>

    <div class="patient-waiting-time-statistic-card__legend">
      <div class="patient-waiting-time-statistic-card__legend-item">
        <div class="patient-waiting-time-statistic-card__legend-icon"></div>
        <div class="patient-waiting-time-statistic-card__legend-text">
          {{ $t(`Statistic.TimeSpentWaitingForPatient`) }}
        </div>
      </div>
      <div class="patient-waiting-time-statistic-card__legend-item">
        <div class="patient-waiting-time-statistic-card__legend-icon"></div>
        <div class="patient-waiting-time-statistic-card__legend-text">
          {{ $t(`Statistic.TimeSpentSeeingPatient`) }}
        </div>
      </div>
    </div>
  </ElCard>
</template>

<script>
import Vue3Apexcharts from 'vue3-apexcharts';

export default {
  name: 'PatientWaitingTimeStatisticCard',
  components: { Vue3Apexcharts },
  props: {
    shadow: {
      type: String,
      default: 'never',
    },
    timeWaiting: Number,
    timeAppointment: Number,
    loading: Boolean,
  },

  computed: {
    valueString() {
      if (typeof this.timeWaiting === 'number') {
        const hours = Math.floor(this.timeWaiting / 60);
        const minutes = Math.ceil(this.timeWaiting % 60);
        return `${hours > 0 ? hours : ''}${
          hours > 0 ? this.$t(`Statistic.Hour`) : ''
        } ${minutes}${this.$t(`Statistic.Minute`)}`;
      }

      return `0${this.$t(`Statistic.Minute`)}`;
    },

    series() {
      if (typeof this.timeWaiting === 'number' && typeof this.timeAppointment === 'number') {
        return [
          {
            name: this.$t(`Statistic.Minutes`),
            data: [Math.ceil(this.timeWaiting), Math.ceil(this.timeAppointment)],
          },
        ];
      }
      return [
        {
          name: this.$t(`Statistic.Minutes`),
          data: [0, 0],
        },
      ];
    },

    chartOptions() {
      return {
        chart: {
          width: 150,
          height: 150,
          type: 'bar',
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            columnWidth: '100%',
            distributed: true,
          },
        },
        colors: ['#FEF281', '#54F6E3'],
        dataLabels: { enabled: false },
        legend: {
          show: false,
          position: 'left',
          horizontalAlign: 'left',
        },
        xaxis: {
          categories: [
            this.$t(`Statistic.TimeSpentWaitingForPatient`),
            this.$t(`Statistic.TimeSpentSeeingPatient`),
          ],
          position: 'bottom',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: { enabled: false },
          labels: {
            show: false,
            style: {
              colors: ['#FEF281', '#54F6E3'],
            },
          },
        },
        yaxis: {
          axisBorder: { show: false },
          axisTicks: { show: false },
          labels: { show: false },
        },
      };
    },
  },

  watch: {
    series: {
      handler(value) {
        this.$refs.chart.updateSeries(value);
      },
      deep: true,
    },
    chartOptions: {
      handler(value) {
        this.$refs.chart.updateOptions(value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/statistic.locales.json" />
