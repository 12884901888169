<template>
  <MiDialog
    class="confirm-delete-modal"
    width="1400"
    :title="$t('Bookkeeping.ToAdvance')"
    v-model="visible">
    <div class="advance-payment-modal-form" v-loading="loading.salary">
      <FormSearchSelect
        clearable
        :placeholder="$t('User.FIOEmployee')"
        field-name="user_id"
        label-key="name"
        field-object-name="user"
        :fetch-data="misB2BApi.usersTemp.getList"
        :disabled="readOnly" />

      <EmployeeInfoCard :employee-id="values.user_id" />

      <FormFieldGroupWrapper
        class="advance-payment-modal-form__fields"
        :title="$t('Bookkeeping.AdvanceAmount')">
        <FormTextField
          :label="$t('Bookkeeping.AdvanceAmount')"
          field-name="amount"
          mask-name="int-14"
          :disabled="readOnly"
          required />
        <FormTextField
          :label="$t('Bookkeeping.PayoutComment')"
          field-name="description"
          :disabled="readOnly" />
      </FormFieldGroupWrapper>

      <FormActionsWrapper align="right">
        <MiButton
          @click="handleCreate"
          type="primary"
          plain
          :disabled="createDisabled"
          v-loading="loading.create"
          >{{ $t('Common.Save') }}</MiButton
        >
        <MiButton
          @click="handleOpenConfirmModal"
          type="primary"
          :disabled="paymentDisabled"
          v-loading="loading.payment"
          >{{ $t('Bookkeeping.IssueAnAdvance') }}</MiButton
        >
      </FormActionsWrapper>
    </div>
    <PaymentConfirmModal
      @on-confirm="handlePayment"
      :amount="values.amount"
      v-model="confirmModalVisible" />
  </MiDialog>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useForm } from 'vee-validate';
import { number, object } from 'yup';

import { PaymentConfirmModal } from '../../PaymentConfirmModal';

import { EmployeeInfoCard } from '@/entities/employee';
import { MiButton, MiDialog } from '~shared/ui';
import {
  FormSearchSelect,
  FormTextField,
  FormActionsWrapper,
  FormFieldGroupWrapper,
} from '@/shared/ui/form';
import { CreateDownPaymentRequest, EmployeeFullDto, SalaryResource, misB2BApi } from '~shared/api';
import { amplitudeService, I18nService } from '~shared/lib';
import { ReceiverResource } from '~shared/types';

type FormValues = CreateDownPaymentRequest & {
  id?: SalaryResource['id'];
  status: SalaryResource['status'];
  user: EmployeeFullDto;
};

const props = defineProps<{
  visible: boolean;
  salaryId?: number;
  retriever?: ReceiverResource | null;
}>();

const emit = defineEmits<{
  'update:visible': [event: boolean];
  'update:salaryId': [event: number | undefined];
  'submit:successful': [];
}>();

const visible = computed({
  get() {
    return props.visible;
  },
  set(event) {
    emit('update:visible', event);
  },
});

const salaryId = computed({
  get() {
    return props.salaryId;
  },
  set(event) {
    emit('update:salaryId', event);
  },
});

const confirmModalVisible = ref(false);

const loading = ref({
  salary: false,
  create: false,
  payment: false,
});

const validationSchema = object({
  user_id: number().required(I18nService.t('Validation.RequiredField')),
  amount: number().required(I18nService.t('Validation.RequiredField')),
});

const { values, setValues, handleSubmit, meta, isSubmitting, errors, validate } =
  useForm<FormValues>({
    validationSchema,
  });

const readOnly = computed(() => Boolean(values.id));

const createDisabled = computed(
  () => readOnly.value || !meta.value.dirty || isSubmitting.value || loading.value.create
);
const paymentDisabled = computed(
  () =>
    !meta.value.dirty ||
    Object.keys(errors.value).length > 0 ||
    values.status === 'paid' ||
    isSubmitting.value ||
    loading.value.payment
);

const prevalidate = async () => {
  return await validate();
};
const handleOpenConfirmModal = async () => {
  if (!values.id) {
    const result = await prevalidate();

    if (!result.valid) return;
    await handleCreate(false);
  }

  confirmModalVisible.value = true;
};

const handleCreate = (needUpdateVisibleModal = true) => {
  return handleSubmit(
    async (values) => {
      loading.value.create = true;

      const payload: CreateDownPaymentRequest = {
        user_id: values.user_id,
        amount: values.amount,
        description: values.description,
      };

      const response = await misB2BApi.salaries.createDownPayment(payload);

      loading.value.create = false;

      if (response) {
        amplitudeService.logEvent('create_salary_advance', {
          salary_advance_id: response.data.data.id,
        });
      }

      if (!needUpdateVisibleModal && response) {
        setValues({
          id: response.data.data.id,
          description: response.data.data.description,
        });
        emit('submit:successful');
      } else if (response) {
        emit('update:visible', false);
        emit('submit:successful');
      }
    },
    (ctx) => {
      // eslint-disable-next-line no-console
      console.log('invalid submit', ctx.errors);
    }
  )();
};

const handlePayment = handleSubmit(
  async (values) => {
    if (!values.id) return;

    loading.value.payment = true;

    const response = await misB2BApi.salaries.paidSalary(values.id);

    loading.value.payment = false;

    if (response) {
      amplitudeService.logEvent('pay_salary_advance', {
        salary_advance_id: response.data.data.id,
      });
      emit('update:visible', false);
      emit('submit:successful');
    }
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);

onUnmounted(() => {
  emit('update:salaryId', undefined);
});

onMounted(async () => {
  if (props.retriever) {
    setValues({
      user_id: props.retriever.id,
      user: {
        id: props.retriever.id,
        name: props.retriever.title,
      },
    });
  }

  if (salaryId.value) {
    loading.value.salary = true;

    const response = await misB2BApi.salaries.getSalary(salaryId.value);

    loading.value.salary = false;

    if (response) {
      const salaryData = response.data.data;
      setValues({
        amount: salaryData.amount,
        description: salaryData.description,
        id: salaryData.id,
        user_id: salaryData.receiver.id,
        status: salaryData.status,
        user: {
          id: salaryData.receiver.id,
          name: salaryData.receiver.title,
        },
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.advance-payment-modal-form {
  &__fields {
    margin-top: 22px;

    :global(.form-field-group-wrapper__content) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 16px;
    }
  }
}

.confirm-modal-content {
  text-align: center;

  &__payment-info {
    margin-bottom: 16px;
  }

  &__amount {
    font-size: 32px;
    font-weight: 600;
  }
}
</style>
