import { CRUDModel } from '@/models/CRUD.model';
import { Study } from '@/models/laboratory/Study.model';
import { I18nService } from '~shared/lib';
import { apiService } from '~shared/api';

export class Order extends CRUDModel {
  static modelName = 'order';
  static tableName = 'orders';

  /**
   * @param {object} [payload]
   * @param {number} payload.id
   * @param {string} payload.status
   * @param {Array<number>} payload.biomaterial_ids
   * @param {Array<Biomaterial>} payload.biomaterials
   * @param {number} payload.user_id
   * @param {User|Patient|object} payload.user
   * @param {number} payload.doctor_id
   * @param {Doctor|object} payload.doctor
   * @param {number} payload.invoice_id
   * @param {Invoice|object} payload.invoice
   * @param {string} payload.created_at
   */
  constructor(payload) {
    super(payload);

    this.status = payload?.status ?? null;
    this.biomaterial_ids = payload?.biomaterial_ids ?? [];
    this.biomaterials = payload?.biomaterials ?? [];

    this.user_id = payload?.user_id ?? null;
    this.user = payload?.user ?? null;
    this.doctor_id = payload?.doctor_id ?? null;
    this.doctor = payload?.doctor ?? null;
    this.invoice_id = payload?.invoice_id ?? null;
    this.invoice = payload?.invoice ?? null;
    this.created_at = payload?.created_at ?? null;
  }

  /**
   * @param {number|string} payload
   * @return {Promise<Order|object|null>}
   */
  static async getByBiomaterialBarCodePayload(payload) {
    const response = await super.find({
      biomaterial_id: payload,
    });

    if (!response.data.data.length) throw new Error(I18nService.t('Base.InvalidBarCode'));
    return response.data.data[0];
  }

  /**
   * @param {number} orderId
   * @returns {Promise<{data: response.data, response: AxiosResponse<any>, [this.modelName]: Order|object}>}
   */
  static async rePickStudiesReferencesGroup(orderId) {
    const response = await apiService.api.instance.post(
      `${Study.tableName}/${orderId}/references/reselection`
    );
    return {
      response,
      data: response.data,
      [this.modelName]: response.data.data,
    };
  }

  static enum = {
    statuses: {
      Created: 'created',
      Assemble: 'assemble',
      AwaitingAssemble: 'awaiting_assemble',
      AwaitingConfirm: 'awaiting_confirm',
      NotConfirm: 'not_confirm',
      Ready: 'ready',
      Canceled: 'canceled',
    },
  };
}
