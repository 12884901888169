import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const CHECKUP_TEMPLATE_HEADER_ROUTE = {
  name: 'CheckupHeaderTemplate',
  path: `${ROUTE_PATH.templates.checkupHeaderTemplate.rawPath}/:id?`,
  component: () => import('./CheckupHeaderTemplate/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor', 'nurse'])],
  meta: {
    title: 'Templates.CreatingCheckupHeader',
  },
};
export const CHECKUP_TEMPLATE_HEADER_LIST_ROUTE = {
  name: 'CheckupHeaderTemplateList',
  path: ROUTE_PATH.templates.CheckupHeaderTemplateList,
  component: () => import('./CheckupHeaderTemplateList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor', 'nurse'])],
  meta: {
    title: 'Templates.CheckupHeaders',
  },
};
export const CHECKUP_TEMPLATE_LIST_ROUTE = {
  name: 'CheckupTemplateList',
  path: ROUTE_PATH.templates.checkups,
  component: () => import('./CheckupTemplateList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor', 'nurse'])],
  meta: {
    title: 'Templates.CheckupTemplates',
  },
};
export const CHECKUP_TEMPLATE_EDITOR_ROUTE = {
  name: 'CheckupTemplateEditor',
  path: ROUTE_PATH.templates.checkupEditor.rawPath,
  component: () => import('./CheckupTemplateEditor/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor', 'nurse'])],
  meta: {
    title: 'Templates.CheckupTemplateEditor',
  },
};
