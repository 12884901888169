{
  "en": {
    "Income": {
      "AcceptIncome": "Accept income",
      "AddParish": "Add income",
      "ArrivalDate": "Arrival date",
      "CancelIncome": "Cancel income",
      "CreateIncome": "Create income",
      "Goods": "Goods",
      "Incomes": "Income",
      "Price": "Price",
      "SearchGoods": "Search for goods",
      "Status": "Status",
      "StatusDelivery": "Delivery status",
      "Statuses": {
        "accepted": "Accepted",
        "canceled": "Cancelled",
        "created": "Created"
      },
      "Supplier": "Supplier",
      "Warehouse": "Warehouse",
      "WriteOffWarehouse": "Write-off warehouse"
    },
    "Nomenclature": {
      "Actions": "Actions",
      "AmountSum": "Amount (sum)",
      "Edit": "Edit",
      "ExpectedQty": "Expected quantity",
      "Name": "Name",
      "NameOfProduct": "Product name",
      "NextDeliveryDate": "Date of next delivery",
      "Nomenclature": "Nomenclature",
      "Notes": "Notes",
      "ProductCategory": "Product category",
      "ProductCost": "Product cost",
      "Qty": "Quantity",
      "QuantityReceived": "Quantity received",
      "SellingPrice": "Selling price",
      "VendorCode": "Vendor code"
    },
    "Outcome": {
      "AddOutcome": "Add outcome",
      "CreateOutcome": "Create outcome",
      "Date": "Date",
      "Expenses": "Expenses",
      "FlowType": "Flow type",
      "Name": "Name",
      "Outcome": "Outcome",
      "OutcomeType": "Outcome type",
      "Remainder": "Remainder",
      "Sale": "Sale",
      "WriteOff": "Write off"
    },
    "Product": {
      "AddProduct": "Add product",
      "CreateProduct": "Create product",
      "EditProduct": "Edit product",
      "GoodsStock": "Goods in stock",
      "ProductSearch": "Search for a product"
    },
    "Supplier": {
      "AddSupplier": "Add supplier",
      "ContactPerson": "Contact Person",
      "EditSupplier": "Edit supplier",
      "FullNameVendorNumber": "Full name or supplier number",
      "NameSupplier": "Supplier name",
      "PhoneNumber": "Phone number",
      "SupplierInformation": "Supplier information",
      "Suppliers": "Suppliers"
    },
    "TransferGoods": {
      "DescriptionGoods": "Goods name",
      "FromStock": "From warehouse",
      "GoodsTransfer": "Goods for transfer",
      "HandOver": "Transfer",
      "ProductSearchNameArticle": "Search for goods by Name or SKU",
      "SelectFromWarehouse": "Select the warehouse to which you want to transfer the goods",
      "SelectToWarehouse": "Select the warehouse from which you want to transfer the goods",
      "ToWarehouse": "To warehouse",
      "TransferGoods": "Goods transfer",
      "TransferGoodsWarehouse": "Goods transfer from warehouse"
    },
    "Warehouse": {
      "AddWarehouse": "Add warehouse",
      "Address": "Address",
      "CreateWarehouse": "Create warehouse",
      "EditWarehouse": "Edit warehouse",
      "Warehouse": "Warehouse",
      "Warehouses": "Warehouses"
    }
  },
  "ru": {
    "Income": {
      "AcceptIncome": "Принять приход",
      "AddParish": "Добавить приход",
      "ArrivalDate": "Дата прихода",
      "CancelIncome": "Отменить",
      "CreateIncome": "Создать приход",
      "Goods": "Товары",
      "Incomes": "Приходы",
      "Price": "Стоимость",
      "SearchGoods": "Поиск товаров",
      "Status": "Статус",
      "StatusDelivery": "Статус доставки",
      "Statuses": {
        "accepted": "Принят",
        "canceled": "Отменен",
        "created": "В ожидании"
      },
      "Supplier": "Поставщик",
      "Warehouse": "Склад",
      "WriteOffWarehouse": "Склад списания"
    },
    "Nomenclature": {
      "Actions": "Действия",
      "AmountSum": "Сумма (сум)",
      "Edit": "Редактировать",
      "ExpectedQty": "Ожидаемое кол-во",
      "Name": "Наименование",
      "NameOfProduct": "Наименование товара",
      "NextDeliveryDate": "Дата следующей поставки",
      "Nomenclature": "Номенклатура",
      "Notes": "Заметки",
      "ProductCategory": "Категория товара",
      "ProductCost": "Стоимость продукта",
      "Qty": "Кол-во",
      "QuantityReceived": "Полученное кол-во",
      "SellingPrice": "Цена реализации",
      "VendorCode": "Артикул"
    },
    "Outcome": {
      "AddOutcome": "Добавить расход",
      "CreateOutcome": "Создать расход",
      "Date": "Дата",
      "Expenses": "Расходы",
      "FlowType": "Тип расхода",
      "Name": "Наименование",
      "Outcome": "Кол-во расхода",
      "OutcomeType": "Тип расхода",
      "Remainder": "Остаток",
      "Sale": "Продажа",
      "WriteOff": "Списание"
    },
    "Product": {
      "AddProduct": "Добавить товар",
      "CreateProduct": "Создать товар",
      "EditProduct": "Редактировать товар",
      "GoodsStock": "Товары в наличии",
      "ProductSearch": "Поиск товара"
    },
    "Supplier": {
      "AddSupplier": "Добавить поставщика",
      "ContactPerson": "Контактное лицо",
      "EditSupplier": "Редактировать поставщика",
      "FullNameVendorNumber": "Полное имя или номер поставщика",
      "NameSupplier": "Наименование поставщика",
      "PhoneNumber": "Номер телефона",
      "SupplierInformation": "Информация о поставщике",
      "Suppliers": "Поставщики"
    },
    "TransferGoods": {
      "DescriptionGoods": "Наименование товаров",
      "FromStock": "Со склада",
      "GoodsTransfer": "Товары для передачи",
      "HandOver": "Передать",
      "ProductSearchNameArticle": "Поиск товара по наименованию или артикулу",
      "SelectFromWarehouse": "Выберите склад на который хотите передать товар",
      "SelectToWarehouse": "Выберите склад с которого хотите передать товар",
      "ToWarehouse": "На склад",
      "TransferGoods": "Передача товаров",
      "TransferGoodsWarehouse": "Передача товаров со склада"
    },
    "Warehouse": {
      "AddWarehouse": "Добавить склад",
      "Address": "Адрес",
      "CreateWarehouse": "Создать склад",
      "EditWarehouse": "Редактировать склад",
      "Warehouse": "Склад",
      "Warehouses": "Склады"
    }
  },
  "uz": {
    "Income": {
      "AcceptIncome": "Kirimni qabul qilish",
      "AddParish": "Kirim qo'shish",
      "ArrivalDate": "Kirim sanasi",
      "CancelIncome": "Bekor qilish",
      "CreateIncome": "Kirimni yaratish",
      "Goods": "Mahsulotlar",
      "Incomes": "Kirimlar",
      "Price": "Narxi",
      "SearchGoods": "Mahsulotlarni qidirish",
      "Status": "Statusi",
      "StatusDelivery": "Yetkazib berish statusi ",
      "Statuses": {
        "accepted": "Qabul qilindi",
        "canceled": "Bekor qilindi",
        "created": "Kutilmoqda"
      },
      "Supplier": "Yetkazib beruvchi",
      "Warehouse": "Ombor",
      "WriteOffWarehouse": "Omborni chiqarish"
    },
    "Nomenclature": {
      "Actions": "Amallar",
      "AmountSum": "Summa (so'm)",
      "Edit": "Tahrirlash",
      "ExpectedQty": "Kutilayotgan miqdori",
      "Name": "Nomi",
      "NameOfProduct": "Mahsulot nomi",
      "NextDeliveryDate": "Keyingi yetkazib berish sanasi",
      "Nomenclature": "Nomenklatura",
      "Notes": "Eslatma",
      "ProductCategory": "Mahsulot kategoriyasi",
      "ProductCost": "Mahsulot narxi",
      "Qty": "Miqdori",
      "QuantityReceived": "Qabul qilingan miqdori",
      "SellingPrice": "Narxi",
      "VendorCode": "Kod"
    },
    "Outcome": {
      "AddOutcome": "Xarajatlarni qo'shish",
      "CreateOutcome": "Chiqimni yaratish",
      "Date": "Sana",
      "Expenses": "Chiqimlar",
      "FlowType": "Chiqim turi",
      "Name": "Nomi",
      "Outcome": "Chiqim miqdori",
      "OutcomeType": "Natija turi",
      "Remainder": "Qoldiq",
      "Sale": "Sotish",
      "WriteOff": "Chiqim"
    },
    "Product": {
      "AddProduct": "Mahsulotni qo’shish",
      "CreateProduct": "Mahsulotni yaratish",
      "EditProduct": "Mahsulotni tahrirlash",
      "GoodsStock": "Sotuvda mavjud mahsulotlar",
      "ProductSearch": "Mahsulot qidirish"
    },
    "Supplier": {
      "AddSupplier": "Yetkazib beruvchini qo’shish",
      "ContactPerson": "Aloqa qiluvchi shaxs",
      "EditSupplier": "Yetkazib beruvchini tahrirlash",
      "FullNameVendorNumber": "Yetkazib beruvchi nomi yoki raqami",
      "NameSupplier": "Yetkazib beruvchi nomi",
      "PhoneNumber": "Telefon raqami ",
      "SupplierInformation": "Yetkazib beruvchi haqida ma'lumot",
      "Suppliers": "Yetkazib beruvchilar"
    },
    "TransferGoods": {
      "DescriptionGoods": "Mahsulot nomi",
      "FromStock": "Ombor dan",
      "GoodsTransfer": "O'tkazish uchun mahsulotlar",
      "HandOver": "O'tkazish",
      "ProductSearchNameArticle": "Nomi yoki SKU bo'yicha mahsulot qidirish",
      "SelectFromWarehouse": "Mahsulotlarni o'tkazmoqchi bo'lgan omborni tanlang",
      "SelectToWarehouse": "Mahsulotlarni o'tkazmoqchi bo'lgan omborni tanlang",
      "ToWarehouse": "Omborga",
      "TransferGoods": "Mahsulotlar o'tkazishi",
      "TransferGoodsWarehouse": "Ombor dan mahsulotlarni o'tkazish"
    },
    "Warehouse": {
      "AddWarehouse": "Omborni qo’shish",
      "Address": "Manzil",
      "CreateWarehouse": "Omborni yaratish",
      "EditWarehouse": "Omborni tahrirlash",
      "Warehouse": "Ombor",
      "Warehouses": "Omborlar"
    }
  }
}