<template>
  <MainLayout content-class="v-appointments" fix-height>
    <LayoutContentHeader class="v-appointments__header">
      <ElDatePicker
        v-model="date"
        class="v-appointments__datepicker"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <PatientsSearchSelect
        class="v-cash-content-header__user"
        :model-value="patient.value.id"
        :default-item="patientFromRoute"
        :placeholder="$t('Patients.SelectPatient')"
        clearable
        only-icon
        @select="patient.value = $event" />

      <!-- !user.doctor_id - типа не валидно теперь, из-за добавления user: useUser() -->
      <UiModelsAutocompleteSearch
        v-show="!user?.doctor_id"
        :model-value="doctor.value.id"
        :model-for-use="Doctor"
        :default-item="doctor.value.id ? doctor.value : null"
        clearable
        :placeholder="$t('Appointments.SelectDoctor')"
        @select="doctor.value = $event" />

      <MiSelectWithOption
        v-if="currentAppointmentList === 'active'"
        :options="statusOptions"
        v-model="status.value"
        :placeholder="$t('Common.Status')"
        multiple />

      <template #actions>
        <a :href="exportDataURL" download @click="sendStatisticDownload">
          <ElButton plain text>
            <template #icon>
              <MiIcon icon="DOWNLOAD" />
            </template>
            {{ $t('Base.Download') }}
          </ElButton>
        </a>
        <ElButton type="primary" @click="createAppointment">
          {{ $t('Appointments.CreateAppointment') }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <AppointmentsTable
      :items="items"
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :total="total"
      :exclude-columns="excludeColumns"
      @item:edit="editItem" />
  </MainLayout>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import noop from 'lodash.noop';

import { MiIcon, MiSelectWithOption } from '~shared/ui';
import {
  usePage,
  usePerPage,
  useUser,
  useQuery,
  useGetDataByInterval,
  authStorageService,
  I18nService,
  DeviceService,
  amplitudeService,
} from '~shared/lib';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { getCurrentDate, ISOStringToDateAppFormat } from '@/utils/dateAndTime.utils';
import { Appointment } from '@/models/appointment/Appointment.model.js';
import { User } from '@/models/User.model';
import { Doctor } from '@/models/Doctor.model';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { DATE_FORMAT } from '~shared/config';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import AppointmentsTable from '@/components/appointments/AppointmentsTable/index.vue';
import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import { MainLayout } from '~widgets/layouts';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import PatientsSearchSelect from '@/components/patients/PatientsSearchSelect/index.vue';
import { useSessionStore } from '~entities/session';

export default {
  name: 'VAppointments',
  components: {
    PatientsSearchSelect,
    LayoutContentHeader,
    MainLayout,
    AppointmentsTable,
    MiIcon,
    MiSelectWithOption,
  },
  setup: () => ({
    getDataByInterval: noop,

    perPage: usePerPage(),
    page: usePage(),
    startAt: useQuery({
      field: 'start_at',
      defaultValue: ISOStringToDateAppFormat(new Date().toISOString()),
    }),
    endAt: useQuery({
      field: 'end_at',
      defaultValue: ISOStringToDateAppFormat(new Date().toISOString()),
    }),
    status: useQuery({ field: 'status', valueIsArray: true }),
    patient: useUser(),
    doctor: useUser({
      fieldNames: {
        id: 'doctor_id',
        name: 'doctor_name',
      },
    }),

    Doctor,
    DATE_FORMAT,
  }),
  data() {
    return {
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.appointments.loading,
      items: (state) => state.appointments.data,
      total: (state) => state.appointments.total,
    }),

    user() {
      return this.sessionStore.user;
    },

    patientFromRoute() {
      return this.patient.value.id && this.patient.value.name ? { ...this.patient.value } : null;
    },

    queryWatchers() {
      return {
        per_page: this.perPage.value,
        page: this.page.value,
        start_at: this.startAt.value,
        end_at: this.endAt.value,
        query_field: this.doctor.value?.id ? 'doctor_id' : null,
        user_id: this.patient.value.id,
        search: this.user?.doctor_id ?? this.doctor.value.id ?? null,
        statuses: this.status.value,
      };
    },

    currentAppointmentList() {
      switch (this.$route.name) {
        case 'Appointments':
          return 'active';
        case 'AppointmentsProvided':
          return 'provided';
        case 'AppointmentsCancelled':
          return 'cancelled';
        default:
          return 'active';
      }
    },

    statusOptions() {
      return [
        {
          value: 'waiting',
          label: I18nService.t(`Appointments.Statuses.waiting`),
        },
        {
          value: 'approved',
          label: I18nService.t(`Appointments.Statuses.approved`),
        },
        {
          value: 'in_progress',
          label: I18nService.t(`Appointments.Statuses.in_progress`),
        },
      ];
    },

    exportDataURL() {
      return mergeOrCreateQuery({
        url: Appointment.exportDataURL,
        query: deleteEmptyValueKeys({
          ...this.queryWatchers,
          lang: I18nService.getLocale(),
          token: authStorageService.getAccessToken(),
          device_id: DeviceService.getDeviceId(),
        }), // per_page, page будут игнорироваться на бэке
      });
    },

    date: {
      get() {
        return [this.startAt.value, this.endAt.value];
      },
      set(value) {
        this.startAt.value = value ? value[0] : null;
        setTimeout(() => (this.endAt.value = value ? value[1] : null));
      },
    },

    excludeColumns() {
      return this.user?.role === User.enum.roles.Doctor
        ? ['doctor', 'end_at', 'patient.phone']
        : ['services'];
    },
  },

  watch: {
    queryWatchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          resetPage: this.page.reset,
          getData: this.getDataByInterval,
          fieldsForResetPage: ['start_at', 'end_at', 'statuses'],
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      setLoading: 'appointments/setLoading',
      setData: 'appointments/setData',
      createItem: 'appointments/createItem',
      editItem: 'appointments/editItem',
    }),

    sendStatisticDownload() {
      amplitudeService.logEvent('appointments.download_excel');
    },

    async getAppointments() {
      this.setLoading(true);

      try {
        const { data } = await Appointment.find(this.queryWatchers);
        this.setData({
          items: data.data,
          total: +data.meta.total,
          overwriteDataState: true,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.setLoading(false);
    },

    async createAppointment() {
      amplitudeService.logEvent('open_modal_create_appointment');

      const action = await this.$store.dispatch(
        'modalAndDrawer/openModal',
        CreateOrEditAppointmentModal
      );

      if (action instanceof GlobalModalClose) return;

      const currentDate = getCurrentDate();
      action.data.appointments.forEach((appointment) => {
        if (!appointment.start_at || appointment.start_at.split(' ')[0] === currentDate)
          this.createItem(appointment);
      });
    },
  },

  mounted() {
    this.getDataByInterval = useGetDataByInterval(this.getAppointments);
    this.getDataByInterval();
  },
};
</script>

<style lang="scss" src="./index.scss" />
