import { insertRouteParams } from '@/utils/router.utils';
import { formatPrice } from '@/utils/price.util';
import { APPOINTMENT_ROUTE } from '@/router/appointments.routes';
import { NOT_REDIRECT, ROUTE_PATH } from '~shared/config';
import { User } from '@/models/User.model';
import { Appointment } from '@/models/appointment/Appointment.model';
import AppointmentStatusTag from '@/components/appointments/AppointmentStatusTag/index.vue';
import { useSessionStore } from '~entities/session';
import { amplitudeService } from '~shared/lib';

export default {
  name: 'VAppointmentDefaultCard',
  components: { AppointmentStatusTag },
  emits: ['update:appointment', 'status:update', 'appointment:edit'],
  props: {
    appointment: {
      type: [Appointment, Object],
      default: () => new Appointment(),
    },
    loading: Object, // object with appointment statuses keys
  },
  data() {
    return {
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },

    isManager() {
      return this.user.role === User.enum.roles.Manager;
    },
    isDoctor() {
      return this.user.role === User.enum.roles.Doctor;
    },

    patientPageLink() {
      if (this.appointment?.patient_id) {
        return ROUTE_PATH.patients.medicalCard.byId(this.appointment?.patient_id);
      }
    },

    infoItems() {
      return [
        {
          label: this.$t('Appointments.StartDate'),
          value: Appointment.getStartDate(this.appointment.start_at || ''),
        },
        {
          label: this.$t('Appointments.StartTime'),
          value: Appointment.getStartTime(this.appointment.start_at || ''),
        },
        {
          label: this.$t('Base.Doctor'),
          value: this.appointment.doctor?.name,
        },
        {
          label: this.$t('Base.Payment'),
          value: this.appointment.invoice?.status
            ? this.$t(`Invoices.Statuses.${this.appointment.invoice?.status}`)
            : '...',
        },
        {
          label: this.$t('Base.StatePrice'),
          value:
            formatPrice({
              price: this.appointment.services.reduce((total, obj) => obj.price + total, 0),
            }) +
            ' ' +
            this.$t('Base.Sum'),
        },
      ];
    },

    groupServicesWithFormattedCost() {
      return this.appointment.group_services_with_count.map((group) => ({
        title: `${group.group_service.title} (${group.count})`,
        count: group.count,
        price: `${formatPrice({ price: group.group_service.price })} ${this.$t('Base.Sum')}`,
      }));
    },

    actionsOptions() {
      return {
        callToReception: {
          isShow:
            this.appointment.status === Appointment.enum.statuses.Approved &&
            (this.isManager || this.appointment.doctor_id === this.user?.doctor_id),
        },
        start: {
          isShow:
            this.appointment.status === Appointment.enum.statuses.Waiting &&
            (this.isManager || this.appointment.doctor_id === this.user?.doctor_id),
        },
        cancel: {
          isShow:
            [Appointment.enum.statuses.Approved, Appointment.enum.statuses.Waiting].includes(
              this.appointment.status
            ) &&
            (this.isManager || this.appointment.doctor_id === this.user?.doctor_id),
        },
        end: {
          isShow:
            this.appointment.status === Appointment.enum.statuses.InProgress &&
            (this.isManager || this.appointment.doctor_id === this.user?.doctor_id),
        },
        reOpen: {
          isShow:
            this.appointment.status === Appointment.enum.statuses.Canceled &&
            (this.isManager || this.appointment.doctor_id === this.user?.doctor_id),
        },

        edit: {
          isShow:
            ![Appointment.enum.statuses.Canceled, Appointment.enum.statuses.Provided].includes(
              this.appointment.status
            ) && this.appointment.permissions.editable,
        },
        goToInspectionCard: {
          isShow:
            [User.enum.roles.Doctor, User.enum.roles.Laboratory] &&
            this.appointment.status === Appointment.enum.statuses.Provided,
        },
      };
    },
  },

  watch: {
    'appointment.id': {
      handler() {
        this.redirectToInspectionCardIfNeeded();
      },
      immediate: true,
    },
  },

  methods: {
    goToInspectionCard() {
      this.$router.replace(
        insertRouteParams({
          path: APPOINTMENT_ROUTE.childrenMap.APPOINTMENT_ROUTE_INSPECTION_CARD._fullPath,
          params: { id: this.appointment.id },
        })
      );
    },

    redirectToInspectionCardIfNeeded() {
      if (
        this.isDoctor &&
        this.user.doctor_id === this.appointment.doctor_id &&
        [Appointment.enum.statuses.InProgress, Appointment.enum.statuses.Provided].includes(
          this.appointment.status
        ) &&
        !this.$route.query[NOT_REDIRECT]
      )
        this.goToInspectionCard();
    },

    startReception() {
      this.$emit('status:update', Appointment.enum.statuses.InProgress);
      amplitudeService.logEvent('start_appointment', {
        patient_id: this.appointment.patient_id,
        appointment_id: this.appointment.id,
      });
    },

    callToReception() {
      this.$emit(
        'status:update',
        this.isManager ? Appointment.enum.statuses.InProgress : Appointment.enum.statuses.Waiting
      );
      amplitudeService.logEvent('call_patient_on_appointment', {
        patient_id: this.appointment.patient_id,
        appointment_id: this.appointment.id,
      });
    },

    endReception() {
      this.$emit('status:update', Appointment.enum.statuses.Provided);
      amplitudeService.logEvent('close_appointment', {
        patient_id: this.appointment.patient_id,
        appointment_id: this.appointment.id,
      });
    },
  },

  setup: () => ({
    Appointment,
    User,
  }),
};
