{
  "en": {
    "GoToDefaultInspectionCard": "Go to inspection card"
  },
  "ru": {
    "GoToDefaultInspectionCard": "Перейти в карту осмотра"
  },
  "uz": {
    "GoToDefaultInspectionCard": "Tekshirish kartasiga otish"
  }
}