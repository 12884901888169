import { useModel } from './useModel';

type UseUserParams = {
  defaultValue?: {
    id: string;
    name: string;
  };
  fieldNames: {
    id: string;
    name: string;
  };
};

const enums = {
  USER_ID: 'user_id',
  USER_NAME: 'user_name',
};

export function useUser(params?: UseUserParams) {
  return useModel({
    defaultValue: params?.defaultValue,
    fieldNames: {
      id: params?.fieldNames?.id ?? enums.USER_ID,
      name: params?.fieldNames?.name ?? enums.USER_NAME,
    },
    modelKeys: {
      valueKey: 'id',
      labelKey: 'name',
    },
  });
}
