<template>
  <div class="v-recommendation">
    <LayoutContentHeader>
      <ElPageHeader
        class="v-app-ins-card-header"
        :title="$t('Base.Back')"
        @back="goToAppointmentInspectionCard">
        <template #content>
          {{ $t('Recommendations.AddServices') }}
        </template>
      </ElPageHeader>
    </LayoutContentHeader>
    <PatientCardRow :patient="appointment.patient" :items="patientCardItems">
      <template #actions>
        <router-link :to="patientAmbulatoryCardPageLink">
          <ElButton type="primary"> {{ $t('Base.AmbulatoryCard') }}</ElButton>
        </router-link>
      </template>
    </PatientCardRow>

    <RecommendationForm class="v-recommendation__form" :appointment="appointment" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

import { Appointment } from '@/models/appointment/Appointment.model.js';
import { Doctor } from '@/models/Doctor.model.js';
import { ROUTE_PATH } from '~shared/config';
import PatientCardRow from '@/components/patients/PatientCardRow/index.vue';
import RecommendationForm from '@/components/appointments/RecommendationForm/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';

export default {
  name: 'VRecommendation',
  components: {
    LayoutContentHeader,
    PatientCardRow,
    RecommendationForm,
  },
  props: {
    appointment: [Appointment, Object],
    patientCardItems: Array,
    patientAmbulatoryCardPageLink: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      setAppointedServices: 'recommendations/setAppointedServices',
      setFavorites: 'recommendations/setFavorites',
    }),
    goToAppointmentInspectionCard() {
      this.$router.push(ROUTE_PATH.appointments.appointment.byId(this.appointment.id));
    },
    async getFavorites() {
      this.loading = true;
      try {
        const { data } = await Doctor.getFavoritesServices();
        this.setFavorites(data.data);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.setAppointedServices(this.appointment.recommendation.group_services);
    this.getFavorites();
  },
  setup: () => ({
    Doctor,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/recommendations.locales.json" />
<i18n src="./index.locales.json" />
