import { ElNotification } from 'element-plus';

import { CRUDModel } from '@/models/CRUD.model';
import { apiService } from '~shared/api';
import { getApiErrorMessage, mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';

/**
 * @class InspectionCardTemplate
 * @extends InspectionCard
 */
export class InspectionCardTemplate extends CRUDModel {
  static modelName = 'template';
  static tableName = 'templates';

  /**
   * @typedef {InspectionCard|object} InspectionCardTemplateConstructorPayload
   * @property {string} type
   * @property {string} title
   * @property {Array<number>} doctor_ids
   * @property {Array<Doctor>} doctors
   * @property {Array<InspectionCardCategory|InspectionCardBlock|object>} basic_data
   */
  /** @param {InspectionCardTemplateConstructorPayload|object} [payload] */
  constructor(payload) {
    super(payload);

    this.type = payload?.type || InspectionCardTemplate.enum.types.Tiny;
    this.title = payload?.title ?? '';
    this.doctor_ids = payload?.doctor_ids ?? [];
    this.doctors = payload?.doctors ?? [];
    this.basic_data = payload?.basic_data ?? '';
  }

  // TODO: копипаст с InspectionCard.model
  static enum = {
    types: {
      Default: 'default', // old realization
      Editor: 'editor', // use @editorjs/editorjs
      Tiny: 'tiny_mce',
    },
  };

  static async getItems(query) {
    try {
      const response = await apiService.api.instance.get(
        mergeOrCreateQuery({
          url: `${this.tableName}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        data: response.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
}
