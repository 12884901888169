<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="product.article" :label="$t('Nomenclature.VendorCode')" />
    <ElTableColumn prop="product.title" :label="$t('Outcome.Name')" />
    <ElTableColumn prop="count_outcome" :label="$t('Outcome.Outcome')" />
    <ElTableColumn prop="outcome_date" :label="$t('Outcome.Date')" />
    <ElTableColumn prop="left_count" :label="$t('Outcome.Remainder')" />
    <ElTableColumn prop="warehouse.title" :label="$t('Income.Warehouse')" />
    <ElTableColumn prop="type" :label="$t('Outcome.FlowType')">
      <template #default="{ row }">
        {{ row.type === 'sale' ? $t('Outcome.Sale') : $t('Outcome.WriteOff') }}
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
import { PropType } from 'vue';

import { OutcomeDto } from '@/types/api';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

export default {
  name: 'OutcomesTable',
  components: {
    UiTableWithPagination,
  },
  emits: ['update:page', 'update:perPage', 'modal:open'],
  props: {
    loading: { type: Boolean, required: true },
    items: { type: Array as PropType<OutcomeDto[]>, required: true },
    page: { type: Number, required: true },
    perPage: { type: Number, required: true },
    total: { type: Number, required: true },
  },
};
</script>

<style lang="scss" scoped></style>
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
