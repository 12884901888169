import axios from 'axios';
import { ElNotification } from 'element-plus';

import { CRUDModel } from '@/models/CRUD.model';
import { apiService } from '~shared/api';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { QueryParams } from '@/types/common';
import {
  ExpenseDto,
  ExpenseRequest,
  ExpenseTypeDto,
  ExpenseTypeRequest,
  MetaDto,
} from '@/types/api';

export type ExpenseFormData = Pick<
  ExpenseDto,
  'accepted_at' | 'expense_date' | 'recipient' | 'status' | 'auto_accept'
> & {
  id?: ExpenseDto['id'] | null;
  expense_type: ExpenseDto['expense_type'] | null;
  amount: ExpenseDto['amount'] | null;
  expense_type_id?: number | null;
};

export class Expense extends CRUDModel {
  static modelName = 'expense';
  static tableName = 'expenses';

  accepted_at: ExpenseFormData['accepted_at'];
  expense_date: ExpenseFormData['expense_date'];
  recipient: ExpenseFormData['recipient'];
  expense_type: ExpenseFormData['expense_type'];
  amount: ExpenseFormData['amount'];
  status: ExpenseFormData['status'];
  auto_accept: ExpenseFormData['auto_accept'];
  expense_type_id: ExpenseFormData['expense_type_id'];

  constructor(payload?: ExpenseFormData) {
    super(payload);

    this.accepted_at = payload?.accepted_at ?? '';
    this.expense_date = payload?.expense_date ?? '';
    this.recipient = payload?.recipient ?? '';
    this.expense_type = payload?.expense_type ?? null;
    this.expense_type_id = payload?.expense_type?.id ?? null;
    this.amount = payload?.amount ?? null;
    this.status = payload?.status ?? 'created';
    this.auto_accept = payload?.auto_accept ?? false;
  }

  static async getItems(query: QueryParams) {
    try {
      const response = await apiService.api.instance.get<{ data: ExpenseDto[]; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `${this.tableName}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async getItemById(id: number) {
    try {
      const response = await apiService.api.instance.get<{ data: ExpenseDto }>(
        `${this.tableName}/${id}`
      );

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async createItem(payload: ExpenseRequest) {
    try {
      const response = await apiService.api.instance.post<{ data: ExpenseDto }>(
        `${this.tableName}/create`,
        payload
      );

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async acceptItem(id: number) {
    const response = await apiService.api.instance.put<{
      data: ExpenseDto;
    }>(`${this.tableName}/${id}/accepted`);
    return {
      response: response,
      data: response.data.data,
    };
  }

  static async cancelItem(id: number) {
    const response = await apiService.api.instance.put<{ data: ExpenseDto }>(
      `${this.tableName}/${id}/canceled`
    );
    return {
      response: response,
      data: response.data.data,
    };
  }

  static async getExpenseTypes(query: QueryParams) {
    try {
      const response = await apiService.api.instance.get<{ data: ExpenseTypeDto[] }>(
        mergeOrCreateQuery({
          url: `${this.tableName}/types`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }

  static async createExpenseType(payload: ExpenseTypeRequest) {
    try {
      const response = await apiService.api.instance.post<{ data: ExpenseTypeDto }>(
        `${this.tableName}/types/create`,
        payload
      );

      return {
        response: response,
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: axios.isAxiosError(err) ? err.message : String(err),
      });
    }
  }
}
