<template>
  <MainLayout>
    <LayoutContentHeader>
      <ElDatePicker
        v-model="datePeriod.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <SelectOrderStatus v-model="status.value" clearable />

      <PatientsSearchSelect
        :model-value="patient.value.id"
        :default-item="patient.value?.id ? patient.value : null"
        clearable
        only-icon
        @select="patient.value = $event" />

      <ScanBiomaterialBarCode @scan:success="biomaterialSuccessScanHandler" />

      <template #actions>
        <ElButton type="primary" @click="createAppointment">
          {{ $t('Appointments.CreateAppointment') }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <OrdersTable
      :items="data"
      :loading="loading"
      v-model:per-page="perPage.value"
      v-model:page="page.value"
      :total="total" />
  </MainLayout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import noop from 'lodash.noop';

import { DATE_FORMAT } from '~shared/config';
import { LABORATORY_ORDER_ROUTE } from '@/router/laboratory.routes';
import {
  useUser,
  useDatePeriod,
  usePerPage,
  usePage,
  useQuery,
  useGetDataByInterval,
  amplitudeService,
} from '~shared/lib';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { Order } from '@/models/laboratory/Order.model';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { MainLayout } from '~widgets/layouts';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import PatientsSearchSelect from '@/components/patients/PatientsSearchSelect/index.vue';
import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import SelectOrderStatus from '@/components/laboratory/SelectOrderStatus/index.vue';
import OrdersTable from '@/components/laboratory/OrdersTable/index.vue';
import ScanBiomaterialBarCode from '@/components/scanner/ScanBiomaterialBarCode/index.vue';

export default {
  name: 'VOrders',
  components: {
    ScanBiomaterialBarCode,
    OrdersTable,
    SelectOrderStatus,
    PatientsSearchSelect,
    LayoutContentHeader,
    MainLayout,
  },
  setup: () => ({
    perPage: usePerPage(),
    page: usePage(),
    datePeriod: useDatePeriod(),
    status: useQuery({
      field: 'status',
    }),
    patient: useUser(),

    getDataByInterval: noop,
    DATE_FORMAT,
  }),
  computed: {
    ...mapState({
      data: (state) => state.orders.data,
      total: (state) => state.orders.total,
      loading: (state) => state.orders.loading,
    }),

    queryWatchers() {
      return {
        per_page: this.perPage.value,
        page: this.page.value,
        start_at: this.datePeriod.value[0],
        end_at: this.datePeriod.value[1],
        status: this.status.value,
        user_id: this.patient.value.id,
      };
    },
  },
  watch: {
    'queryWatchers': {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          getData: this.getDataByInterval,
          resetPage: this.page.reset,
          fieldsForResetPage: ['status', 'start_at', 'patient_id'],
        });
      },
      deep: true,
    },
    'datePeriod.value'(val, old) {
      if (JSON.stringify(val) !== JSON.stringify(old)) {
        amplitudeService.logEvent('laboratory_orders.change_date_rage');
      }
    },
    'status.value'(val, old) {
      if (val !== old) {
        amplitudeService.logEvent('laboratory_orders.change_status');
      }
    },
    'patient.value.id'(val, old) {
      if (val !== old) {
        amplitudeService.logEvent('laboratory_orders.change_patient');
      }
    },
  },

  methods: {
    ...mapActions({
      setLoading: 'orders/setLoading',
      setData: 'orders/setData',
    }),

    async getOrders() {
      this.setLoading(true);

      const { data } = await Order.find(this.queryWatchers);
      this.setData({
        items: data.data,
        total: data.meta.total,
        overwriteDataState: true,
      });

      this.setLoading(false);
    },

    biomaterialSuccessScanHandler(order) {
      this.sendAmplitudeScanBarcodeLaboratory(
        order?.id,
        order.user?.id,
        order.biomaterials.map((item) => item.id)
      );
      this.$router.push({
        name: LABORATORY_ORDER_ROUTE.name,
        params: {
          id: order.id,
        },
      });
    },

    async createAppointment() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditAppointmentModal,
        payload: {
          disableDefaultAction: true,
        },
      });

      if (!(action instanceof GlobalModalClose)) this.getDataByInterval();
    },

    sendAmplitudeScanBarcodeLaboratory(orderId, patientId, biomaterialIds) {
      amplitudeService.logEvent('scan_barcode_laboratory', {
        order_id: orderId,
        patient_id: patientId,
        biomaterial_id: biomaterialIds,
      });
    },
  },

  mounted() {
    this.getDataByInterval = useGetDataByInterval(this.getOrders);
    this.getDataByInterval();
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
