<template>
  <MiDialog class="confirm-delete-modal" width="540" :title="modalTitle" v-model="modelValue">
    <div class="confirm-delete-modal__body">
      <MiCard shadow="never">
        <h1 v-if="!$slots.default">{{ bodyTitle }}</h1>
        <slot v-else />
      </MiCard>
      <div
        class="confirm-delete-modal__footer"
        :class="{
          'confirm-delete-modal__footer-right': footerAlign === 'right',
          'confirm-delete-modal__footer-left': footerAlign === 'left',
          'confirm-delete-modal__footer-center': footerAlign === 'center',
        }">
        <template v-if="!$slots.footer">
          <MiButton @click="handleBtnCancel" type="danger" plain> {{ $t('Base.Cancel') }}</MiButton>
          <MiButton @click="$emit('onConfirm')" :type="btnType">
            {{ btnTitle ? btnTitle : $t('Base.Delete') }}</MiButton
          >
        </template>
        <slot v-else name="footer"></slot>
      </div>
    </div>
  </MiDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ButtonProps } from 'element-plus';

import { MiButton, MiCard, MiDialog } from '~shared/ui';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    modalTitle: string;
    bodyTitle?: string;
    btnTitle?: string;
    btnType?: ButtonProps['type'];
    footerAlign?: 'left' | 'right' | 'center';
  }>(),
  { modelValue: false, btnType: 'primary', footerAlign: 'center' }
);

const emits = defineEmits<{
  'update:modelValue': [event: boolean];
  'onConfirm': [];
}>();

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(event) {
    emits('update:modelValue', event);
  },
});

const handleBtnCancel = () => {
  emits('update:modelValue', false);
};
</script>

<style lang="scss" src="./index.scss" />
