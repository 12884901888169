import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';
import { ROUTE_PATH } from '~shared/config';

export const APPOINTMENTS_ROUTE = {
  name: 'APPOINTMENTS',
  path: ROUTE_PATH.appointments.activeList.rawPath,
  component: 'VAppointments',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Views.Appointments.Title',
  },
};

export const APPOINTMENT_ROUTE = {
  name: 'APPOINTMENT',
  path: '/appointments/:id',
  component: 'VAppointment',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Base.PatientRecord',
  },
  props: true,
  redirect: (route) => route.path + '/default-card',

  childrenMap: {
    APPOINTMENT_ROUTE_DEFAULT_CARD: {
      name: 'APPOINTMENT_DEFAULT_CARD',
      path: 'default-card',
      _fullPath: '/appointments/:id/default-card',
      component: 'VAppointmentDefaultCard',
    },
    APPOINTMENT_ROUTE_INSPECTION_CARD: {
      name: 'APPOINTMENT_INSPECTION_CARD',
      path: 'inspection-card',
      _fullPath: '/appointments/:id/inspection-card',
      component: 'VAppointmentInspectionCard',
      beforeEnter: [onlyUserHasRoleMiddleware(['director', 'doctor', 'laboratory', 'manager'])],
    },
    APPOINTMENT_ROUTE_RECOMMENDATION: {
      name: 'APPOINTMENT_RECOMMENDATION',
      path: 'recommendation',
      _fullPath: '/appointments/:id/recommendation',
      component: 'VRecommendation',
      beforeEnter: [onlyUserHasRoleMiddleware(['doctor', 'laboratory'])],
    },
  },
  children: [], // утилита сама подставит сюда роуты из childrenMap
};

export const APPOINTMENTS_PROVIDED_ROUTE = {
  name: 'AppointmentsProvided',
  path: ROUTE_PATH.appointments.providedList.rawPath,
  component: 'VAppointments',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Views.Appointments.Title',
  },
};

export const APPOINTMENTS_CANCELLED_ROUTE = {
  name: 'AppointmentsCancelled',
  path: ROUTE_PATH.appointments.cancelledList.rawPath,
  component: 'VAppointments',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Views.Appointments.Title',
  },
};

export const routes = [
  APPOINTMENTS_ROUTE,
  APPOINTMENT_ROUTE,
  APPOINTMENTS_PROVIDED_ROUTE,
  APPOINTMENTS_CANCELLED_ROUTE,
];
