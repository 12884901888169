import { DEFAULT_PRICE_DELIMITER } from '~shared/config';

export function formatPrice({
  price,
  delimiter = DEFAULT_PRICE_DELIMITER,
}: {
  price?: string | number | null;
  delimiter?: string;
}) {
  return (price ?? 0)
    .toString()
    .split(/(?=(?:\d{3})+(?:\.|$))/g)
    .join(delimiter);
}
