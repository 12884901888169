{
  "en": {
    "CreateNewPatient": "Create a new patient",
    "GoToPatient": "Go to patient chart",
    "ParentFullName": "Full Name of the parent",
    "ParentPhone": "Parent number",
    "PatientIsInSystem": "The patient is in the system",
    "RebindPhone": "Unlink from this account"
  },
  "ru": {
    "CreateNewPatient": "Создать нового пациента",
    "GoToPatient": "Перейти к карте пациента",
    "ParentFullName": "ФИО родителя",
    "ParentPhone": "Номер родителя",
    "PatientIsInSystem": "Пациент есть в системе",
    "RebindPhone": "Отвязать от этого аккаунта"
  },
  "uz": {
    "CreateNewPatient": "Yangi bemormi yaratish",
    "GoToPatient": "Bemor kartasiga utish",
    "ParentFullName": "Ota-onasi-ni F.I.Sh",
    "ParentPhone": "Ota-ona nomeri",
    "PatientIsInSystem": "Bemor tizimda bor",
    "RebindPhone": "Shu akkuntdan uzish"
  }
}