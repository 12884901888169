<template>
  <div class="sidebar-menu">
    <div class="sidebar-menu-top">
      <img class="sidebar-menu__logo" src="@/assets/images/logo.svg" alt="logo" />
    </div>
    <nav class="sidebar-menu__nav sidebar-menu-nav">
      <ElMenu
        :default-active="route.path"
        class="sidebar-menu__container"
        router
        :default-openeds="defaultOpeneds">
        <template v-for="item in menuItems">
          <template v-if="item.submenu?.length">
            <ElSubMenu :index="item.key" :key="item.key">
              <template #title>
                <MiIcon v-if="item.icon" :icon="item.icon" />
                <span> {{ item.title }}</span>
              </template>
              <ElMenuItem
                v-for="subItem in item.submenu"
                class="sidebar-menu-nav__item"
                :index="subItem.key"
                :key="subItem.key"
                @click="handleMenuItemClick(subItem.routePath)">
                <template #title>
                  <div class="sidebar-menu-nav__item-link" v-if="subItem.routePath">
                    <MiIcon v-if="subItem.icon" :icon="subItem.icon" />
                    <span> {{ subItem.title }}</span>
                  </div>
                </template>
              </ElMenuItem>
            </ElSubMenu>
          </template>

          <template v-else>
            <ElMenuItem
              :index="item.key"
              :key="item.key"
              class="sidebar-menu-nav__item"
              @click="handleMenuItemClick(item.routePath)">
              <template #title>
                <div class="sidebar-menu-nav__item-link" v-if="item.routePath">
                  <MiIcon v-if="item.icon" :icon="item.icon" />
                  <span> {{ item.title }}</span>
                </div>
              </template>
            </ElMenuItem>
          </template>
        </template>
      </ElMenu>
    </nav>

    <div class="sidebar-menu__actions sidebar-menu-actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import { getSidebarMenuItems } from '../lib';

import { useSessionStore } from '~entities/session';
import { MiIcon } from '~shared/ui';

const route = useRoute();
const router = useRouter();

const sessionStore = useSessionStore();
const { user } = storeToRefs(sessionStore);
const doctorId = computed(() => user.value?.doctor_id);

const menuItems = computed(() => {
  if (user.value?.role) {
    return getSidebarMenuItems({
      doctorId: doctorId.value,
      userRole: user.value?.role,
    });
  } else {
    return [];
  }
});

const defaultOpeneds = computed(
  (): string[] =>
    menuItems.value
      .map((item) => item.prefix)
      .filter((prefix) => !!prefix && route.path.startsWith(prefix)) as string[]
);

const handleMenuItemClick = (path: string | undefined) => {
  if (!path) return;
  router.push(path);
};
</script>

<style lang="scss" src="./index.scss" />
