<template>
  <div class="referral-commission-table">
    <MiTable
      fit
      :data="tableData"
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :total="tableTotal">
      <ElTableColumn min-width="80px" prop="created_at" :label="$t('Common.RecordingDate')" />
      <ElTableColumn prop="invoice_id" :label="$t('CashRegister.Invoice')">
        <template #default="{ row }: { row: CommissionResource }">
          <MiButton size="small" link type="primary" @click="handleShowInvoice(row.invoice_id)">
            &#8470;{{ row.invoice_id }}
          </MiButton>
        </template>
      </ElTableColumn>
      <ElTableColumn prop="payment_subject_title" :label="$t('Common.Services')" />
      <ElTableColumn prop="payment_subject_price" :label="$t('Common.Cost')">
        <template #default="{ row }: { row: CommissionResource }">{{
          row.payment_subject_price ?? '-'
        }}</template>
      </ElTableColumn>
      <ElTableColumn
        v-if="!notPaid"
        prop="refCommission"
        :label="$t('Referral.CommissionReferralSource')">
        <template #default="{ row }">
          {{ computePercentOfCommission(row.amount, row.payment_subject_price) }}
        </template>
      </ElTableColumn>
      <ElTableColumn v-if="!notPaid" prop="amount" :label="$t('Bookkeeping.Commission(sum)')" />
      <ElTableColumn
        prop="status"
        v-if="isReferrerHasCommission && !notPaid"
        min-width="80px"
        :label="$t('Common.Status')">
        <template #default="{ row }"> <ReferralAccrualStatus :status="row.status" /> </template
      ></ElTableColumn>
    </MiTable>
    <div v-if="!notPaid" class="referral-commission-table__total">
      <span class="referral-commission-table__paid">{{ $t('Bookkeeping.TotalToBePaid') }}</span>
      <span class="referral-commission-table__amount"
        >{{ amountSum }} {{ $t('Common.Sum').toLowerCase() }}</span
      >
    </div>
    <InvoiceModal
      :invoice-id="currentInvoiceId"
      v-model="isInvoiceModalVisible"
      @close="handleCloseInvoiceModal"
      @success:update="handleSuccessUpdateInvoiceModal" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, shallowRef, watch } from 'vue';

import { InvoiceModal } from '~features/InvoiceModal';
import { ReferralAccrualStatus } from '~entities/referral';
import { MiTable, MiButton } from '~shared/ui';
import { usePage, usePerPage, useQuery, useDatePeriod, getMonthPeriod } from '~shared/lib';
import {
  GetReferrersCommissionsParams,
  misB2BApi,
  ReferrerResource,
  CommissionResource,
} from '~shared/api';

const props = withDefaults(
  defineProps<{
    currentReferralId: number;
    currentReferrer?: ReferrerResource;
    notPaid: boolean;
  }>(),
  {
    notPaid: false,
  }
);

const emit = defineEmits<{
  invoiceChanged: [];
}>();

const page = usePage();
const perPage = usePerPage();
const date = useDatePeriod(getMonthPeriod());
const status = useQuery({
  field: 'status',
});
const amountSum = shallowRef(0);

const loading = shallowRef(false);
const tableTotal = shallowRef(0);

const tableData = ref<CommissionResource[]>([]);

const getQueryPayload = computed((): GetReferrersCommissionsParams => {
  return {
    page: page.value,
    per_page: perPage.value,
    status: props.notPaid ? null : status.value,
    start_at: date.value[0],
    end_at: date.value[1],
    referrerId: props.currentReferralId,
    not_paid_invoice: props.notPaid || undefined,
  };
});

const currentInvoiceId = ref<number | null>(null);
const isInvoiceModalVisible = ref(false);

const getReferrerCommissionTable = async () => {
  if (!props.currentReferralId) return;
  loading.value = true;
  const response = await misB2BApi.referrers.getReferrersCommissions(getQueryPayload.value);

  if (response.data) {
    tableData.value = response.data.data;
    tableTotal.value = response.data.meta.total;
    amountSum.value = response.data.amount_sum;
  }

  loading.value = false;
};

const isReferrerHasCommission = computed(() => {
  return Boolean(props.currentReferrer?.recommendation_commission ?? true);
});

const handleShowInvoice = (invoiceId: number) => {
  currentInvoiceId.value = invoiceId;
  isInvoiceModalVisible.value = true;
};
const handleCloseInvoiceModal = () => {
  currentInvoiceId.value = null;
};

const handleSuccessUpdateInvoiceModal = async () => {
  emit('invoiceChanged');
  await getReferrerCommissionTable();
};

const computePercentOfCommission = (amount: number, payment_subject_price: number) => {
  const percent = Math.round((amount / payment_subject_price) * 100);
  return Number.isNaN(percent) ? 0 : percent;
};

watch(
  () => getQueryPayload.value,
  async () => {
    await getReferrerCommissionTable();
  }
);

onMounted(async () => {
  await getReferrerCommissionTable();
});

defineExpose({
  getReferrerCommissionTable,
});
</script>
<style lang="scss" src="./index.scss" />
