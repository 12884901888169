<template>
  <UiModelsAutocompleteSearch
    class="select-reference"
    v-loading="loading.create"
    label="title"
    :disabled="disabled"
    :model-for-use="Reference"
    :placeholder="$t('Bookkeeping.EnterNameOrSourceID')"
    :model-value="modelValue"
    :default-item="defaultItem"
    :show-create-option="showCreateOption"
    :search-query="searchQuery"
    @update:model-value="$emit('update:modelValue', $event)"
    @select="$emit('select', $event)"
    @create="createReference">
    <template #default="{ item }">
      <span class="select-reference__item">
        <span>{{ item.title }}</span>
        <span class="select-reference__item-id">{{ item.id ? item.id : '-' }}</span>
      </span>
    </template>
  </UiModelsAutocompleteSearch>
</template>

<script>
import axios from 'axios';

import { ReferenceModel } from '@/models/Reference.model';

export default {
  name: 'SelectReference',
  emits: ['update:modelValue', 'select'],
  props: {
    modelValue: [Number, String],
    defaultItem: [ReferenceModel, Object],
    showCreateOption: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      loading: {
        create: false,
      },
    };
  },
  computed: {
    searchQuery() {
      return {
        // TODO: костыль
        query_field: ['name'],
      };
    },
  },

  methods: {
    async createReference(query) {
      if (this.loading.create) return;
      this.loading.create = true;

      try {
        const { data } = await ReferenceModel.create({
          title: query,
        });

        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessCreated') });
        this.$emit('update:modelValue', data.data.id);
        this.$emit('select', data.data);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.create = false;
    },
  },
  setup: () => ({ Reference: ReferenceModel }),
};
</script>

<style lang="scss" src="./index.scss" scoped></style>
