{
  "en": {
    "Recommendations": {
      "AddServices": "Add services",
      "AddToFavorites": "Add to favorites",
      "Appointed": "Appointed",
      "AssignToPatient": "Assign to the patient",
      "EditFavorites": "Edit favorites",
      "Favorites": "Favorites",
      "FavoriteServices": "Favorite services",
      "HasRecommendation": "This patient has a doctor's recommendation",
      "RecordByRecommendation": "Record by recommendation",
      "SearchFavoriteAndRecordType": "Search favorite and Record Type"
    }
  },
  "ru": {
    "Recommendations": {
      "AddServices": "Добавить назначения",
      "AddToFavorites": "Добавить в избранные",
      "Appointed": "Назначенное",
      "AssignToPatient": "Назначить пациенту",
      "EditFavorites": "Редактриовать избранное",
      "Favorites": "Избранное",
      "FavoriteServices": "Избранные назначения",
      "HasRecommendation": "У данного пациента есть назначение от врача",
      "RecordByRecommendation": "Записать по назначению",
      "SearchFavoriteAndRecordType": "Поиск избранного и типа записи"
    }
  },
  "uz": {
    "Recommendations": {
      "AddServices": "Uchrashuvlarni qo'shing",
      "AddToFavorites": "Sevimlilarga qo'shing",
      "Appointed": "Tayinlangan",
      "AssignToPatient": "Bemorga tayinlang",
      "EditFavorites": "Sevimlilarni tahrirlash",
      "Favorites": "Sevimlilar",
      "FavoriteServices": "Sevimli xizmatlar",
      "HasRecommendation": "Bu bemor shifokor tavsiyasiga ega",
      "RecordByRecommendation": "Tavsiya bo'yicha yozib oling",
      "SearchFavoriteAndRecordType": "Sevimlilar va post turini qidiring"
    }
  }
}