<template>
  <ElDialog
    class="salary-crud-modal"
    :model-value="modelValue"
    width="100%"
    :title="isNew ? $t('Salary.AddCalculating') : $t('Salary.EditCalculating')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <div v-if="loading.getData" class="salary-crud-modal__loading" v-loading="true" />
    <VeeForm v-else class="salary-crud-modal__form" as="ElForm">
      <SalaryForm
        v-if="!!formData.values"
        :form-data="formData.values"
        @close:modal="$emit('update:modelValue')"
        @action="$emit('action', $event)" />
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'SalaryCrudModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { reactive, watch, computed } from 'vue';
import { Form as VeeForm } from 'vee-validate';

// eslint-disable-next-line import/no-internal-modules
import SalaryForm from './SalaryForm/index.vue';

import { Salary, SalaryFormData } from '@/models/accounting/Salary.model';
import { User } from '@/models/User.model';
import { EmployeeDto } from '@/types/api';

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    id?: number | null;
    userId?: SalaryFormData['user_id'];
  }>(),
  {
    modelValue: false,
    id: null,
    userId: null,
  }
);

defineEmits(['update:modelValue', 'action']);

const formData = reactive<{
  values: SalaryFormData | null;
}>({ values: null });

const loading = reactive({
  getData: false,
});

const isNew = computed(() => !props.id);

const getSalaryById = async (id: number) => {
  loading.getData = true;

  const result = await Salary.getItemById(id);

  if (result) {
    formData.values = { ...new Salary(result.data) };
  } else {
    formData.values = {
      ...new Salary(),
      user_id: props.userId,
    };
  }

  loading.getData = false;
};

const getUserById = async (id: number) => {
  const result = await User.findOneById<EmployeeDto>(id);
  if (result) {
    formData.values = {
      ...new Salary(),
      user_id: result.data.data.id,
      user: result.data.data,
    };
  }
};

watch(
  () => props.id,
  async (value) => {
    if (value) {
      await getSalaryById(value);
    } else if (props.userId) {
      await getUserById(props.userId);
    } else {
      formData.values = {
        ...new Salary(),
      };
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />
