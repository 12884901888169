import { EVENT_CALENDAR_TYPES } from '@/components/EventCalendar/index.enum';
import { prevOrNextMonthByISOString } from '@/utils/dateAndTime.utils';
import MonthCalendar from '@/components/EventCalendar/MonthCalendar/index.vue';
import DayCalendar from '@/components/EventCalendar/DayCalendar/index.vue';
import { useSessionStore } from '~entities/session';
import { MiIcon } from '~shared/ui';

export default {
  name: 'EventCalendar',
  emits: [
    'update:type',
    'update:date',
    'click:event',
    'update:start_at',
    'update:end_at',
    'click:day',
  ],
  components: { MonthCalendar, DayCalendar, MiIcon },
  props: {
    loading: Boolean,
    type: {
      type: String,
      default: EVENT_CALENDAR_TYPES.MONTH,
      validator: (val) => [EVENT_CALENDAR_TYPES.MONTH, EVENT_CALENDAR_TYPES.DAY].includes(val),
    },
    date: String, // ISO format
    start_at: String,
    end_at: String,

    /**
     * @property {object} monthData
     * @property {Array<object>} monthData[key] key = YYYY-MM-DD
     * @property {string} monthData[key][].title
     */
    monthData: Object,

    /**
     * @param {Array<object>} dayData
     * @param {{id: number, title: string}} dayData[].column
     * @param {Array<Appointment>} dayData[].data
     */
    dayData: Array,
  },
  data() {
    return {
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },
    month() {
      return new Date(this.date).getMonth();
    },
  },

  methods: {
    prevMonth() {
      this.$emit('update:date', prevOrNextMonthByISOString(this.date, { type: 'prev' }));
    },
    nextMonth() {
      this.$emit('update:date', prevOrNextMonthByISOString(this.date, { type: 'next' }));
    },

    /** @param {string} date - ISO format */
    setDayTypeAndDate(date) {
      this.$emit('click:day', date);
    },
  },

  setup: () => ({
    types: Object.keys(EVENT_CALENDAR_TYPES).map((key) => EVENT_CALENDAR_TYPES[key]),
    EVENT_CALENDAR_TYPES,
  }),
};
