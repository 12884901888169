<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @row-click="goToWarehouse"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="title" :label="$t('Warehouse.Warehouse')" />
    <ElTableColumn prop="address" :label="$t('Warehouse.Address')" />
    <ElTableColumn prop="description" :label="$t('Nomenclature.Notes')" />
    <ElTableColumn :label="$t('Nomenclature.Actions')" width="150px">
      <template #default="{ row }">
        <ElButton size="small" type="primary" plain @click.stop="$emit('modal:open', row)"
          >{{ $t('Nomenclature.Edit') }}
        </ElButton>
      </template>
    </ElTableColumn>
    <ElTableColumn width="150px">
      <ElButton type="primary" text>
        <template #icon>
          <MiIcon icon="EYE" />
        </template>
        {{ $t('Base.View') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>
<script lang="ts">
import { PropType } from 'vue';
import { useRouter } from 'vue-router';

import { WarehouseDto } from '@/types/api';
import { WAREHOUSE_TABLE_ROUTE } from '@/router/warehouse.routes';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import { MiIcon } from '~shared/ui';

export default {
  name: 'WarehousesTable',
  components: {
    UiTableWithPagination,
    MiIcon,
  },
  emits: ['update:page', 'update:perPage', 'modal:open'],
  props: {
    loading: { type: Boolean, required: true },
    items: { type: Array as PropType<WarehouseDto[]>, required: true },
    page: { type: Number, required: true },
    perPage: { type: Number, required: true },
    total: { type: Number, required: true },
  },
  setup() {
    const router = useRouter();
    const goToWarehouse = (warehouse: WarehouseDto) => {
      router.push({
        path: WAREHOUSE_TABLE_ROUTE.path,
        query: {
          warehouse_name: warehouse.title,
          warehouse_id: warehouse.id,
        },
      });
    };
    return {
      goToWarehouse,
    };
  },
};
</script>

<style lang="scss" scoped></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
