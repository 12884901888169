<template>
  <ElTimePicker v-bind="$props" v-model="value" />
</template>

<script setup lang="ts">
import { ElTimePicker, TimePickerDefaultProps, ModelValueType } from 'element-plus';
import { computed } from 'vue';

type Props = {
  modelValue: ModelValueType;
} & Partial<TimePickerDefaultProps>;

const props = withDefaults(defineProps<Props>(), {
  teleported: true,
  editable: true,
  clearable: true,
});
const emit = defineEmits<{
  'update:modelValue': [e: ModelValueType];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val: ModelValueType) {
    emit('update:modelValue', val);
  },
});
</script>

<style lang="scss" src="./index.scss" />
