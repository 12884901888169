<template>
  <FieldGroupWrapper>
    <div class="schedule__timepickers">
      <FormTimeField field-name="start" :disabled="formValue.checked" />
      <FormTimeField field-name="end" :disabled="formValue.checked" />
    </div>
    <div class="schedule__modal-bottom">
      <FormCheckboxField field-name="checked" :label="$t('User.DayOff')" />
      <FormActionsWrapper>
        <ElButton class="schedule__btn" @click="submit" type="primary"
          >{{ $t('Base.Save') }}
        </ElButton>
      </FormActionsWrapper>
    </div>
  </FieldGroupWrapper>
</template>

<script lang="ts">
export default {
  name: 'ScheduleForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useForm } from 'vee-validate';

import { validationSchema } from './validationSchema';

import FormTimeField from '@/components/common/form/ui/FormTimeField/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import FormCheckboxField from '@/components/common/form/ui/FormCheckboxField/index.vue';

type FormData = {
  start: string;
  end: string;
  checked: boolean;
};

const props = defineProps<{
  formData: FormData;
}>();
const emit = defineEmits(['change:schedule']);

// eslint-disable-next-line vue/no-setup-props-destructure
const { handleSubmit, values: formValue } = useForm({
  initialValues: props.formData,
  validationSchema,
  validateOnMount: false,
});

const submit = handleSubmit(
  async (values) => {
    emit('change:schedule', values);
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/accounting.locales.json" />
