<template>
  <ElDialog
    class="create-or-edit-appointment-modal"
    :model-value="modelValue"
    :title="$t(`Title.${appointment?.id ? 'Edit' : 'Create'}`)"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElForm
      id="create-or-edit-appointment"
      class="create-or-edit-appointment-modal-form"
      label-position="top"
      v-loading="loading.appointment"
      @submit.prevent="submitHandler">
      <UiDataModalGrid class="create-or-edit-appointment-modal-form__grid">
        <template #info>
          <ElCard :header="$t('Base.PatientInfo')" shadow="never">
            <PatientsSearchSelectDataBlock
              v-show="!patientPart.show"
              v-model="appointment.patient_id"
              :search-query="patientsOptions.searchQuery"
              :default-item="patient || patientPart.newPatient || appointment.patient"
              :disabled="!!appointment.id"
              :hide-select="!!appointment.id"
              :required="!patientPart.show"
              :create-permission="permissions.createPatient"
              :appointment="appointment"
              @reference:update="appointment.reference = $event"
              @create="sendStatisticStartCreatePatient"
              @foundPatient="sendStatisticFoundPatient"
              @reattachPatient="sendStatisticReattachPatient"
              @select="sendStatisticSelectPatient"
              @getItems="sendStatisticGSearchPatients"
              ref="patient">
              <template #content-append>
                <!-- Select referral doctor -->
                <SelectReference
                  v-model="appointment.reference_id"
                  :default-item="appointment.reference"
                  show-create-option
                  :disabled="appointment.invoice_id"
                  @select="handleSelectSource" />
              </template>
            </PatientsSearchSelectDataBlock>
          </ElCard>
        </template>

        <template #control>
          <ElCard shadow="never">
            <CreateAppointmentSubjectOrServiceGroupWithCount
              v-model:appointment="appointment"
              :patient="patient"
              :set-default-my-doctor="setDefaultMyDoctor"
              reset-after-create
              ref="create_or_edit_appointment_subject"
              @subject:create="addSubject"
              @selectSlot="sendStatisticSelectSlot" />
          </ElCard>
        </template>

        <template #content>
          <AppointmentSubjectsOrServiceGroupWithCountTable
            class="create-or-edit-appointment-modal-form__table-part"
            :items="
              appointment.id ? appointment.group_services_with_count : appointment.appointments
            "
            editable
            deletable
            @item:update="updateSubject"
            @item:remove="removeSubject" />
        </template>

        <template #footer>
          <ElCard class="create-or-edit-appointment-modal-form__cost-part" shadow="never">
            <ElFormItem v-if="isNotDoctor" :label="$t('Base.Discount') + ' (%)'">
              <ElInput v-model="appointment.discount" type="number" min="0" max="100" />
            </ElFormItem>
            <ElFormItem :label="$t('Base.Total')">
              {{ discountedCost }}
            </ElFormItem>
          </ElCard>
        </template>
      </UiDataModalGrid>
    </ElForm>

    <!--  Actions  -->
    <template #footer>
      <ElFormItem>
        <div class="create-or-edit-appointment-modal-form__actions">
          <ElButton
            type="primary"
            native-type="submit"
            form="create-or-edit-appointment"
            :loading="loading.form">
            {{ $t(`Base.${appointment?.id ? 'Edit' : 'Create'}`) }}
          </ElButton>
        </div>
      </ElFormItem>
    </template>
  </ElDialog>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
