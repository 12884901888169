import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const WAREHOUSE_PATH_PREFIX = '/warehouse';

export const NOMENCLATURE_TABLE_ROUTE = {
  name: 'NOMENCLATURE_TABLE',
  path: `${WAREHOUSE_PATH_PREFIX}/nomenclature`,
  component: 'VNomenclature',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Nomenclature.Nomenclature',
  },
};

export const WAREHOUSES_TABLE_ROUTE = {
  name: 'WAREHOUSES_TABLE',
  path: `${WAREHOUSE_PATH_PREFIX}/warehouses`,
  component: 'VWarehouses',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Warehouse.Warehouses',
  },
};

export const WAREHOUSE_TABLE_ROUTE = {
  name: 'WAREHOUSE_TABLE',
  path: `${WAREHOUSE_PATH_PREFIX}/goods-stock`,
  component: 'VGoodsStock',
  props: true,
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Product.GoodsStock',
  },
};

export const SUPPLIERS_TABLE_ROUTE = {
  name: 'SUPPLIERS_TABLE',
  path: `${WAREHOUSE_PATH_PREFIX}/suppliers`,
  component: 'VSuppliers',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Supplier.Suppliers',
  },
};

export const INCOME_TABLE_ROUTE = {
  name: 'INCOME_TABLE',
  path: `${WAREHOUSE_PATH_PREFIX}/incomes`,
  component: 'VIncome',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Income.Incomes',
  },
};

export const OUTCOME_TABLE_ROUTE = {
  name: 'OUTCOME_TABLE',
  path: `${WAREHOUSE_PATH_PREFIX}/outcomes`,
  component: 'VOutcome',
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['warehouse', 'accountant', 'director', 'manager']),
  ],
  meta: {
    title: 'Outcome.Expenses',
  },
};

export const routes = [
  NOMENCLATURE_TABLE_ROUTE,
  WAREHOUSES_TABLE_ROUTE,
  WAREHOUSE_TABLE_ROUTE,
  SUPPLIERS_TABLE_ROUTE,
  INCOME_TABLE_ROUTE,
  OUTCOME_TABLE_ROUTE,
];
