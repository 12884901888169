<template>
  <div class="field-group-divider" />
</template>

<script lang="ts">
export default {
  name: 'FieldGroupDivider',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup />

<style lang="scss" src="./index.scss"></style>
