{
  "en": {
    "Bookkeeping": {
      "AccrualAmount": "Accrual amount (sum)",
      "Accruals": "Accruals",
      "AddRefSource": "Add ref source",
      "AdmissionPee": "Admission fee",
      "AdvanceAmount": "Advance amount",
      "AmountOfOutstandingAdvances": "Amount of outstanding advances",
      "AmountOfPaymentsTotal": "Amount of payments total",
      "AppointmentFee": "Appointment fee",
      "BasicCommission": "Basic commission",
      "Bookkeeping": "Bookkeeping",
      "Brought": "Brought",
      "CalcPeriod": "Calculation period",
      "CalculatePayout": "Calculate payout",
      "CalculateSalary": "Calculate the salary",
      "CalculateSalaryFull": "Calculate the salary",
      "CalculationPeriod": "Calculation period",
      "CalculationType": "Calculation type",
      "CalculationTypes": {
        "down_payment": "Advance",
        "salary": "Salary"
      },
      "Commission(sum)": "Commission (sum)",
      "EmployeeProfile": "Employee profile",
      "EmployeeSalary": "Salary",
      "EnterNameOperation": "Enter the name of the operation",
      "EnterNameOrSourceID": "Enter the name or ID of the source",
      "EnterNumberTransactions": "Enter the number of transactions",
      "EnterTransactionAmount": "Enter transaction amount",
      "FindRefSource": "Find a ref source",
      "IssueAnAdvance": "Issue an advance",
      "JobTitle": "Job title",
      "LastPaidPeriod": "Last paid period",
      "MessageDublicateOperations": "This operation has already been added",
      "Operation": "Type of operation",
      "OperationName": "Operation name",
      "OperationType": {
        "minus": "Retention",
        "plus": "Accrual"
      },
      "PaySalary": "Pay a salary",
      "PaymentConfirmation": "Do you want to confirm the issuance of this amount?",
      "PaymentDate": "Payment date",
      "PayoutComment": "Payout comment",
      "Qty": "Quantity",
      "ReferralAccruals": "Referral accruals",
      "ReferralSources": "Referral sources",
      "SalaryCalculation": "Salary calculation",
      "SalaryDetailType": {
        "appointment": "Appointment fee",
        "down_payment": "unwritten advance",
        "recommendation": "Admission fee",
        "salary": "Salary"
      },
      "SalaryDetails": "Salary details",
      "SalaryStatuses": {
        "not_paid": "Unpaid",
        "paid": "Paid"
      },
      "Schedule": "Work schedule",
      "SelectOperation": "Select operation type",
      "SourceID": "Source ID",
      "SourceName": "The name of the source",
      "Specialization": "Specialization",
      "ToAdvance": "To advance",
      "TotalDebts": "Total debts",
      "TotalOutstandingAdvances": "Total outstanding advances",
      "TotalPayouts": "Total payouts",
      "TotalToBePaid": "Total to be paid",
      "TransactionAmount": "Transaction amount",
      "UnissuedAccruals": "Unissued accruals",
      "UnpaidCommission": "Unpaid commission"
    }
  },
  "ru": {
    "Bookkeeping": {
      "AccrualAmount": "Сумма начисления (сум)",
      "Accruals": "Начисления",
      "AddRefSource": "Добавить реф источник",
      "AdmissionPee": "Комиссия за назначение",
      "AdvanceAmount": "Сумма аванса",
      "AmountOfOutstandingAdvances": "Сумма несписанных авансов",
      "AmountOfPaymentsTotal": "Сумма выплат общая",
      "AppointmentFee": "Комиссия за прием",
      "BasicCommission": "Базовая комиссия",
      "Bookkeeping": "Бухгалтерия",
      "Brought": "Принесенные",
      "CalcPeriod": "Период расчета",
      "CalculatePayout": "Рассчитать выплату",
      "CalculateSalary": "Рассчитать ЗП",
      "CalculateSalaryFull": "Рассчитать зарплату",
      "CalculationPeriod": "Период расчета",
      "CalculationType": "Тип расчета",
      "CalculationTypes": {
        "down_payment": "Аванс",
        "salary": "Зарплата"
      },
      "Commission(sum)": "Комиссия (сум)",
      "EmployeeProfile": "Профиль сотрудника",
      "EmployeeSalary": "Оклад",
      "EnterNameOperation": "Введите название операции",
      "EnterNameOrSourceID": "Введите название или ID источника",
      "EnterNumberTransactions": "Введите кол-во операции",
      "EnterTransactionAmount": "Введите сумму операции",
      "FindRefSource": "Поиск реф источника",
      "IssueAnAdvance": "Выдать аванс",
      "JobTitle": "Должность",
      "LastPaidPeriod": "Посл оплаченный период",
      "MessageDublicateOperations": "Такая операция уже добавлена",
      "Operation": "Тип операции",
      "OperationName": "Название операции",
      "OperationType": {
        "minus": "Удержание",
        "plus": "Начисление"
      },
      "PaySalary": "Выдать зарплату",
      "PaymentConfirmation": "Вы действительно хотите подтвердить выдачу данной суммы?",
      "PaymentDate": "Дата оплаты",
      "PayoutComment": "Комментарий к выплате",
      "Qty": "Кол-во",
      "ReferralAccruals": "Реф начисления",
      "ReferralSources": "Реф источники",
      "SalaryCalculation": "Расчет ЗП",
      "SalaryDetailType": {
        "appointment": "Комиссия за прием",
        "down_payment": "Несписанный аванс",
        "recommendation": "Комиссия за назначение",
        "salary": "Оклад"
      },
      "SalaryDetails": "Детализация зарплаты",
      "SalaryStatuses": {
        "not_paid": "Не выдано",
        "paid": "Выдано"
      },
      "Schedule": "График работы",
      "SelectOperation": "Выберите тип операции",
      "SourceID": "ID источника",
      "SourceName": "Название источника",
      "Specialization": "Специализация",
      "ToAdvance": "Начислить аванс",
      "TotalDebts": "Всего задолженностей",
      "TotalOutstandingAdvances": "Всего не списанных авансов",
      "TotalPayouts": "Всего выплат",
      "TotalToBePaid": "Итого на выплату",
      "TransactionAmount": "Cумма операции",
      "UnissuedAccruals": "Невыданные начисления",
      "UnpaidCommission": "Невыплаченная комиссия"
    }
  },
  "uz": {
    "Bookkeeping": {
      "AccrualAmount": "Hisobga olingan miqdori (so'm)",
      "Accruals": "Yig'imlar",
      "AddRefSource": "Ref manbasini qo'shish",
      "AdmissionPee": "Tayinlash komissiyasi",
      "AdvanceAmount": "Avans miqdori",
      "AmountOfOutstandingAdvances": "To'lanmagan avanslar miqdori",
      "AmountOfPaymentsTotal": "To'lovlarning umumiy miqdori",
      "AppointmentFee": "Qabul uchun komissiya",
      "BasicCommission": "Asosiy komissiya",
      "Bookkeeping": "Muhassabat",
      "Brought": "Olingan",
      "CalcPeriod": "Hisoblash davri",
      "CalculatePayout": "To'lovni hisoblash",
      "CalculateSalary": "Ish haqini hisoblash",
      "CalculateSalaryFull": "Ish haqini hisoblash",
      "CalculationPeriod": "Hisoblash davri",
      "CalculationType": "Hisoblash turi",
      "CalculationTypes": {
        "down_payment": "Avans",
        "salary": "Maosh"
      },
      "Commission(sum)": "Komissiya (sum)",
      "EmployeeProfile": "Xodim profil",
      "EmployeeSalary": "Oylik",
      "EnterNameOperation": "Amalni nomini kiriting",
      "EnterNameOrSourceID": "Manba nomini yoki identifikatorini kiriting",
      "EnterNumberTransactions": "Amalning miqdorini kiriting",
      "EnterTransactionAmount": "Amalning miqdorini kiriting",
      "FindRefSource": "Ref manbani izlash",
      "IssueAnAdvance": "Avans chiqarish",
      "JobTitle": "Lavozim",
      "LastPaidPeriod": "Oxirgi to'langan davr",
      "MessageDublicateOperations": "Bunday amal allaqachon qo'shildi",
      "Operation": "Amalning turi",
      "OperationName": "Amalning nomi",
      "OperationType": {
        "minus": "O'chirish",
        "plus": "Hisobga olish"
      },
      "PaySalary": "Maosh berish",
      "PaymentConfirmation": "Siz haqiqatan ham ushbu summaning berishini tasdiqlamohlaysizmi?",
      "PaymentDate": "To'lash sanasi",
      "PayoutComment": "To'lov bo'yicha sharh",
      "Qty": "Miqdori",
      "ReferralAccruals": "Yo'naltiruvchi hisob-kitoblari",
      "ReferralSources": "Yo'naltiruvchi manbalari",
      "SalaryCalculation": "Maoshni hisoblash",
      "SalaryDetailType": {
        "appointment": "Qabul uchun komissiya",
        "down_payment": "Xisobdan yozilmagan avans",
        "recommendation": "Mablag'ni belgilash uchun komissiya",
        "salary": "Oylik"
      },
      "SalaryDetails": "Maoshning tafsili",
      "SalaryStatuses": {
        "not_paid": "Berilmagan",
        "paid": "Berilgan"
      },
      "Schedule": "Ish jadvali",
      "SelectOperation": "Amalning turi tanlang",
      "SourceID": "Manba identifikatori",
      "SourceName": "Manba nomi",
      "Specialization": "Mutaxassislik",
      "ToAdvance": "Avans bermoq",
      "TotalDebts": "Umumiy qarzlar",
      "TotalOutstandingAdvances": "Jami to'liq avanslar",
      "TotalPayouts": "Jami to'lovlar",
      "TotalToBePaid": "To'lov uchun jami",
      "TransactionAmount": "Amal miqdori",
      "UnissuedAccruals": "Berilmagan yig'imlar",
      "UnpaidCommission": "To'lanmagan komissiya"
    }
  }
}
