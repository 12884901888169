<template>
  <ElInput class="mi-input" v-bind="$props" v-model="value">
    <template v-if="$slots.prefix" #prefix>
      <slot name="prefix" />
    </template>
    <template v-if="$slots.suffix" #suffix>
      <slot name="suffix" />
    </template>
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend" />
    </template>
    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
  </ElInput>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ElInput, InputProps } from 'element-plus';

type Props = Partial<InputProps>;

const props = withDefaults(defineProps<Props>(), {
  validateEvent: true,
});
const emit = defineEmits<{
  'update:modelValue': [e: string | number | undefined | null];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val: string | number | undefined | null) {
    emit('update:modelValue', val);
  },
});
</script>

<style lang="scss" src="./index.scss" />
