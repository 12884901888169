<template>
  <ElTable
    class="appointments-subjects-table"
    :data="itemsWithPayload"
    row-key="_id"
    max-height="300px"
    :empty-text="$t('Base.NoData')"
    default-expand-all>
    <ElTableColumn prop="_title" :label="$t('Base.Services')" />
    <ElTableColumn prop="_price" :label="$t('Base.SumCost')" width="110px" />

    <ElTableColumn prop="count" :label="$t('Base.Quantity')">
      <template #default="{ row }">
        <span v-if="!editable && !row.children">{{ row.count }}</span>
        <ElInput
          v-if="editable && !row.children"
          :model-value="row.count"
          type="number"
          min="1"
          @update:modelValue="updateCountHandler(row, $event)" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="doctor.name" :label="$t('Base.Doctor')" />
    <ElTableColumn prop="start_at" :label="$t('Appointments.StartDate')" width="150px" />

    <ElTableColumn prop="actions" width="70px">
      <template #default="{ row }">
        <ElButton v-if="deletable" text size="small" @click="removeHandler(row)">
          <template #icon> <MiIcon icon="CANCEL" /> </template>
        </ElButton>
      </template>
    </ElTableColumn>
  </ElTable>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

import { MiIcon } from '~shared/ui';
import { formatPrice } from '@/utils/price.util';

export default {
  name: 'AppointmentSubjectsOrServiceGroupWithCountTable',
  components: { MiIcon },
  emits: ['item:update', 'item:remove'],
  props: {
    /** @type {Array<AppointmentSubject|AppointmentServiceGroupWithCount>} */
    items: Array,
    editable: Boolean,
    deletable: Boolean,
  },
  computed: {
    itemsWithPayload() {
      return this.items.map((subject) => {
        let item = null;

        if (this.isAppointmentSubjectsFlow) {
          item = {
            ...subject,
            _title: subject.group_services.length,
            _price: formatPrice({
              price: subject.group_services_with_count?.reduce(
                (acc, groupServiceWithCount) =>
                  acc +
                  (groupServiceWithCount.group_service.price * groupServiceWithCount.count ?? 0),
                0
              ),
            }),

            children: subject.group_services_with_count.map((elem) => ({
              ...elem,

              _title: elem.group_service.title,
              _price: formatPrice({ price: elem.group_service.price }),
              doctor: subject.doctor,

              _subject_id: subject._id,
            })),
          };
        } else {
          item = {
            ...subject,
            _title: subject.group_service.title,
            _price: formatPrice({ price: subject.group_service.price }),
          };
        }

        return item;
      });
    },

    isAppointmentSubjectsFlow() {
      return !this.items.length || this.items[0].group_services_with_count;
    },
  },

  methods: {
    updateCountHandler(childrenItem, value) {
      if (!this.isAppointmentSubjectsFlow) {
        this.$emit('item:update', { ...childrenItem, count: +value });
      }

      if (this.isAppointmentSubjectsFlow) {
        const subject = this.items.find((elem) => elem._id === childrenItem._subject_id);
        const childrenItemIndex = subject.group_services_with_count.findIndex(
          (elem) => elem._id === childrenItem._id
        );
        const group_services_with_count = cloneDeep(subject.group_services_with_count);
        group_services_with_count[childrenItemIndex].count = +value;

        this.$emit('item:update', {
          ...subject,
          group_services_with_count,
        });
      }
    },

    /** @param {AppointmentSubject|AppointmentServiceGroupWithCount|object} item */
    removeHandler(item) {
      if (!this.isAppointmentSubjectsFlow) return this.$emit('item:remove', item);

      // !isAppointmentSubjectsFlow
      const isSubject = !item._subject_id;

      if (isSubject) this.$emit('item:remove', item, 'appointment');
      if (!isSubject) {
        const subject = cloneDeep(this.items.find((subject) => subject._id === item._subject_id));

        if (subject.group_services_with_count.length === 1)
          return this.$emit('item:remove', subject, 'service');

        this.$emit('item:update', {
          ...subject,
          group_service_ids: subject.group_service_ids.filter((id) => id !== item.group_service_id),
          group_services: subject.group_services.filter(
            (groupService) => groupService.id !== item.group_service_id
          ),
          group_services_with_count: subject.group_services_with_count.filter(
            (elem) => elem._id !== item._id
          ),
        });
      }
    },
  },
};
</script>
