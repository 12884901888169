{
  "en": {
    "CashRegister": {
      "AddInvoice": "Add invoices",
      "AllInvoices": "All invoices",
      "AskingCancelInvoice": "Are you sure you want to cancel this invoice?",
      "AverageInvoice": "Average receipt",
      "Bill": "Issue invoice",
      "CancelInvoice": "Cancel invoice",
      "CashRegister": "Cash Register",
      "ChangeBalance": "Change balance",
      "Create": "Create an account",
      "CreateAccount": "Create account",
      "CurrentBalance": "Current balance",
      "DateLastVisit": "Date of last visit",
      "Debtors": "Debtors",
      "Deposit": "Deposit",
      "PaymentType": "Payment type",
      "DepositType": {
        "added": "Added",
        "subtracted": "Subtracted"
      },
      "Invoice": "Invoice",
      "Invoices": "Invoices",
      "InvoicesNumber": "Invoices number",
      "Number": "Number",
      "Paid": "Paid",
      "PatientNotes": "Patient Notes",
      "PaymentMethod": "Payment Method",
      "PaymentState": "Payment state",
      "ReferralAgent": "Referral agent",
      "Remainder": "Remainder",
      "RepeatPatients": "Repeat patients",
      "Statistic": {
        "amount": "Total amount",
        "amount_card": "Card (online)",
        "amount_cash": "Cash",
        "amount_online": "Transfers",
        "count": "Number of records",
        "online": "Transfers",
        "payed_amount": "Paid"
      },
      "Statuses": {
        "canceled": "Cancelled",
        "not_paid": "Not paid",
        "overpay": "Overpayment",
        "paid": "Paid",
        "partially_paid": "Part paid",
        "refund": "Refund"
      },
      "SuccessPayed": "Successfully paid",
      "SuccessRefunded": "Successfully refunded",
      "Types": {
        "custom": "Other",
        "hospital": "Hospital",
        "services": "Services",
        "treatment": "Treatment"
      },
      "WhoSentYou": "WhoSentYou"
    }
  },
  "ru": {
    "CashRegister": {
      "AddInvoice": "Добавить счет",
      "AllInvoices": "Все счета",
      "AskingCancelInvoice": "Вы действительно хотите отменить оплату этого счета?",
      "AverageInvoice": "Средний чек",
      "Bill": "Выставить счёт",
      "CancelInvoice": "Отмена счета",
      "PaymentType": "Тип оплаты",
      "CashRegister": "Касса",
      "ChangeBalance": "Изменить баланс",
      "Create": "Создать счёт",
      "CreateAccount": "Создать счет",
      "CurrentBalance": "Текущий баланс",
      "DateLastVisit": "Дата посл посещ",
      "Debtors": "Должники",
      "Deposit": "Депозит",
      "DepositType": {
        "added": "Поступление",
        "subtracted": "Оплата"
      },
      "Invoice": "Счет",
      "Invoices": "Счета",
      "InvoicesNumber": "Номер счета",
      "Number": "Номер",
      "Paid": "Оплачено",
      "PatientNotes": "Заметки про пациента",
      "PaymentMethod": "Способ оплаты",
      "PaymentState": "Статус оплаты",
      "ReferralAgent": "Реферальный агент",
      "Remainder": "Остаток",
      "RepeatPatients": "Повторные пациенты",
      "Statistic": {
        "amount": "Общая сумма",
        "amount_card": "Карта (онлайн)",
        "amount_cash": "Наличные",
        "amount_online": "Переводы",
        "count": "Количество записей",
        "online": "Переводы",
        "payed_amount": "Оплаченные"
      },
      "Statuses": {
        "canceled": "Отменён",
        "not_paid": "Не оплачено",
        "overpay": "Переплата",
        "paid": "Оплачен",
        "partially_paid": "Часть оплачена",
        "refund": "Возврат"
      },
      "SuccessPayed": "Успешно оплачено",
      "SuccessRefunded": "Успешно возвращено",
      "Types": {
        "custom": "Прочее",
        "hospital": "Стационар",
        "services": "Услуги",
        "treatment": "Лечение"
      },
      "WhoSentYou": "Кто вас отправил"
    }
  },
  "uz": {
    "CashRegister": {
      "AddInvoice": "Hisob-faktura qoshish ",
      "AllInvoices": "Barcha hisob-fakturalar",
      "AskingCancelInvoice": "??Вы действительно хотите отменить оплату этого счета?",
      "AverageInvoice": "O'rtacha check",
      "PaymentType": "To'lov turi",
      "Bill": "Hisobni to'lash",
      "CancelInvoice": "??Отмена счета",
      "CashRegister": "Kassa",
      "ChangeBalance": "Balasni o'zgartirish",
      "Create": "Hisob yaratish",
      "CreateAccount": "Hisob ochish",
      "CurrentBalance": "Joriy balans",
      "DateLastVisit": "Oxirgi tashrif sanasi",
      "Debtors": "Qarzdorlar",
      "Deposit": "Depozit",
      "DepositType": {
        "added": "??Поступление",
        "subtracted": "??Оплата"
      },
      "Invoice": "Hisob-faktura",
      "Invoices": "Hisob-fakturalar",
      "InvoicesNumber": "Hisob-fakturalar raqami",
      "Number": "Nomer",
      "Paid": "To'langan",
      "PatientNotes": "Bemor haqida eslatmalar",
      "PaymentMethod": "Tolov usuli",
      "PaymentState": "To'lov holati",
      "ReferralAgent": "Referal agent",
      "Remainder": "Qoldiq",
      "RepeatPatients": "Bemorlarni takrorlang",
      "Statistic": {
        "amount": "Umumiy qiymat",
        "amount_card": "Karta onlayn",
        "amount_cash": "Naqd",
        "amount_online": "Transferlar",
        "count": " Yozuvlar soni",
        "online": "Transferlar",
        "payed_amount": "To'langan"
      },
      "Statuses": {
        "canceled": "Bekor qilingan",
        "not_paid": "To'lanmagan",
        "overpay": "Ortiqcha",
        "paid": "To'langan",
        "partially_paid": "Qismi to'langan",
        "refund": "Qaytarilgan"
      },
      "SuccessPayed": "Muvaffaqiyatli to'landi",
      "SuccessRefunded": "Muvaffaqiyatli qaytarildi",
      "Types": {
        "custom": "Boshqa",
        "hospital": "Kasalxona",
        "services": "Xizmatlar",
        "treatment": "Davolash"
      },
      "WhoSentYou": "Sizni kim yubordi"
    }
  }
}
