<template>
  <ElForm class="favorites-form" label-position="top" @submit.prevent="submitHandler">
    <ElCard shadow="never" v-loading="loading.save">
      <div class="favorites-form__content">
        <ElFormItem>
          <div class="favorites-form__services-select-wrapper">
            <UiModelsAutocompleteSearch
              class="favorites-form__services-select"
              :model-value="groupServiceIds"
              :models="groupServices"
              label="title"
              :placeholder="$t('Recommendations.SearchFavoriteAndRecordType')"
              :model-for-use="ServiceGroup"
              multiple
              collapse-tags
              collapse-tags-tooltip
              @select="selectGroupServices" />
            <ElButton
              class="favorites-form__add-services-button"
              type="primary"
              @click="addFavoriteServices">
              {{ $t('Base.Add') }}
            </ElButton>
          </div>
        </ElFormItem>
      </div>
    </ElCard>
    <UiTableWithPagination
      :data="favoriteServices"
      :loading="false"
      :hide-pagination="true"
      :max-height="528"
      @update:page="$emit('update:page', $event)"
      @update:per-page="$emit('update:perPage', $event)">
      <ElTableColumn prop="title" label="Услуга" />
      <ElTableColumn prop="price" label="Цена" width="100px" />
      <ElTableColumn prop="delete" label="Действия" width="120px">
        <template #default="{ row }">
          <ElButton type="danger" plain @click.stop="removeFavoriteService(row)" size="small">
            {{ $t('Base.Delete') }}
          </ElButton>
        </template>
      </ElTableColumn>
    </UiTableWithPagination>
    <div class="favorites-form__actions">
      <ElButton type="primary" native-type="submit"
        >{{ $t('Recommendations.AddToFavorites') }}
      </ElButton>
    </div>
  </ElForm>
</template>

<script>
import axios from 'axios';

import { ServiceGroup } from '@/models/ServiceGroup.model';
import { Doctor } from '@/models/Doctor.model';
import FavoritesModal from '@/components/appointments/FavoritesModal/index.vue';

export default {
  name: 'FavoritesForm',
  data() {
    return {
      loading: {
        save: false,
        services: false,
      },
      favoriteServices: [...this.$store.state.recommendations.favorites.groupServices],
      groupServices: [...this.$store.state.recommendations.favorites.groupServices],
      groupServiceIds: [...this.$store.state.recommendations.favorites.groupServiceIds],
    };
  },
  methods: {
    selectGroupServices(payload) {
      this.groupServiceIds = payload.map((item) => item.id);
      this.groupServices = payload;
    },

    addFavoriteServices() {
      this.favoriteServicesUpdate(this.groupServices);
    },

    removeFavoriteService(payload) {
      this.favoriteServicesUpdate([{ ...payload, selected: false }]);
    },

    favoriteServicesUpdate(payload) {
      const ids = payload.map((item) => item.id);
      const filteredFavoriteServices = this.favoriteServices.filter(
        (item) => !ids.includes(item.id)
      );
      this.favoriteServices = filteredFavoriteServices.concat(
        payload
          .map((item) => ({
            ...item,
            selected: item.selected ?? true,
          }))
          .filter((item) => item.selected !== false)
      );
    },

    async submitHandler() {
      try {
        const { data } = await Doctor.updateFavoritesServices({
          group_service_ids: this.favoriteServices.map((item) => item.id),
        });
        this.$notify({
          type: 'success',
          title: this.$t('Notifications.SuccessUpdated'),
        });
        this.$store.dispatch('recommendations/setFavorites', data.data);
        this.$store.dispatch('modalAndDrawer/closeModal', {
          component: FavoritesModal,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
    },
  },
  setup: () => ({
    ServiceGroup,
  }),
};
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/recommendations.locales.json" />
