{
  "en": {
    "Title": "Treatment plan"
  },
  "ru": {
    "Title": "План лечения"
  },
  "uz": {
    "Title": "Davolash rejasi"
  }
}