<template>
  <PrinterDocument
    class="inspection-card-printer-document"
    :meta-blocks="metaBlocks"
    :content-blocks="contentBlocks"
    full-page
    ref="printerDocument">
    <template v-if="isEditorType" #content>
      <EditorCard
        :data="inspectionCard.basic_data?.length ? inspectionCard.basic_data[0] : null"
        readonly
        ref="editorCard" />
    </template>
  </PrinterDocument>
</template>

<script>
import { excludeTime } from '@/utils/dateAndTime.utils';
import { Appointment } from '@/models/appointment/Appointment.model';
import { InspectionCard } from '@/models/InspectionCard.model';
import { InspectionCardElement } from '@/models/InspectionCardElement.model';
import { InspectionCardBlock } from '@/models/InspectionCardBlock.model';
import { PrinterDocumentContentBlock as ContentBlock } from '@/models/client/PrinterDocumentContentBlock.model';
import PrinterDocument from '@/components/printer/PrinterDocument/index.vue';
import EditorCard from '@/components/EditorCard/index.vue';

export default {
  name: 'InspectionCardPrinterDocument',
  components: { EditorCard, PrinterDocument },
  props: {
    appointment: [Appointment, Object],
    inspectionCard: [InspectionCard, Object],
  },
  computed: {
    isEditorType() {
      return this.inspectionCard.type === InspectionCard.enum.types.Editor;
    },

    doctorBlocks() {
      const servicesText = this.appointment.services.map((service) => service.title).join(', ');
      return {
        services: servicesText,
        date: excludeTime(this.appointment.start_at),
      };
    },

    metaBlocks() {
      return [
        [
          {
            label: this.$t('Patients.Patient'),
            value: this.appointment.patient.name,
          },
          {
            label: this.$t('User.Birthdate'),
            value: this.appointment.patient.birthdate,
          },
          {
            label: this.$t('User.Gender'),
            value: this.$t(`User.Genders.${this.appointment.patient.gender}`),
          },
        ],
        [
          {
            label: this.$t('Base.Doctor'),
            value: this.appointment.doctor.name,
          },
          {
            label: this.$t('Base.Services'),
            value:
              this.appointment.services.length > 1
                ? this.appointment.services.map((service) => service.title)
                : this.appointment.services[0]?.title,
            type: this.appointment.services.length > 1 ? ContentBlock.enum.types.List : null,
          },
          {
            label: this.$t('Appointments.StartDate'),
            value: this.appointment.start_at,
          },
        ],
      ];
    },
    contentBlocks() {
      return this.isEditorType
        ? []
        : [
            ...this.inspectionCard.basic_data.map((elem) => {
              return {
                label: elem.label,
                value: InspectionCardBlock.getTextAnswer(elem),
                inline: elem.meta.horizontal ?? false,
                type:
                  elem.type === InspectionCardElement.enum.types.Category
                    ? ContentBlock.enum.types.Title
                    : null,
              };
            }),
          ];
    },
  },

  methods: {
    async print() {
      this.$refs?.editorCard?.rerender(); // TODO: как реализуется обновление редактора по пропсу - удалить эту строку
      await this.$refs.printerDocument.print();
    },
  },
};
</script>

<style src="./index.scss"></style>
