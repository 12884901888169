<template>
  <ElTable :data="items" v-bind="$attrs" :empty-text="$t('Base.NoData')">
    <ElTableColumn prop="subject.title" :label="$t('Base.Naming')" />
    <ElTableColumn prop="subject.price" :label="$t('Base.Price')">
      <template #default="{ row }"> {{ row.price }}</template>
    </ElTableColumn>
    <ElTableColumn prop="count" :label="$t('Base.Quantity')">
      <template #default="{ row }">
        <span v-if="!editable">{{ row.count }}</span>
        <ElInput
          v-if="editable"
          :model-value="row.count"
          type="number"
          min="1"
          @update:modelValue="updateItemCount(row, $event)" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="actions" width="66px">
      <template #default="{ row }">
        <div class="payment-subjects-table__actions">
          <ElButton v-show="deletable" text size="small" @click="$emit('item:remove', row)">
            <template #icon>
              <MiIcon icon="CANCEL" />
            </template>
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </ElTable>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

import { MiIcon } from '~shared/ui';
import { Invoice } from '@/models/Invoice.model';

export default {
  name: 'PaymentSubjectsTable',
  emits: ['item:update', 'item:remove'],
  components: { MiIcon },
  props: {
    invoice: [Invoice, Object],
    /** @type {Array<InvoicePaymentSubject|object>} */
    items: Array,
    editable: Boolean,
    deletable: Boolean,
  },

  methods: {
    updateItemCount(item, count) {
      const cloneItem = cloneDeep(item);

      this.$emit('item:update', {
        paymentSubject: {
          ...cloneItem,
          count: +count,
        },
        oldPaymentSubject: cloneItem,
      });
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
