import { onlyUserIsAuthMiddleware } from '~shared/lib';

export const TREATMENT_ROUTE = {
  name: 'DOCTORS_TREATMENT',
  path: '/treatment/:id',
  component: 'VTreatment',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Base.Template',
  },
  props: true,
};

export const routes = [TREATMENT_ROUTE];
