import { computed, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';

type queryParamsType = {
  field: string;
  defaultValue?: string | number | boolean | string[];
  formatter?: ((value: unknown) => string) | null;
  valueIsNumber?: boolean;
  valueIsBoolean?: boolean;
  valueIsArray?: boolean;
  valueIsNumberArray?: boolean;
  routerPush?: boolean;
};

type queryValueType = string | string[] | undefined;

// type processedQueryValueType = number | boolean | string | string[] | null;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type processedQueryValueType = any;

export function useQuery({
  field,
  defaultValue = undefined,
  formatter = null,
  valueIsNumber = false,
  valueIsBoolean = false,
  valueIsArray = false,
  valueIsNumberArray = false,
  routerPush = false,
}: queryParamsType) {
  const router = useRouter();
  const route = useRoute();
  const updateRoute = routerPush ? router.push : router.replace;
  const reset = async function () {
    const query = { ...route.query };
    delete query[field];
    await updateRoute({ ...route, query });
  };

  const value = computed<processedQueryValueType>({
    get() {
      const value = (route.query[field] ?? defaultValue) as queryValueType;

      if (value !== undefined) {
        if (valueIsNumber) {
          return Number(value);
        }

        if (valueIsBoolean && typeof value !== 'boolean') {
          return Boolean(Number(value));
        }
        if (valueIsArray && typeof value === 'string' && !Array.isArray(value)) {
          return value.split(',');
        }

        if (valueIsNumberArray && value !== undefined && !(value instanceof Array)) {
          return value.split(',').map((item) => Number(item));
        }
      }

      return value;
    },
    set(value) {
      if (formatter) {
        value = formatter(value);
      }
      if (valueIsBoolean) {
        value = Number(value);
      }

      value = value?.toString() ?? undefined;

      if (!value && !value?.length) {
        reset();
        return;
      }

      updateRoute({
        ...route,
        query: { ...route.query, [field]: value },
      });
    },
  });

  return reactive({
    value: value,
    reset: reset,
  });
}
