<template>
  <MiFormItem
    :class="[
      'form-date-picker',
      {
        'form-date-picker_in-column': props.inColumn,
        'form-date-picker_only-value': props.onlyValue && props.disabled,
        'form-date-picker_in-line-space-between':
          !props.inColumn && !!props.label && props.spaceBetween,
      },
    ]"
    :error="errorMessage"
    :label="props.label"
    :required="required">
    <div v-if="props.onlyValue && props.disabled">{{ value }}</div>
    <MiDatePicker
      v-bind="$attrs"
      v-else
      v-model="value"
      :disabled="props.disabled"
      :format="props.format"
      :value-format="props.valueFormat"
      :validate-event="false"
      :placeholder="props.placeholder || $t('Base.PleaseSelect')"
      @change="$emit('change')" />
  </MiFormItem>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';

import { FULL_DATE_FORMAT } from '~shared/config/date';
import { MiDatePicker, MiFormItem } from '~shared/ui';

type Props = {
  onlyValue?: boolean;
  inColumn?: boolean;
  spaceBetween?: boolean;
  disabled?: boolean;
  required?: boolean;
  fieldName: string;
  label?: string;
  placeholder?: string;
  format?: string;
  valueFormat?: string;
};

const props = withDefaults(defineProps<Props>(), {
  onlyValue: false,
  inColumn: true,
  spaceBetween: false,
  disabled: false,
  required: false,
  label: '',
  format: FULL_DATE_FORMAT,
  valueFormat: FULL_DATE_FORMAT,
  placeholder: '',
});

// eslint-disable-next-line vue/no-setup-props-destructure
const { value, errorMessage } = useField<string>(props.fieldName);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
