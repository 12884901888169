<template>
  <div class="salary-form-form">
    <div class="salary-form-form__field-groups">
      <FieldGroupWrapper :title="$t('User.EmployeeInformation')" :grid-columns="'three-columns'">
        <div class="salary-form-form__left-field-group">
          <FormSearchSelectField
            field-name="user_id"
            field-object-name="user"
            :model-for-use="User"
            label-key="name"
            disabled
            :placeholder="$t('User.FullNameOrPhoneNumber')" />
          <FormTextField
            field-name="user.name"
            :label="$t('User.FullName')"
            disabled
            :in-column="false"
            only-value />
          <FormTextField
            field-name="user.phone"
            :label="$t('Base.PhoneNumber')"
            disabled
            :in-column="false"
            only-value />
          <FormTextField
            field-name="user.address"
            :label="$t('User.Address')"
            disabled
            :in-column="false"
            only-value />
          <FormTextField
            field-name="user.speciality"
            :label="$t('User.Speciality')"
            disabled
            :in-column="false"
            only-value />
        </div>
        <div />
        <div class="salary-form-form__right-field-group">
          <FormTextField
            field-name="user.salary"
            :label="$t('Bookkeeping.EmployeeSalary')"
            disabled
            :in-column="false"
            only-value />

          <FormTextField
            field-name="user.date_last_salary"
            :label="$t('Salary.DateOfLastPayment')"
            disabled
            :in-column="false"
            only-value />
          <FormTextField
            field-name="user.amount_last_salary"
            :label="$t('Salary.PreviousPaymentAmount')"
            disabled
            :in-column="false"
            only-value />
          <FormTextField
            field-name="status"
            :label="$t('Income.Status')"
            disabled
            :in-column="false"
            only-value>
            <StatusTag
              :title="$t(`Salary.Status.${values.status}`)"
              :map-status="mapStatus"
              :value="values.status"
              size="small" />
          </FormTextField>
        </div>
      </FieldGroupWrapper>
    </div>

    <SalaryDetails :disabled="!isNew" />

    <FormActionsWrapper align="right">
      <ElPopconfirm
        :disabled="actionOptions.cancelDisabled"
        :title="$t('Ui.PopConfirm.DefaultTitle')"
        @confirm="updateSalaryStatus('canceled')">
        <template #reference>
          <ElButton
            type="danger"
            plain
            :loading="loading.cancel"
            :disabled="actionOptions.cancelDisabled">
            {{ $t('Base.Cancel') }}
          </ElButton>
        </template>
      </ElPopconfirm>
      <ElPopconfirm
        :disabled="actionOptions.payOutDisabled"
        :title="$t('Ui.PopConfirm.DefaultTitle')"
        @confirm="updateSalaryStatus('payed')">
        <template #reference>
          <ElButton
            type="primary"
            plain
            :loading="loading.payOut"
            :disabled="actionOptions.payOutDisabled">
            {{ $t('Salary.PayOut') }}
          </ElButton>
        </template>
      </ElPopconfirm>
      <ElButton
        type="primary"
        native-type="submit"
        :loading="isSubmitting"
        :disabled="actionOptions.saveDisabled"
        @click="submitForm"
        >{{ $t('Base.Save') }}
      </ElButton>
    </FormActionsWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'SalaryForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import axios from 'axios';
import { computed, reactive, watch } from 'vue';
import { useField, useForm } from 'vee-validate';
import { ElNotification } from 'element-plus';

import { validationSchema } from './validationSchema';
// eslint-disable-next-line import/no-internal-modules
import SalaryDetails from './SalaryDetails/index.vue';

import { User } from '@/models/User.model';
import { Salary, SalaryFormData } from '@/models/accounting/Salary.model';
import { MapStatusType } from '@/components/common/StatusTag/types';
import { EmployeeDto, SalaryStatus } from '@/types/api';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import StatusTag from '@/components/common/StatusTag/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';

const props = defineProps<{
  formData: SalaryFormData;
}>();

const isNew = computed(() => !props.formData.id);

const loading = reactive({
  save: false,
  payOut: false,
  cancel: false,
});

const mapStatus: MapStatusType<SalaryStatus> = {
  waiting: 'warning',
  payed: 'success',
  canceled: 'danger',
};

const emit = defineEmits(['submit', 'close:modal', 'action']);

// eslint-disable-next-line vue/no-setup-props-destructure
const { values, isSubmitting, errors, handleSubmit } = useForm<SalaryFormData>({
  initialValues: props.formData,
  validationSchema,
});

const actionOptions = computed(() => ({
  isNewService: !props.formData.id,
  payOutDisabled: isNew.value || props.formData.status === 'payed',
  cancelDisabled: isNew.value || props.formData.status === 'canceled',
  saveDisabled: Object.keys(errors.value).length > 0 || !isNew.value,
}));

const updateSalaryStatus = async (status: SalaryFormData['status']) => {
  if (props.formData.id) {
    const response = await Salary.updateStatus({
      id: props.formData.id,
      status,
    });
    emit(
      'action',
      new GlobalModalInstance({
        name: 'updated',
        data: response?.data ?? {},
      })
    );
    emit('close:modal');
  }
};

const submitForm = handleSubmit(
  async (values) => {
    const formData = {
      ...values,
      payment_details: values.payment_details.filter((f) => !f.deleted),
    };
    const response = await Salary.createItem(formData);

    emit(
      'action',
      new GlobalModalInstance({
        name: 'created',
        data: response?.data ?? {},
      })
    );
    emit('close:modal');
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);

const { value: userId } = useField<SalaryFormData['user_id']>('user_id');
const { setValue: setUser } = useField<SalaryFormData['user']>('user');

watch(
  () => userId.value,
  async (value) => {
    if (value) {
      try {
        const { data } = await User.findOneById<EmployeeDto>(value);

        const user = {
          ...data.data,
          speciality: data.data.doctor?.specialties.map((item) => item.title).join(', '),
        };

        setUser(user);
      } catch (err) {
        ElNotification({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/doctors.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/validation.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/ui.locales.json" />
<i18n src="@/locales/user.locales.json" />
