{
  "en": {
    "Title": "Clinic workload"
  },
  "ru": {
    "Title": "Загруженность клиники"
  },
  "uz": {
    "Title": "Klinikaning ish yuki"
  }
}