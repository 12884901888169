{
  "en": {
    "Referral": {
      "AddReferralSource": "Add referral source",
      "AddReferralSourceShort": "Add ref source",
      "BaseCommission": "Base commission (%)",
      "CalculatePayout": "Calculate payout",
      "Comment": "Comment",
      "Commission": "Commission",
      "CommissionReferralSource": "Commission to referral source",
      "Commissions": "Commissions",
      "Consultations": "Consultations",
      "DefaultCommissionPercentage": "Default commission (%)",
      "EditReferralSource": "Edit referral source",
      "EnterComment": "Enter a comment",
      "EnterPhoneNumberReferralSource": "Enter the phone number of the referral source",
      "EnterTheNameOfTheReferralSource": "Enter the name of the referral source",
      "IndividualCommissionAmount": "Individual commission amount",
      "IndividualCommissionPercentage": "Individual commission (%)",
      "IndividualCommissions": "Individual commissions",
      "InputServiceName": "Input service name",
      "Link": "Link",
      "NameOfReferralSource": "Referral source name",
      "NoCommission": "No commission",
      "NoCommissionIsCharged": "No commission is charged to the referral source",
      "OtherServices": "Other services",
      "ReferralSource": "Referral source",
      "ReferralSourceContacts": "Referral source contacts",
      "ReferralSourceId": "Referral source ID",
      "RemoveSource": "Remove source",
      "ServiceName": "Service name",
      "SourceName": "Source name",
      "SpecifySourceCommissionPercentage": "Specify the source's commission as a percentage",
      "UnpaidBills": "Unpaid bills"
    }
  },
  "ru": {
    "Referral": {
      "AddReferralSource": "Добавить реферальный источник",
      "Analyses": "Анализы",
      "BaseCommission": "Базовая комиссия (%)",
      "CalculatePayout": "Рассчитать выплату",
      "Comment": "Комментарий",
      "Commission": "Комиссия",
      "CommissionReferralSource": "Комиссия реф источнику",
      "Commissions": "Комиссии",
      "Consultations": "Консультации",
      "Cost": "Стоимость",
      "DefaultCommissionPercentage": "Комиссия по умолчанию (%)",
      "EditReferralSource": "Редактировать реферальный источник",
      "EnterComment": "Введите комментарий",
      "EnterPhoneNumberReferralSource": "Введите номер телефона реф источника",
      "EnterTheNameOfTheReferralSource": "Введите название реф источника",
      "IndividualCommissionAmount": "Индивидуальная комиссия (сум)",
      "IndividualCommissionPercentage": "Индивидуальная комиссия (%)",
      "IndividualCommissions": "Индивидуальные комиссии",
      "InputServiceName": "Введите название услуги",
      "Link": "Ссылка",
      "NameOfReferralSource": "Название реферального источника",
      "NoCommission": "Без комиссии",
      "NoCommissionIsCharged": "Реф источнику не начисляется комиссия",
      "OtherServices": "Другие услуги",
      "ReferralSource": "Реферальный источник",
      "ReferralSourceContacts": "Контакты реф источника",
      "ReferralSourceId": "ID реф источника",
      "RemoveSource": "Удалить источник",
      "ServiceName": "Название услуги",
      "SourceName": "Название реф источника ",
      "SpecifySourceCommissionPercentage": "Укажите комиссию источника в процентах",
      "UnpaidBills": "Неоплаченные счета"
    }
  },
  "uz": {
    "Referral": {
      "AddReferralSource": "Referal manbani qo'shish",
      "Analyses": "Tahlillar",
      "BaseCommission": "Bazoviy komissiya (%)",
      "CalculatePayout": "To'lovni hisoblash",
      "Comment": "Izoh",
      "Commission": "Komissiya",
      "CommissionReferralSource": "Referal manbaga komissiya",
      "Commissions": "Komissiyalar",
      "Consultations": "Konsultatsiyalar",
      "Cost": "Narxi",
      "DefaultCommissionPercentage": "Standart komissiya foizi (%)",
      "EditReferralSource": "Referal manbani tahrirlash",
      "EnterComment": "Izoh kiriting",
      "EnterPhoneNumberReferralSource": "Referal manbaning telefon raqamini kiriting",
      "EnterTheNameOfTheReferralSource": "Referal manbaning nomini kiriting",
      "IndividualCommissionAmount": "Individual komissiya summasi",
      "IndividualCommissionPercentage": "Individual komissiya foizi (%)",
      "IndividualCommissions": "Individual komissiyalar",
      "InputServiceName": "Xizmat nomi kiriting",
      "Link": "Havola",
      "NameOfReferralSource": "Referal manbaning nomi",
      "NoCommissionIsCharged": "Yo'naltiruvchi manbadan komissiya berilmaydi.",
      "OtherServices": "Boshqa xizmatlar",
      "ReferralSource": "Referal manba",
      "ReferralSourceContacts": "Referal manbaning aloqa ma'lumotlari",
      "ReferralSourceId": "Referal manbani Id",
      "RemoveSource": "Istechonni o'chirish",
      "ServiceName": "Xizmat nomi",
      "SourceName": "Manbaning nomi",
      "SpecifySourceCommissionPercentage": "Istechoning komissiyasini foizda ko'rsating",
      "UnpaidBills": "To'lanmagan hisoblar"
    }
  }
}
