<template>
  <div>
    <ElFormItem class="services-by-category-search" :label="$t('Base.Search')">
      <ElInput v-model="searchString" @input="debouncedInput" />
    </ElFormItem>

    <ElContainer class="services-by-category-list" ref="servicesByCategoryList">
      <ServicesByCategory :key="favorites.id" :category-data="favorites">
        <template #header>
          <div class="services-by-category-list__favorites-header">
            <img src="@/assets/images/icon-favorites.svg" alt="icon" />{{
              $t('Recommendations.Favorites')
            }}
          </div>
        </template>
        <template #actions>
          <ElButton type="primary" text link @click="openFavoritesModal">
            {{ $t('Recommendations.EditFavorites') }}</ElButton
          >
        </template>
      </ServicesByCategory>
      <ServicesByCategory v-for="item in servicesByCategory" :key="item.id" :category-data="item" />
      <ElContainer
        v-show="totalPages === null || page < totalPages"
        class="services-by-category-list__observable-item"
        v-loading="loading"
        ref="observableItem"></ElContainer>
    </ElContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash.debounce';
import noop from 'lodash.noop';

// eslint-disable-next-line import/no-internal-modules
import ServicesByCategory from '../ServicesByCategory/index.vue';

import { useIntersectionObserver } from '~shared/lib';
import { Service } from '@/models/Service.model.js';
import FavoritesModal from '@/components/appointments/FavoritesModal/index.vue';

export default {
  name: 'ServicesByCategoryList',
  components: { ServicesByCategory },
  data() {
    return {
      loading: false,
      servicesByCategory: [],
      searchString: '',
      debouncedSearchString: '',
    };
  },
  computed: {
    ...mapState({
      favorites: (state) => state.recommendations.favorites,
    }),
  },
  watch: {
    debouncedSearchString: {
      handler(value) {
        this.searchServices(value);
      },
    },
  },
  methods: {
    openFavoritesModal() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: FavoritesModal,
      });
    },

    async searchServices(value) {
      this.page = 1;
      this.servicesByCategory = [];
      await this.getServicesByCategory({
        per_page: this.perPage,
        page: this.page,
        title: value,
      });
    },

    async getServicesByCategory(query) {
      this.loading = true;

      const { data } = await Service.getServicesByCategory(query);

      this.servicesByCategory = [
        ...this.servicesByCategory,
        ...data.data.map(({ id, title, ...rest }) => ({
          id,
          title,
          groupServices: [...rest.group_services],
          groupServiceIds: [...rest.group_service_ids],
        })),
      ];
      this.totalPages = data.meta.last_page;

      this.loading = false;
    },

    async intersectCallback([entry], observer) {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
        this.page += 1;
        if (this.totalPages === null || this.page <= this.totalPages) {
          await this.getServicesByCategory({
            per_page: this.perPage,
            page: this.page,
            title: this.searchString,
          });
        }
        observer.observe(this.$refs.observableItem.$el);
      }
    },
  },
  setup: () => ({
    debouncedInput: noop,
    perPage: 5,
    page: 0,
    totalPages: null,
  }),
  mounted() {
    this.debouncedInput = debounce((value) => {
      this.debouncedSearchString = value;
    }, 600);
    useIntersectionObserver(this.$refs.observableItem.$el, this.intersectCallback, {
      root: this.$refs.servicesByCategoryList.$el,
    });
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/recommendations.locales.json" />
