import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const STATISTIC_DASHBOARD_ROUTE = {
  name: 'STATISTIC_DASHBOARD',
  path: '/statistic/dashboard',
  component: 'VStatisticDashboard',
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director'])],
  meta: {
    title: 'Views.StatisticDashboard.Title',
  },
};

export const routes = [STATISTIC_DASHBOARD_ROUTE];
