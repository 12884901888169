<template>
  <ElSelect
    multiple
    collapse-tags
    clearable
    :model-value="modelValue"
    :placeholder="$t('Placeholder')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElOption
      v-for="option in options"
      :key="option.value"
      :label="option.label"
      :value="option.value" />
  </ElSelect>
</template>

<script>
import { User } from '@/models/User.model';

export default {
  name: 'SelectUserRole',
  emits: ['update:modelValue'],
  props: {
    modelValue: [String, Array],
  },

  computed: {
    options() {
      const restrictedRole = User.enum.roles.Patient;

      return Object.keys(User.enum.roles)
        .map((key) => ({
          label: this.$t(`User.Roles.${User.enum.roles[key]}`),
          value: User.enum.roles[key],
        }))
        .filter((role) => role.value !== restrictedRole);
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/user.locales.json" />
<i18n src="./index.locales.json" />
