<template>
  <ElTable class="treatment-drugs-table" :data="itemsWithPayload" v-bind="$attrs">
    <ElTableColumn prop="title" :label="$t('Base.Naming')" />
    <ElTableColumn prop="_price" :label="$t('Base.Price')" />

    <ElTableColumn prop="count" :label="$t('Base.Days')">
      <template #default="{ row }">
        <span v-if="!editable">{{ row.count }}</span>
        <ElInput
          v-if="editable"
          :model-value="row.count"
          type="number"
          min="1"
          @update:model-value="$emit('drug:update', { ...row, count: $event })"
          @change="$emit('drug:change', row)" />
      </template>
    </ElTableColumn>

    <ElTableColumn v-if="deletable" width="70px">
      <template #default="{ row }">
        <ElButton type="danger" text size="small" @click="$emit('drug:remove', row)">
          <template #icon> <MiIcon icon="CANCEL" /> </template>
        </ElButton>
      </template>
    </ElTableColumn>
  </ElTable>
</template>

<script>
import { formatPrice } from '@/utils/price.util';
import { MiIcon } from '~shared/ui';

export default {
  name: 'TreatmentDrugsTable',
  components: { MiIcon },
  emits: ['drug:remove', 'drug:update', 'drug:change'],
  props: {
    /** @type {Array<TreatmentDrug|object>} */
    items: Array,
    editable: Boolean,
    deletable: Boolean,
    showIsDeleted: Boolean,
  },
  computed: {
    filteredItems() {
      return this.showIsDeleted ? this.items : this.items.filter((drug) => !drug.is_deleted);
    },

    itemsWithPayload() {
      return this.filteredItems.map((elem) => ({
        ...elem,
        _price: formatPrice({ price: elem.price }),
      }));
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
