import { ElNotification } from 'element-plus';

import { CRUDModel } from '@/models/CRUD.model';
import { apiService } from '~shared/api';
import { getApiErrorMessage, mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { QueryParams } from '@/types/common';
import { MetaDto, MigrationDto, WarehouseDto } from '@/types/api';

export type WarehouseFormData = Omit<WarehouseDto, 'id'> & {
  id?: number | null;
};

export class Warehouse extends CRUDModel {
  static modelName = 'warehouse';
  static tableName = 'warehouses';

  title: string;
  address: string;
  description: string;

  constructor(payload?: WarehouseFormData) {
    super(payload);

    this.title = payload?.title ?? '';
    this.address = payload?.address ?? '';
    this.description = payload?.description ?? '';
  }

  static async getItems(query: QueryParams) {
    const response = await apiService.api.instance.get<{ data: WarehouseDto[]; meta: MetaDto }>(
      mergeOrCreateQuery({
        url: `${this.tableName}`,
        query: deleteEmptyValueKeys(query),
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async getItemById(id: number) {
    const response = await apiService.api.instance.get<{ data: WarehouseDto }>(
      `${this.tableName}/${id}`
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async createItem(payload: WarehouseDto) {
    const response = await apiService.api.instance.post<{ data: WarehouseDto }>(
      `${this.tableName}/create`,
      payload
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async updateItem(id: number, payload: WarehouseDto) {
    const response = await apiService.api.instance.put<{ data: WarehouseDto }>(
      `${this.tableName}/${id}/update`,
      payload
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async deleteItem(id: number) {
    const response = await apiService.api.instance.delete<{ data: WarehouseDto }>(
      `${this.tableName}/${id}/delete`
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async migrateProducts(payload: MigrationDto) {
    try {
      const response = await apiService.api.instance.post<{ data: MigrationDto }>(
        `${this.tableName}/migrate/products`,
        payload
      );

      return { response, data: response.data.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
}
