﻿{
  "en": {
    "Validation": {
      "GreaterZero": "Amount must be greater than or equal to 1",
      "MaxAmount": "The maximum possible amount is",
      "RequiredField": "This is a required field",
      "MaxLength": "Maximum string length - ",
      "OnlyZeroValues": "Value cannot contain only 0",
      "MaxValue": "The maximum possible value is"
    }
  },
  "ru": {
    "Validation": {
      "GreaterZero": "Значение должно быть больше или равно 1",
      "MaxAmount": "Максимально возможная сумма -",
      "RequiredField": "Это обязательное поле",
      "MaxLength": "Максимальная длина строки - ",
      "OnlyZeroValues": "Значение не может содержать только 0",
      "MaxValue": "Максимальное возможное значение"
    }
  },
  "uz": {
    "Validation": {
      "GreaterZero": "Qiymat 1 dan yuqori yoki teng bo'lishi kerak",
      "MaxAmount": "Mumkin bo'lgan maksimal miqdor -",
      "RequiredField": "Bu majburiy maydondir",
      "MaxLength": "??Максимальная длина строки - ",
      "OnlyZeroValues": "??Значение не может содержать только 0",
      "MaxValue": "Mumkin bo'lgan maksimal qiymat"
    }
  }
}
