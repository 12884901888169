<template>
  <div>
    <ElSelect
      class="select-biomaterial-type"
      v-loading="loading"
      :model-value="modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
      @select="$emit('select', $event)">
      <ElOption
        v-for="item in items"
        :key="item.title"
        :label="item.biomaterial_type_translate"
        :value="item.title" />
    </ElSelect>

    <UiRequiredHiddenInput v-show="required" :model-value="modelValue" :required="required" />
  </div>
</template>

<script>
import { Biomaterial } from '@/models/laboratory/Biomaterial.model';

export default {
  name: 'SelectBiomaterial',
  emits: ['update:modelValue', 'select'],
  props: {
    modelValue: String,
    required: Boolean,
  },
  data() {
    return {
      /** @type {Array<{title: string, biomaterial_type_translate: string}>} items */
      items: [],
      loading: false,
    };
  },
  methods: {
    async getBiomaterialTypes() {
      this.loading = true;
      const { biomaterial_types } = await Biomaterial.getBiomaterialTypes();
      this.items = biomaterial_types;
      this.loading = false;
    },
  },

  mounted() {
    this.getBiomaterialTypes();
  },
};
</script>

<style lang="scss" src="./index.scss" />
