<template>
  <MiDialog class="scan-modal" v-model="value" :title="$t('Base.Scan')" width="320px">
    <div class="scan-modal-content">
      <MiIcon class="scan-modal-content__icon" icon="SCAN" />
      <div class="scan-modal-content__text">{{ text || $t('Base.Scanning') }}</div>

      <input
        class="scan-modal-content__input"
        ref="input"
        v-model="data"
        @keydown.enter="handleEnterInput" />
    </div>
  </MiDialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { MiDialog, MiIcon } from '~shared/ui';

const props = defineProps<{
  modelValue: boolean;
  text?: string | null;
}>();

const emit = defineEmits<{
  'update:modelValue': [e: boolean];
  'scan:success': [e: string];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const data = ref('');
const input = ref();

watch(
  () => props.modelValue,
  (value) => {
    data.value = '';
    if (value) setTimeout(() => input.value?.focus());
  }
);

const reset = () => {
  data.value = '';
  input.value?.focus();
};

const handleEnterInput = () => {
  emit('scan:success', data.value);
};

defineExpose({
  reset,
});
</script>

<style lang="scss" src="./index.scss" />
