<template>
  <div class="v-patient-default">
    <div class="v-patient-default-main">
      <!--  Patient  -->
      <div class="v-patient-default__item v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Title') }}</div>
          <div class="v-patient-default__actions">
            <ElButton type="primary" plain @click="editPatient">
              {{ $t('Patients.EditPatient') }}
            </ElButton>
          </div>
        </div>

        <div class="v-patient-default-item__body" v-loading="loading.profile">
          <PatientCardRow
            grid
            :patient="patient"
            :items="patientItems"
            :appointment="appointment"
            shadow="never">
            <template #actions>
              <ElButton
                v-show="isManager"
                type="primary"
                :loading="braceletLoading"
                @click="printBracelet">
                {{ $t('Patients.PrintBracelet') }}
              </ElButton>

              <router-link v-show="isDoctor" :to="patientAmbulatoryCardPageLink">
                <ElButton type="primary">{{ $t('Base.AmbulatoryCard') }}</ElButton>
              </router-link>
            </template>
          </PatientCardRow>
        </div>
      </div>

      <!--  Appointments  -->
      <div class="v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Appointments.Appointments') }}</div>

          <ElButton size="small" type="primary" plain @click="$emit('appointment:create')">
            {{ $t('Appointments.CreateAppointment') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!appointments?.length && !loading.appointments"
          :description="$t('Base.NoData')" />

        <AppointmentsTable
          v-show="appointments?.length || loading.appointments"
          :items="appointments"
          :total="appointments?.length"
          :per-page="appointments?.length || 1"
          :page="1"
          :loading="loading.appointments"
          :exclude-columns="excludeColumns"
          :max-height="500" />
        <div class="v-patient-default-item__action">
          <p @click="goToAppointmentsPatient">
            {{ $t('Appointments.AllAppointments') }}
          </p>
        </div>
      </div>

      <!--  Invoices  -->
      <div class="v-patient-default__item v-patient-default-item" v-show="!isDoctor">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Base.Invoice') }}</div>
          <!--          <ElButton type="primary" plain @click="$emit('appointment:create')">-->
          <ElButton size="small" type="primary" plain @click="$emit('invoice:create')">
            {{ $t('Invoices.Create') }}
          </ElButton>
        </div>
        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!invoices?.length && !loading.invoices"
          :description="$t('Base.NoData')" />
        <InvoicesTable
          v-show="invoices?.length || loading.invoices"
          :items="invoices"
          :total="invoices?.length"
          :per-page="invoices?.length || 1"
          :page="1"
          :loading="loading.invoices"
          :columns="{ id: false, user: false }" />
        <div class="v-patient-default-item__action">
          <p @click="goToCashPatient">
            {{ $t('Invoices.AllInvoices') }}
          </p>
        </div>
      </div>

      <!--  Orders  -->
      <div class="v-patient-default__item v-patient-default-item" v-show="false">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Base.Orders') }}</div>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!orders?.length && !loading.orders"
          :description="$t('Base.NoData')" />

        <OrdersTable
          v-show="orders?.length || loading.orders"
          :items="orders"
          :total="orders?.length"
          :per-page="orders?.length || 1"
          :page="1"
          :loading="loading.orders" />
      </div>

      <!--  Children  -->
      <div class="v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('User.Children') }}</div>
          <ElButton type="primary" plain @click="$emit('patient:createChildren')">
            {{ $t('User.AddChildren') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!patient.childrens?.length"
          :description="$t('Base.NoData')" />

        <PatientsTable
          v-show="patient?.childrens?.length || loading.profile"
          :items="patient.childrens"
          :loaing="loading.profile"
          :total="patient?.childrens?.length"
          :per-page="patient?.childrens?.length || 1"
          :page="1"
          hide-on-single-page
          :loading="loading.profile"
          :max-height="500"
          layout="prev, pager, next, sizes" />
      </div>
    </div>

    <div class="v-patient-default-side">
      <!--  HospitalsTable-->
      <div class="v-patient-default__item v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Hospital.Hospital') }}</div>
          <ElButton size="small" type="primary" plain @click="$emit('hospital:create')">
            {{ $t('Base.Create') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!hospital?.length && !loading.hospital"
          :description="$t('Base.NoData')" />

        <HospitalsTable
          :data="hospital"
          :total="hospital?.length"
          :loading="loading.hospital"
          :per-page="hospital?.length || 1"
          :page="1"
          :columns="{ user: false, department: false, status: false }" />
      </div>

      <!--  Treatments  -->
      <div class="v-patient-default__item v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Base.TableTreatment') }}</div>

          <ElButton
            v-show="!isManager"
            size="small"
            plain
            type="primary"
            @click="$emit('treatment:create')">
            {{ $t('Base.SetTreatment') }}
          </ElButton>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!treatments?.length && !loading.treatments"
          :description="$t('Base.NoData')" />

        <TreatmentsTable
          v-show="treatments?.length || loading.treatments"
          :items="treatments"
          :total="treatments?.length"
          :per-page="treatments?.length || 1"
          :page="1"
          :loading="loading.treatments"
          :columns="{ doctor: false, created: false }"
          :max-height="500"
          @item:update="$emit('treatment:updated', $event)" />
      </div>

      <!--  AnalyzesStatusesTable  -->
      <div class="v-patient-default__item v-patient-default-item">
        <div class="v-patient-default-item__header v-patient-default-item-header">
          <div class="v-patient-default__title">{{ $t('Laboratory.Analysis.Analyzes') }}</div>
          <!--          <ElButton size="small" type="primary" plain @click="$emit('hospital:create')">-->
          <!--            {{ $t('Laboratory.AssignAnalyzes') }}-->
          <!--          </ElButton>-->
          <div class="v-patient-default-item__action right">
            <p @click="goToAnalysisPatient">
              {{ $t('Laboratory.Analysis.AllAnalyzes') }}
            </p>
          </div>
        </div>

        <ElEmpty
          class="v-patient-default-item-empty"
          v-show="!orders?.length && !loading.orders"
          :description="$t('Base.NoData')" />

        <AnalyzesStatusesTable
          v-show="orders?.length || loading.orders"
          :items="orders"
          :loading="loading.orders" />
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
