<template>
  <UiTableWithPagination
    table-class="templates-table"
    :data="data"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="rowClickHandler">
    >
    <ElTableColumn prop="title" :label="$t('Templates.Templates')"></ElTableColumn>

    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="160px">
      <template #default="{ row }">
        <div class="templates-table__actions">
          <ElButton
            type="danger"
            :loading="deleteLoading && deleteItemId === row.id"
            plain
            @click.stop="deleteTemplate(row)">
            {{ $t('Base.Delete') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import axios from 'axios';

import { DOCTORS_TEMPLATE_ROUTE } from '@/router/doctors.routes';
import { InspectionCardTemplate } from '@/models/InspectionCardTemplate.model';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

export default {
  name: 'TemplatesTable',
  components: { UiTableWithPagination },
  props: {
    data: Array,
    loading: Boolean,
    page: Number,
    perPage: Number,
    total: Number,
  },
  data() {
    return {
      deleteLoading: false,
      deleteItemId: null,
    };
  },

  methods: {
    rowClickHandler(row) {
      this.goToTemplate(row);
    },

    goToTemplate(template) {
      this.$router.push({
        name: DOCTORS_TEMPLATE_ROUTE.name,
        params: { id: template.id },
      });
    },

    async deleteTemplate(template) {
      this.deleteLoading = true;
      this.deleteItemId = template.id;

      try {
        await InspectionCardTemplate.delete(template);
        // возможно template.id
        this.$store.dispatch('templates/deleteItem', template);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.deleteLoading = false;
      this.deleteItemId = null;
    },
  },
};
</script>

<style src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
