<template>
  <ElTag :type="typeByStatus[status]" effect="dark" round>
    {{ $t(`Hospital.Statuses.${status}`) }}
  </ElTag>
</template>

<script>
import { Hospital } from '@/models/hospital/Hospital.model';

export default {
  name: 'HospitalStatusTag',
  props: {
    status: String,
  },

  computed: {
    typeByStatus() {
      return {
        [Hospital.enum.statuses.IN_PROGRESS]: '',
        [Hospital.enum.statuses.COMPLETED]: 'success',
        [Hospital.enum.statuses.CANCELED]: 'danger',
      };
    },
  },
};
</script>
