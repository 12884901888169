import { NavigationGuardWithThis } from 'vue-router';

import { ROUTE_PATH, USER_KEY } from '../config';
import { UserRoleEnum } from '../api';

export const onlyUserIsAuthMiddleware: NavigationGuardWithThis<undefined> = function (
  _to,
  _from,
  next
) {
  const user = localStorage.getItem(USER_KEY);

  if (user) {
    return next();
  }

  next(ROUTE_PATH.auth.login);
};

export const onlyUserNotAuthMiddleware: NavigationGuardWithThis<undefined> = function (
  _to,
  _from,
  next
) {
  const user = localStorage.getItem(USER_KEY);

  if (user) {
    return next(ROUTE_PATH.root);
  }

  next();
};

export const onlyUserHasRoleMiddleware = (
  roles: UserRoleEnum[]
): NavigationGuardWithThis<undefined> => {
  return (_to, _from, next) => {
    const userAsString = localStorage.getItem(USER_KEY);
    const currentUser = userAsString ? JSON.parse(userAsString) : null;
    const hasAccessRole = currentUser?.role ? roles.includes(currentUser.role) : false;

    if (hasAccessRole) {
      return next();
    }

    return next(ROUTE_PATH.noAccess);
  };
};
