<template>
  <ElDialog
    width="480px"
    class="user-credentials-modal"
    :title="$t('User.EmployeeLoginAndPassword')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm class="user-credentials-form" as="ElForm" :initial-values="props.formData">
      <FieldGroupWrapper>
        <FormTextField :label="$t('User.Email')" field-name="email" maxlength="255" disabled />
        <FormTextField
          :label="$t('User.Password')"
          field-name="password"
          maxlength="255"
          disabled />
        <FormTextField
          :label="$t('User.ActivationKey')"
          field-name="key_activating"
          maxlength="255"
          disabled />
        <div class="user-credentials-form__hint">
          {{ $t('User.UserCredentialsHint') }}
        </div>
      </FieldGroupWrapper>
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'UserCredentialsModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { Form as VeeForm } from 'vee-validate';

import { AnyObject } from '@/types/common';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';

const props = defineProps<{
  formData: AnyObject;
}>();

defineEmits(['update:modelValue', 'action']);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/validation.locales.json" />
<i18n src="@/locales/ui.locales.json" />
