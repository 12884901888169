<template>
  <div
    :class="[
      'form-actions',
      {
        'form-actions_align-left': props.align === 'left',
        'form-actions_align-right': props.align === 'right',
        'form-actions_align-center': props.align === 'center',
      },
    ]">
    <slot />
  </div>
</template>

<script lang="ts">
export default {
  name: 'FormActionsWrapper',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
const props = withDefaults(defineProps<{ align?: 'left' | 'right' | 'center' }>(), {
  align: 'center',
});
</script>

<style lang="scss" src="./index.scss"></style>
