import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const APPOINTMENT_ROUTE = {
  name: 'Appointment',
  path: ROUTE_PATH.appointments.appointment.rawPath,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'doctor', 'nurse', 'laboratory']),
  ],
  meta: {
    title: 'Base.PatientRecord',
  },
};
