import axios from 'axios';

import { APPOINTMENT_ROUTE } from '@/router/appointments.routes';
import { PAGE_SIZES } from '~shared/config';
import { Appointment } from '@/models/appointment/Appointment.model';
import { User } from '@/models/User.model';
import InvoiceStatusTag from '@/components/invoices/InvoiceStatusTag/index.vue';
import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import AppointmentStatusTag from '@/components/appointments/AppointmentStatusTag/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import { MiIcon } from '~shared/ui';
import { useSessionStore } from '~entities/session';
import { ROUTE_PATH } from '~shared/config';
import { amplitudeService } from '~shared/lib';

export default {
  name: 'AppointmentsTable',
  components: { UiTableWithPagination, AppointmentStatusTag, InvoiceStatusTag, MiIcon },
  emits: ['update:perPage', 'update:page', 'item:edit'],
  props: {
    /** @type { Array<Appointment|object> } items */
    items: Array,
    loading: Boolean,
    page: Number,
    perPage: Number,
    total: Number,

    /** @typedef {"patient"|"patient.phone"|"doctor"|"start_at"|"end_at"|"payment"|"status"|"services"|"created_at"|"actions"} AppointmentsTableColumnKey */
    /** @type {Array<AppointmentsTableColumnKey>} excludeColumns */
    excludeColumns: Array,
  },
  data() {
    return {
      sessionStore: useSessionStore(),
      statusLoading: false,
    };
  },

  computed: {
    user() {
      return this.sessionStore.user;
    },

    isManager() {
      return this.user.role === User.enum.roles.Manager;
    },
    isDoctor() {
      return this.user.role === User.enum.roles.Doctor;
    },

    pageCount() {
      return Math.ceil(this.total / this.perPage);
    },
    pageSizes() {
      return PAGE_SIZES;
    },

    itemsWithPayload() {
      return this.items.map((elem) => ({
        ...elem,
        _start_at: elem.start_at?.split(' ')[1],
        _end_at: elem.end_at?.split(' ')[1],
        _services: elem.services.map((service) => service.title).join(', \n'),
      }));
    },

    columnsOptions() {
      return {
        'patient': {
          isShow: !this.excludeColumns?.includes('patient'),
        },
        'patient.phone': {
          isShow: !this.excludeColumns?.includes('patient.phone'),
        },
        'doctor': {
          isShow: !this.excludeColumns?.includes('doctor'),
        },
        'start_at': {
          isShow: !this.excludeColumns?.includes('start_at'),
        },
        'end_at': {
          isShow: !this.excludeColumns?.includes('end_at'),
        },
        'payment': {
          isShow: !this.excludeColumns?.includes('payment'),
        },
        'status': {
          isShow: !this.excludeColumns?.includes('status'),
        },
        'services': {
          isShow: !this.excludeColumns?.includes('services'),
        },
        'created_at': {
          isShow: !this.excludeColumns?.includes('created_at'),
        },
        'actions': {
          isShow: !this.excludeColumns?.includes('actions'),
        },
        'actions-eye': {
          isShow: !this.excludeColumns?.includes('actions-eye'),
        },
      };
    },
  },
  methods: {
    getTableRowClassName(row) {
      return `appointment_${row.row.status}`;
    },

    goToAppointment(payload) {
      // для регистратуры и лаборатории оставляем старую страницу с возможностью редиактирования услуги
      if (this.user?.role === 'manager' || this.user?.role === 'laboratory') {
        this.$router.push({
          name: APPOINTMENT_ROUTE.name,
          params: { id: payload.id },
        });
      } else {
        this.$router.push(ROUTE_PATH.appointments.appointment.byId(payload.id));
      }

      amplitudeService.logEvent('view_patient_medcard', {
        patient_id: payload.patient_id ?? '',
      });
    },

    addAppointment() {
      this.$store.dispatch('modalAndDrawer/openModal', CreateOrEditAppointmentModal);
    },

    sendStatisticCallToReceptionReception(id, patient_id) {
      amplitudeService.logEvent('call_patient_on_appointment', {
        patient_id: patient_id,
        appointment_id: id,
      });
    },

    sendStatisticEndReception(id, patient_id) {
      amplitudeService.logEvent('close_appointment', {
        patient_id: patient_id,
        appointment_id: id,
      });
    },

    async updateAppointmentStatus({ id, patient_id }, status) {
      if (status === 'waiting' || status === 'approved') {
        this.sendStatisticCallToReceptionReception(id, patient_id);
      }

      if (status === 'in_progress') {
        this.sendStatisticEndReception(id, patient_id);
      }

      try {
        this.statusLoading = true;
        const { data } = await Appointment.updateStatus({
          id: id,
          status: status,
        });
        this.$emit('item:edit', data.data);

        if (
          this.isDoctor &&
          [Appointment.enum.statuses.Waiting, Appointment.enum.statuses.InProgress].includes(status)
        )
          this.$router.push(ROUTE_PATH.appointments.appointment.byId(id));
        if (
          this.isDoctor &&
          [Appointment.enum.statuses.Provided, Appointment.enum.statuses.Canceled].includes(status)
        ) {
          this.sessionStore.closeActiveAppointmentForCurrentUser();
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      } finally {
        this.statusLoading = false;
      }
    },
  },
  setup: () => ({
    Appointment,
  }),
};
