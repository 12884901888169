<template>
  <div class="form-field-group">
    <div class="form-field-group__title" v-if="title">
      <div class="form-field-group__title-text">
        {{ title }}
      </div>

      <div class="form-field-group__title-append">
        <slot name="title-append" />
      </div>
      <slot name="actions" />
    </div>
    <div
      :class="[
        'form-field-group__content',
        {
          'form-field-group__content_grid-3-col': props.gridColumns === 'three-columns',
          'form-field-group__content_grid-5-col': props.gridColumns === 'five-columns',
        },
      ]">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'FieldGroupWrapper',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{ title?: string | null; gridColumns?: 'three-columns' | 'five-columns' | null }>(),
  {
    title: null,
    gridColumns: null,
  }
);
</script>

<style lang="scss" src="./index.scss"></style>
