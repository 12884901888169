<template>
  <ElDialog
    class="create-or-edit-analysis-modal"
    :title="$t('Laboratory.Analysis.Analysis')"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue')">
    <ElForm
      id="create-or-edit-analysis-modal-form"
      class="create-or-edit-analysis-modal__content"
      label-position="top"
      @submit.prevent="submitHandler">
      <ElFormItem :label="$t('Base.NamingShort')">
        <ElInput v-model="analysis.name" required />
      </ElFormItem>

      <ElFormItem :label="$t('Base.NamingInRu')">
        <ElInput v-model="analysis.title_ru" required />
      </ElFormItem>
      <ElFormItem :label="$t('Base.NamingInUz')">
        <ElInput v-model="analysis.title_uz" required />
      </ElFormItem>

      <ElFormItem :label="$t('Laboratory.Biomaterial.Biomaterial')">
        <SelectBiomaterial v-model="analysis.biomaterial_type" />
      </ElFormItem>

      <ElFormItem :label="$t('Laboratory.Analysis.Category')">
        <ElInput v-model="analysis.category" />
      </ElFormItem>

      <ElFormItem :label="$t('Laboratory.Analysis.ValueType')">
        <SelectAnalysisValueType v-model="analysis.value_type" />
      </ElFormItem>

      <ElFormItem :label="$t('Base.Measure')">
        <UiModelsAutocompleteSearch
          v-model="analysis.measure"
          label="title"
          value="title"
          :model-for-use="Measure"
          method-name="findForAnalysis"
          :required="formOptions.measure.required"
          :disabled="formOptions.measure.disabled" />
      </ElFormItem>

      <ElFormItem :label="$t('Base.Services')">
        <UiModelsAutocompleteSearch
          v-model="analysis.group_service_ids"
          :models="analysis.group_services"
          :default-item="data?.group_services"
          :model-for-use="ServiceGroup"
          label="title"
          multiple
          required
          @select="analysis.group_services = $event" />
      </ElFormItem>
    </ElForm>

    <template #footer>
      <ElButton
        type="primary"
        native-type="submit"
        form="create-or-edit-analysis-modal-form"
        :loading="loading">
        {{ analysis.id ? $t('Base.Edit') : $t('Base.Create') }}
      </ElButton>
    </template>
  </ElDialog>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
