<template>
  <ElDialog
    class="create-or-edit-references-value-modal"
    :title="$t('Laboratory.ReferencesValue')"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElForm
      class="create-or-edit-references-value-modal__content"
      id="create-or-edit-references-modal-form"
      label-position="top"
      @submit.prevent="submitHandler">
      <ElFormItem :label="$t('Laboratory.StartValue')">
        <ElInput
          v-model="referencesValue.start_value"
          :required="formOptions.start_value.required" />
      </ElFormItem>
      <ElFormItem :label="$t('Laboratory.EndValue')">
        <ElInput v-model="referencesValue.end_value" :required="formOptions.end_value.required" />
      </ElFormItem>
      <ElFormItem :label="$t('Base.Text')">
        <ElInput v-model="referencesValue.norm_text" :required="formOptions.norm_text.required" />
      </ElFormItem>

      <ElFormItem :label="$t('Laboratory.Analysis.Analysis')">
        <UiModelsAutocompleteSearch
          v-model="referencesValue.analysis_id"
          :model-for-use="Analysis"
          label="title"
          :default-item="referencesValue.analysis"
          :required="formOptions.analysis.required"
          :disabled="formOptions.analysis.disabled"
          @select="referencesValue.analysis = $event" />
      </ElFormItem>

      <ElFormItem :label="$t('Laboratory.ReferencesGroup')">
        <SelectReferencesGroup
          v-model="referencesValue.references_group_id"
          :default-item="referencesValue.references_group"
          :required="formOptions.references_group.required"
          :disabled="formOptions.references_group.disabled"
          @select="referencesValue.references_group = $event" />
      </ElFormItem>
    </ElForm>

    <template #footer>
      <ElButton
        type="primary"
        native-type="submit"
        form="create-or-edit-references-modal-form"
        :loading="loading">
        {{ referencesValue.id ? $t('Base.Save') : $t('Base.Create') }}
      </ElButton>
    </template>
  </ElDialog>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
