import { CRUDModel } from '@/models/CRUD.model';

/**
 * @class Department
 * @extends CRUDModel
 */
export class Department extends CRUDModel {
  static modelName = 'department';
  static tableName = 'departments';

  /**
   * @param {object} [payload]
   * @param {string} payload.title
   * @param {number} payload.clinic_id
   * @param {Clinic|object} payload.clinic
   */
  constructor(payload) {
    super(payload);

    this.title = payload?.title ?? null;
    this.clinic_id = payload?.clinic_id ?? null;
    this.clinic = payload?.clinic ?? null;
  }
}
