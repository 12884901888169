<template>
  <ElDialog class="mi-dialog" v-bind="$props" v-model="value">
    <template v-if="$slots.header" #header>
      <slot name="header" />
    </template>
    <template v-if="$slots.footer" #footer>
      <slot name="footer" />
    </template>
    <slot />
  </ElDialog>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ElDialog, DialogProps } from 'element-plus';

type Props = Partial<DialogProps>;

const props = withDefaults(defineProps<Props>(), {
  width: '480px',
  appendToBody: true,
  modal: true,
  lockScroll: true,
  closeOnPressEscape: true,
  closeOnClickModal: true,
  showClose: true,
});

const emits = defineEmits<{
  'update:modelValue': [e: boolean];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  },
});
</script>
<style lang="scss" src="./index.scss" />
