<template>
  <div class="transfer-goods-form">
    <div class="transfer-goods-form__selects">
      <FieldGroupWrapper :title="$t('TransferGoods.FromStock')">
        <FormSearchSelectField
          field-name="from_warehouse_id"
          field-object-name="from_warehouse"
          :placeholder="$t('TransferGoods.SelectToWarehouse')"
          :model-for-use="Warehouse"
          :in-column="true"
          @select="onFromWarehouseSelect"
          space-between
          show-create-option />
      </FieldGroupWrapper>
      <FieldGroupWrapper :title="$t('TransferGoods.ToWarehouse')">
        <FormSearchSelectField
          :disabled="fromWarehouseId === null"
          :search-query="{ not_id: fromWarehouseId }"
          field-name="to_warehouse_id"
          field-object-name="to_warehouse"
          :placeholder="$t('TransferGoods.SelectFromWarehouse')"
          :model-for-use="Warehouse"
          :in-column="true"
          space-between
          show-create-option />
      </FieldGroupWrapper>
    </div>
    <FieldGroupWrapper :title="$t('TransferGoods.GoodsTransfer')">
      <TransformGoodsTableFillingForm
        @table:add="addProductInTable"
        :from-warehouse-id="fromWarehouseId" />
      <TransformGoodsTable
        :loading="loading.table"
        :items="tableData.products"
        @table:delete="deleteProductInTable" />
    </FieldGroupWrapper>
    <FormActionsWrapper align="right">
      <ElButton
        class="transfer-goods-form__btn"
        type="primary"
        plain
        @click="submitForm"
        :disabled="submitDisabled">
        {{ $t('TransferGoods.HandOver') }}
      </ElButton>
    </FormActionsWrapper>
  </div>
</template>

<script lang="ts">
export default {
  name: 'TransferGoodsForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import { computed, reactive, ref } from 'vue';

import { Warehouse } from '@/models/warehouse/Warehouse.model';
import { validationSchema } from '@/components/warehouse/modals/TransferGoodsCrudModal/TransferGoodsForm/validationSchema';
import { MigrationFormData, OutcomeProductDto, WarehouseDto } from '@/types/api';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import TransformGoodsTableFillingForm from '@/components/warehouse/modals/TransferGoodsCrudModal/TransformGoodsTableFillingForm/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
import TransformGoodsTable from '@/components/warehouse/modals/TransferGoodsCrudModal/TransformGoodsTable/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';

const emit = defineEmits(['close:modal']);

const addProductInTable = (product: OutcomeProductDto) => {
  tableData.products.push(product);
};

const tableData = reactive<{ products: OutcomeProductDto[] }>({
  products: [],
});

const { isSubmitting, errors, handleSubmit } = useForm<MigrationFormData>({
  validationSchema,
});

const submitDisabled = computed(
  () =>
    Object.keys(errors.value).length > 0 || isSubmitting.value || tableData.products.length === 0
);

const loading = reactive({
  table: false,
});

const fromWarehouseId = ref<number | null>(null);
const onFromWarehouseSelect = (warehouse: WarehouseDto) => {
  fromWarehouseId.value = warehouse.id;
};

const deleteProductInTable = (id: number) => {
  tableData.products = tableData.products.filter((f) => f.product_id !== id);
};

const submitForm = handleSubmit(
  async (values) => {
    const requestData = {
      products: tableData.products,
      old_warehouse_id: values.from_warehouse_id,
      new_warehouse_id: values.to_warehouse_id,
    };

    await Warehouse.migrateProducts(requestData);

    emit('close:modal');
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss" />
