import axios from 'axios';

import { PrinterService } from '@/services/printer.service';
import { formatPrice } from '@/utils/price.util';
import { Invoice } from '@/models/Invoice.model';
import { ServiceGroup } from '@/models/ServiceGroup.model';
import { Transaction } from '@/models/Transaction.model';
import { Patient } from '@/models/Patient.model';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { MiIcon } from '@/shared/ui';
import { amplitudeService } from '~shared/lib';
import CreateInvoicePaymentSubject from '@/components/invoices/CreateOrEditOrPayInvoiceModal/CreateInvoicePaymentSubject/index.vue';
import PaymentSubjectsTable from '@/components/invoices/CreateOrEditOrPayInvoiceModal/PaymentSubjectsTable/index.vue';
import PatientsSearchSelectDataBlock from '@/components/patients/PatientsSearchSelectDataBlock/index.vue';
import PatientsSearchSelect from '@/components/patients/PatientsSearchSelect/index.vue';
import UiModelsAutocompleteSearch from '@/components/ui/UiModelsAutocompleteSearch/index.vue';
import InvoiceStatusTag from '@/components/invoices/InvoiceStatusTag/index.vue';
import InvoicePayOrRefundModal from '@/components/invoices/InvoicePayOrRefundModal/index.vue';
import TransactionsTable from '@/components/transactions/TransactionsTable/index.vue';
import InvoicePrinterCheck from '@/components/invoices/InvoicePrinterCheck/index.vue';
import CreateOrEditPatient from '@/components/patients/CreateOrEditPatientModal/CreateOrEditPatient/index.vue';
import SelectReference from '@/components/appointments/SelectReference/index.vue';

export default {
  name: 'CreateOrEditOrPayInvoiceModal',
  components: {
    CreateOrEditPatient,
    CreateInvoicePaymentSubject,
    PaymentSubjectsTable,
    InvoicePayOrRefundModal,
    InvoiceStatusTag,
    UiModelsAutocompleteSearch,
    PatientsSearchSelect,
    PatientsSearchSelectDataBlock,
    TransactionsTable,
    InvoicePrinterCheck,
    SelectReference,
    MiIcon,
  },
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    id: [Number, String],
  },
  data() {
    return {
      /** @type {Invoice} invoice */
      invoice: new Invoice(),
      loading: {
        invoice: false,
        form: false,
        print: false,
        printBracelet: false,
      },
      payModalIsOpen: false,
      payType: Transaction.enum.types.PayIn,

      check: {
        qrPayload: null,
      },
    };
  },
  computed: {
    totalPrice() {
      let sum = this.invoice.id
        ? this.invoice.discounted_amount
        : this.invoice.payment_subjects.reduce(
            (acc, elem) => acc + elem.subject.price * elem.count,
            0
          );
      sum =
        !this.invoice.id && this.invoice.discount > 0
          ? sum - sum * (this.invoice.discount / 100)
          : sum;

      return `${formatPrice({ price: sum })} ${this.$t('Base.Sum')}`;
    },

    editable() {
      return this.id ? this.invoice.status === Invoice.enum.statuses.NotPaid : true;
    },
    isShowPayButton() {
      return (
        !!this.invoice.id &&
        [Invoice.enum.statuses.NotPaid, Invoice.enum.statuses.PartiallyPaid].includes(
          this.invoice.status
        )
      );
    },
    isShowRefundButton() {
      return (
        !!this.invoice.id &&
        [
          Invoice.enum.statuses.Paid,
          Invoice.enum.statuses.PartiallyPaid,
          Invoice.enum.statuses.Overpay,
        ].includes(this.invoice.status)
      );
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.invoice = new Invoice({});
      },
      immediate: true,
    },

    id: {
      handler(value) {
        if (value) this.getInvoiceById();
      },
      immediate: true,
    },
  },

  methods: {
    async getInvoiceById() {
      this.loading.invoice = true;
      const { invoice } = await Invoice.findOneById(this.id);
      this.invoice = new Invoice(invoice);
      this.loading.invoice = false;
    },

    async submitHandler() {
      if (this.loading.form || !this.validate()) return;
      this.loading.form = true;

      try {
        const invoice = this.invoice.id ? await this.update() : await this.create();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.invoice.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalInstance({
            name: this.invoice.id ? 'updated' : 'created',
            data: { invoice },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.form = false;
    },

    async create() {
      amplitudeService.logEvent('invoice_create');

      if (this.$refs.patient.isNewPatientFlow()) {
        const patient = await this.$refs.patient.createPatient();
        this.invoice.user_id = patient.id;
        this.invoice.user = patient;
      }

      const { invoice } = await Invoice.create(this.invoice);
      return invoice;
    },

    async update() {
      amplitudeService.logEvent('invoice_modal.save');
      const { invoice } = await Invoice.update({ id: this.invoice.id, payload: this.invoice });
      return invoice;
    },

    /**
     * Доролнительная проверка
     * @return {boolean} если успешно true
     */
    validate() {
      // not invoice.user_id
      if (this.$refs.patient.isNewPatientFlow() && !this.$refs.patient.validate()) return false;

      if (!this.invoice.payment_subjects.length) {
        this.$notify({
          type: 'error',
          title: this.$t('Appointments.SelectServices'),
        });
        return false;
      }

      return true;
    },

    /** @param {InvoicePaymentSubject|object} subject */
    attachPaymentSubject(subject) {
      // В текущей реализации используется только ServiceGroup
      this.invoice.subjects.unshift({ group_service_id: subject.subject.id, count: subject.count });
      this.invoice.payment_subjects.unshift(subject);
    },

    /**
     * @param {InvoicePaymentSubject|object} paymentSubject
     * @param {InvoicePaymentSubject|object} oldPaymentSubject
     * */
    // TODO: при избавлении от subjects переделать
    updatePaymentSubject({ paymentSubject, oldPaymentSubject }) {
      // В текущей реализации используется только ServiceGroup
      const subjectIndex = this.invoice.subjects.findIndex(
        (subject) =>
          subject.group_service_id === oldPaymentSubject.subject.id &&
          subject.count === oldPaymentSubject.count
      );
      if (subjectIndex >= 0) this.invoice.subjects[subjectIndex].count = paymentSubject.count;

      const paymentSubjectIndex = this.invoice.payment_subjects.findIndex((subject) =>
        subject.id && paymentSubject.id
          ? subject.id === paymentSubject.id
          : subject._id === paymentSubject._id
      );
      this.invoice.payment_subjects[paymentSubjectIndex] = paymentSubject;
    },

    /** @param {InvoicePaymentSubject|object} paymentSubject */
    deletePaymentSubject(paymentSubject) {
      // В текущей реализации используется только ServiceGroup
      const subjectIndex = this.invoice.subjects.findIndex(
        (subject) =>
          subject.group_service_id === paymentSubject.subject.id &&
          subject.count === paymentSubject.count
      );

      this.invoice.subjects = this.invoice.subjects.filter((elem, index) => index !== subjectIndex);
      this.invoice.payment_subjects = this.invoice.payment_subjects.filter((elem) =>
        elem.id && paymentSubject.id
          ? elem.id !== paymentSubject.id
          : elem._id !== paymentSubject._id
      );
    },

    pay() {
      amplitudeService.logEvent('invoice_modal.open_create_transaction_modal', {
        appointment_ids: this.invoice?.appointments_ids,
        doctor_ids: this.invoice?.appointments.map((appointment) => appointment.doctor_id),
        patient_id: this.invoice.user?.id,
      });

      this.payType = Transaction.enum.types.PayIn;
      this.payModalIsOpen = true;
    },
    refund() {
      amplitudeService.logEvent('invoice_modal.open_refund_modal');
      this.payType = Transaction.enum.types.PayOut;
      this.payModalIsOpen = true;
    },

    async invoicePayModalActionHandler(action) {
      this.$emit('action', action);
      this.payModalIsOpen = false;
    },

    async print() {
      amplitudeService.logEvent('invoice_modal.print');

      if (this.loading.print) return;
      this.loading.print = true;

      try {
        const { data } = await Patient.getQrCodeLinkByUserId(this.invoice.user?.id);
        this.check.qrPayload = data.link;
        this.$refs.invoiceCheck.print();
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.print = false;
    },

    async printBracelet() {
      amplitudeService.logEvent('invoice_modal.print_bracelet');

      if (this.loading.printBracelet) return;
      this.loading.printBracelet = true;

      try {
        await PrinterService.printBraceletByPatientId(this.invoice.user?.id);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.printBracelet = false;
    },
  },

  mounted() {
    if (this.id) {
      amplitudeService.logEvent('open_invoice_modal');
    } else {
      amplitudeService.logEvent('invoice_modal.open');
    }
  },

  setup: () => ({
    ServiceGroup,
    Invoice,
  }),
};
