import { CRUDModel } from '@/models/CRUD.model';
import { apiService } from '~shared/api';

export class Treatment extends CRUDModel {
  static modelName = 'treatment';
  static tableName = 'treatments';

  /**
   * @typedef TreatmentAppointment
   * @property {number|string} day
   * @property {TreatmentDrug|object} drug
   * @property {Appointment|object} appointment
   */
  /**
   * @param {object} [payload]
   * @param {string} payload.title
   * @param {number} payload.user_id
   * @param {Patient|User} payload.user
   * @param {number} payload.doctor_id
   * @param {Doctor} payload.doctor
   * @param {string} payload.status
   * @param {Array<TreatmentAppointment>} payload.appointments
   *
   * @param {number} payload.discount
   * @param {number} payload.discounted_amount
   * @param {string} payload.description
   * @param {Array<DiseaseCode>} payload.diseases_codes
   * @param {Array<number>} payload.diseases_code_codes
   * @param {Array<TreatmentDrug>} payload.drugs
   *
   * @param {string|Date} payload.created_at
   */
  constructor(payload) {
    super(payload);

    this.title = payload?.title ?? null;
    this.user_id = payload?.user_id ?? null;
    this.user = payload?.user ?? null;
    this.doctor_id = payload?.doctor_id ?? null;
    this.doctor = payload?.doctor ?? null;
    this.status = payload?.status ?? null;
    this.appointments = payload?.appointments ?? [];

    this.discount = payload?.discount ?? 0;
    this.description = payload?.description ?? null;
    this.diseases_codes = payload?.diseases_codes ?? null;
    this.diseases_code_codes = payload?.diseases_code_codes ?? null;
    this.drugs = payload?.drugs ?? [];

    this.created_at = payload?.created_at ?? null;
  }

  static async findByUserId(userId) {
    const response = await apiService.api.instance.get(`${this.tableName}/${userId}`);
    return {
      response,
      data: response.data,
      [this.modelName]: response.data.data,
    };
  }
  static async findById(id) {
    const response = await apiService.api.instance.get(`${this.tableName}/${id}/get`);
    return {
      response,
      data: response.data,
      [this.modelName]: response.data.data,
    };
  }

  static async close(id) {
    const response = await apiService.api.instance.put(`${this.tableName}/${id}/closed`);
    return {
      response,
      data: response.data,
      [this.modelName]: response.data.data,
    };
  }

  /**
   * @param {number} id
   * @return {Promise<{data: response.data, response: AxiosResponse<any>, invoice: Invoice}>}
   */
  static async getInvoiceByTreatmentId(id) {
    const response = await apiService.api.instance.post(`${this.tableName}/${id}/invoice`);
    return {
      response,
      data: response.data,
      invoice: response.data.data,
    };
  }

  /**
   * @param {Treatment|object} treatment
   * @return number
   */
  static calculateDiscountedAmount(treatment) {
    const sum = treatment.drugs.reduce((acc, drug) => acc + drug.price * drug.count, 0);
    return treatment.discount > 0 ? sum * ((100 - treatment.discount) / 100) : sum;
  }

  /**
   * @param {Treatment|object} treatment
   * @return {number}
   */
  static calculateDuration(treatment) {
    return Math.max(...treatment.drugs.map((drug) => drug.count));
  }

  static enum = {
    statuses: {
      Created: 'created',
      Closed: 'closed',
    },
  };
}
