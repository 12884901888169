<template>
  <ElCard class="treatment-card" :shadow="shadow">
    <div v-for="part in items" :key="part[0].label" class="treatment-card__part-wrapper">
      <div v-for="item in part" :key="item.title" class="treatment-card__part treatment-card-part">
        <div class="treatment-card-part__title">{{ item.label }}</div>
        <div class="treatment-card-part__value">{{ item.value }}</div>
      </div>
    </div>
  </ElCard>
</template>

<script>
import { Treatment } from '@/models/Treatment.model';

export default {
  name: 'TreatmentCard',
  emits: ['treatment:update'],
  props: {
    data: [Treatment, Object],
    loading: Boolean,
    shadow: {
      type: String,
      default: 'never',
    },
  },
  computed: {
    items() {
      return [
        [
          {
            label: this.$t('Base.Naming'),
            value: this.data.title,
          },
          {
            label: this.$t('Patients.Patient'),
            value: this.data.user.name,
          },
          {
            label: this.$t('Base.Doctor'),
            value: this.data.doctor.name,
          },
        ],
        [
          {
            label: this.$t('Base.Status'),
            value: this.$t(`Treatments.Statuses.${this.data.status}`),
          },
          {
            label: this.$t('Treatments.Duration'),
            value: `${Treatment.calculateDuration(this.data)} ${this.$t('Base.Days')}`,
          },
          {
            label: this.$t('Base.Notes'),
            value: this.data.description,
          },
        ],
      ];
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/treatments.locales.json" />
