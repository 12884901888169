<template>
  <MiCard class="employee-info-card" v-loading="loading">
    <template #header>
      <div class="employee-info-card__header">
        <div class="employee-info-card__header-left">
          <h3 class="employee-info-card__name">{{ employee?.name }}</h3>
        </div>
        <div class="employee-info-card__header-actions">
          <MiButton
            :disabled="!employee?.id"
            @click="handleForwardEmployeeProfile"
            type="primary"
            size="small"
            plain>
            {{ $t('Bookkeeping.EmployeeProfile') }}
          </MiButton>
        </div>
      </div>
    </template>
    <div class="employee-info-card__body">
      <div>
        <MiAvatar
          :src="employee?.doctor?.avatar?.link"
          class="employee-info-card__avatar"
          shape="circle"
          :size="184" />
      </div>
      <div class="employee-info-card__body-right">
        <MiInfoField :label="$t('User.Birthdate')">
          {{ employee?.birthdate ?? '...' }}
        </MiInfoField>
        <MiInfoField :label="$t('User.PhoneNumber')">
          {{ employee?.phone ?? '...' }}
        </MiInfoField>
        <MiInfoField :label="$t('User.Address')">
          {{ employee?.address ?? '...' }}
        </MiInfoField>
        <MiInfoField :label="$t('Bookkeeping.Specialization')">
          {{ specializations ?? '...' }}
        </MiInfoField>
        <MiInfoField :label="$t('Bookkeeping.EmployeeSalary')">
          {{ employee?.salary ?? '...' }}
        </MiInfoField>
        <MiInfoField :label="$t('Bookkeeping.AppointmentFee')">
          {{ employee?.appointment_commission ? `${employee.appointment_commission} %` : '...' }}
        </MiInfoField>
        <MiInfoField :label="$t('Bookkeeping.AdmissionPee')">
          {{
            employee?.doctor_recommendation_commission
              ? `${employee.doctor_recommendation_commission} %`
              : '...'
          }}
        </MiInfoField>
        <MiInfoField class="employee-info-card__schedule" :label="$t('Bookkeeping.Schedule')">
          <ScheduleList
            class="employee-info-card__schedule"
            :working-time="employee?.working_time" />
        </MiInfoField>
      </div>
    </div>
  </MiCard>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { computed, shallowRef, ref, watch } from 'vue';

import { ScheduleList } from '../ScheduleList';

import { EmployeeFullDto, misB2BApi } from '~shared/api';
import { ROUTE_PATH } from '~shared/config';
import { MiCard, MiButton, MiAvatar, MiInfoField } from '~shared/ui';

const props = defineProps<{
  employeeId?: number | null;
}>();

const emits = defineEmits<{
  updateReferrerId: [event: number | null];
}>();
const router = useRouter();

const employee = ref<EmployeeFullDto | null>(null);
const loading = shallowRef(false);

const fetchEmployee = async (id: number) => {
  employee.value = null;
  loading.value = true;

  const response = await misB2BApi.usersTemp.getById(id);

  if (response && response?.data) {
    employee.value = response.data;
    emits('updateReferrerId', response.data.referrer_id);
  }

  loading.value = false;
};

const handleForwardEmployeeProfile = () => {
  if (!employee.value) return;

  if (employee.value?.doctor?.id) {
    router.push(ROUTE_PATH.users.doctor.byId(employee.value.doctor.id));
  } else {
    router.push(ROUTE_PATH.users.user.byId(employee.value.id));
  }
};

const specializations = computed(() => {
  return employee.value?.doctor?.specialties.map((item) => item.title).join(' ');
});

watch(
  () => props.employeeId,
  (value) => {
    if (value) {
      fetchEmployee(value);
    }
  },
  {
    immediate: true,
  }
);
</script>

<style lang="scss" src="./index.scss" />
