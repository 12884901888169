import { defineStore } from 'pinia';
import { shallowRef } from 'vue';

export const useSalaryStore = defineStore('salaryStore', () => {
  const confirmModalVisible = shallowRef(false);
  const amountToBePaid = shallowRef(0);

  function setAmountToBePaid(payload: number) {
    amountToBePaid.value = payload;
  }

  return {
    setAmountToBePaid,
    amountToBePaid,
    confirmModalVisible,
  };
});
