<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="goToTreatment">
    <ElTableColumn
      v-if="columns?.doctor ?? true"
      column-key="doctor"
      prop="doctor.name"
      :label="$t('Base.DoctorFullName')" />
    <ElTableColumn prop="title" :label="$t('Base.Naming')" />
    <ElTableColumn
      v-if="columns?.created ?? true"
      column-key="created"
      prop="created_at"
      :label="$t('Base.DateAppoint')" />
    <ElTableColumn :label="$t('Base.Status')">
      <template #default="{ row }">
        <TreatmentStatusTag :status="row.status" />
      </template>
    </ElTableColumn>

    <ElTableColumn v-if="isDoctor" prop="actions" :label="$t('Base.Actions')">
      <template #default="{ row }">
        <div class="treatments-table-actions">
          <ElButton
            v-show="row.status !== Treatment.enum.statuses.Closed && !actionShow"
            type="primary"
            :loading="localLoading.closeTreatment && closedTreatmentId == row.id"
            @click.stop="closeTreatment(row)">
            {{ $t('Treatments.CloseTreatment') }}
          </ElButton>

          <ElButton v-show="actionShow" type="primary" text @click.stop="goToTreatment(row)">
            <template #icon>
              <MiIcon icon="EYE" />
            </template>
            {{ $t('Base.Open') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import axios from 'axios';

import { PAGE_SIZES } from '~shared/config';
import { TREATMENT_ROUTE } from '@/router/treatments.routes';
import { Treatment } from '@/models/Treatment.model';
import { User } from '@/models/User.model';
import TreatmentStatusTag from '@/components/treatments/TreatmentStatusTag/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import { MiIcon } from '~shared/ui';
import { useSessionStore } from '~entities/session';

export default {
  name: 'TreatmentsTable',
  components: { UiTableWithPagination, TreatmentStatusTag, MiIcon },
  emits: ['item:update', 'update:page', 'update:perPage'],
  props: {
    /** @param { Array<Treatment|object> } items */
    items: Array,
    loading: Boolean,
    page: Number,
    perPage: Number,
    total: Number,
    search: String,
    actionShow: Boolean,
    columns: Object,
  },
  data() {
    return {
      localLoading: {
        closeTreatment: false,
      },
      closedTreatmentId: null,
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },

    isDoctor() {
      return this.user.role === User.enum.roles.Doctor;
    },

    hasItems() {
      return !!this.items.length;
    },
    pageCount() {
      return Math.ceil(this.total / this.perPage);
    },
    pageSizes() {
      return PAGE_SIZES;
    },

    itemsWithPayload() {
      return this.items.map((elem) => ({
        ...elem,
        _duration: Treatment.calculateDuration(elem),
      }));
    },
  },

  methods: {
    goToTreatment(row) {
      this.$router.push({
        name: TREATMENT_ROUTE.name,
        params: {
          id: row.id,
        },
      });
    },

    async closeTreatment(treatment) {
      if (this.localLoading.closeTreatment) return;
      this.localLoading.closeTreatment = true;
      this.closedTreatmentId = treatment.id;

      try {
        const { data } = await Treatment.close(treatment.id);
        this.$emit('item:update', data.data);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.localLoading.closeTreatment = false;
      this.closedTreatmentId = null;
    },
  },

  setup: () => ({
    Treatment,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/treatments.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
