import { apiService } from '~shared/api';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { QueryParams } from '@/types/common';
import { MetaDto, OutcomeDto, OutcomePayloadDto } from '@/types/api';

export type OutcomeFormData = Pick<OutcomeDto, 'type'> & {
  product_id: OutcomeDto['product_id'] | null;
  product: OutcomeDto['product'] | null;
  warehouse_id: OutcomeDto['warehouse_id'] | null;
  warehouse: OutcomeDto['warehouse'] | null;
  count_outcome: OutcomeDto['count_outcome'] | null;
  left_count: OutcomeDto['left_count'] | null;
};

export class Outcome {
  static modelName = 'outcome';
  static tableName = 'outcomes';

  product_id: OutcomeFormData['product_id'];
  product: OutcomeFormData['product'];
  warehouse_id: OutcomeFormData['warehouse_id'];
  warehouse: OutcomeFormData['warehouse'];
  type: OutcomeFormData['type'];
  count_outcome: OutcomeFormData['count_outcome'];
  left_count: OutcomeFormData['left_count'];

  constructor(payload?: OutcomeFormData) {
    this.product_id = payload?.product_id ?? null;
    this.product = payload?.product ?? null;
    this.warehouse_id = payload?.warehouse_id ?? null;
    this.warehouse = payload?.warehouse ?? null;
    this.type = payload?.type ?? 'sale';
    this.count_outcome = payload?.count_outcome ?? null;
    this.left_count = payload?.left_count ?? null;
  }

  static async getItems(query: QueryParams) {
    const response = await apiService.api.instance.get<{ data: OutcomeDto[]; meta: MetaDto }>(
      mergeOrCreateQuery({
        url: `${this.tableName}`,
        query: deleteEmptyValueKeys(query),
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async createItem(payload: OutcomePayloadDto) {
    const response = await apiService.api.instance.post<{ data: OutcomeDto }>(
      `${this.tableName}/create`,
      payload
    );

    return {
      response: response,
      data: response.data,
    };
  }
}
