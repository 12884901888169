<template>
  <div class="ui-models-autocomplete-search">
    <ElSelect
      :class="['ui-models-autocomplete-search__select', $attrs.class]"
      :model-value="modelValue"
      remote
      filterable
      reserve-keyword
      :remote-method="debouncedGetItems"
      remote-show-suffix
      :loading="loading"
      :multiple="multiple"
      :disabled="disabled"
      :placeholder="placeholder || $t('Base.PleaseInput')"
      :clearable="clearable"
      :no-data-text="$t('Base.NoData')"
      :collapse-tags="collapseTags"
      :collapse-tags-tooltip="collapseTagsTooltip"
      @input="inputHandler"
      @update:model-value="$emit('update:modelValue', $event)"
      @visible-change="getItems"
      @change="selectHandler"
      popper-class="ui-models-autocomplete-search__popper"
      ref="component">
      <template #prefix>
        <MiIcon class="ui-models-autocomplete-search__icon" icon="SEARCH" />
      </template>
      <ElOption
        class="ui-models-autocomplete-search__option"
        v-for="item in items"
        :key="item[value]"
        :label="item[label]"
        :value="item[value]">
        <slot :item="item" :label="item[label]" :value="item[value]"></slot>
      </ElOption>

      <template #empty>
        <div class="ui-models-autocomplete-search-empty">
          <div class="ui-models-autocomplete-search-empty__text">
            {{ loading ? $t('Base.Loading') : $t('Base.NoData') }}
          </div>

          <ElButton
            v-if="showCreateOption"
            v-show="!loading"
            class="ui-models-autocomplete-search-empty__create"
            type="primary"
            size="default"
            @click="createItem">
            <template #icon>
              <MiIcon icon="PLUS" />
            </template>
            {{ $t('Base.Create') }}
          </ElButton>
        </div>
      </template>
    </ElSelect>

    <UiRequiredHiddenInput v-show="required" :model-value="modelValue" :required="required" />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';

import { MiIcon } from '~shared/ui';
import { CRUDModel } from '@/models/CRUD.model';

export default {
  name: 'UiModelsAutocompleteSearch',
  emits: ['update:modelValue', 'update:models', 'create', 'select', 'getItems'],
  components: { MiIcon },
  slots: ['default', 'empty', 'create'],
  props: {
    modelValue: [Number, String, Boolean, Array],
    /**
     * Обязательно при использовании multiple
     * Иначе не возможно получить всю модель при селекте
     */
    models: {
      type: Array,
      default: () => [],
    },

    // принимает все классы расширяющий CRUDModel
    modelForUse: [CRUDModel, Function],
    // для поиска вызвается find метод этого класса
    // TODO: заменить на fetch функцию
    methodName: {
      type: String,
      default: 'find',
    },

    defaultItem: [CRUDModel, Object, Array],
    searchQuery: Object,
    // поле для показа
    label: {
      type: String,
      default: 'name',
    },
    // поле для значения
    value: {
      type: [Number, String],
      default: 'id',
    },

    formatter: Function, // formatter for input
    showCreateOption: Boolean,
    multiple: Boolean,
    required: Boolean,
    disabled: Boolean,
    placeholder: String,
    clearable: Boolean,
    collapseTags: Boolean,
    collapseTagsTooltip: Boolean,
  },
  data() {
    return {
      query: '',
      items: [],
      loading: false,
      debouncedGetItems: null,
    };
  },
  watch: {
    modelValue: {
      handler(value) {
        if (!value) this.query = '';
      },
    },
    defaultItem: {
      handler() {
        if (this.defaultItem && !!this.defaultItem.id) {
          this.items = this.defaultItem instanceof Array ? this.defaultItem : [this.defaultItem];
        }
      },
      immediate: true,
    },
  },

  methods: {
    /** @param {InputEvent} event */
    inputHandler(event) {
      if (this.formatter) event.target.value = this.formatter(event.target.value);
    },

    async getItems(query) {
      if (typeof query === 'string' && query) {
        this.$emit('getItems');
      }

      this.loading = true;

      const { data } = await this.modelForUse[this.methodName]({
        page: 1,
        per_page: 30,
        query_type: 'ILIKE',
        query_field: [this.label],
        query_operator: 'OR',
        search: typeof query === 'string' ? query : '',

        ...(this.searchQuery || {}),
      });

      this.items = data.data;

      this.loading = false;
    },

    selectHandler(idOrIds) {
      let result = this.multiple ? [] : this.items.find((elem) => elem[this.value] === idOrIds);
      if (this.multiple) {
        result = idOrIds.map((elem) => {
          return (
            this.models.find((item) => item[this.value] === elem) ??
            this.items.find((item) => item[this.value] === elem)
          );
        });
      }
      const cloneResult = cloneDeep(result);

      this.$emit('update:models', cloneResult); // TODO: кажется это лишнее
      this.$emit('select', cloneResult);
    },

    createItem() {
      return this.$emit('create', this.$refs.component.query);
    },

    focus() {
      this.$refs.component.focus();
    },
    blur() {
      this.$refs.component.blur();
    },

    // На multiple селектах с поиском теряется фокус
    fixFocusBug() {
      Array.from(document.querySelectorAll('.ui-models-autocomplete-search__popper')).forEach(
        (elem) => elem.removeAttribute('tabIndex')
      );
    },
  },

  updated() {
    if (this.models && this.models.length > 0) {
      const filteredModels = this.models.filter((f) => {
        return !!f;
      });
      const ids = filteredModels.map((item) => item[this.value ?? 'id']);
      const items = this.items.filter((f) => {
        return !ids.includes(f[this.value ?? 'id']);
      });

      this.items = [...filteredModels, ...items];
    }
  },
  mounted() {
    this.debouncedGetItems = debounce(this.getItems, 350);
    this.fixFocusBug();
  },
  beforeUnmount() {
    this.debouncedGetItems.cancel();
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
