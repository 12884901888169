import { CRUDModel } from '@/models/CRUD.model';

/**
 * @class Chamber
 * @extends CRUDModel
 */
export class Chamber extends CRUDModel {
  static modelName = 'chamber';
  static tableName = 'chambers';

  /**
   * @param {object} [payload]
   * @param {number|string} payload.number
   * @param {string} payload.class
   * @param {string} payload.location
   * @param {number} payload.capacity
   * @param {number} payload.reside
   * @param {number} payload.price
   *
   * @param {number} payload.department_id
   * @param {Department|object} payload.department
   * @param {number} payload.clinic_id
   * @param {Clinic|object} payload.clinic
   */
  constructor(payload) {
    super(payload);

    this.number = payload?.number ?? null;
    this.class = payload?.class ?? null;
    this.location = payload?.location ?? null;
    this.capacity = payload?.capacity ?? null;
    this.reside = payload?.reside ?? null;
    this.price = payload?.price ?? null;

    this.department_id = payload?.department_id ?? null;
    this.department = payload?.department ?? null;
    this.clinic_id = payload?.clinic_id ?? null;
    this.clinic = payload?.clinic ?? null;
  }
}
