import { CRUDModel } from '@/models/CRUD.model';

export class Analysis extends CRUDModel {
  static modelName = 'analysis';
  static tableName = 'analyses';

  /**
   * @param {object} [payload]
   * @param {string} payload.name
   * @param {string} payload.title
   * @param {string} payload.title_ru
   * @param {string} payload.title_uz
   * @param {string} payload.biomaterial_type
   * @param {string} payload.biomaterial_type_translate
   * @param {string} payload.category
   * @param {string} payload.measure
   * @param {string} payload.value_type
   *
   * @param {Array<number>} payload.group_service_ids
   * @param {Array<ServiceGroup>} payload.group_services
   * @param {string} payload.created_at DATE_FORMAT
   */
  constructor(payload) {
    super(payload);

    this.name = payload?.name ?? null;
    this.title = payload?.title ?? null;
    this.title_ru = payload?.title_ru ?? null;
    this.title_uz = payload?.title_uz ?? null;
    this.biomaterial_type = payload?.biomaterial_type ?? null;
    this.biomaterial_type_translate = payload?.biomaterial_type_translate ?? null;
    this.category = payload?.category ?? null;
    this.measure = payload?.measure ?? null;
    this.value_type = payload?.value_type ?? Analysis.enum.valueTypes.Default;

    this.group_service_ids = payload?.group_service_ids ?? [];
    this.group_services = payload?.group_services ?? [];
    this.created_at = payload?.created_at ?? null;
  }

  static enum = {
    valueTypes: {
      Default: 'default',
      Boolean: 'boolean',
      Textarea: 'textarea',
      File: 'file',
    },
  };
}
