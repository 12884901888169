import { CRUDModel } from '@/models/CRUD.model';
import { apiService } from '~shared/api';
import { mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';
import { QueryParams } from '@/types/common';
import { MetaDto, ProductDto, ProductWithCountDto, ProductWithWarehousesDto } from '@/types/api';

export type ProductFormData = Omit<ProductDto, 'id' | 'price'> & {
  id?: number | null;
  price?: number | null;
};

export class Product extends CRUDModel {
  static modelName = 'product';
  static tableName = 'products';

  title: ProductFormData['title'];
  article: ProductFormData['article'];
  price: ProductFormData['price'];
  description: ProductFormData['description'];

  constructor(payload?: ProductFormData) {
    super(payload);

    this.title = payload?.title ?? '';
    this.article = payload?.article ?? '';
    this.price = payload?.price ?? null;
    this.description = payload?.description ?? '';
  }

  static async getItems(query: QueryParams) {
    const response = await apiService.api.instance.get<{ data: ProductDto[]; meta: MetaDto }>(
      mergeOrCreateQuery({
        url: `${this.tableName}`,
        query: deleteEmptyValueKeys(query),
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async getItemsWithCountByWarehouseId(query: QueryParams) {
    const response = await apiService.api.instance.get<{ data: ProductWithCountDto[] }>(
      mergeOrCreateQuery({
        url: `${this.tableName}/withCount`,
        query: deleteEmptyValueKeys(query),
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async getItemsWithWarehouses(query: QueryParams) {
    const response = await apiService.api.instance.get<{
      data: ProductWithWarehousesDto[];
      meta: MetaDto;
    }>(
      mergeOrCreateQuery({
        url: `${this.tableName}/byWarehouses`,
        query: deleteEmptyValueKeys(query),
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async getCountExistsItemsWithCountByWarehouseId(query: QueryParams) {
    const response = await apiService.api.instance.get<{
      data: ProductWithWarehousesDto[];
      meta: MetaDto;
    }>(
      mergeOrCreateQuery({
        url: `${this.tableName}/withCount`,
        query: deleteEmptyValueKeys({
          ...query,
          count_exists: 1,
        }),
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async getItemById(id: number) {
    const response = await apiService.api.instance.get<{ data: ProductDto }>(
      mergeOrCreateQuery({
        url: `${this.tableName}/${id}`,
        query: deleteEmptyValueKeys({}),
      })
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async createItem(payload: ProductDto) {
    const response = await apiService.api.instance.post<{ data: ProductDto }>(
      `${this.tableName}/create`,
      payload
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async updateItem(id: number, payload: ProductDto) {
    const response = await apiService.api.instance.put<{ data: ProductDto }>(
      `${this.tableName}/${id}/update`,
      payload
    );

    return {
      response: response,
      data: response.data,
    };
  }

  static async deleteItem(id: number) {
    const response = await apiService.api.instance.delete<{ data: ProductDto }>(
      `${this.tableName}/${id}/delete`
    );

    return {
      response: response,
    };
  }
}
