<template>
  <div class="patient-search-select-block">
    <div class="patient-search-select-block__select-container">
      <FormSearchSelect
        label-key="name"
        value-key="id"
        v-bind="$attrs"
        v-if="!hideSearch"
        :field-name="fieldName"
        :field-object-name="fieldObjectName"
        :disabled="disabled"
        :fetch-data="misB2BApi.patientsTemp.getList">
        <template #optionTemplate="{ item }">
          <span class="patient-search-select-block__item">
            <span>{{ item.name }}</span>
            <span class="patient-search-select-block__item-med">{{
              item.humId ? item.humId : '-'
            }}</span>
          </span>
        </template>
      </FormSearchSelect>
      <ScanPatientBracelet
        v-if="!hideSearch"
        only-icon
        :disabled="disabled"
        @scan:success="scanHandler" />
    </div>
    <PatientCard :patient="user">
      <template v-if="$slots.patientCardAppend" #append="{ patient }">
        <slot name="patientCardAppend" :patient="patient" />
      </template>
    </PatientCard>
    <slot name="append" />
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';

import { PatientCard } from '../PatientCard';

import { misB2BApi, PatientShortDto } from '~shared/api';
import { FormSearchSelect } from '~shared/ui/form';
import { ScanPatientBracelet } from '~entities/patient';
const props = withDefaults(
  defineProps<{
    fieldName?: string;
    fieldObjectName?: string;
    disabled?: boolean;
    hideSearch?: boolean;
  }>(),
  {
    fieldName: 'user_id',
    fieldObjectName: 'user',
    hideSearch: false,
  }
);

// eslint-disable-next-line vue/no-setup-props-destructure
const { value: user, setValue: setUser } = useField<PatientShortDto>(props.fieldObjectName);

const scanHandler = (patient: PatientShortDto) => {
  setUser(patient);
};
</script>

<style lang="scss" src="./index.scss" />
