import { GenericAbortSignal } from 'axios';

import { AnyObject, QueryParams } from '~shared/types';

export const useAbortable = (
  apiFunction: (
    query?: QueryParams,
    signal?: GenericAbortSignal
  ) => Promise<{ data: AnyObject[] } | undefined>
) => {
  return (): [
    (payload?: QueryParams) => Promise<{ data: AnyObject[] } | undefined>,
    () => void,
  ] => {
    const abortController = new AbortController();
    const abortableSearch = async (payload: QueryParams = {}) => {
      return await apiFunction(payload, abortController.signal);
    };

    return [
      abortableSearch,
      () => {
        try {
          abortController.abort('Canceled');
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      },
    ];
  };
};
