import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const USERS_PATH_PREFIX = '/users';

export const DOCTORS_TEMPLATES_ROUTE = {
  name: 'DOCTORS_TEMPLATES',
  path: '/templates',
  component: 'VTemplates',
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor'])],
  meta: {
    title: 'Base.Templates',
  },
};

export const DOCTORS_TEMPLATE_ROUTE = {
  name: 'DOCTORS_TEMPLATE',
  path: '/templates/:id',
  component: 'VTemplate',
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor'])],
  meta: {
    title: 'Base.Template',
  },
  props: true,
};

export const DOCTORS_ROUTE = {
  name: 'DOCTORS',
  path: `${USERS_PATH_PREFIX}/doctors`,
  component: 'VDoctors',
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['manager', 'director', 'accountant']),
  ],
  meta: {
    title: 'Base.Doctors',
  },
};

export const DOCTOR_ROUTE = {
  name: 'DOCTOR',
  path: `${USERS_PATH_PREFIX}/doctors/:id`,
  component: 'VDoctor',
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['manager', 'director', 'doctor', 'accountant']),
  ],
  meta: {
    title: 'Base.Doctor',
  },
  props: true,
  childrenMap: {
    DEVICES: {
      name: 'DOCTOR_DEVICES',
      path: 'devices',
      _fullPath: `/doctor/:id/devices`,
      component: 'VDoctorDevices',
      meta: {
        title: 'User.Devices',
      },
      props: true,
    },
    SALARY: {
      name: 'DOCTOR_SALARY',
      path: 'salary',
      _fullPath: `/doctor/:id/salary`,
      component: 'VDoctorSalary',
      meta: {
        title: 'User.Accounting',
      },
      props: true,
    },
  },
};

export const DOCTORS_SETTINGS_ROUTE = {
  name: 'DOCTORS_SETTINGS',
  path: '/profile/settings',
  component: 'VSettings',
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['doctor'])],
  meta: {
    title: 'Base.Settings',
  },
};

export const routes = [
  DOCTORS_ROUTE,
  DOCTOR_ROUTE,
  DOCTORS_TEMPLATE_ROUTE,
  DOCTORS_SETTINGS_ROUTE,
  DOCTORS_TEMPLATES_ROUTE,
];
