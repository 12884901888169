<template>
  <div class="table-wrapper">
    <ElScrollbar class="table-wrapper__scrollbar">
      <ElTable
        :class="['table-wrapper__table', tableClass]"
        :data="data"
        :max-height="maxHeight"
        :empty-text="$t('Base.NoData')"
        :default-sort="defaultSort"
        v-bind="$attrs"
        v-loading="loading"
        ref="elTable">
        <template v-if="$slots.empty" #empty>
          <slot name="empty"></slot>
        </template>
        <slot></slot>
      </ElTable>
    </ElScrollbar>

    <ElPagination
      v-show="!hidePagination"
      class="table-wrapper__pagination"
      :page-count="pageCount"
      :total="total"
      :current-page="page"
      :page-size="perPage"
      :page-sizes="PAGE_SIZES"
      background
      hide-on-single-page
      layout="prev, pager, next, sizes"
      @update:current-page="$emit('update:page', $event)"
      @update:page-size="$emit('update:perPage', $event)" />
  </div>
</template>

<script lang="ts">
export default {
  name: 'MiTable',
};
</script>

<script setup lang="ts">
import { Sort, ElTable } from 'element-plus';
import { computed, watch, ref } from 'vue';

import { PAGE_SIZES } from '~shared/config';

type Props = {
  loading: boolean;
  hidePagination?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  page: number;
  perPage: number;
  total: number;
  tableClass?: string;
  maxHeight?: number;
  defaultSort?: Sort;
};

const elTable = ref<InstanceType<typeof ElTable> | null>(null);

const props = defineProps<Props>();

// defineEmits<{
//   (e: 'update:page', $event: number): void;
//   (e: 'update:perPage', event: number): void;
//   (e: 'row-click', event: any): void;
// }>();

const pageCount = computed(() => {
  return Math.ceil(props.total / props.perPage);
});

watch(
  () => props.loading,
  () => {
    if (elTable.value) {
      elTable.value.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
);
</script>

<style lang="scss" src="./index.scss"></style>
