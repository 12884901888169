<template>
  <div class="invoice-modal__aside">
    <div class="invoice-modal-aside__title-wrapper">
      <h2 class="invoice-modal-aside__title">{{ $t('Base.PatientInfo') }}</h2>
      <MiButton @click="handleForwardProfile" :disabled="!editMode" type="primary" link
        >{{ $t('Contingent.Profile') }}
      </MiButton>
    </div>
    <PatientSearchSelectBlock :hide-search="editMode">
      <template #patientCardAppend="{ patient }">
        <MiHorizInfoField :label="$t('Contingent.PriceListCategory')">{{
          patient?.price_list?.title ?? '-'
        }}</MiHorizInfoField>
        <div class="invoice-modal__note-field">
          <FormTextField
            type="textarea"
            :label="$t('Base.Notes')"
            :placeholder="$t('CashRegister.PatientNotes')"
            :disabled="editMode"
            field-name="note" />
        </div>
        <div class="invoice-modal__refaral-field">
          <FormSearchSelect
            clearable
            :label="$t('CashRegister.ReferralAgent')"
            :placeholder="$t('Bookkeeping.EnterNameOrSourceID')"
            field-name="referrer_id"
            field-object-name="referrer"
            :disabled="editMode"
            :fetch-data="misB2BApi.appointmentsTemp.getSourceList">
            <template #optionTemplate="{ item }">
              <span class="invoice-modal-aside__item">
                <span>{{ item.title }}</span>
                <span class="invoice-modal-aside__item-id">{{ item.id ? item.id : '-' }}</span>
              </span>
            </template>
          </FormSearchSelect>
        </div>
      </template>
    </PatientSearchSelectBlock>
  </div>
</template>

<script setup lang="ts">
import { useFieldValue } from 'vee-validate';
import { useRouter } from 'vue-router';

import { InvoiceFullDto, misB2BApi } from '~shared/api';
import { ROUTE_PATH } from '~shared/config';
import { FormSearchSelect, FormTextField } from '~shared/ui/form';
import { MiButton, MiHorizInfoField } from '~shared/ui';
import { PatientSearchSelectBlock } from '~features/PatientSearchSelectBlock';

defineProps<{
  editMode: boolean;
}>();

const router = useRouter();

const currentInvoice = useFieldValue<InvoiceFullDto>('currentInvoice');

const handleForwardProfile = () => {
  if (currentInvoice.value?.user)
    router.push(ROUTE_PATH.patients.profiles.byId(currentInvoice.value.user.id));
};
</script>

<style src="./index.scss" />
