<template>
  <div class="service-main-form">
    <UiTabs
      :title="$t('Services.ServiceInformation')"
      v-model="type"
      type="border-card"
      class="service-main-form__tabs">
      <template #title-append>
        <FormSwitchField :label="$t('Services.ShowInApp')" field-name="is_published" />
      </template>
      <ElTabPane
        :label="$t('Services.ServiceType.Consultation')"
        name="consultation"
        :disabled="!actionOptions.isNewService">
        <ConsultationForm v-if="type === 'consultation'" />
      </ElTabPane>
      <ElTabPane
        :label="$t('Services.ServiceType.Product')"
        name="product"
        :disabled="!actionOptions.isNewService">
        <ProductForm v-if="type === 'product'" />
      </ElTabPane>
      <ElTabPane
        :label="$t('Services.ServiceType.Procedure')"
        name="procedure"
        :disabled="!actionOptions.isNewService">
        <ProcedureForm v-if="type === 'procedure'" />
      </ElTabPane>
      <ElTabPane :label="$t('Services.ServiceType.Analysis')" name="analysis" disabled
        >Not implemented
      </ElTabPane>
    </UiTabs>
    <FieldGroupWrapper :title="$t('Services.ServiceCosts')">
      <!-- <ExpenseCategoriesForm /> -->
      <!-- <FieldGroupDivider /> -->
      <ServiceProductsTableFillingForm
        @table:add="addProduct"
        @product:create="onProductCreateButtonClick" />
      <ServiceProductsTable :items="productTableItems" @table:delete="deleteProduct" />
      <!-- <FormTotalAmount :total-amount="productTableTotalSum" /> -->
    </FieldGroupWrapper>
    <FieldGroupWrapper v-if="formData.price_lists.length">
      <div class="service-main-form__priceLists">
        <h3 class="service-main-form__price-title">
          {{ $t('Services.TotalCostServiceDifferentPriceListsSum') }}
        </h3>
        <FormTextField
          class="service-main-form__price-item"
          v-for="(priceList, idx) in formData.price_lists"
          :key="priceList.id"
          :label="priceList.title"
          :field-name="`price_lists[${idx}].price`"
          :placeholder="$t('Base.Price')"
          mask-name="int-12" />
      </div>
    </FieldGroupWrapper>
    <!--    <ServiceCalculation-->
    <!--      :pre-validate="preValidate"-->
    <!--      :request-disabled="actionOptions.requestDisabled" />-->
    <FormActionsWrapper align="right">
      <ElPopconfirm
        :title="$t('Ui.PopConfirm.DefaultTitle')"
        @confirm="deleteService"
        v-if="!actionOptions.isNewService">
        <template #reference>
          <ElButton type="danger" :loading="isDeleting">
            {{ $t('Base.Delete') }}
          </ElButton>
        </template>
      </ElPopconfirm>
      <ElButton
        type="primary"
        :disabled="actionOptions.requestDisabled"
        :loading="isSubmitting"
        @click="submitForm"
        >{{ actionOptions.isNewService ? `+ ${$t('Base.Add')}` : $t('Base.Save') }}
      </ElButton>
    </FormActionsWrapper>
    <ProductCrudModal
      v-if="nestedModalData.product.visible"
      v-model="nestedModalData.product.visible"
      :form-data="nestedModalData.product.data"
      append-to-body />
  </div>
</template>

<script lang="ts">
export default {
  name: 'ServiceMainForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useField, useFieldArray, useForm } from 'vee-validate';
import { computed, ref } from 'vue';

// eslint-disable-next-line import/no-internal-modules
import ConsultationForm from '../ConsultationForm/index.vue';
// eslint-disable-next-line import/no-internal-modules
// import ExpenseCategoriesForm from '../ExpenseCategoriesForm/index.vue';
// eslint-disable-next-line import/no-internal-modules
import ProductForm from '../ProductForm/index.vue';
// eslint-disable-next-line import/no-internal-modules
import ServiceProductsTable from '../ServiceProductsTable/index.vue';
// eslint-disable-next-line import/no-internal-modules
import ServiceProductsTableFillingForm from '../ServiceProductsTableFillingForm/index.vue';
import { validationSchema } from '../validationSchema';

import { amplitudeService } from '~shared/lib';
import ProcedureForm from '@/components/accounting/modals/ServiceCrudModal/ProcedureForm/index.vue';
//import ServiceCalculation from '@/components/accounting/modals/ServiceCrudModal/ServiceCalculation/index.vue';
// import FieldGroupDivider from '@/components/common/form/FieldGroupDivider/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';
// import FormTotalAmount from '@/components/common/form/FormTotalAmount/index.vue';
import FormSwitchField from '@/components/common/form/ui/FormSwitchField/index.vue';
import UiTabs from '@/components/ui/UiTabs/index.vue';
import ProductCrudModal from '@/components/warehouse/modals/ProductCrudModal/index.vue';
import {
  ServiceGroupConsultationFormData,
  ServiceGroupBase,
  ServiceGroupProcedureFormdata,
  ServiceGroupProductFormData,
} from '@/models/accounting/Service.model';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { Product } from '@/models/warehouse/Product.model';
import { ExpenseProduct, ProductDto, ServiceGroupType } from '@/types/api';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';

const props = defineProps<{
  formData:
    | ServiceGroupConsultationFormData
    | ServiceGroupProcedureFormdata
    | ServiceGroupProductFormData;
}>();

const isDeleting = ref(false);

const emit = defineEmits(['submit', 'close:modal', 'action']);

// eslint-disable-next-line vue/no-setup-props-destructure
const { isSubmitting, errors, handleSubmit } = useForm<
  ServiceGroupConsultationFormData | ServiceGroupProcedureFormdata | ServiceGroupProductFormData
>({
  initialValues: props.formData,
  validationSchema,
  validateOnMount: false,
});

// const preValidate = async () => {
//   const result = await validate();
//   return result;
// };

const actionOptions = computed(() => ({
  isNewService: !props.formData.id,
  requestDisabled: Object.keys(errors.value).length > 0,
}));

const { value: type } = useField<ServiceGroupType>('type');

const nestedModalData = ref({
  product: { visible: false, data: { ...new Product() } },
});

const {
  fields: products,
  push: productPush,
  remove: productRemove,
} = useFieldArray<ExpenseProduct>('expenses_products');

const productTableItems = computed(() => products.value.map((field) => ({ ...field.value })));
// const productTableTotalSum = computed(() =>
//   products.value.reduce((acc, field) => acc + field.value.total_amount, 0)
// );

const addProduct = (values: { product: ProductDto; count: number }) => {
  const { product, count } = values;
  productPush({
    price: product.price,
    count,
    title: product.title,
    id: product.id,
    total_amount: count * product.price,
  });
};

const deleteProduct = (id: number) => {
  const idx = products.value.findIndex((item) => item.value.id === id);
  productRemove(idx);
};

const onProductCreateButtonClick = async (value: string) => {
  nestedModalData.value.product.data.title = value;
  nestedModalData.value.product.visible = true;
};

const deleteService = async () => {
  if (props.formData.id) {
    isDeleting.value = true;
    await ServiceGroupBase.deleteItem(props.formData.id);
    isDeleting.value = false;
    emit(
      'action',
      new GlobalModalInstance({
        name: 'deleted',
        data: { id: props.formData.id },
      })
    );
    emit('close:modal');
  }
};

const submitForm = handleSubmit(
  async (values) => {
    const formData = {
      ...values,
      expenses_categories: values.expenses_categories.filter((f) => !f.deleted),
      price_lists: values.price_lists.map((priceList) => ({
        price: priceList.price ? +priceList.price : null,
        price_list_id: priceList.id,
      })),
    };

    let responseData: Record<string, unknown>;

    if (formData.id) {
      const response = await ServiceGroupBase.updateItem({
        id: formData.id,
        payload: formData,
      });
      if (response) {
        const changeService = response.data as {
          id: string;
        };
        amplitudeService.logEvent('edit_service', {
          service_id: changeService.id,
        });
      }
      responseData = response?.data ?? {};
    } else {
      amplitudeService.logEvent('create_service');
      const response = await ServiceGroupBase.createItem(formData);
      responseData = response?.data ?? {};
    }

    emit(
      'action',
      new GlobalModalInstance({
        name: formData.id ? 'updated' : 'created',
        data: { ...responseData },
      })
    );
    emit('close:modal');
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss" />
