<template>
  <UiTableWithPagination
    :data="data"
    :loading="loading"
    :page="1"
    :per-page="data.length || 1"
    :total="data.length"
    @row-click="goToReception">
    <ElTableColumn prop="drug.title" :label="$t('Base.Naming')" />
    <ElTableColumn prop="day" :label="$t('DateAndTime.Day')" />
    <ElTableColumn prop="appointment.start_at" :label="$t('Appointments.StartDate')" />
    <ElTableColumn :label="$t('Base.Status')">
      <template #default="{ row }">
        <AppointmentStatusTag :status="row.appointment.status" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="actions" width="200" :label="$t('Base.Actions')">
      <template #default>
        <div class="reception-table__actions">
          <ElButton type="primary" text>
            <template #icon> <MiIcon icon="EYE" /> </template>
            {{ $t('Base.Open') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import { APPOINTMENT_ROUTE } from '@/router/appointments.routes';
import AppointmentStatusTag from '@/components/appointments/AppointmentStatusTag/index.vue';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import { MiIcon } from '~shared/ui';

export default {
  name: 'AppointmentsByTreatmentTable',
  components: { UiTableWithPagination, AppointmentStatusTag, MiIcon },
  props: {
    /** @type {Array<TreatmentAppointment>} data */
    data: Array,
    loading: Boolean,
  },
  computed: {},

  methods: {
    goToReception(row) {
      this.$router.push({
        name: APPOINTMENT_ROUTE.name,
        params: {
          id: row.appointment.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
