<template>
  <MainLayout fix-height>
    <LayoutContentHeader>
      <template #actions>
        <ElButton type="primary" @click="createTemplate">
          <template #icon>
            <MiIcon icon="PLUS" />
          </template>
          {{ $t('Templates.AddTemplate') }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <TemplatesTable
      class="v-templates-content__table"
      :data="data"
      :loading="loading"
      :total="total"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </MainLayout>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

import { MiIcon } from '~shared/ui';
import { InspectionCardTemplate } from '@/models/InspectionCardTemplate.model.js';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { usePage, usePerPage } from '~shared/lib';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { DOCTORS_TEMPLATE_ROUTE } from '@/router/doctors.routes';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import CreateOrEditTemplateModal from '@/components/doctors/CreateOrEditTemplateModal/index.vue';
import TemplatesTable from '@/components/doctors/TemplatesTable/index.vue';
import { MainLayout } from '~widgets/layouts';

export default {
  name: 'VTemplates',
  components: { MainLayout, LayoutContentHeader, TemplatesTable, MiIcon },
  setup: () => ({
    perPage: usePerPage(99),
    page: usePage(),
  }),
  data() {
    return {
      loading: false,
    };
  },

  watch: {
    queryWatchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          getData: this.getTemplates,
          resetPage: this.page.reset,
        });
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapState({
      data: (state) => state.templates.data,
      total: (state) => state.templates.total,
    }),

    queryWatchers() {
      return {
        perPage: this.perPage.value,
        page: this.page.value,
        type: InspectionCardTemplate.enum.types.Editor,
      };
    },
  },

  methods: {
    async getTemplates() {
      this.loading = true;

      try {
        const { data } = await InspectionCardTemplate.find({
          per_page: this.perPage.value,
          page: this.page.value,
        });
        this.$store.dispatch('templates/setData', { items: data.data, overwriteDataState: true });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    async createTemplate() {
      const action = await this.$store.dispatch(
        'modalAndDrawer/openModal',
        CreateOrEditTemplateModal
      );

      if (!(action instanceof GlobalModalClose)) {
        this.$router.push({
          name: DOCTORS_TEMPLATE_ROUTE.name,
          params: { id: action.data.template.id },
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
