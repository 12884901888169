<template>
  <ElForm class="create-appointment-subject" @submit.prevent="createSubject">
    <div
      class="create-appointment-subject__recommendations"
      v-if="filteredRecommendations.length > 0">
      <div class="create-appointment-subject__recommendations-text">
        {{ $t('Recommendations.HasRecommendation') }}
      </div>
      <div class="create-appointment-subject__recommendations-count">
        {{ filteredRecommendations.length }}
      </div>
      <ElButton
        class="create-appointment-subject__recommendations-appoint-button"
        type="primary"
        :disabled="isAppointButtonDisabled"
        @click="addRecomendationToSelect"
        >{{ $t('Recommendations.RecordByRecommendation') }}
      </ElButton>
    </div>

    <div class="create-appointment-subject__part create-appointment-subject-part">
      <!--  Doctor  -->
      <UiModelsAutocompleteSearch
        class="create-appointment-subject__doctor"
        :model-value="doctor?.id"
        clearable
        :disabled="disabled"
        :model-for-use="Doctor"
        :default-item="appointment?.doctor ?? user?.doctor"
        :search-query="doctorOptions.searchQuery"
        :placeholder="$t('Appointments.SelectDoctor')"
        :required="doctorOptions.required"
        @select="doctor = $event" />

      <!--  GroupService  -->
      <UiModelsAutocompleteSearch
        class="create-appointment-subject__services"
        :model-value="groupServiceIds"
        :models="groupServices"
        label="title"
        :disabled="disabled"
        :placeholder="$t('Appointments.SelectServices')"
        :model-for-use="ServiceGroup"
        :search-query="groupServicesOptions.searchQuery"
        :required="groupServicesOptions.required"
        :multiple="groupServicesOptions.multiple"
        @select="selectGroupServices">
        <template #default="{ item }"> {{ generateLabel(item) }}</template>
      </UiModelsAutocompleteSearch>
    </div>

    <div class="create-appointment-subject__part create-appointment-subject-part">
      <!--  Select Date type  -->
      <ElRadioGroup class="create-appointment-subject__date-type" v-model="isLiveQueue">
        <ElRadio :label="true"> {{ $t('Appointments.LiveQueue') }}</ElRadio>
        <ElRadio :label="false"> {{ $t('Appointments.RecordOnTime') }}</ElRadio>
      </ElRadioGroup>

      <!--  Date  -->
      <ScheduleSlotsSelect
        v-show="true"
        :label="$t('DateAndTime.SelectDate')"
        v-model:start-at="startAt"
        v-model:end-at="endAt"
        :default-start-at="appointment.start_at"
        :default-end-at="appointment.end_at"
        :search-query="slotsOptions.searchQuery"
        :dependencies="slotsOptions.dependencies"
        :disabled="slotsOptions.disabled"
        @selectSlot="$emit('selectSlot')" />

      <ElButton class="create-appointment-subject__submit" type="primary" native-type="submit">
        {{ $t('Base.Add') }}
      </ElButton>
    </div>
  </ElForm>
</template>

<script>
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';

import { formatPrice } from '@/utils/price.util';
import { Appointment } from '@/models/appointment/Appointment.model';
import { AppointmentSubject } from '@/models/appointment/AppointmentSubject.model';
import { ServiceGroup } from '@/models/ServiceGroup.model';
import { Doctor } from '@/models/Doctor.model';
import { Patient } from '@/models/Patient.model';
import { AppointmentServiceGroupWithCount } from '@/models/appointment/AppointmentServiceGroupWithCount.model';
import ScheduleSlotsSelect from '@/components/appointments/ScheduleSlotsSelect/index.vue';
import { useSessionStore } from '~entities/session';

export default {
  name: 'CreateAppointmentSubjectOrServiceGroupWithCount',
  emits: ['subject:create', 'update:appointment', 'selectSlot'],
  components: { ScheduleSlotsSelect },
  props: {
    appointment: [Appointment, Object], // use for edit
    patient: [Patient, Object],
    setDefaultMyDoctor: Boolean,
    disabled: Boolean,
    resetAfterCreate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      /** @type {AppointmentSubject|AppointmentServiceGroupWithCount} subject  */
      appointmentItem: this.appointment.id
        ? new AppointmentServiceGroupWithCount()
        : new AppointmentSubject(),
      isLiveQueue: true,
      appointedRecommendations: [],
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },

    tableItemIds() {
      return this.appointment.id
        ? this.appointment.group_services_with_count.reduce(
            (acc, curr) => [...acc, ...curr.group_service_ids],
            []
          )
        : this.appointment.appointments.reduce(
            (acc, curr) => [...acc, ...curr.group_service_ids],
            []
          );
    },

    filteredRecommendations() {
      return this.appointedRecommendations
        .map((item) => ({
          group_services: item.group_services.filter((f) => !this.tableItemIds.includes(f.id)),
          group_service_ids: item.group_service_ids.filter((f) => !this.tableItemIds.includes(f)),
        }))
        .filter((f) => f.group_services.length > 0);
    },

    isAppointButtonDisabled() {
      const firstRecommendationIds = this.filteredRecommendations[0].group_service_ids;
      return firstRecommendationIds.every((item) => this.groupServiceIds.includes(item));
    },

    // Если их нету, значит идёт редактирование
    // Если Appointment новый вернет true если существующий вернет false
    isAppointmentSubjectsFlow() {
      return !this.appointment.id;
    },

    doctor: {
      get() {
        return this.isAppointmentSubjectsFlow
          ? this.appointmentItem.doctor
          : this.appointment.doctor;
      },
      set(value) {
        if (this.isAppointmentSubjectsFlow) {
          this.appointmentItem.doctor_id = value?.id || null;
          this.appointmentItem.doctor = value;
        } else {
          this.$emit('update:appointment', {
            ...this.appointment,
            doctor_id: value?.id || null,
            doctor: value,
          });
        }
      },
    },
    doctorOptions() {
      return {
        required: false,
        searchQuery: {
          group_service_ids: this.isAppointmentSubjectsFlow
            ? this.appointmentItem.group_service_ids
            : this.appointment.group_service_ids,
        },
      };
    },

    /** @return {number|Array<number>} */
    groupServiceIds() {
      return this.isAppointmentSubjectsFlow
        ? this.appointmentItem.group_service_ids
        : this.appointmentItem.group_service_id;
    },
    groupServices() {
      return this.isAppointmentSubjectsFlow
        ? this.appointmentItem.group_services
        : [this.appointmentItem.group_service];
    },
    groupServicesOptions() {
      return {
        multiple: !!this.isAppointmentSubjectsFlow,
        required: true,
        searchQuery: {
          query_field: null,
          query_type: null,
          query_operator: null,
          some_services: true,
          doctor_id: this.doctor?.id,
          user_id: this.appointment?.patient_id,
        },
      };
    },

    startAt: {
      get() {
        return this.isAppointmentSubjectsFlow
          ? this.appointmentItem.start_at
          : this.appointment.start_at;
      },
      set(value) {
        if (this.isAppointmentSubjectsFlow) {
          this.appointmentItem.start_at = value;
        } else {
          this.$emit('update:appointment', { ...this.appointment, start_at: value });
        }
      },
    },
    endAt: {
      get() {
        return this.isAppointmentSubjectsFlow
          ? this.appointmentItem.end_at
          : this.appointment.end_at;
      },
      set(value) {
        if (this.isAppointmentSubjectsFlow) {
          this.appointmentItem.end_at = value;
        } else {
          this.$emit('update:appointment', { ...this.appointment, end_at: value });
        }
      },
    },
    slotsOptions() {
      return {
        disabled: this.isAppointmentSubjectsFlow ? !this.groupServiceIds.length : false,
        dependencies: [
          this.groupServiceIds instanceof Array ? this.groupServiceIds : [this.groupServiceIds],
          this.doctor?.id,
        ],
        searchQuery: {
          group_service_ids: this.groupServiceIds,
          doctor_id: this.doctor?.id,
          appointment_id: this.appointment?.id,
        },
      };
    },
  },

  watch: {
    'appointment.patient_id': {
      handler(value) {
        if (value) {
          this.getPatientRecommendations(value);
        }
      },
      immediate: true,
    },

    'isAppointmentSubjectsFlow': {
      handler(value) {
        this.appointmentItem = value
          ? new AppointmentSubject(this.appointment)
          : new AppointmentServiceGroupWithCount();
      },
      immediate: true,
    },

    'appointment.start_at': {
      handler(value) {
        if (value) {
          this.isLiveQueue = false;
        }
      },
      immediate: true,
    },

    'user.doctor_id': {
      handler(value) {
        if (value && this.setDefaultMyDoctor && this.isAppointmentSubjectsFlow) {
          this.appointmentItem = new AppointmentSubject({
            ...this.appointmentItem,

            doctor_id: value,
            doctor: this.user.doctor,
          });
        }
      },
      immediate: true,
    },

    'isLiveQueue': {
      handler() {
        this.startAt = null;
        this.endAt = null;
      },
    },
  },

  methods: {
    createSubject() {
      this.$emit('subject:create', cloneDeep(this.appointmentItem));
      if (this.resetAfterCreate) this.reset();
    },

    async getPatientRecommendations(id) {
      if (
        this.user.role === 'manager' ||
        this.user.role === 'doctor' ||
        this.user.role === 'registry'
      ) {
        try {
          const { data } = await Patient.getRecommendations(id);
          this.appointedRecommendations = data.data;
        } catch (err) {
          this.$notify({
            type: 'error',
            title: axios.isAxiosError(err) ? err.message : String(err),
          });
        }
      }
    },

    addRecomendationToSelect() {
      const recommendation = this.filteredRecommendations[0];
      this.selectGroupServices(recommendation.group_services);
    },

    reset() {
      if (this.isAppointmentSubjectsFlow) {
        this.appointmentItem = new AppointmentSubject({
          doctor_id: this.setDefaultMyDoctor ? this.user.doctor_id : null,
          doctor: this.setDefaultMyDoctor ? this.user.doctor : null,
        });
        this.isLiveQueue = true;
      }

      if (!this.isAppointmentSubjectsFlow) {
        this.appointmentItem = new AppointmentServiceGroupWithCount();
      }
    },

    /** @param {ServiceGroup|Array<ServiceGroup>} items */
    selectGroupServices(items) {
      if (this.isAppointmentSubjectsFlow) {
        this.appointmentItem.group_service_ids = items.map((item) => item.id);
        this.appointmentItem.group_services = items;
        this.appointmentItem.group_services_with_count = items.map(
          (item) =>
            new AppointmentServiceGroupWithCount({
              uuid: item.id,
              group_service_id: item.id,
              group_service: item,
              count: 1,
            })
        );
      }

      if (!this.isAppointmentSubjectsFlow) {
        this.appointmentItem.group_service_id = items.id;
        this.appointmentItem.group_service = items;
      }
    },

    /** @param {ServiceGroup} item */
    generateLabel(item) {
      return `${item.title} - ${formatPrice({ price: item.price })} ${this.$t('Base.Sum')}`;
    },
  },

  setup: () => ({
    ServiceGroup,
    Doctor,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
