<template>
  <div>
    <ul>
      <li v-for="item in scheduleList" :key="item.day">{{ item.day }}: {{ item.time }}</li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import { I18nService } from '~shared/lib';
import { EmployeeDto } from '@/types/api';

const props = defineProps<{
  workingTime?: EmployeeDto['working_time'] | null;
}>();

const daysOfWeek = ref([
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]);

const dayAbbreviations: Record<string, string> = {
  monday: I18nService.t('Base.DaysOfWeek.Monday'),
  tuesday: I18nService.t('Base.DaysOfWeek.Tuesday'),
  wednesday: I18nService.t('Base.DaysOfWeek.Wednesday'),
  thursday: I18nService.t('Base.DaysOfWeek.Thursday'),
  friday: I18nService.t('Base.DaysOfWeek.Friday'),
  saturday: I18nService.t('Base.DaysOfWeek.Saturday'),
  sunday: I18nService.t('Base.DaysOfWeek.Sunday'),
};

const scheduleList = computed(() => {
  const scheduleList:
    | {
        day: string;
        time: string;
      }[]
    | PromiseLike<
        {
          day: string;
          time: string;
        }[]
      > = [];

  if (props.workingTime) {
    daysOfWeek.value.forEach((day) => {
      const startTime = props.workingTime?.[`${day}_start` as keyof typeof props.workingTime];
      const endTime = props.workingTime?.[`${day}_end` as keyof typeof props.workingTime];

      const dayAbbreviation = dayAbbreviations[day.toLowerCase() as keyof typeof dayAbbreviations];
      const timeRange =
        startTime && endTime
          ? `${startTime} - ${endTime}`
          : I18nService.t('Base.DaysOfWeek.DayOff');

      scheduleList.push({
        day: dayAbbreviation ?? '...',
        time: timeRange,
      });
    });
  } else {
    scheduleList.push({
      day: '...',
      time: '...',
    });
  }
  return scheduleList;
});
</script>

<style scoped lang="scss"></style>
<i18n src="@/locales/base.locales.json" />
