import axios from 'axios';

import { Hospital } from '@/models/hospital/Hospital.model';
import { Department } from '@/models/hospital/Department.model';
import { Chamber } from '@/models/hospital/Chamber.model';
import { Doctor } from '@/models/Doctor.model';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import PatientsSearchSelectDataBlock from '@/components/patients/PatientsSearchSelectDataBlock/index.vue';

export default {
  name: 'CreateOrEditHospitalModal',
  emits: ['update:modelValue', 'action'],
  components: { PatientsSearchSelectDataBlock },
  props: {
    modelValue: Boolean,
    id: [Number, String],
    patient: [Object],
  },
  data() {
    return {
      /** @type {Hospital} hospital */
      hospital: new Hospital(),
      loading: {
        form: false,
        hospital: false,
      },
    };
  },

  watch: {
    'id': {
      handler(value) {
        if (value) {
          this.getHospital();
        } else if (!value && this.patient) {
          this.hospital = new Hospital({
            user_id: this.patient.id,
            user: this.patient,
          });
        }
      },
      immediate: true,
    },

    'hospital.department_id': {
      handler() {
        this.hospital.chamber_id = null;
        this.hospital.chamber = null;
      },
    },
  },

  methods: {
    async getHospital() {
      this.loading.hospital = true;
      const { hospital } = await Hospital.findOneById(this.id);
      this.hospital = hospital;
      this.loading.hospital = false;
    },

    async submit() {
      if (!this.customValidate() || this.loading.form) return;
      this.loading.form = true;

      try {
        const hospital = this.hospital.id ? await this.update() : await this.create();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.hospital.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalInstance({
            name: this.hospital.id ? 'updated' : 'created',
            data: { hospital },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.form = false;
    },

    customValidate() {
      return this.$refs.patient.isNewPatientFlow() ? this.$refs.patient.validate() : true;
    },

    async create() {
      if (this.$refs.patient.isNewPatientFlow()) {
        const patient = await this.$refs.patient.createPatient();
        this.hospital.user_id = patient.id;
        this.hospital.user = patient;
      }

      const { hospital } = await Hospital.create(this.hospital);
      return hospital;
    },
    async update() {
      const { hospital } = await Hospital.update({ id: this.hospital.id, payload: this.hospital });
      return hospital;
    },
  },

  setup: () => ({
    Department,
    Chamber,
    Doctor,
  }),
};
