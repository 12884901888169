import {
  ROUTE_PATH,
  TEMPLATES_PATH_PREFIX,
  USERS_PATH_PREFIX,
  WAREHOUSE_PATH_PREFIX,
  PATIENTS_PATH_PREFIX,
  INPATIENT_CARE_PATH_PREFIX,
  APPOINTMENTS_PATH_PREFIX,
  BOOKKEEPING_PATH_PREFIX,
  CASH_REGISTER_PATH_PREFIX,
} from '~shared/config';
import { SidebarMenuItem } from '~shared/types';
import { I18nService } from '~shared/lib';
import { UserRoleEnum } from '~shared/api';

type getSidebarMenuItemsParams = {
  doctorId?: number | null;
  userRole: UserRoleEnum;
};

export const getSidebarMenuItems = (params: getSidebarMenuItemsParams): SidebarMenuItem[] => {
  const items: SidebarMenuItem[] = [
    {
      roles: ['director'],
      routePath: ROUTE_PATH.statistic.dashboard,
      icon: 'DASHBOARD',
      title: I18nService.t('Views.StatisticDashboard.Title'),
      key: ROUTE_PATH.statistic.dashboard,
    },
    {
      roles: ['director', 'manager', 'doctor', 'nurse', 'registry'],
      routePath: PATIENTS_PATH_PREFIX,
      icon: 'USER_TAG',
      title: I18nService.t('Base.Patients'),
      key: PATIENTS_PATH_PREFIX,
    },
    {
      roles: ['director', 'manager', 'doctor', 'nurse'],
      routePath: INPATIENT_CARE_PATH_PREFIX,
      icon: 'HOSPITAL',
      title: I18nService.t('Hospital.Hospital'),
      key: INPATIENT_CARE_PATH_PREFIX,
    },
    {
      roles: ['manager'],
      routePath: ROUTE_PATH.bookkeeping.services,
      icon: 'FILTER',
      title: I18nService.t('Views.Services.Title'),
      key: ROUTE_PATH.bookkeeping.services,
    },
    {
      roles: ['manager', 'director', 'accountant', 'doctor'],
      icon: 'WALLET_OUTLINE',
      title: I18nService.t('Base.CashRegister'),
      prefix: CASH_REGISTER_PATH_PREFIX,
      key: CASH_REGISTER_PATH_PREFIX,
      submenu: [
        {
          roles: ['director', 'manager', 'accountant', 'doctor'],
          routePath: ROUTE_PATH.cashRegister.invoices,
          title: I18nService.t('CashRegister.Invoices'),
          key: ROUTE_PATH.cashRegister.invoices,
        },
        {
          roles: ['director', 'manager', 'accountant', 'doctor'],
          routePath: ROUTE_PATH.cashRegister.depositList,
          title: I18nService.t('CashRegister.Deposit'),
          key: ROUTE_PATH.cashRegister.depositList,
        },
      ],
    },
    {
      roles: ['director', 'accountant'],
      icon: 'MEDICAL',
      title: I18nService.t('User.Users'),
      prefix: USERS_PATH_PREFIX,
      key: USERS_PATH_PREFIX,
      submenu: [
        {
          routePath: ROUTE_PATH.users.employees,
          icon: 'HOSPITAL',
          title: I18nService.t('User.Employees'),
          key: ROUTE_PATH.users.employees,
        },
        {
          routePath: ROUTE_PATH.users.doctors,
          icon: 'DOCTORS',
          title: I18nService.t('Base.Doctors'),
          key: ROUTE_PATH.users.doctors,
        },
      ],
    },
    {
      roles: ['nurse', 'manager', 'director', 'registry', 'doctor'],
      routePath: APPOINTMENTS_PATH_PREFIX,
      icon: 'NOTE',
      title: I18nService.t('Views.Appointments.Title'),
      key: APPOINTMENTS_PATH_PREFIX,
      prefix: APPOINTMENTS_PATH_PREFIX,
      submenu: [
        {
          roles: ['director', 'manager', 'doctor', 'nurse'],
          routePath: ROUTE_PATH.appointments.activeList.withQuery(),
          title: I18nService.t('Appointments.ActiveAppointments'),
          key: ROUTE_PATH.appointments.activeList.rawPath,
        },
        {
          roles: ['director', 'manager', 'doctor', 'nurse'],
          routePath: ROUTE_PATH.appointments.providedList.withQuery(),
          title: I18nService.t('Appointments.Provided'),
          key: ROUTE_PATH.appointments.providedList.rawPath,
        },
        {
          roles: ['director', 'manager', 'doctor', 'nurse'],
          routePath: ROUTE_PATH.appointments.cancelledList.withQuery(),
          title: I18nService.t('Appointments.Cancelled'),
          key: ROUTE_PATH.appointments.cancelledList.rawPath,
        },
      ],
    },
    {
      roles: ['director', 'manager', 'laboratory'],
      routePath: ROUTE_PATH.laboratory.orders,
      icon: 'NOTE',
      title: I18nService.t('Laboratory.Analysis.Analyzes'),
      key: ROUTE_PATH.laboratory.orders,
    },
    {
      roles: ['director', 'accountant', 'manager'],
      icon: 'BOOKKEEPING',
      title: I18nService.t('Bookkeeping.Bookkeeping'),
      prefix: BOOKKEEPING_PATH_PREFIX,
      key: BOOKKEEPING_PATH_PREFIX,
      submenu: [
        // {
        //   roles: ['director', 'accountant'],
        //   routePath: ROUTE_PATH.bookkeeping.employees.list,
        //   title: I18nService.t('Common.Employees'),
        //   key: ROUTE_PATH.bookkeeping.employees.list,
        // },
        {
          roles: ['director', 'accountant'],
          routePath: ROUTE_PATH.bookkeeping.salaryCalcList,
          title: I18nService.t('Bookkeeping.SalaryCalculation'),
          key: ROUTE_PATH.bookkeeping.salaryCalcList,
        },
        {
          roles: ['director', 'accountant'],
          routePath: ROUTE_PATH.bookkeeping.outcomes,
          title: I18nService.t('Outcome.Expenses'),
          key: ROUTE_PATH.bookkeeping.outcomes,
        },
        {
          roles: ['director', 'accountant'],
          routePath: ROUTE_PATH.bookkeeping.services,
          title: I18nService.t('Views.Services.Title'),
          key: ROUTE_PATH.bookkeeping.services,
        },
        {
          roles: ['director', 'accountant', 'manager'],
          routePath: ROUTE_PATH.bookkeeping.refSource,
          title: I18nService.t('Bookkeeping.ReferralSources'),
          key: ROUTE_PATH.bookkeeping.refSource,
        },
        {
          roles: ['director', 'accountant', 'manager'],
          routePath: ROUTE_PATH.bookkeeping.refAccrual,
          title: I18nService.t('Bookkeeping.ReferralAccruals'),
          key: ROUTE_PATH.bookkeeping.refAccrual,
        },
      ],
    },
    {
      roles: ['director', 'manager', 'warehouse', 'accountant'],
      icon: 'WAREHOUSES',
      title: I18nService.t('Warehouse.Warehouse'),
      prefix: WAREHOUSE_PATH_PREFIX,
      key: WAREHOUSE_PATH_PREFIX,
      submenu: [
        {
          routePath: ROUTE_PATH.warehouse.nomenclature,
          icon: 'NOMENCLATURE',
          title: I18nService.t('Nomenclature.Nomenclature'),
          key: ROUTE_PATH.warehouse.nomenclature,
        },
        {
          routePath: ROUTE_PATH.warehouse.warehouses,
          icon: 'WAREHOUSES',
          title: I18nService.t('Warehouse.Warehouses'),
          key: ROUTE_PATH.warehouse.warehouses,
        },
        {
          routePath: ROUTE_PATH.warehouse.suppliers,
          icon: 'SUPPLIERS',
          title: I18nService.t('Supplier.Suppliers'),
          key: ROUTE_PATH.warehouse.suppliers,
        },
        {
          routePath: ROUTE_PATH.warehouse.incomes,
          icon: 'INCOME',
          title: I18nService.t('Income.Incomes'),
          key: ROUTE_PATH.warehouse.incomes,
        },
        {
          routePath: ROUTE_PATH.warehouse.outcomes,
          icon: 'OUTCOME',
          title: I18nService.t('Outcome.Expenses'),
          key: ROUTE_PATH.warehouse.outcomes,
        },
        {
          routePath: ROUTE_PATH.warehouse.goodsStock,
          icon: 'GOODS_STOCK',
          title: I18nService.t('Product.GoodsStock'),
          key: ROUTE_PATH.warehouse.goodsStock,
        },
      ],
    },
    {
      roles: ['director', 'manager', 'registry', 'doctor'],
      routePath: ROUTE_PATH.appointmentSchedule,
      icon: 'DASHBOARD_NAV',
      title: I18nService.t('Appointments.ReceptionCalendar'),
      key: ROUTE_PATH.appointmentSchedule,
    },
    {
      roles: ['doctor', 'nurse'],
      icon: 'TEMPLATE_NAV',
      title: I18nService.t('Templates.Templates'),
      prefix: TEMPLATES_PATH_PREFIX,
      key: TEMPLATES_PATH_PREFIX,
      submenu: [
        {
          routePath: ROUTE_PATH.templates.checkups,
          title: I18nService.t('Templates.CheckupTemplates'),
          key: ROUTE_PATH.templates.checkups,
        },
        {
          roles: ['doctor', 'nurse'],
          routePath: ROUTE_PATH.templates.CheckupHeaderTemplateList,
          title: I18nService.t('Templates.CheckupHeaders'),
          key: ROUTE_PATH.templates.CheckupHeaderTemplateList,
        },
      ],
    },
    {
      roles: ['doctor'],
      routePath: params.doctorId
        ? ROUTE_PATH.users.doctor.byId(params.doctorId)
        : ROUTE_PATH.users.doctors,
      icon: 'DASHBOARD',
      title: I18nService.t('Base.Statistics'),
      key: params.doctorId
        ? ROUTE_PATH.users.doctor.byId(params.doctorId)
        : ROUTE_PATH.users.doctors,
    },
    {
      roles: ['laboratory'],
      routePath: ROUTE_PATH.laboratory.analyzes,
      icon: 'LIST_BOX_OUTLINE',
      title: I18nService.t('Laboratory.Analysis.Analyzes'),
      key: ROUTE_PATH.laboratory.analyzes,
    },
    {
      roles: ['laboratory'],
      routePath: ROUTE_PATH.laboratory.referencesGroups,
      icon: 'LIST_BOX_OUTLINE',
      title: I18nService.t('Laboratory.ReferencesGroups'),
      key: ROUTE_PATH.laboratory.referencesGroups,
    },
  ];

  return items.filter((item) => {
    const itemHasCurrentRole = item.roles?.includes(params.userRole);

    if (itemHasCurrentRole && item.submenu?.length) {
      item.submenu = item.submenu.filter(
        (subItem) => !subItem?.roles || subItem.roles?.includes(params.userRole)
      );
    }

    return itemHasCurrentRole;
  });
};
