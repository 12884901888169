import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const SERVICES_ROUTE = {
  name: 'SERVICES',
  path: '/services',
  component: 'VServices',
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'manager'])],
  meta: {
    title: 'Views.Services.Title',
  },
};

export const routes = [SERVICES_ROUTE];
