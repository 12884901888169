{
  "en": {
    "Statistic": {
      "AppointmentByTime": "Appointment by time",
      "AppointmentSources": "Clinic appointment sources",
      "AverageAppointmentTime": "Average appointment time",
      "Completed": "Completed",
      "CompletedAppointments": "Completed appointments",
      "CorrectlyCompletedAppointments": "Correctly completed appointments",
      "Hour": "h",
      "Income": "Income (mln. Uzbek som)",
      "Incomplete": "Incomplete",
      "LiveQueuing":"Live queuing",
      "Minute": "m",
      "Minutes": "Minutes",
      "MoneyAmount": "Money amount",
      "NumberOfAppointments": "Number of appointments",
      "PatientFeedback": "Patient feedback",
      "PatientWaitingTime": "Patient waiting time",
      "PersonalSite": "Personal site",
      "Phone": "Phone",
      "ProvidedServices": "Provided services",
      "Quantity": "Quantity",
      "Time": "Time",
      "TimeSpentSeeingPatient": "Time spent seeing a patient",
      "TimeSpentWaitingForPatient": "Time spent waiting for a patient",
      "TopDoctorsByAmount": "Top doctors by amount",
      "TopDoctorsByCount": "Top doctors by appointments",
      "TopServicesByAmount": "Top services by amount",
      "TopServicesByCount": "Top services by receptions",
      "TotalNumberOfPatients": "Total number of patients"
    }
  },
  "ru": {
    "Statistic": {
      "AppointmentByTime":"Запись на время",
      "AppointmentSources": "Источники записи в клинику",
      "AverageAppointmentTime": "Среднее время приема",
      "Completed": "Завершенные",
      "CompletedAppointments": "Завершенные приемы",
      "CorrectlyCompletedAppointments": "Корректно завершенные приемы",
      "Hour": "ч",
      "Income": "Доход (млн. сумов)",
      "Incomplete": "Незавершенные",
      "LiveQueuing":"Живая очередь",
      "Minute": "м",
      "Minutes": "Минуты",
      "MoneyAmount": "Сумма",
      "NumberOfAppointments": "Количество приемов",
      "PatientFeedback": "Отзывы пациентов",
      "PatientWaitingTime": "Время ожидания пациента",
      "PersonalSite": "Личный сайт",
      "Phone": "Телефон",
      "ProvidedServices": "Оказанные услуги",
      "Quantity": "Количество",
      "Time": "Время",
      "TimeSpentSeeingPatient": "Время потраченное на прием пациента",
      "TimeSpentWaitingForPatient": "Время потраченное на ожидание пациента",
      "TopDoctorsByAmount": "Топ врачи по сумме",
      "TopDoctorsByCount": "Топ врачи по приёмам",
      "TopServicesByAmount": "Топ услуг по сумме",
      "TopServicesByCount": "Топ услуг по приёмам",
      "TotalNumberOfPatients": "Общее количество пациентов"
    }
  },
  "uz": {
    "Statistic": {
      "AppointmentByTime":"Vaqt bo'yicha tayinlash",
      "AppointmentSources": "Klinikaga tayinlanish manbalari",
      "AverageAppointmentTime": "O'rtacha uchrashuv vaqti",
      "Completed": "Bajarildi",
      "CompletedAppointments": "Tugallangan uchrashuvlar",
      "CorrectlyCompletedAppointments": "To'g'ri bajarilgan uchrashuvlar",
      "Hour": "ch",
      "Income": "Daromad (million so‘m)",
      "Incomplete": "Tugallanmagan",
      "LiveQueuing":"Jonli navbat",
      "Minute": "m",
      "Minutes": "Pul miqdori",
      "MoneyAmount": "Сумма",
      "NumberOfAppointments": "Qabul qilishlar soni",
      "PatientFeedback": "Bemorlarning sharhlari",
      "PatientWaitingTime": "Bemorning kutish vaqti",
      "PersonalSite": "Shaxsiy sayt",
      "Phone": "Telefon",
      "ProvidedServices": "Ko'rsatilgan xizmatlar",
      "Quantity": "Miqdori",
      "Time": "Vaqt",
      "TimeSpentSeeingPatient": "Bemorni ko'rish uchun sarflangan vaqt",
      "TimeSpentWaitingForPatient": "Bemorni kutish uchun sarflangan vaqt",
      "TopDoctorsByAmount": "Top-shifokorlar narx bo‘yicha",
      "TopDoctorsByCount": "Top-shifokorlar qabul bo‘yicha ",
      "TopServicesByAmount": "Top-xizmatlar narx bo‘yicha",
      "TopServicesByCount": "Top-xizmatlar qabul bo‘yicha ",
      "TotalNumberOfPatients": "Bemorlarning umumiy soni"
    }
  }
}