{
  "en": {
    "Common": {
      "Action": "Action",
      "Actions": "Actions",
      "Amount": "Amount",
      "Back": "Back",
      "Cancel": "Cancel",
      "Complete": "Complete",
      "Confirm": "Confirm",
      "ConfirmAction": "Confirm action",
      "Confirmation": "Confirmation",
      "Contact": "Contact",
      "Cost": "Cost",
      "CreationDate": "Creation date",
      "DateOfReceipt": "Date of receipt",
      "Delete": "Delete",
      "Doctor": "Doctor",
      "Doctors": "Doctors",
      "DownloadExcelFile": "Download Excel file",
      "Edit": "Edit",
      "Employee": "Employee",
      "EmployeeSearch": "Employee search",
      "Employees": "Employees",
      "FIOEmployee": "Employee's full name",
      "GoToHomePage": "Go to home page",
      "Loading": "Loading",
      "Logout": "Logout",
      "No": "No",
      "NoAccess": "No access",
      "NoData": "No data",
      "NumberOfRecords": "Number of records",
      "PageNotFound": "Page not found",
      "Patient": "Patient",
      "PatientFullName": "Patient full name",
      "Price": "Price",
      "PriceListCategory": "Price list category",
      "RecordingDate": "Recording date",
      "Save": "Save",
      "SaveChanges": "Save Changes",
      "Search": "Search",
      "Service": "Service",
      "Services": "Services",
      "Status": "Status",
      "Sum": "Som",
      "Total": "Total",
      "View": "View",
      "Yes": "Yes"
    }
  },
  "ru": {
    "Common": {
      "Action": "Действие",
      "Actions": "Действия",
      "Amount": "Сумма",
      "Back": "Назад",
      "Cancel": "Отмена",
      "Complete": "Завершить",
      "Confirm": "Подтвердить",
      "ConfirmAction": "Подтвердите действие",
      "Confirmation": "Подтверждение",
      "Contact": "Контакт",
      "Cost": "Стоимость",
      "CreationDate": "Дата создания",
      "DateOfReceipt": "Дата приема",
      "Delete": "Удалить",
      "Doctor": "Врач",
      "Doctors": "Врачи",
      "DownloadExcelFile": "Скачать Excel файл",
      "Edit": "Редактировать",
      "Employee": "Сотрудник",
      "EmployeeSearch": "Поиск сотрудников",
      "Employees": "Сотрудники",
      "FIOEmployee": "ФИО сотрудника",
      "GoToHomePage": "Перейти на главную страницу",
      "Loading": "Загрузка...",
      "Logout": "Выйти",
      "No": "Нет",
      "NoAccess": "Нет доступа",
      "NoData": "Нет данных",
      "NumberOfRecords": "Кол-во записей",
      "PageNotFound": "Страница не найдена",
      "Patient": "Пациент",
      "PatientFullName": "ФИО пациента",
      "Price": "Цена",
      "PriceListCategory": "Категория прайслиста",
      "RecordingDate": "Дата записи",
      "Save": "Сохранить",
      "SaveChanges": "Сохранить изменения",
      "Search": "Поиск",
      "Service": "Услуга",
      "Services": "Услуги",
      "Status": "Статус",
      "Sum": "Cум",
      "Total": "Итого",
      "View": "Просмотр",
      "Yes": "Да"
    }
  },
  "uz": {
    "Common": {
      "Action": "Harakat",
      "Actions": "Harakatlar",
      "Amount": "Miqdor",
      "Back": "Orqaga",
      "Cancel": "Bekor qilish",
      "Complete": "Bajarildi",
      "Confirm": "Tasdiqlash",
      "ConfirmAction": "Tasdiqlang",
      "Confirmation": "Tasdiq",
      "Contact": "Aloqa",
      "Cost": "Narxi",
      "CreationDate": "Yaratilgan sana",
      "DateOfReceipt": "Qabul san'ati",
      "Delete": "Oʻchirish",
      "Doctor": "Doktor",
      "Doctors": "Doktorlar",
      "DownloadExcelFile": "Excel faylini yuklab oling",
      "Edit": "Tahrirlash",
      "Employee": "Xodim",
      "EmployeeSearch": "Hamkasblarni qidiring",
      "Employees": "Xodimlar",
      "FIOEmployee": "Xodimning to'liq ismi",
      "GoToHomePage": "Bosh sahifaga o'tish",
      "Loading": "Yuklanmoqda...",
      "Logout": "Chiqish",
      "No": "Yo'q",
      "NoAccess": "Kirish imkoni yo'q",
      "NoData": "Malumot topilmadi",
      "NumberOfRecords": " Yozuvlar soni",
      "PageNotFound": "Sahifa topilmadi",
      "Patient": "Bemor",
      "PatientFullName": "Bemorning ismi",
      "Price": "Narxi",
      "PriceListCategory": "Narx ro'yhati kategoriya",
      "RecordingDate": "??Yozib olingan sanasi",
      "Save": "Saqlash",
      "SaveChanges": "O'zgarishlarni saqlash",
      "Search": "Topish",
      "Service": "Xizmat",
      "Services": "Xizmatlar",
      "Status": "Holat",
      "Sum": "Uzbek sum",
      "Total": "Jami",
      "View": "Ko'rish",
      "Yes": "Ha"
    }
  }
}