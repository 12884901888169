{
  "en": {
    "Title": "Patient information"
  },
  "ru": {
    "Title": "Информация о пациенте"
  },
  "uz": {
    "Title": "Bemor haqida ma'lumot"
  }
}