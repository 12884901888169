{
  "en": {
    "Appointment": "Reception",
    "CompanyTitle": "Download the app",
    "Creator": "Recorded",
    "Paid": "Paid"
  },
  "ru": {
    "Appointment": "Прием",
    "CompanyTitle": "Скачайте приложение",
    "Creator": "Записал",
    "Paid": "Оплачено"
  },
  "uz": {
    "Appointment": "Tayinlash",
    "CompanyTitle": "Ilovani yuklab oling",
    "Creator": "Yozib oldi",
    "Paid": "To'langan"
  }
}