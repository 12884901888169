<template>
  <ElDialog
    class="create-or-edit-template-modal"
    :title="data?.id ? $t('Templates.Edit') : $t('Templates.Create')"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElForm
      id="create-or-edit-template-modal-form"
      label-position="top"
      @submit.prevent="submitHandler">
      <ElFormItem :label="$t('Templates.Name')">
        <ElInput v-model="template.title" :placeholder="$t('Base.WriteText')" required />
      </ElFormItem>
    </ElForm>

    <template #footer>
      <ElButton
        type="primary"
        native-type="submit"
        form="create-or-edit-template-modal-form"
        :loading="loading">
        {{ data?.id ? $t('Base.Edit') : $t('Base.Create') }}
      </ElButton>
    </template>
  </ElDialog>
</template>

<script>
import axios from 'axios';

import { InspectionCardTemplate } from '@/models/InspectionCardTemplate.model';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { useSessionStore } from '~entities/session';

export default {
  name: 'CreateOrEditTemplateModal',
  emits: ['update:modelValue', 'action'],
  props: {
    data: [InspectionCardTemplate, Object],
  },
  data() {
    return {
      template: null,
      loading: false,
      sessionStore: useSessionStore(),
    };
  },

  computed: {
    user() {
      return this.sessionStore.user;
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.template = new InspectionCardTemplate(this.data || {});
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async submitHandler() {
      this.loading = true;

      try {
        const template = this.template.id ? await this.editTemplate() : await this.createTemplate();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.template.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalInstance({
            name: this.template.id ? 'updated' : 'created',
            data: { template },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    async createTemplate() {
      const { template } = await InspectionCardTemplate.create(this.template);
      return template;
    },

    async editTemplate() {
      const { template } = await InspectionCardTemplate.update({
        id: this.template.id,
        payload: this.template,
      });
      return template;
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
