<template>
  <ElFormItem
    class="mi-info-field"
    size="default"
    :class="{
      'mi-info-field--horizontal': horizontal,
      'mi-info-field--space-between': spaceBetween,
    }">
    <div class="mi-info-field-info">
      <slot></slot>
    </div>
  </ElFormItem>
</template>
<script lang="ts">
export default {
  name: 'MiInfoField',
};
</script>

<script lang="ts" setup>
type Props = {
  horizontal?: boolean;
  spaceBetween?: boolean;
};
defineProps<Props>();
</script>

<style lang="scss" src="./index.scss" />
