import { CRUDModel } from '@/models/CRUD.model';
import { apiService } from '~shared/api';
import { mergeOrCreateQuery } from '@/utils/http.util';

export class ServiceGroup extends CRUDModel {
  static modelName = 'groupService';
  static tableName = 'services/group';

  /**
   * @param {object} [payload]
   * @param {number} payload.id
   * @param {string} payload.title
   * @param {string} payload.title_ru
   * @param {string} payload.title_uz
   * @param {number} payload.price
   * @param {number} payload.clinic_id
   * @param {Clinic} payload.clinic
   * @param {Array<Service>} payload.services
   * @param {Array<number>} payload.service_ids
   * @param {number} payload.category_id
   * @param {Category} payload.category
   * @param {Array<number>} payload.doctor_ids
   * @param {Array<Doctor>} payload.doctors
   * @param {string} payload.slot_duration
   */
  constructor(payload) {
    super(payload);

    this.title = payload?.title ?? null;
    this.title_ru = payload?.title_ru ?? null;
    this.title_uz = payload?.title_uz ?? null;
    this.price = payload?.price ?? null;
    this.clinic_id = payload?.clinic_id ?? null;
    this.clinic = payload?.clinic ?? null;
    this.services = payload?.services ?? null;
    this.service_ids = payload?.service_ids ?? null;
    this.category_id = payload?.category_id ?? null;
    this.category = payload?.category ?? null;
    this.doctor_ids = payload?.doctor_ids ?? []; // virtual field
    this.doctors = payload?.doctors ?? []; // virtual field
    this.slot_duration = payload?.slot_duration ?? null;
  }

  static async find(payload) {
    return super.find({
      _url: `services/group/list`,
      ...payload,
    });
  }

  /**
   * @param {string} startAt DATE_FORMAT from dateAndTime.config
   * @param {string} endAt DATE_FORMAT from dateAndTime.config
   * @return {Promise<{data: any, response: AxiosResponse<any>}>}
   */
  static async getTopByPeriod({ startAt, endAt }) {
    const response = await apiService.api.instance.get(
      mergeOrCreateQuery({
        url: `statistics/services/top`,
        query: { start_at: startAt, end_at: endAt },
      })
    );
    return { response, data: response.data };
  }
}
