import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';
import ElementPlus from 'element-plus';
import { createApp } from 'vue';
import 'dayjs/locale/ru'; // Date and time localization
dayjs.locale('ru');
import VueJivosite from '@bchteam/vue-jivosite';

import './index.scss';

import App from './App.vue';
import { router, storeVuex, storePinia } from './providers';

import { I18nPlugin, getJivoChatWidgetId } from '~shared/lib';

const app = createApp(App)
  .use(router)
  // TODO: переходное решение, в будущем выпилить
  .use(storeVuex)
  .use(storePinia)
  .use(I18nPlugin)
  .use(ElementPlus)
  .use(VueJivosite, {
    widgetId: getJivoChatWidgetId(),
  });

// init global ui components
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UiComponents = import.meta.glob<true, string, any>('../components/ui/**/*.vue', {
  eager: true,
});
for (const key in UiComponents) {
  const component = UiComponents[key].default;
  app.component(component.name, component);
}

// init sentry
if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [import.meta.env.VITE_CURRENT_MODE_SITE_URL, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

app.mount('#app');
