import axios from 'axios';
import throttle from 'lodash.throttle';

import { insertRouteParams } from '@/utils/router.utils';
import { Patient } from '@/models/Patient.model';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { PHONE_CONFIRM_MODAL_CONFIRMED_ACTION } from '@/components/PhoneConfirmModal/index.enum';
import { FULL_DATE_FORMAT } from '~shared/config';
import { Appointment } from '@/models/appointment/Appointment.model';
import { MiSelectSearch } from '@/shared/ui';
import { misB2BApi } from '~shared/api';
import PhoneConfirmModal from '@/components/PhoneConfirmModal/index.vue';
import SelectReference from '@/components/appointments/SelectReference/index.vue';

export default {
  name: 'CreateOrEditPatient',
  components: { MiSelectSearch, SelectReference, PhoneConfirmModal },
  emits: ['update:modelValue', 'action', 'reference:update', 'foundPatient', 'reattachPatient'],
  props: {
    appointment: {
      type: [Appointment, Object],
    },
    modelValue: Boolean,
    nameOrPhone: String,
    data: [Patient, Object],

    disableDefaultAction: Boolean, // отключаем дефолтное поведение после создания
  },
  data() {
    return {
      loading: {
        form: false,
        attach: false,
      },
      isChildren: false,
      /** @type Patient */
      patient: null,
      /** @type Patient */
      oldPatient: null,
      hasPatient: false,
      hasPatientFromOtherClinic: false,

      isOpenPhoneConfirmModal: false,
      isRebinding: false, // если пользователь был найден и мы успешно подтвердили код - можем создать новый акк
      code: null, // для хранения кода подтверждения при rebinding или смене номера

      throttleCheckHasPatient: null,
      misB2BApi,
    };
  },
  computed: {
    phone: {
      get() {
        return this.isChildren ? this.patient.parent?.phone : this.patient.phone;
      },
      set(value) {
        if (this.isChildren) this.patient.parent.phone = value;
        else this.patient.phone = value;
      },
    },
    hasPhoneNumber() {
      return true;
    },

    oldPatientPageUrl() {
      return insertRouteParams({
        path: PATIENT_ROUTE.path,
        params: { id: this.oldPatient?.id },
      });
    },
    isChildrenSwitchIsDisabled() {
      return !!this.data?.id || !!this.data?.parent_id;
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.patient = new Patient(this.data || {});
        if (this.data?.parent_id) this.isChildren = true;
      },
      immediate: true,
    },
    nameOrPhone: {
      handler() {
        this.nameOrPhoneWatcherHandler();
      },
      immediate: true,
    },
    isChildren: {
      handler(value) {
        this.patient.phone = '';
        this.resetHasPatient();

        if (value) {
          this.patient = { ...new Patient({ ...this.patient, parent: new Patient() }) };
        }
      },
    },
    phone: {
      handler() {
        this.phoneWatcherHandler();
      },
    },
  },

  methods: {
    async submitHandler() {
      if (this.loading.form) return;
      this.loading.form = true;

      try {
        const patient = this.data?.id ? await this.editPatient() : await this.createPatient();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.patient.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalInstance({
            action: this.patient.id ? 'updated' : 'created',
            data: { patient },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.form = false;
    },

    customValidate() {
      const button = this.$refs.actions.querySelector("button[type='submit']");

      if (this.hasPatient) {
        this.$notify({ type: 'error', title: this.$t('Patients.PatientAlreadyExists') });
        return false;
      }

      const isPatientErrors = !this.patient.name || (!this.isChildren && !this.patient.phone);
      const isChildrenErrors =
        this.isChildren &&
        (this.patient.parent_id ? false : !this.patient.parent.phone || !this.patient.parent.name);

      if (isPatientErrors || isChildrenErrors) {
        button.click();
        this.$notify({ type: 'error', title: this.$t('Notifications.FillRequiredFields') });
        return false;
      }

      return true;
    },

    async createPatient() {
      const { patient } = this.isRebinding
        ? await Patient.rebinding({ patient: this.patient, code: this.code })
        : await Patient.create(this.patient, this.isChildren);
      if (!this.disableDefaultAction) {
        setTimeout(() => this.goToPatient({ patientId: patient.id }));
      }
      return patient;
    },

    async editPatient() {
      if (this.data.phone !== this.patient.phone) {
        await this.openPhoneConfirmModal();
      }

      const { patient } = await Patient.update({
        id: this.patient.id,
        payload: this.isRebinding ? { ...this.patient, code: this.code } : this.patient,
      });
      return patient;
    },

    openPhoneConfirmModal() {
      this.isOpenPhoneConfirmModal = true;
      return new Promise((resolve) => {
        this.phoneConfirmPromise.resolve = resolve;
      });
    },

    // проверку телефона для создания нового пациента или смены текущего номера
    async checkPhoneForRebinding(action) {
      if (action.name !== PHONE_CONFIRM_MODAL_CONFIRMED_ACTION || !action.data.code) return;

      this.resetHasPatient();
      this.isOpenPhoneConfirmModal = false;
      this.isRebinding = true;
      this.code = action.data.code;
      this.patient = new Patient({ ...(this.data || {}), phone: this.patient.phone });

      this.phoneConfirmPromise.resolve();
    },

    // привязать к нашей клинике
    async attachPatient() {
      this.loading.attach = true;
      try {
        const { patient } = await Patient.attachPatient({ patient_id: this.oldPatient.id });

        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessAttached') });
        this.$emit('action', new GlobalModalInstance({ name: 'attached', data: { patient } }));
        if (!this.disableDefaultAction)
          this.goToPatient({ patient: patient, patientId: patient.id });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
      this.loading.attach = false;
    },

    async checkHasPatient() {
      if (this.isChildren) this.resetPatientParent();
      else this.resetHasPatient();

      const { patient, attach_clinic } = await Patient.checkPatient({ phone: this.phone });
      if (!patient) return;

      this.$emit('foundPatient');

      if (this.isChildren && patient) {
        this.patient.parent_id = patient.id;
        this.patient.parent = patient;
      }
      if (!this.isChildren) {
        this.oldPatient = patient;
        this.hasPatient = true;
        this.hasPatientFromOtherClinic = !attach_clinic;
      }
    },
    resetHasPatient() {
      this.isRebinding = false;
      this.oldPatient = null;
      this.hasPatient = false;
      this.hasPatientFromOtherClinic = false;
    },
    resetPatientParent() {
      if (this.patient.parent) {
        this.patient.parent_id = null;
        this.patient.parent.name = null;
      }
    },

    goToPatient({ patientId }) {
      this.$router.push({
        name: PATIENT_ROUTE.name,
        params: {
          id: patientId || this.patient.id,
        },
      });
    },

    nameOrPhoneWatcherHandler() {
      if (!this.nameOrPhone) return;

      const isName = this.queryIsName(this.nameOrPhone);
      isName ? (this.patient.name = this.nameOrPhone) : (this.patient.phone = this.nameOrPhone);
    },
    phoneWatcherHandler() {
      if (!this.hasPhoneNumber) return;
      this.throttleCheckHasPatient();
    },
    queryIsName(query) {
      return /[a-zA-Zа-яА-Я?\s]/gim.test(query);
    },

    handleOpenPhoneConfirmModal() {
      if (!this.data) {
        this.$emit('reattachPatient');
      }

      this.isOpenPhoneConfirmModal = true;
    },

    async setDefaultPriceList() {
      const response = await misB2BApi.priceLists.getList();
      if (response?.data && response.data[0]) {
        this.patient.price_list = response.data[0];
        this.patient.price_list_id = response.data[0].id;
      }
    },
  },

  created() {
    this.throttleCheckHasPatient = throttle(this.checkHasPatient, 300);
    // Не запускаем при редактировании
    if (!this.data) {
      this.phoneWatcherHandler();
      this.setDefaultPriceList();
    }
  },

  setup: () => ({
    FULL_DATE_FORMAT: FULL_DATE_FORMAT,
    phoneConfirmPromise: { resolve: null },
    Patient,
  }),
};
