{
  "en": {
    "Templates": {
      "AddTemplate": "Add Template",
      "AskingRemoveCheckup": "??Вы действительно хотите шаблон осмотра?",
      "AskingRemoveCheckupHeader": "Are you sure you want to remove the checkup header?",
      "CheckupHeader": "Checkup header",
      "CheckupHeaderName": "Checkup header name",
      "CheckupHeaders": "Checkup headers",
      "CheckupTemplateEditor": "Checkup template editor",
      "CheckupTemplates": "Checkup templates",
      "Confidentiality": "Confidentiality",
      "Create": "Create a template",
      "CreatingCheckupHeader": "Creating checkup header",
      "DateOfCreation": "Date of creation",
      "DocumentPlaceholder": "Choose the form you want to fill out",
      "Edit": "Edit template",
      "HeaderName": "Header name",
      "InspectionCardHeader": "Inspection card header",
      "Name": "Template name",
      "NewTemplate": "New template",
      "OtherAnswer": "Your ovtet",
      "Personal": "Personal",
      "Public": "Public",
      "RemoveCheckup": "??Удалить шаблон осмотра",
      "RemoveCheckupHeader": "Remove checkup header",
      "SelectDocument": "Select document",
      "SelectTemplate": "Select template",
      "Template": "Template",
      "Templates": "Templates"
    }
  },
  "ru": {
    "Templates": {
      "AddTemplate": "Добавить шаблон",
      "AskingRemoveCheckup": "Вы действительно хотите шаблон осмотра?",
      "AskingRemoveCheckupHeader": "Вы действительно хотите удалить шапку осмотра?",
      "CheckupHeader": "Шапки осмотра",
      "CheckupHeaderName": "Название шапки осмотра",
      "CheckupHeaders": "Шапки осмотров",
      "CheckupTemplateEditor": "Редактор шаблонов осмотра",
      "CheckupTemplates": "Шаблоны осмотров",
      "Confidentiality": "Конфиденциальность",
      "Create": "Создать шаблон",
      "CreatingCheckupHeader": "Создание шапки осмотра",
      "DateOfCreation": "Дата создания",
      "DocumentPlaceholder": "Выберите форму которую хотите заполнить",
      "Edit": "Редактировать шаблон",
      "HeaderName": "Название шапки",
      "InspectionCardHeader": "Шапка карты осмотра",
      "Name": "Название шаблона",
      "NewTemplate": "Новый шаблон",
      "OtherAnswer": "Свой овтет",
      "Personal": "Личная",
      "Public": "Публичная",
      "RemoveCheckup": "Удалить шаблон осмотра",
      "RemoveCheckupHeader": "Удалить шапку осмотра",
      "SelectDocument": "Выбрать документ",
      "SelectTemplate": "Выбрать шаблон",
      "Template": "Шаблон",
      "Templates": "Шаблоны"
    }
  },
  "uz": {
    "Templates": {
      "AddTemplate": "Shablon qo'shish",
      "AskingRemoveCheckup": "??Вы действительно хотите шаблон осмотра?",
      "AskingRemoveCheckupHeader": "Haqiqatan ham tekshiruv sarlavhasini olib tashlamoqchimisiz?",
      "CheckupHeader": "Tekshirish sarlavhasi",
      "CheckupHeaderName": "Tekshirish sarlavhasi nomi",
      "CheckupHeaders": "Tekshiruv sarlavhalari",
      "CheckupTemplateEditor": "??Редактор шаблонов осмотра",
      "CheckupTemplates": "Tekshiruv shablonlari",
      "Confidentiality": "Maxfiylik",
      "Create": "Shablon yaratish",
      "CreatingCheckupHeader": "Tekshirish sarlavhasini yaratish",
      "DateOfCreation": "Yaratilgan sana",
      "DocumentPlaceholder": "To'ldirishingiz kerak bo'lgan  formani tanlang",
      "Edit": "Shablonni tahrirlash",
      "HeaderName": "Sarlavha nomi",
      "InspectionCardHeader": "??Inspection card header",
      "Name": "Shablon nomi",
      "NewTemplate": "??New template",
      "OtherAnswer": "Boshqa javob",
      "Personal": "Shaxsiy",
      "Public": "Ommaviy",
      "RemoveCheckup": "??Удалить шаблон осмотра",
      "RemoveCheckupHeader": "Tekshirish Shablonni olib tashlang",
      "SelectDocument": "Hujjatni tanlash",
      "SelectTemplate": "Shablonni tanlang",
      "Template": "Shablon",
      "Templates": "Shablonlar"
    }
  }
}
