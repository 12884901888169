<template>
  <UiTableWithPagination
    :data="dataWithPayload"
    :page="page"
    :per-page="perPage"
    :total="total"
    :loading="loading"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="rowClickHandler">
    <ElTableColumn prop="title" :label="$t('Base.Naming')" />
    <ElTableColumn prop="_gender" :label="$t('User.Gender')" />
    <ElTableColumn prop="_agePeriod" :label="$t('User.Age')" />

    <ElTableColumn :label="$t('Base.Actions')" width="180px">
      <template #default="{ row }">
        <ElButton type="primary" plain @click.stop="edit(row)">
          {{ $t('Base.Edit') }}
        </ElButton>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { LABORATORY_REFERENCES_GROUPS_VALUES_ROUTE } from '@/router/laboratory.routes';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import CreateOrEditReferencesGroupModal from '@/components/laboratory/referencesGroups/CreateOrEditReferencesGroupModal/index.vue';

export default {
  name: 'ReferencesGroupsTable',
  emits: ['update:page', 'update:perPage', 'item:update'],
  components: { UiTableWithPagination },
  props: {
    /** @type {Array<ReferencesGroup>} */
    data: Array,
    total: Number,
    loading: Boolean,
    page: Number,
    perPage: Number,
  },
  computed: {
    dataWithPayload() {
      return this.data.map((referencesGroup) => ({
        ...referencesGroup,
        _gender: this.$t(`User.Genders.${referencesGroup.gender}`),
        _agePeriod: `${referencesGroup.start_age} - ${referencesGroup.end_age}`,
      }));
    },
  },

  methods: {
    async edit(referencesGroup) {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditReferencesGroupModal,
        payload: {
          data: referencesGroup,
        },
      });

      if (!(action instanceof GlobalModalClose))
        this.$emit('item:update', action.data.referencesGroup);
    },

    rowClickHandler(referencesGroup) {
      this.$router.push({
        name: LABORATORY_REFERENCES_GROUPS_VALUES_ROUTE.name,
        params: {
          referencesGroupId: referencesGroup.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
