import { AxiosError, GenericAbortSignal } from 'axios';
import { ElNotification } from 'element-plus';

import {
  apiService,
  GetPatientListResponseData,
  GetPatientResponseData,
  GetReferredByResponseData,
  ProfileFullResource,
} from '~shared/api';
import { useAbortable } from '~shared/hooks/useAbortable';
import { QueryParams } from '~shared/types';
import { getApiErrorMessage, joinQueryParams, prepareTokenForBracelet } from '~shared/lib';

const urlPath = '/patients';

export const getById = async (id: number) => {
  const response = await apiService.api.instance.get<{ data: ProfileFullResource }>(
    `${urlPath}/${id}`
  );
  return {
    ...response.data,
  };
};

export const getQrCodeLinkByUserId = async (userId: string | number) => {
  const response = await apiService.api.instance.get<{ link: string }>(`printer/${userId}/link`);

  return {
    ...response.data,
  };
};

export const getList = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  const response = await apiService.api.instance.get<GetPatientListResponseData>(
    joinQueryParams({
      url: urlPath,
      query: query,
    }),
    {
      signal,
    }
  );
  return {
    ...response.data,
  };
};

export const getByBracelet = async (patientName: string) => {
  const token = prepareTokenForBracelet(patientName);
  const response = await apiService.api.instance.get<GetPatientResponseData>(
    `patients/getByOneTimeToken?oneTimeToken=${token}`
  );
  return {
    ...response.data,
  };
};

export const getReferredBy = async (userId: string | number) => {
  try {
    const response = await apiService.api.instance.get<GetReferredByResponseData>(
      `v2/patients/${userId}/referred-by`
    );

    return {
      ...response.data,
    };
  } catch (err) {
    if ((err as AxiosError)?.response?.status === 404) {
      return err;
    } else {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
};

export const abortableGetList = useAbortable(getList);
