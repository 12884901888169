import axios from 'axios';

import { InspectionCardTemplate } from '@/models/InspectionCardTemplate.model';
import { InspectionCard } from '@/models/InspectionCard.model';
import TinyEditor from '@/components/common/TinyEditor/index.vue';
import { MainLayout } from '~widgets/layouts';
import EditorCard from '@/components/EditorCard/index.vue';
import PrinterDocument from '@/components/printer/PrinterDocument/index.vue';
import { MiIcon } from '~shared/ui';

export default {
  name: 'VTemplate',
  components: { MainLayout, EditorCard, PrinterDocument, TinyEditor, MiIcon },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      loading: {
        template: false,
        form: false,
      },
      /** @type {InspectionCardTemplate} template */
      template: new InspectionCardTemplate(),
    };
  },

  watch: {
    id: {
      handler(value) {
        if (value) this.getTemplateById(value);
      },
      immediate: true,
    },
  },

  computed: {
    isTinyEditor() {
      return this.template?.type === InspectionCard.enum.types.Tiny;
    },
    tinyData: {
      get() {
        return this.template?.basic_data ?? '';
      },
      set(val) {
        this.template.basic_data = val;
      },
    },
  },

  methods: {
    async getTemplateById(id) {
      this.loading.template = true;

      const { template } = await InspectionCardTemplate.findOneById(id);
      this.template = template;

      this.loading.template = false;
    },

    async updateTemplate() {
      if (this.loading.form) return;
      this.loading.form = true;
      const basic_data = this.isTinyEditor ? this.tinyData : { ...this.template.basic_data[0] };

      try {
        await InspectionCardTemplate.update({
          id: this.template.id,
          payload: { ...this.template, basic_data },
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.form = false;
    },

    updateEditorHandler(payload) {
      this.template.basic_data[0].blocks = payload.blocks;
    },
    changeEditorHandler(payload) {
      this.template.basic_data[0].blocks = payload.blocks;
    },

    print() {
      this.$refs?.printerEditorCard?.rerender(); // TODO: как реализуется обновление редактора по пропсу - удалить эту строку
      this.$refs?.printerDocument.print();
    },
  },
};
