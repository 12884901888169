<template>
  <ElContainer v-bind="$attrs" class="main-layout">
    <ElAside class="main-layout__menu">
      <slot name="menu">
        <SidebarMenu />
      </slot>
    </ElAside>

    <ElContainer
      :class="['main-layout__main ', { 'main-layout__main_fix-height': fixHeight }, mainClass]">
      <ElHeader class="main-layout__header main-layout__el-header">
        <div class="main-layout__header-container">
          <div class="main-layout__header-left-content">
            <PatientsSearch v-if="!isWarehouseOrAccountant" class="main-layout__search" />
          </div>
          <!--    <NotificationsHeader></NotificationsHeader>-->

          <div class="main-layout__header-actions">
            <ClinicSelect v-if="userRole === 'director'" />
            <RedirectToAppointmentBtn v-if="isManagerOrDoctor" />
          </div>

          <LangSelect />
          <!-- <NotificationsDrawer /> -->
          <ElDivider direction="vertical" />
          <LayoutUserDropdown />
        </div>
      </ElHeader>

      <ElMain :class="['main-layout__content', contentClass]" v-loading="loading">
        <slot></slot>
      </ElMain>
    </ElContainer>
  </ElContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { LayoutUserDropdown } from '../LayoutUserDropdown';

import { RedirectToAppointmentBtn } from '~features/RedirectToAppointmentBtn';
import { ClinicSelect } from '~features/ClinicSelect';
import { useSessionStore } from '~entities/session';
import { LangSelect } from '~features/LangSelect';
import { SidebarMenu } from '~features/SidebarMenu';
// TODO: FSD
import PatientsSearch from '@/components/patients/PatientsSearch/index.vue';

const sessionStore = useSessionStore();
const { userRole } = storeToRefs(sessionStore);

defineProps<{
  fixHeight?: boolean;
  contentClass?: string;
  mainClass?: string;
  loading?: boolean;
}>();

const isWarehouseOrAccountant = computed(
  () => userRole.value === 'warehouse' || userRole.value === 'accountant'
);

const isManagerOrDoctor = computed(
  () => userRole.value === 'manager' || userRole.value === 'doctor'
);
</script>

<style lang="scss" src="./index.scss" />
