import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';
import { ROUTE_PATH } from '~shared/config';

export const ACCOUNTING_ROUTE_OUTCOME = {
  name: 'ACCOUNTING_ROUTE_OUTCOME',
  path: ROUTE_PATH.bookkeeping.outcomes,
  component: 'VAccountingOutcome',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Outcome.Expenses',
  },
};

export const ACCOUNTING_ROUTE_SERVICES = {
  name: 'ACCOUNTING_ROUTE_SERVICES',
  path: ROUTE_PATH.bookkeeping.services,
  component: 'VServices',
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: 'Views.Services.Title',
  },
};

export const routes = [ACCOUNTING_ROUTE_OUTCOME, ACCOUNTING_ROUTE_SERVICES];
