export function loadAndGenerateStoreModules() {
  const modules = {};

  const loadedModules = import.meta.glob('@/store/**/*.store.js', { eager: true });
  for (const key in loadedModules) {
    const module = loadedModules[key].default;
    modules[module.name] = module;
  }

  return modules;
}
