<template>
  <MiTag v-if="status" :size="size" :type="typeByStatus[status]">
    {{ $t(`Invoices.Statuses.${status}`) }}
  </MiTag>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { TagProps } from 'element-plus';

import { InvoiceStatusEnum } from '~shared/api';
import { MiTag, MiTagProps } from '~shared/ui';

withDefaults(
  defineProps<{
    status?: InvoiceStatusEnum;
    size?: MiTagProps['size'];
  }>(),
  {
    size: 'default',
  }
);

const typeByStatus = computed<Record<InvoiceStatusEnum, TagProps['type']>>(() => {
  return {
    partially_paid: 'warning',
    paid: 'success',
    not_paid: 'danger',
    canceled: 'info',
    refund: '',
    overpay: '',
  };
});
</script>
