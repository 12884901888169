<template>
  <MiDialog height="700px" class="invoice-modal" width="1240px" v-model="isModalOpen">
    <template #header>
      <div v-if="editMode" class="invoice-modal__header">
        <div>{{ $t('Base.Invoice') }}:</div>
        <div>#{{ values.currentInvoice?.id }}</div>
        <InvoiceStatusTag
          v-if="values.currentInvoice.status"
          class="invoice-modal__header-status"
          :status="values.currentInvoice.status" />
      </div>

      <div v-else class="create-or-pay-invoice-modal__header">
        {{ $t('Invoices.Create') }}
      </div>
    </template>

    <div v-loading="loading" class="invoice-modal__wrapper">
      <InvoiceAside :edit-mode="editMode" />

      <div class="invoice-modal__content">
        <h2 class="invoice-modal__title">{{ $t('Base.Services') }}</h2>
        <MiCard class="invoice-modal__services-card" shadow="never">
          <template #header>
            <MiFilterBox class="invoice-modal__filter-box">
              <InvoiceServiceSelect
                @add-service="addService"
                :selected-user="selectedUser"
                :read-only="readOnly" />
            </MiFilterBox>
          </template>

          <InvoiceServicesTable
            class="invoice-modal__table"
            :edit-mode="editMode"
            :read-only="readOnly" />

          <InvoiceTotalAmount class="invoice-modal__total-amount" :read-only="readOnly" />
        </MiCard>

        <InvoiceTransactionsTable v-if="editMode" />

        <InvoiceSummaryAmountTable v-if="editMode" />
      </div>
    </div>

    <FormActionsWrapper v-loading="loading" align="right">
      <template v-if="values.currentInvoice.id">
        <MiButton v-show="isShowRefundButton" plain type="danger" @click="handleRefund">{{
          $t('Base.Refund')
        }}</MiButton>
        <MiButton v-show="editMode" type="primary" plain @click="handlePrint">
          <template #icon>
            <MiIcon icon="PRINTER" />
          </template>
          {{ $t('Base.Print') }}</MiButton
        >
        <MiButton
          :disabled="isServicesEmpty"
          v-show="!readOnly"
          type="primary"
          plain
          @click="handleUpdateInvoice"
          >{{ $t('Base.Save') }}</MiButton
        >
        <MiButton v-show="isShowPayButton" type="primary" @click="handlePay">{{
          $t('Base.Pay')
        }}</MiButton>
      </template>

      <template v-else>
        <MiButton :disabled="isServicesEmpty" type="primary" @click="handleCreateInvoice">{{
          $t('CashRegister.AddInvoice')
        }}</MiButton>
      </template>
    </FormActionsWrapper>

    <InvoicePayOrRefundModal
      v-if="editMode"
      v-model="isVisiblePayModal"
      :invoice="values.currentInvoice"
      :type="payType"
      @action="handleUpdateInvoicePayment" />
    <InvoicePrinterCheck
      :invoice="values.currentInvoice"
      :qr-payload="qrPayload"
      ref="refInvoiceCheck" />
  </MiDialog>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import { computed, ref, shallowRef, watch } from 'vue';
import { array, number, object } from 'yup';

import { InvoiceAside } from './InvoiceAside';
import { InvoiceServicesTable } from './InvoiceServicesTable';
import { InvoiceTransactionsTable } from './InvoiceTransactionsTable';
import { InvoiceSummaryAmountTable } from './InvoiceSummaryAmountTable';
import { InvoiceServiceSelect } from './InvoiceServiceSelect';
import { InvoiceTotalAmount } from './InvoiceTotalAmount';

import { InvoiceStatusTag } from '~entities/invoice';
import { FormActionsWrapper } from '~shared/ui/form';
import { MiDialog, MiCard, MiFilterBox, MiButton, MiIcon } from '~shared/ui';
import {
  CreateInvoicePayload,
  InvoiceFullDto,
  misB2BApi,
  PatientShortDto,
  PaymentSubjectDto,
  ReferrerDto,
  ServiceGroupDto,
  ShortPaymentSubjectDto,
  TransactionsType,
} from '~shared/api';
import InvoicePrinterCheck from '@/components/invoices/InvoicePrinterCheck/index.vue';
import InvoicePayOrRefundModal from '@/components/invoices/InvoicePayOrRefundModal/index.vue';

const props = defineProps<{
  modelValue: boolean;
  invoiceId?: number | null;
}>();

const emit = defineEmits<{
  'update:modelValue': [e: boolean];
  'success:update': [];
}>();

const validationSchema = object({
  discount: number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .min(0)
    .max(100),
  currentInvoice: object({
    payment_subjects: array()
      .of(
        object({
          count: number()
            .nullable()
            .transform((value) => (isNaN(value) ? 1 : value))
            .min(1),
        })
      )
      .min(1),
  }),
});

type InvoiceType = Partial<Omit<InvoiceFullDto, 'payment_subjects'>> & {
  payment_subjects: InvoiceFullDto['payment_subjects'] | ShortPaymentSubjectDto[];
  transactions: InvoiceFullDto['transactions'];
  appointments: InvoiceFullDto['appointments'];
};

const { values, handleSubmit, setValues, setFieldValue, resetForm, resetField } = useForm<{
  user: PatientShortDto;
  discount: number;
  note: string;
  referrer: ReferrerDto;
  referrer_id: number;
  currentInvoice: InvoiceType;
}>({
  initialValues: {
    discount: 0,
    currentInvoice: {
      payment_subjects: [],
      transactions: [],
      appointments: [],
    },
  },
  validationSchema: validationSchema,
});

const loading = shallowRef(false);

const qrPayload = shallowRef();
const refInvoiceCheck = ref();

const isVisiblePayModal = shallowRef(false);
const payType = shallowRef<Exclude<TransactionsType, 'discount'>>('pay_in');

const isModalOpen = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const selectedUser = computed(() => {
  return values.user?.id;
});

const editMode = computed(() => {
  return Boolean(values.currentInvoice.id);
});

const readOnly = computed(() => {
  return !(editMode.value ? values.currentInvoice.status === 'not_paid' : true);
});

const isShowPayButton = computed(() => {
  return editMode.value && ['not_paid', 'partially_paid'].includes(values.currentInvoice.status!);
});

const isShowRefundButton = computed(() => {
  return (
    editMode.value && ['paid', 'partially_paid', 'overpay'].includes(values.currentInvoice.status!)
  );
});

const isServicesEmpty = computed(() => {
  return values.currentInvoice.payment_subjects?.length
    ? values.currentInvoice.payment_subjects?.length < 1
    : true;
});

const addService = (subject: ServiceGroupDto, subjectCount: number) => {
  if (!subject) return;
  const clonePaymentSubjects = [
    { count: subjectCount, subject: subject, price: subject.price },
    ...values.currentInvoice.payment_subjects,
  ];
  setFieldValue('currentInvoice.payment_subjects', clonePaymentSubjects);
};

const handleCreateInvoice = handleSubmit(
  async (values) => {
    loading.value = true;
    const createInvoicePayload: CreateInvoicePayload = {
      user_id: values.user.id,
      discount: values.discount,
      description: values.note,
      subjects:
        values.currentInvoice.payment_subjects?.map(
          (item: PaymentSubjectDto | ShortPaymentSubjectDto) => ({
            group_service_id: item.subject.id,
            count: item.count,
          })
        ) || [],
      reference_id: values.referrer.id,
    };

    const response = await misB2BApi.invoicesTemp.create(createInvoicePayload);

    if (response?.data) {
      isModalOpen.value = false;
      emit('success:update');
    }

    loading.value = false;
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);

const handleUpdateInvoice = handleSubmit(
  async (values) => {
    if (!values.currentInvoice.id) {
      return;
    }
    loading.value = true;
    const response = await misB2BApi.invoicesTemp.update(values.currentInvoice.id, {
      ...values.currentInvoice,
      discount: values.discount,
    });

    if (response?.data) {
      isModalOpen.value = false;
      emit('success:update');
    }

    loading.value = false;
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);

const handleUpdateInvoicePayment = async () => {
  isVisiblePayModal.value = false;
  emit('success:update');

  if (
    (values.currentInvoice.status === 'not_paid' ||
      values.currentInvoice.status === 'partially_paid') &&
    props.invoiceId
  ) {
    return await getInvoiceById(props.invoiceId);
  }
  isModalOpen.value = false;
};

const handleRefund = () => {
  payType.value = 'pay_out';
  isVisiblePayModal.value = true;
};

const handlePay = () => {
  payType.value = 'pay_in';
  isVisiblePayModal.value = true;
};

const handlePrint = async () => {
  if (loading.value || !values.currentInvoice?.user?.id) {
    return;
  }
  loading.value = true;
  const response = await misB2BApi.patientsTemp.getQrCodeLinkByUserId(
    values.currentInvoice.user.id!
  );
  if (response) {
    qrPayload.value = response.link;
    await refInvoiceCheck.value.print();
  }
  loading.value = false;
};

const getInvoiceById = async (invoiceId: number) => {
  loading.value = true;
  const response = await misB2BApi.invoicesTemp.getById(invoiceId);
  if (response?.data) {
    setValues({
      user: response.data.user,
      discount: response.data.discount,
      note: response.data.description,
      currentInvoice: response.data,
      referrer: response.data.referrer,
      referrer_id: response.data.referrer?.id,
    });
  }
  loading.value = false;
};

const resetInvoice = () => {
  resetField('currentInvoice');
  resetForm();
};

watch(
  () => props.invoiceId,
  async (value) => {
    resetInvoice();
    if (value) {
      await getInvoiceById(value);
    }
  },
  { immediate: true }
);

watch(
  () => props.modelValue,
  () => {
    resetInvoice();
  },
  { immediate: true }
);
</script>

<style lang="scss" src="./index.scss" />
