<template>
  <ElCard class="inspection-card" shadow="never" v-loading.fullscreen="loading.template">
    <ElForm
      class="inspection-card-form"
      label-position="top"
      id="inspection-card"
      ref="form"
      @submit.prevent="submitHandler">
      <div class="inspection-card-form__content">
        <div class="inspection-card-form__content-header">
          <ElFormItem
            v-show="blocksOptions.templateSelect.isShow || isTinyEditor || !isTinyEditor"
            :label="$t('Templates.SelectTemplate')">
            <!--  Select template  -->
            <UiModelsAutocompleteSearch
              v-show="blocksOptions.templateSelect.isShow || isTinyEditor || !isTinyEditor"
              class="inspection-card-type-template__select"
              value="id"
              label="title"
              :model-value="templateIdsModel"
              :model-for-use="InspectionCardTemplate"
              :required="blocksOptions.templateSelect.required"
              :models="selectedTemplatesModel"
              :disabled="blocksOptions.templateSelect.disabled"
              :multiple="!isTinyEditor"
              ref="template_select"
              :search-query="{
                appointment_id: appointment.id,
              }"
              @select="selectedTemplatesModel = $event"
              @update:model-value="selectTemplate" />
          </ElFormItem>
          <ElFormItem :label="$t('Appointments.SelectIDC')">
            <!--  Select IDC  -->
            <UiModelsAutocompleteSearch
              class="inspection-card-form__codes"
              :model-value="appointment?.disease_ids"
              :model-for-use="DiseaseCode"
              :models="currentCodesObjects"
              :placeholder="$t('Appointments.SelectIDC')"
              label="title"
              value="code"
              multiple
              :disabled="isDirectorOrManager || readonly"
              @select="currentCodesObjects = $event" />
          </ElFormItem>
        </div>

        <template v-if="isTinyEditor">
          <TinyEditor v-model:content="tinyData" :disabled="isDirectorOrManager" />
          <ElButton
            v-if="!actionsOptions.save.isShow && !isDirectorOrManager"
            class="inspection-card__save-edit"
            type="primary"
            :disabled="!selectedTemplatesModel.length"
            @click="save">
            {{ $t('Base.Save') }}
          </ElButton>
        </template>

        <!--  Template  -->
        <!--  Old cards -->
        <template v-if="!isTinyEditor">
          <TemplateResult
            v-if="blocksOptions.oldTemplate.isShow"
            v-model="inspectionCard.basic_data"
            :readonly="blocksOptions.oldTemplate.readonly"
            @block:update="updateInspectionCardBlock"
            @change="saveOrCreateInspectionCard">
          </TemplateResult>

          <!--  New cards  -->
          <ElCollapse
            v-if="blocksOptions.newTemplate.isShow"
            :model-value="inspectionCard.template_ids">
            <ElCollapseItem
              v-for="item in blocksByTemplates"
              :key="item.template.id"
              :title="item.template.title"
              :name="item.template.id">
              <TemplateResult
                :model-value="item.basic_data"
                :readonly="blocksOptions.newTemplate.readonly"
                @block:update="updateInspectionCardBlock"
                @change="saveOrCreateInspectionCard" />
            </ElCollapseItem>
          </ElCollapse>

          <!--  Editor  -->
          <EditorCard
            ref="editorCard"
            v-if="blocksOptions.editor.isShow"
            :data="inspectionCard.basic_data.length ? inspectionCard.basic_data[0] : null"
            :readonly="blocksOptions.editor.readonly"
            @update:data="updateEditorData"
            @change="saveOrCreateInspectionCard" />
        </template>
      </div>

      <!--  Actions  -->
      <ElFormItem class="inspection-card-form__actions">
        <div class="inspection-card-form-actions">
          <ElButton v-if="!isTinyEditor" text @click="print">
            <template #icon>
              <MiIcon icon="PRINTER" />
            </template>
            {{ $t('Base.Print') }}
          </ElButton>

          <ElButton
            v-show="actionsOptions.save.isShow"
            type="primary"
            :loading="loading.save"
            @click="handleSaveInspectionCard">
            {{ $t('Base.SaveChanges') }}
          </ElButton>

          <!--          <ElButton-->
          <!--            type="primary"-->
          <!--            @click="createTemplate"-->
          <!--            v-if="!isTinyEditor && !blocksOptions.newTemplate.isShow">-->
          <!--            <template #icon>-->
          <!--              <MiIcon icon="PLUS" />-->
          <!--            </template>-->
          <!--            {{ $t('Appointments.SaveTemplate') }}-->
          <!--          </ElButton>-->

          <ElButton
            v-show="actionsOptions.closeTreatment.isShow"
            type="warning"
            plain
            @click="closeTreatment">
            {{ $t('Treatments.CloseTreatment') }}
          </ElButton>

          <ElDivider direction="vertical" class="inspection-card-form-actions__divider" />

          <ElButton
            v-show="actionsOptions.setControlAppointment.isShow"
            type="primary"
            plain
            @click="setControlAppointment">
            {{ $t('Appointments.SetControlAppointment') }}
          </ElButton>

          <ElButton
            v-show="actionsOptions.setExamination.isShow"
            type="primary"
            plain
            @click="setExamination">
            {{ $t('Appointments.SetExamination') }}
          </ElButton>

          <ElButton
            v-show="actionsOptions.setTreatment.isShow"
            type="warning"
            plain
            @click="setTreatment">
            {{ $t('Base.SetTreatment') }}
          </ElButton>

          <ElButton
            v-show="actionsOptions.provide.isShow"
            type="primary"
            native-type="submit"
            :loading="loading.provide">
            {{ $t('Appointments.EndReception') }}
          </ElButton>
        </div>
      </ElFormItem>
    </ElForm>

    <InspectionCardPrinterDocument
      v-if="!isTinyEditor"
      :appointment="appointment"
      :inspection-card="inspectionCard"
      ref="inspectionCardPrinterDocument" />
  </ElCard>
</template>

<script>
// TODO: декомпозировать и отрефакторить, разросся
import axios from 'axios';
import { mapState } from 'vuex';

import { MiIcon } from '~shared/ui';
import { getSetsDifference } from '@/utils/sets.util';
import { Appointment } from '@/models/appointment/Appointment.model';
import { InspectionCardTemplate } from '@/models/InspectionCardTemplate.model';
import { DiseaseCode } from '@/models/DiseaseCode.model';
import { InspectionCard } from '@/models/InspectionCard.model';
import { Treatment } from '@/models/Treatment.model';
import { User } from '@/models/User.model';
import TemplateResult from '@/components/templates/TemplateResult/index.vue';
import EditorCard from '@/components/EditorCard/index.vue';
import CreateOrEditTreatmentModal from '@/components/treatments/CreateOrEditTreatmentModal/index.vue';
import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import InspectionCardPrinterDocument from '@/components/appointments/InspectionCardPrinterDocument/index.vue';
import CreateOrEditTemplateModal from '@/components/doctors/CreateOrEditTemplateModal/index.vue';
import TinyEditor from '@/components/common/TinyEditor/index.vue';
import { useSessionStore } from '~entities/session';
import { ROUTE_PATH } from '~shared/config';
import { amplitudeService } from '~shared/lib';
import { misB2BApi } from '~shared/api';

export default {
  name: 'InspectionCard',
  components: {
    TemplateResult,
    EditorCard,
    InspectionCardPrinterDocument,
    TinyEditor,
    MiIcon,
  },
  emits: ['update:appointment', 'appointment:provide'],
  props: {
    appointment: [Appointment, Object],
    readonly: Boolean,
    isProvided: Boolean,
  },
  data() {
    return {
      inspectionCard: null,
      loading: {
        template: false, // получения шаблона
        provide: false,
        closeTreatment: false,
        save: false,
      },
      tinyData: '',
      selectedTemplate: null,
      newTemplate: null,
      currentTemplateType: this.appointment?.inspection_card?.type ?? '',
      templateIdsModel: [],
      selectedTemplatesModel: [],
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    ...mapState({
      templates: (state) => state.templates.data,
    }),
    user() {
      return this.sessionStore.user;
    },

    currentCodesObjects: {
      get() {
        return this.appointment?.disease_codes;
      },
      async set(value) {
        await this.$emit('update:appointment', {
          ...this.appointment,
          disease_codes_object: value,
          disease_ids: value.map((item) => item.code),
        });

        this.updateCodes();
      },
    },

    isTreatment() {
      return !!this.appointment.treatment_id;
    },
    isDirectorOrManager() {
      return (
        this.user.role === User.enum.roles.Director || this.user.role === User.enum.roles.Manager
      );
    },
    isTinyEditor() {
      return this.currentTemplateType === InspectionCard.enum.types.Tiny;
    },

    blocksOptions() {
      return {
        templateTypes: {
          isShow: !this.readonly && !this.isProvided && !this.isTreatment,
        },

        templateSelect: {
          isShow: !this.readonly && !this.isProvided && !this.isTreatment,
          required:
            !this.isTreatment &&
            (!this.appointment.inspection_card_id || !this.inspectionCard.template_ids.length),
          disabled: this.readonly,
        },

        oldTemplate: {
          isShow:
            this.inspectionCard.type === InspectionCard.enum.types.Default &&
            this.inspectionCard.basic_data.length &&
            !this.inspectionCard.template_ids.length,
          readonly: this.readonly,
        },
        newTemplate: {
          isShow:
            this.inspectionCard.type === InspectionCard.enum.types.Default &&
            this.inspectionCard.template_ids.length,
          readonly: this.readonly,
        },

        editor: {
          isShow: this.inspectionCard.type === InspectionCard.enum.types.Editor,
          readonly: this.readonly,
        },
      };
    },

    actionsOptions() {
      return {
        provide: {
          isShow: !this.isProvided && !this.readonly,
        },
        save: {
          isShow: this.isProvided && !this.readonly,
        },
        setTreatment: {
          isShow: !this.isProvided && !this.readonly && !this.isTreatment,
        },
        closeTreatment: {
          isShow:
            !this.isProvided &&
            !this.readonly &&
            this.appointment.treatment_id &&
            this.appointment.treatment.status === Treatment.enum.statuses.Created,
        },
        setControlAppointment: {
          isShow: !this.isProvided && !this.readonly,
        },
        setExamination: {
          isShow: !this.isProvided && !this.readonly,
        },
      };
    },

    blocksByTemplates() {
      return this.inspectionCard.templates.map((template) => ({
        template: template,
        basic_data: this.inspectionCard.basic_data.filter(
          (elem) => elem.template_id === template.id
        ),
      }));
    },
  },

  watch: {
    'appointment.id': {
      handler() {
        this.inspectionCard = new InspectionCard(
          this.appointment?.inspection_card || {
            user_id: this.appointment.patient_id,
            appointment_id: this.appointment.id,
          }
        );

        this.selectDefaultTemplateIfNeeded();
      },
      immediate: true,
    },
  },

  methods: {
    async submitHandler() {
      this.loading.provide = true;
      const { data } = await Appointment.updateStatus({
        id: this.appointment.id,
        status: Appointment.enum.statuses.Provided,
      });
      if (data) {
        amplitudeService.logEvent('close_appointment', {
          patient_id: data.data.patient_id,
          appointment_id: data.data.id,
        });
        await this.$router.push(ROUTE_PATH.appointments.activeList.withQuery());
      }
      this.loading.provide = false;
    },

    async updateCodes() {
      await Appointment.updateAppointmentCodes(this.appointment.id, {
        disease_code_codes: this.appointment.disease_ids,
      });
    },

    save() {
      this.saveOrCreateInspectionCard({
        basic_data: this.tinyData,
      });
    },

    async getSelectedTemplate(templateIds) {
      let newTemplateId = templateIds;

      if (Array.isArray(templateIds)) {
        newTemplateId = getSetsDifference({
          sets: [this.templateIdsModel, templateIds],
        })[0];
      }

      let newTemplate = null;

      if (Array.isArray(this.selectedTemplatesModel)) {
        newTemplate = this.selectedTemplatesModel.find(
          (template) => template?.id === newTemplateId
        );
      } else {
        newTemplate = this.selectedTemplatesModel;
      }
      const prevTemplateType = this.currentTemplateType;
      this.currentTemplateType = newTemplate?.type;

      if (this.currentTemplateType !== prevTemplateType) {
        this.templateIdsModel = [];
        this.selectedTemplatesModel = [];
        templateIds = newTemplateId;
      }

      if (this.isTinyEditor) {
        this.templateIdsModel = newTemplate.id;
      } else {
        this.templateIdsModel = Array.isArray(templateIds) ? [...templateIds] : [templateIds];
      }

      return newTemplate;
    },

    processTemplateDataForTiny(newTemplate) {
      this.templateIdsModel = newTemplate.id;

      return {
        basic_data: newTemplate.basic_data,
        template_id: newTemplate.id,
        title: newTemplate.title,
        template: newTemplate,
      };
    },

    processOldTemplateDataForModel(newTemplate) {
      let basic_data = Array.isArray(this.inspectionCard.basic_data)
        ? [...this.inspectionCard.basic_data]
        : [];
      let templates = [...this.inspectionCard.templates];

      const newTemplateIsEditorType = this.currentTemplateType === InspectionCard.enum.types.Editor;
      const newTemplateIsDefaultType =
        this.currentTemplateType === InspectionCard.enum.types.Default;
      const hasEditorTypeTemplate = templates.some(
        (template) => template.type === InspectionCard.enum.types.Editor
      );
      const hasDefaultTypeTemplate = templates.some(
        (template) => template.type === InspectionCard.enum.types.Default
      );

      if (
        (newTemplateIsDefaultType && hasEditorTypeTemplate) ||
        (newTemplateIsEditorType && hasDefaultTypeTemplate)
      ) {
        templates = [];
        basic_data = [];
        this.selectedTemplatesModel = newTemplate;
        this.templateIdsModel = [newTemplate.id];
      }
      templates.push(newTemplate);

      if (newTemplateIsEditorType) {
        basic_data[0] =
          basic_data.length > 0
            ? {
                ...basic_data[0],
                blocks: [...basic_data[0].blocks, ...newTemplate.basic_data[0].blocks],
              }
            : { ...newTemplate.basic_data[0] };
        setTimeout(() => {
          this.$refs.editorCard?.rerender();
        }, 100);
      } else if (newTemplateIsDefaultType) {
        basic_data = [...basic_data, ...newTemplate.basic_data];
      }

      return {
        basic_data,
        templates,
      };
    },

    resetOldTemplates() {
      this.inspectionCard.templates = [];
      this.inspectionCard.template_ids = [];
    },

    resetTinyTemplate() {
      this.inspectionCard.template = null;
      this.tinyData = '';
      this.inspectionCard.templates = [];
    },

    async selectTemplate(templateIds) {
      await this.$nextTick();
      if (!templateIds) return;
      let basic_data,
        templates = [],
        template,
        template_id;

      if (this.inspectionCard.template_ids.length > templateIds?.length) {
        this.templateIdsModel = [];
        this.selectedTemplatesModel = [];
        templates = [];
        basic_data = [];
      } else if (templateIds.length || templateIds) {
        const newTemplate = await this.getSelectedTemplate(templateIds);

        if (this.isTinyEditor) {
          this.resetOldTemplates();
          const tinyModel = this.processTemplateDataForTiny(newTemplate);
          if (newTemplate.id >= 1) {
            this.loading.template = true;
            const response = await misB2BApi.templates.getByIdWithHeader(newTemplate.id, {
              appointment_id: this.appointment.id,
            });
            this.loading.template = false;
            basic_data = response?.data?.content ?? tinyModel.basic_data;
          } else {
            basic_data = tinyModel.basic_data;
          }
          template = tinyModel.template;
          template_id = tinyModel.template_id;
          this.selectedTemplatesModel = [newTemplate];
          this.tinyData = basic_data;
        } else {
          // TODO Надо переписать весь код
          // this.resetTinyTemplate();
          const oldModel = this.processOldTemplateDataForModel(newTemplate);
          basic_data = oldModel.basic_data;
          templates = oldModel.templates;
          this.selectedTemplatesModel = [...templates];
        }
      }

      this.$refs.template_select?.blur();

      this.inspectionCard = new InspectionCard({
        ...this.inspectionCard,
        template_ids: templates.map((template) => template.id),
        templates,
        basic_data,
        template_id,
        template,
        type: this.currentTemplateType ?? InspectionCard.enum.types.Default,
      });
      this.checkTinyEditorForEmptyArray();
      this.saveOrCreateInspectionCard();
    },

    async handleSaveInspectionCard() {
      if (this.isTinyEditor) {
        this.save();
      } else {
        this.saveOrCreateInspectionCard();
      }
    },

    async saveOrCreateInspectionCard(updatedData) {
      this.loading.save = true;

      try {
        const { data } = this.appointment.inspection_card_id
          ? await InspectionCard.update({
              payload: {
                ...this.inspectionCard,
                appointment_id: this.appointment.id,
                ...updatedData,
              },
              id: this.appointment.inspection_card_id,
            })
          : await InspectionCard.create(this.inspectionCard);

        if (this.appointment.inspection_card_id) {
          amplitudeService.logEvent('change_inspection_card', {
            patient_id: this.appointment.patient_id,
          });
        }

        this.$emit('update:appointment', {
          ...this.appointment,
          inspection_card_id: data.data.id,
          inspection_card: data.data,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.save = false;
    },

    selectDefaultTemplateIfNeeded() {
      if (
        !this.isTreatment &&
        !this.appointment.inspection_card_id &&
        this.appointment.status !== 'provided' &&
        this.templates[0]
      ) {
        // this.selectTemplate([this.templates[0].id]);
      }
    },

    /**
     * @param {number} id
     * @return {Promise<InspectionCardTemplate>}
     */
    async getTemplateById(id) {
      this.loading.template = true;
      const { data } = await InspectionCardTemplate.findOneById(id);
      this.loading.template = false;

      return data.data;
    },

    // for type = 'default'
    updateInspectionCardBlock(block) {
      const index = this.inspectionCard.basic_data.findIndex(
        (elem) => elem.template_id === block.template_id && elem.id === block.id
      );

      this.inspectionCard.basic_data[index] = block;
    },

    // TODO: добавить обработку мультишаблонности в использовании нового типа
    updateEditorData(data) {
      this.inspectionCard.basic_data = [data];
    },

    async setTreatment() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditTreatmentModal,
        payload: {
          user: this.appointment.patient,
          appointment: this.appointment,
        },
      });
    },
    async closeTreatment() {
      if (this.loading.closeTreatment) return;
      this.loading.closeTreatment = true;

      try {
        const { data } = await Treatment.close(this.appointment.treatment_id);
        this.$emit('update:appointment', {
          ...this.appointment,
          treatment: data.data,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: err?.response?.data?.message || this.$t('Notifications.Error'),
        });
      }

      this.loading.closeTreatment = false;
    },

    async setControlAppointment() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditAppointmentModal,
        payload: {
          disableDefaultAction: true,
          patient: this.appointment.patient,
        },
      });
    },
    async setExamination() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditAppointmentModal,
        payload: {
          disableDefaultAction: true,
          patient: this.appointment.patient,
          setDefaultMyDoctor: false,
        },
      });
    },

    async createTemplate() {
      const template = {
        ...this.inspectionCard,
        id: undefined,
        type: InspectionCard.enum.types.Editor,
        basic_data: this.inspectionCard.basic_data[0],
      };

      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditTemplateModal,
        payload: {
          disableDefaultAction: true,
          data: template,
        },
      });
    },

    handleSelect(template) {
      this.selectedTemplatesModel = template;
    },

    print() {
      this.$refs.inspectionCardPrinterDocument.print();
    },
    checkTinyEditorForEmptyArray() {
      if (this.isTinyEditor && Array.isArray(this.inspectionCard.basic_data)) {
        this.inspectionCard.basic_data = 'none';
        this.inspectionCard.template.basic_data = 'none';
      }
    },
  },

  mounted() {
    if (!this.appointment.inspection_card_id) return;

    if (this.isTinyEditor) {
      this.templateIdsModel = this.appointment.inspection_card.template_id;
      this.selectedTemplatesModel = [this.appointment.inspection_card.template];
      this.tinyData = this.appointment.inspection_card.basic_data;
    } else {
      this.templateIdsModel = this.appointment.inspection_card.template_ids;
      this.selectedTemplatesModel = this.appointment.inspection_card.templates;
    }
    if (!this.appointment?.disease_ids) {
      const cloneAppointment = { ...this.appointment };
      cloneAppointment.disease_ids = this.appointment?.disease_codes.map((item) => item.code);
      this.$emit('update:appointment', cloneAppointment);
    }
  },

  setup: () => ({
    InspectionCard,
    InspectionCardTemplate,
    DiseaseCode,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
