<template>
  <ElCard
    :class="['statistic-simple-card', `statistic-simple-card_${classModificator}`]"
    v-loading="loading"
    :header="title"
    :shadow="shadow">
    <div class="statistic-simple-card__info statistic-simple-card-info">
      <div class="statistic-simple-card-info__value">
        {{ valueFormatter ? valueFormatter(value) : value }}
      </div>
      <div :class="['statistic-simple-card-info__percentage', percentageDirectionClass]">
        <div class="statistic-simple-card-info__percentage-icon">
          <MiIcon :icon="isPercentageIncrease ? 'INCREASE_PERCENTAGE' : 'DECREASE_PERCENTAGE'" />
        </div>
        <div class="statistic-simple-card-info__percentage-value">{{ percentageString }}</div>
      </div>
    </div>

    <div v-if="type === 'moneyIcon'">
      <img
        class="statistic-simple-card-info__icon"
        src="@/assets/images/icon-dollar.svg"
        alt="logo" />
    </div>
    <Vue3Apexcharts
      v-else
      class="statistic-simple-card-info__chart"
      :type="type"
      :style="{ width: '120px', minWidth: '120px', height: '90px' }"
      :options="chartOptions"
      :series="series"
      ref="chart" />
  </ElCard>
</template>

<script>
import Vue3Apexcharts from 'vue3-apexcharts';

import { MiIcon } from '~shared/ui';

export default {
  name: 'UiStatisticCard',
  components: { Vue3Apexcharts, MiIcon },
  props: {
    loading: Boolean,
    title: String,
    value: [Number, String],
    valueFormatter: Function,
    percentage: Number,
    chart: Object,
    chartColor: [String, Array],
    chartLabels: Array,
    chartType: String,
    classModificator: String,

    shadow: {
      type: String,
      default: 'never',
    },
  },
  computed: {
    percentageString() {
      if (this.percentage === null || this.percentage === undefined) return '0%';
      return (this.percentage > 0 ? `+${this.percentage}` : this.percentage) + '%';
    },

    isPercentageIncrease() {
      return this.percentage >= 0;
    },

    percentageDirectionClass() {
      return `statistic-simple-card-info__percentage_${
        this.isPercentageIncrease ? 'success' : 'danger'
      }`;
    },

    type() {
      return this.chartType ?? 'line';
    },

    series() {
      if (this.type === 'pie') {
        let value = 0;
        if (this.value) {
          value = Number.isInteger(this.value) ? this.value : Number(this.value.toFixed(2));
        }
        return [value, 100 - value];
      } else {
        return [
          {
            name: '',
            data: Object.keys(this.chart ?? {}).map((key) => this.chart[key]),
          },
        ];
      }
    },

    colors() {
      if (this.chartColor) {
        return Array.isArray(this.chartColor) ? this.chartColor : [this.chartColor];
      }
      return ['#2E93fA', '#EF7AEA'];
    },

    labels() {
      if (this.chartLabels) {
        return this.chartLabels;
      }
      return ['pie1', 'pie2'];
    },

    chartOptions() {
      if (this.type === 'pie') {
        return {
          chart: {
            type: 'pie',
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              endAngle: 360,
            },
          },
          colors: this.colors,
          labels: this.labels,
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          fill: {
            type: 'gradient',
          },
        };
      } else {
        return {
          chart: {
            type: 'line',
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          colors: this.colors,
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },

          yaxis: {
            labels: { show: false },
          },
          xaxis: {
            categories: Object.keys(this.chart ?? {}),
            labels: { show: false },
          },

          grid: { show: false },
        };
      }
    },
  },

  watch: {
    chart() {
      this.$refs.chart.updateOptions(this.chartOptions);
      this.$refs.chart.updateSeries(this.series);
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
