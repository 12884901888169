import axios, { AxiosError } from 'axios';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';

import { QueryParams } from '../../types';
import { PER_PAGE, SEARCH, PAGE } from '../../config';

const deleteEmptyValueKeys = (object: QueryParams) => {
  const returnedObject: QueryParams = {};

  Object.keys(object).forEach((key) => {
    const value = object[key];

    if (value === undefined || value === null) return;
    if (typeof value === 'string' && value.length === 0) return;
    if (Array.isArray(value) && value.length === 0) return;

    returnedObject[key] = value;
  });

  return returnedObject;
};

export const joinQueryParams = ({ url, query }: { url: string; query: QueryParams }) => {
  const [urlExcludeQuery, inlineQueries] = url.split('?');

  const preparedQuery = deleteEmptyValueKeys(query);

  const queries = new URLSearchParams(
    `${
      (inlineQueries || '') +
      Object.keys(preparedQuery).reduce((acc, key) => acc + `&${key}=${preparedQuery[key]}`, '')
    }`
  );

  return `${urlExcludeQuery}?${queries.toString()}`;
};

export const getApiErrorMessage = (err: unknown) => {
  let message = String(err);
  if (axios.isAxiosError(err)) {
    const error = err as AxiosError<{ message?: string }>;
    if (error.response?.data?.message) {
      message = error.response?.data?.message;
    } else {
      message = err.message;
    }
  }
  return message;
};

const checkIsEqualArrays = (arr1: (string | number)[], arr2: (string | number)[]) => {
  return isEqual(sortBy(arr1), sortBy(arr2));
};

// Проверяет нужно ли сбосить текущию страницу в пагинации и если параметры не изменились не делаеь запрос
export async function compareQueriesThenLoadData<T extends QueryParams>({
  query,
  oldQuery,
  fieldsForResetPage = [],
  resetPage,
  getData,
}: {
  query: T;
  oldQuery: T | undefined;
  fieldsForResetPage?: string[];
  resetPage?: () => void;
  getData: () => void;
}) {
  const defaultFieldsForReset = [PER_PAGE, SEARCH];

  const allFieldsForResetPage = [...defaultFieldsForReset, ...fieldsForResetPage];

  const queryKeys = Object.keys(query);

  const changedParamsKeys: string[] = [];

  if (!oldQuery) {
    return getData();
  }

  queryKeys.forEach((key) => {
    if (
      Array.isArray(query[key]) &&
      Array.isArray(oldQuery[key]) &&
      !checkIsEqualArrays(query[key] as (string | number)[], oldQuery[key] as (string | number)[])
    ) {
      changedParamsKeys.push(key);
    } else if (query[key] !== oldQuery[key]) {
      changedParamsKeys.push(key);
    }
  });
  // Если нужно будет сбросить page
  if (changedParamsKeys.some((key) => allFieldsForResetPage.includes(key))) {
    if (resetPage) await resetPage();
    // Не загружать данные так как мы ждем что page обновится и сюда придет еще раз
    if (query[PAGE] !== 1) {
      return;
    }
    return getData();
  }
  // Если не надо сбросить page
  if (changedParamsKeys.length > 0) {
    return getData();
  }
}
