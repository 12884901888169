<template>
  <div>
    <FormTextField
      size="default"
      :disabled="readOnly"
      data-maska="###"
      class="invoice-modal__discount-amount"
      :in-column="false"
      :label="$t('Base.Discount') + ' (%)'"
      field-name="discount"
      type="number"
      placeholder="0 %"
      min="0"
      max="100" />
    <FormInfoField
      class="invoice-modal__total-price"
      size="default"
      :in-column="false"
      format="price"
      :label="$t('Base.Total')"
      field-name="total">
      {{ totalPrice }} {{ $t('Base.Sum') }}
    </FormInfoField>
  </div>
</template>

<script setup lang="ts">
import { useFieldValue } from 'vee-validate';
import { computed } from 'vue';

import { InvoiceFullDto, ShortPaymentSubjectDto } from '~shared/api';
import { thousandsSeparator } from '~shared/lib';
import { FormInfoField, FormTextField } from '~shared/ui/form';

defineProps<{
  readOnly: boolean;
}>();

const currentInvoice = computed(() => {
  return useFieldValue<InvoiceFullDto>('currentInvoice').value;
});

const discount = computed(() => {
  return Number(useFieldValue<number>('discount').value);
});

const totalPrice = computed(() => {
  const paymentSubjects = currentInvoice.value.payment_subjects as ShortPaymentSubjectDto[];
  const price = Math.floor(
    (paymentSubjects.reduce((acc, elem) => acc + elem.price * elem.count, 0) || 0) *
      ((100 - (discount.value <= 100 && discount.value > 0 ? discount.value : 0)) / 100)
  );
  return `${thousandsSeparator(price)}`;
});
</script>

<style lang="scss" src="./index.scss" />
