{
  "en": {
    "Base": {
      "Absinthe": "Missing",
      "Actions": "Actions",
      "Add": "Add",
      "Allergy": "Allergy",
      "AmbulatoryCard": "Outpatient card",
      "Amount": "sum",
      "Assign": "Assign",
      "Attach": "Bind",
      "AuthMessage": "Please log in to your personal account to schedule patient appointments",
      "Authorization": "Authorization",
      "Back": "Back",
      "Blocked": "Contact your system administrator",
      "BlockedTitle": "Device locked",
      "BloodTransfusion": "Blood transfusion",
      "Cabinet": "Cabinet",
      "CalendarAppointments": "Calendar Receptions",
      "Call": "Call",
      "CallToReception": "Call for an appointment",
      "Cancel": "Cancel",
      "CancelConfirmation": "Cancel confirmation",
      "Capacity": "Capacity",
      "CashRegister": "Cash Register",
      "Category": "Category",
      "ChangeLang": "Change the language",
      "ChooseClinic": "Choose a clinic",
      "Class": "Class",
      "Clinic": "Clinic",
      "ClinicalExamination": "Medical examination",
      "Close": "Close",
      "Code": "Code",
      "ColDays": "Number of days",
      "Confirm": "Confirm",
      "ConnectToManager": "Please contact your manager to solve this problem.",
      "Cost": "Cost",
      "Create": "Create",
      "CreateNew": "Create new",
      "Creator": "Creator",
      "Dashboard": "Dashboard",
      "DateAppoint": "Appointment date",
      "Days": "Days",
      "DaysOfWeek": {
        "DayOff": "Day off",
        "Friday": "FR",
        "Monday": "MO",
        "Saturday": "SA",
        "Sunday": "SU",
        "Thursday": "TH",
        "Tuesday": "TU",
        "Wednesday": "WE"
      },
      "Delete": "Delete",
      "Description": "Description",
      "Diabetes": "Diabetes mellitus",
      "Discount": "Discount",
      "Doctor": "Doctor",
      "DoctorFullName": "Doctor FULL name",
      "DoctorFullName2": "Doctor full name",
      "Doctors": "Doctors",
      "DoctorsSchedule": "Doctors' schedule",
      "Download": "Download",
      "Duration": "Duration",
      "Edit": "Edit",
      "EndTreatment": "Finish treatment",
      "Exit": "Exit",
      "Fluorography": "Fluorography",
      "ForgotPassword": "Forgot password",
      "Full name": "FULL NAME",
      "Income": "Income",
      "Infectious diseases": "Infectious diseases",
      "Info": "Information",
      "Information": "Information",
      "Inspection": "Inspection",
      "InspectionCard": "Inspection card",
      "InvalidBarCode": "Invalid barcode",
      "InvalidQrCode": "Invalid QR code",
      "InviteByNumber": "Invite by number",
      "Invoice": "Invoice",
      "Invoices": "Invoices",
      "Loading": "Loading",
      "Location": "Location",
      "LogIn": "Sign in",
      "Login": "Login",
      "LoginСonfirmed": "I have a confirmation code",
      "Logout": "Logout",
      "MakeAppointment": "Make an appointment",
      "Measure": "Unit of measurement",
      "Naming": "Name",
      "NamingInEn": "Name in English",
      "NamingInRu": "Name in Russian",
      "NamingInUz": "Name in Uzbek",
      "NamingShort": "Abbreviated. name",
      "No": "No",
      "NoData": "No data",
      "Notes": "Notes",
      "Open": "Open",
      "OpenCard": "Open",
      "Orders": "Requests",
      "Paid": "Pay",
      "Password": "Password",
      "Patient": "Patient",
      "PatientDocument": "Patient documents",
      "PatientInfo": "Patient Information",
      "PatientRecord": "Patient record",
      "Patients": "Patients",
      "PatientsRecords": "Patient records",
      "Pay": "Pay",
      "Payment": "Payment",
      "Percent": "Percent",
      "PhoneNumber": "Phone number",
      "PleaseInput": "Please enter",
      "PleaseSelect": "Please choose",
      "Prev": "Return",
      "Price": "Price",
      "Print": "Print",
      "Quantity": "Quantity",
      "QuantityDays": "Number of days",
      "Queue": "Queue",
      "QueueForToday": "Queue for today",
      "Reaction": "Reaction",
      "ReasonService": "Service case",
      "Refund": "Refund",
      "Register": "Register",
      "Save": "Save",
      "SaveChanges Method": "Save Changes",
      "Scan": "Scan",
      "ScanBarCode": "Scan barcode",
      "ScanBracelet": "Scan bracelet",
      "Scanning": "Scan ...",
      "Search": "Search",
      "SelectCategory": "Select a category",
      "SelectDate": "Select a date",
      "SelectServiceСategory": "Select service category",
      "SelectStatus": "Select status",
      "Service": "Service",
      "Services": "Services",
      "SetTime": "Select a time",
      "SetTreatment": "Prescribe treatment",
      "Settings": "Settings",
      "Skip": "Skip",
      "StatePrice": "Cost",
      "Statistics": "Statistics",
      "Status": "Status",
      "Sum": "Uzbek sum",
      "SumCost": "Amount",
      "SurgicalIntervention": "Surgical interventions",
      "TableTreatment": "Treatment diaries",
      "Template": "Template",
      "Templates": "Templates",
      "Time": "Time",
      "Total": "Total",
      "TreatmentJournal": "Treatment Diary",
      "Type": "Type",
      "Upload": "Upload",
      "View": "View",
      "WaitingConfirm": "After confirmation, you will be automatically logged in",
      "WaitingConfirmTitle": "Wait for system administrator confirmation",
      "Workload": "Workload",
      "WriteText": "Write the text..."
    }
  },
  "ru": {
    "Base": {
      "Absent": "Отсутствует",
      "Actions": "Действия",
      "Add": "Добавить",
      "Allergy": "Аллергия",
      "AmbulatoryCard": "Амбулаторная карта",
      "Appoint": "Назначить",
      "Appointment": "Запись",
      "Attach": "Привязать",
      "AuthMessage": "Войдите в личный кабинет, для записи пациентов на прием",
      "Authorization": "Авторизация",
      "Back": "Назад",
      "Blocked": "Обратитесь к администратору системы",
      "BlockedTitle": "Устройство заблокировано",
      "BloodTransfusion": "Переливание крови",
      "Cabinet": "Кабинет",
      "CalendarAppointments": "Календарь приемов",
      "Call": "Позвонить",
      "CallToReception": "Вызвать на прием",
      "Cancel": "Отменить",
      "CancelConfirmation": "Отменить подтверждение",
      "Capacity": "Вместимость",
      "CashRegister": "Касса",
      "Category": "Категория",
      "ChangeLang": "Сменить язык",
      "ChooseClinic": "Выберите клинику",
      "Class": "Класс",
      "Clinic": "Клиника",
      "ClinicalExamination": "Диспансеризация",
      "Close": "Закрыть",
      "Code": "Код",
      "ColDays": "Кол. дней",
      "Confirm": "Подтвердить",
      "ConnectToManager": "Пожалуйста, свяжитесь с вашим менеджером чтобы решить эту проблему.",
      "Cost": "Стоимость",
      "Create": "Создать",
      "CreateNew": "Создать новый",
      "Creator": "Создатель",
      "Custom": "Пользовательский",
      "Dashboard": "Дашборд",
      "DateAppoint": "Дата назначение",
      "Days": "Дней",
      "DaysOfWeek": {
        "DayOff": "Выходной",
        "Friday": "ПТ",
        "Monday": "ПН",
        "Saturday": "СБ",
        "Sunday": "ВС",
        "Thursday": "ЧТ",
        "Tuesday": "ВТ",
        "Wednesday": "СР"
      },
      "Default": "По умолчанию",
      "Delete": "Удалить",
      "Description": "Описание",
      "Diabetes": "Сахарный диабет",
      "Discount": "Скидка",
      "Doctor": "Доктор",
      "DoctorFullName": "Врач ФИО",
      "DoctorFullName2": "Ф.И.О врача",
      "Doctors": "Врачи",
      "DoctorsSchedule": "Расписание врачей",
      "Download": "Загрузить",
      "Duration": "Продолжительность",
      "Edit": "Редактировать",
      "EndTreatment": "Закончить лечение",
      "Fluorography": "Флюорография",
      "ForgotPassword": "Забыли пароль?",
      "FullName": "ФИО",
      "Income": "Доход",
      "InfectiousDiseases": "Инфекционные заболевания",
      "Info": "Информация",
      "Inspection": "Осмотр",
      "InspectionCard": "Карта осмотра",
      "InvalidBarCode": "Невалидный штрих-код",
      "InvalidQrCode": "Невалидный qr код",
      "InviteByNumber": "Пригласить по номеру",
      "Invoice": "Счёт",
      "Invoices": "Счета",
      "Loading": "Загрузка...",
      "Location": "Локация",
      "LogIn": "Войти",
      "Login": "Логин",
      "LoginСonfirmed": "У меня есть код подтверждения",
      "Logout": "Выйти",
      "MakeAppointment": "Записать на приём",
      "Measure": "Единица измерения",
      "Naming": "Название",
      "NamingInEn": "Название на английском",
      "NamingInRu": "Название на русском",
      "NamingInUz": "Название на узбекском",
      "NamingShort": "Сокращ. название",
      "No": "Нет",
      "NoData": "Нет данных",
      "Notes": "Заметки",
      "Open": "Открыть",
      "OpenCard": "Открыть",
      "Orders": "Заявки",
      "Password": "Пароль",
      "Patient": "Пациент",
      "PatientDocument": "Документы пациента",
      "PatientInfo": "Информация о пациенте",
      "PatientRecord": "Запись пациента",
      "Patients": "Пациенты",
      "PatientsRecords": "Записи пациентов",
      "Pay": "Оплатить",
      "Payment": "Оплата",
      "Percent": "Процент",
      "PhoneNumber": "Номер телефона",
      "PleaseInput": "Пожалуйста, введите",
      "PleaseSelect": "Пожалуйста выберите",
      "Prev": "Вернуться",
      "Price": "Цена",
      "Print": "Распечатать",
      "Quantity": "Количество",
      "QuantityDays": "Количество дней",
      "Queue": "Очередь",
      "QueueForToday": "Очередь на сегодня",
      "Reaction": "Реакция",
      "ReasonService": "Случай обслуживания",
      "Refund": "Возврат",
      "Replace": "Заменить",
      "Save": "Сохранить",
      "SaveChanges": "Сохранить изменения",
      "Scan": "Сканировать",
      "ScanBarCode": "Сканировать штрих-код",
      "ScanBracelet": "Сканер браслета",
      "Scanning": "Сканирование ...",
      "Search": "Поиск",
      "SelectCategory": "Выберите категорию",
      "SelectDate": "Выберите дату",
      "SelectServiceСategory": "Выберите категорию услуг",
      "SelectStatus": "Выберите статус",
      "Service": "Услуга",
      "Services": "Услуги",
      "SetTime": "Выберите время",
      "SetTreatment": "Назначить лечение",
      "Settings": "Настройки",
      "SignUp": "Регистрация",
      "Skip": "Пропустить",
      "StatePrice": "Стоимость",
      "Statistics": "Статистика",
      "Status": "Статус",
      "Sum": "Cум",
      "SumCost": "Сумма",
      "SurgicalIntervention": "Хирургические вмешательста",
      "TableTreatment": "Дневники лечений",
      "Template": "Шаблон",
      "Templates": "Шаблоны",
      "Text": "Текст",
      "Time": "Время",
      "Total": "Итого",
      "TreatmentJournal": "Дневник лечения",
      "Type": "Тип",
      "Value": "Значение",
      "VerificationCode": "Код подтверждения",
      "View": "Посмотреть",
      "WaitingConfirm": "Ожидание подтверждения",
      "WaitingConfirmTitle": "Ожидайте подтверждение администратора системы",
      "Workload": "Загруженность",
      "WriteText": "Напишите текст...",
      "Yes": "Да"
    }
  },
  "uz": {
    "Base": {
      "Absent": "Mavjud emas",
      "Actions": "Harakatlar",
      "Add": "Qoshish",
      "Allergy": "Allergiya",
      "AmbulatoryCard": "Ambulatoriya kartasi",
      "Appoint": "Tayinlash",
      "Appointment": "Yozuv",
      "Attach": "Bog'lash",
      "AuthMessage": "Bemorlarni taqvimlamoq uchun shaxsiy kabinetingga kiring",
      "Authorization": "Tasdiqlash",
      "Back": "Orqaga",
      "Blocked": "Tizim administratoringizga murojaat qiling",
      "BlockedTitle": "Qurilish zablokirovkasi",
      "BloodTransfusion": "Qon quyish",
      "Cabinet": "Kabinet",
      "CalendarAppointments": "Qabullar taqvimi",
      "Call": "Qongiroq qilish",
      "CallToReception": "Qabul qilish",
      "Cancel": "Qaytish",
      "CancelConfirmation": "Tasdiqni bekor qiling",
      "Capacity": "Sig'im",
      "CashRegister": "Kassa",
      "Category": "Kategoriya",
      "ChangeLang": "Tilni ozgartirish",
      "ChooseClinic": "Klinikani tanlang",
      "Class": "Klass",
      "Clinic": "Klinikasi",
      "ClinicalExamination": "Dispanser ko'rigi",
      "Close": "Yopish",
      "Code": "Kod",
      "ColDays": "Kunlar Miqdori",
      "Confirm": "Tasdiqlash",
      "ConnectToManager": "Ushbu muammoni hal qilish uchun menejeringizga murojaat qiling.",
      "Cost": "Narxi",
      "Create": "Yaratish",
      "CreateNew": "Yangi yaratish",
      "Creator": "Yaratuvchi",
      "Custom": "Maxsus",
      "Dashboard": "Dashboard",
      "DateAppoint": "Tayinlash sanasi",
      "Days": "Kunlar",
      "DaysOfWeek": {
        "DayOff": "Dam olish kuni",
        "Friday": "JU",
        "Monday": "DU",
        "Saturday": "SH",
        "Sunday": "YA",
        "Thursday": "PA",
        "Tuesday": "SE",
        "Wednesday": "CH"
      },
      "Default": "Standart",
      "Delete": "Oʻchirish",
      "Description": "Tavsif",
      "Diabetes": "Qandli diabet",
      "Discount": "Chegirma",
      "Doctor": "Shifokor",
      "DoctorFullName": "Shifokor FIO",
      "DoctorFullName2": "Shifokor FIO",
      "Doctors": "Shifokorlar",
      "DoctorsSchedule": "Shifokorlar jadvali",
      "Download": "Yuklash",
      "Duration": "Davomiyligi",
      "Edit": "Tahrirlash",
      "EndTreatment": "Davolanishni tugating",
      "Fluorography": "Florografiya",
      "ForgotPassword": "Parolni unutdingizmi?",
      "FullName": "F.I.Sh",
      "Income": "Daromad",
      "InfectiousDiseases": "Yuqumli kasalliklar",
      "Info": "Ma'lumot",
      "Inspection": "Tekshirish",
      "InspectionCard": "Tekshirish kartasi",
      "InvalidBarCode": "??Невалидный штрих-код??",
      "InvalidQrCode": "??Невалидный qr код??",
      "InviteByNumber": "Telefondan ulash",
      "Invoice": "Hisob",
      "Invoices": "Hisoblar",
      "Loading": "Yuklanmoqda...",
      "Location": "Lokasiya",
      "LogIn": "Kirish",
      "Login": "Login",
      "LoginСonfirmed": "Tasdiqlash kodim bor",
      "Logout": "Chiqish",
      "MakeAppointment": "Qabulga yozdirish",
      "Measure": "O‘lchash birligi",
      "Naming": "Nomi",
      "NamingInEn": "Nomi ingliz tilida",
      "NamingInRu": "Nomi rus tilida",
      "NamingInUz": "Nomi o'zbek tilida",
      "NamingShort": "Qisqartma",
      "No": "Yo'q",
      "NoData": "Malumot topilmadi",
      "Notes": "Eslatmalar",
      "Open": "Ochish",
      "OpenCard": " Ochish",
      "Orders": "Ilovalar",
      "Password": "Parol",
      "Patient": "Bemor",
      "PatientDocument": "Bemor hujjatlari",
      "PatientInfo": "Bemor haqida ma'lumot",
      "PatientRecord": "Bemor yozuvi",
      "Patients": "Bemorlar",
      "PatientsRecords": "Bemorlar yozuvlari",
      "Pay": "Tolash",
      "Payment": "To'lov",
      "Percent": "Protsent",
      "PhoneNumber": "Telefon raqami",
      "PleaseInput": "Iltimos, kiriting",
      "PleaseSelect": "Iltimos tanlang",
      "Prev": "Qaytish",
      "Price": "Narxi",
      "Print": "Chop etish",
      "Quantity": "Miqdori",
      "QuantityDays": "Kunlar miqdori",
      "Queue": "Navbat",
      "QueueForToday": "Bugunga navbat",
      "Reaction": "Reaktsiya",
      "ReasonService": "Xizmat ishi",
      "Refund": "Qaytarish",
      "Replace": "O'zgartirish",
      "Save": "Saqlash",
      "SaveChanges": "O'zgarishlarni saqlash",
      "Scan": "Skanerlash",
      "ScanBarCode": "Shtrihkodni skanerlash",
      "ScanBracelet": "Brasletni skanerlash",
      "Scanning": "Skanerlanyapti ...",
      "Search": "Topish",
      "SelectCategory": "Kategoriya tanlang",
      "SelectDate": "Sanani tanlang",
      "SelectServiceСategory": "Xizmat kategoriyasini tanlang",
      "SelectStatus": "Statusni tanlang",
      "Service": "Xizmat",
      "Services": "Xizmatlar",
      "SetTime": "Vaqt tanlang",
      "SetTreatment": "Davolashni tayinlash",
      "Settings": "Sozlamalar",
      "SignUp": "Ro'yxatdan o'tish",
      "Skip": "O'tkazib yuborish",
      "StatePrice": "Narxi",
      "Statistics": "Statistika",
      "Status": "Holat",
      "Sum": "Som",
      "SumCost": "Miqdor",
      "SurgicalIntervention": "Jarrohlik aralashuvlar",
      "TableTreatment": "Davolash jurnali",
      "Template": "Namuna",
      "Templates": "Namunalar",
      "Text": "Text",
      "Time": "Vaqt",
      "Total": "Jami",
      "TreatmentJournal": "Davolash jurnali",
      "Type": "Turi",
      "Value": "Qiymati",
      "VerificationCode": "Tasdiqlash kodi",
      "View": "Korish",
      "WaitingConfirm": "Tasdiqlash kutilmoqda",
      "WaitingConfirmTitle": "Tizim administratori tasdiqlanishini kuting",
      "Workload": "Bandligi",
      "WriteText": "Matn yozing..."
    }
  }
}