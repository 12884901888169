import { Hospital } from '@/models/hospital/Hospital.model';

export default {
  name: 'HospitalCard',
  props: {
    hospital: [Hospital, Object],
    shadow: {
      type: String,
      default: 'never',
    },
  },
  computed: {
    items() {
      return {
        base: [
          {
            label: this.$t('Hospital.Department'),
            value: this.hospital.chamber.department.title,
          },
          {
            label: this.$t('Base.Doctor'),
            value: this.hospital.doctor.name,
          },
          {
            label: this.$t('Hospital.StartDate'),
            value: this.hospital.created_at,
          },
          {
            label: this.$t('DateAndTime.DaysCount'),
            value: this.hospital.count_days ?? '...',
          },
          {
            label: this.$t('Hospital.DischargedDate'),
            value: this.hospital.discharged_at ?? '...',
          },
        ],
        secondary: [
          {
            label: this.$t('Hospital.Chamber'),
            value: this.hospital.chamber.number,
          },
          {
            label: this.$t('Base.Class'),
            value: this.hospital.chamber.class,
          },
          {
            label: this.$t('Base.Capacity'),
            value: this.hospital.chamber.capacity,
          },
          {
            label: this.$t('Base.Workload'),
            value: this.hospital.chamber.reside,
          },
          {
            label: this.$t('Base.Location'),
            value: this.hospital.chamber.location,
          },
        ],
      };
    },
  },
};
