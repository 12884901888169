import { DATE_DELIMITER } from '../config';

export const addZeroPrefix = (value: number | string) => {
  return value?.toString().length < 2 ? '0' + value : value;
};

export const ISOStringToDateAppFormat = (date?: string) => {
  const _date = new Date(date ?? new Date());

  const year = _date.getFullYear();
  const month = _date.getMonth() + 1;
  const day = _date.getDate();

  return `${addZeroPrefix(day)}${DATE_DELIMITER}${addZeroPrefix(month)}${DATE_DELIMITER}${year
    .toString()
    .slice(2)}`;
};

export const convertDateToAppFormat = (
  dateStr: string,
  params?: { withTime?: boolean; shortYear?: boolean }
) => {
  const date = new Date(dateStr).toLocaleString('ru', {
    year: params?.shortYear ? '2-digit' : 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const time = new Date(dateStr).toLocaleString('ru', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'Asia/Tashkent',
  });

  return `${date}${params?.withTime ? ` ${time}` : ''}`;
};

export const getMonthPeriod = (date?: string) => {
  const currentDate = new Date(date ?? new Date());
  const startDate = new Date(currentDate);
  startDate.setDate(1);

  return [
    ISOStringToDateAppFormat(startDate.toString()),
    ISOStringToDateAppFormat(currentDate.toISOString()),
  ];
};
