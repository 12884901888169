import {
  CreateInvoicePayload,
  GetInvoiceByIdResponseData,
  GetInvoiceListResponseData,
  InvoiceFullDto,
  InvoiceStatisticResponseData,
  UpdateInvoicePayload,
} from '../types';

import { joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'invoices';

export const getList = async (query: QueryParams = {}) => {
  const response = await apiService.api.instance.get<GetInvoiceListResponseData>(
    joinQueryParams({
      url: urlPath,
      query,
    })
  );
  return {
    ...response.data,
  };
};

export const getListForDoctor = async (query: QueryParams = {}) => {
  const response = await apiService.api.instance.get<GetInvoiceListResponseData>(
    joinQueryParams({
      url: `${urlPath}/doctor`,
      query,
    })
  );
  return {
    ...response.data,
  };
};

export const cancel = async (id: number) => {
  const response = await apiService.api.instance.put<{ data: InvoiceFullDto }>(
    `${urlPath}/${id}/canceled`
  );
  return {
    ...response.data,
  };
};

export const getPaymentDrivers = async () => {
  const response = await apiService.api.instance.get<{ data: string[] }>(`payment_drivers`);

  return {
    ...response.data,
  };
};

export const getStatistic = async (query: QueryParams = {}) => {
  const response = await apiService.api.instance.get<{ data: InvoiceStatisticResponseData }>(
    joinQueryParams({
      url: 'statistics/invoices',
      query,
    })
  );

  return {
    ...response.data,
  };
};

export const getStatisticForDoctor = async (query: QueryParams = {}) => {
  const response = await apiService.api.instance.get<{ data: InvoiceStatisticResponseData }>(
    joinQueryParams({
      url: 'statistics/invoices/doctor',
      query,
    })
  );

  return {
    ...response.data,
  };
};

export const create = async (payload: CreateInvoicePayload) => {
  const response = await apiService.api.instance.post<GetInvoiceByIdResponseData>(
    'invoices/create',
    payload
  );
  return {
    ...response.data,
  };
};

export const getById = async (invoiceId: number) => {
  const response = await apiService.api.instance.get<GetInvoiceByIdResponseData>(
    `invoices/${invoiceId}`
  );
  return {
    ...response.data,
  };
};

export const update = async (invoiceId: number, payload: UpdateInvoicePayload) => {
  const response = await apiService.api.instance.put<GetInvoiceByIdResponseData>(
    `invoices/${invoiceId}/update`,
    payload
  );
  return {
    ...response.data,
  };
};
