<template>
  <MiCard class="referral-source-card">
    <MiInfoField :label="$t('Referral.SourceName')"> {{ referralData?.title ?? '-' }}</MiInfoField>
    <MiInfoField :label="$t('Referral.Commission')">
      {{
        referralData?.recommendation_commission
          ? `${referralData.recommendation_commission} %`
          : '-'
      }}</MiInfoField
    >
    <MiInfoField :label="$t('Base.PhoneNumber')">{{ referralData?.phone ?? '-' }}</MiInfoField>
    <MiInfoField v-if="withActions" :label="$t('Base.Actions')">
      <MiButton
        :disabled="!referralData?.id"
        size="small"
        plain
        type="primary"
        @click="handleEditBtn(referralData?.id)">
        {{ $t('Common.Edit') }}
      </MiButton>
    </MiInfoField>
  </MiCard>
</template>

<script lang="ts" setup>
import { MiCard, MiInfoField, MiButton } from '~shared/ui';
import { ReferrerListResource, ReferrerResource } from '~shared/api';

defineProps<{
  referralData?: ReferrerResource | ReferrerListResource;
  withActions?: boolean;
}>();

const emits = defineEmits<{
  handleEditBtn: [event: number];
}>();

const handleEditBtn = (id: number | undefined) => {
  if (typeof id !== 'number') return;
  emits('handleEditBtn', id);
};
</script>

<style lang="scss" src="./index.scss"></style>
