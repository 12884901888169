<template>
  <ElForm
    id="create-or-edit-patient"
    class="create-or-edit-patient"
    label-position="top"
    ref="form"
    @submit.prevent="submitHandler">
    <div class="create-or-edit-patient__content">
      <div class="content__header">
        <!--  Is Children  -->
        <ElFormItem v-show="!isChildrenSwitchIsDisabled">
          <ElSwitch size="small" :active-text="$t('User.IsChildren')" v-model="isChildren" />
        </ElFormItem>

        <!--  Foreigner  -->
        <ElFormItem>
          <ElCheckbox v-model="patient.is_foreign" :label="$t('User.Is_foreginer')" />
        </ElFormItem>
      </div>

      <!--  Phone  -->
      <ElFormItem :label="$t(isChildren ? 'ParentPhone' : 'User.Phone')">
        <UiPhoneInput
          :disable-mask="patient.is_foreign"
          class="create-or-edit-patient__field"
          v-model="phone"
          required />
      </ElFormItem>

      <!--  FullName  -->
      <ElFormItem
        v-show="hasPhoneNumber && !hasPatient"
        :label="$t('User.FullName') + ` (${$t('User.FullNameFormat').toLowerCase()})`">
        <ElInput
          class="create-or-edit-patient__field"
          v-model="patient.name"
          minlength="3"
          required />
      </ElFormItem>

      <!--  Address  -->
      <ElFormItem v-show="hasPhoneNumber && !hasPatient" :label="$t('User.PleaseInputAddress')">
        <ElInput v-model="patient.place_residence" />
      </ElFormItem>

      <!--      Категория прайслиста-->
      <ElFormItem :label="$t('Common.PriceListCategory')">
        <MiSelectSearch
          clearable
          v-model:model-object-value="patient.price_list"
          v-model:model-value="patient.price_list_id"
          :fetch-data="misB2BApi.priceLists.getList" />
      </ElFormItem>

      <!--      Select referral doctor-->
      <ElFormItem>
        <SelectReference
          v-if="appointment"
          v-model="appointment.reference_id"
          :default-item="appointment.reference"
          show-create-option
          @select="$emit('reference:update', $event)" />
      </ElFormItem>

      <!--  Parent  -->
      <ElFormItem v-if="isChildren" v-show="hasPhoneNumber" :label="$t('ParentFullName')">
        <ElInput v-model="patient.parent.name" required :disabled="!!patient.parent_id" />
      </ElFormItem>

      <!--  From  -->
      <ElFormItem
        v-show="patient.is_foreign && hasPhoneNumber && !hasPatient"
        :label="$t('User.From')">
        <ElInput
          :placeholder="$t('User.CountryCity')"
          class="create-or-edit-patient__field"
          v-model="patient.country" />
      </ElFormItem>

      <div class="content__birthdate-gender">
        <!--  Birthdate  -->
        <ElFormItem
          class="birthdate"
          v-show="hasPhoneNumber && !hasPatient"
          :label="$t('User.Birthdate')">
          <UiDateInput v-model="patient.birthdate" />
          <!--          <ElDatePicker
            v-model="patient.birthdate"
            type="date"
            placeholder="00.00.2000"
            format="DD.MM.YYYY"
            :value-format="FULL_DATE_FORMAT" />-->
        </ElFormItem>

        <!--  Gender  -->
        <ElFormItem v-show="hasPhoneNumber && !hasPatient" :label="$t('User.Gender')">
          <UiGenderSelect class="create-or-edit-patient__field" v-model="patient.gender" required />
        </ElFormItem>
      </div>
      <!--  OldPatient  -->
      <ElFormItem v-show="hasPatient">
        <div class="create-patient-modal-patient">
          <div class="create-patient-modal-patient__title">{{ $t('PatientIsInSystem') }}</div>

          <router-link
            v-show="!hasPatientFromOtherClinic"
            class="create-patient-modal-patient__name"
            :to="oldPatientPageUrl">
            {{ oldPatient?.name }}
          </router-link>
          <div v-show="hasPatientFromOtherClinic" class="create-patient-modal-patient__name">
            {{ oldPatient?.name }}
          </div>
        </div>
      </ElFormItem>
    </div>

    <!--  Actions  -->
    <div class="create-or-edit-patient-actions" ref="actions">
      <ElButton
        v-show="!hasPatient"
        type="primary"
        form="create-or-edit-patient"
        native-type="submit"
        :loading="loading.form"
        :disabled="hasPatient">
        {{ $t(data?.id ? 'Common.SaveChanges' : 'Patients.AddPatient') }}
      </ElButton>

      <!--  hasPatient && !hasPatientFromOtherClinic  -->
      <router-link v-show="hasPatient && !hasPatientFromOtherClinic" :to="oldPatientPageUrl">
        <ElButton type="primary">{{ $t('GoToPatient') }}</ElButton>
      </router-link>

      <ElButton
        v-show="data?.id ? hasPatient : hasPatient && !hasPatientFromOtherClinic"
        type="primary"
        @click="handleOpenPhoneConfirmModal">
        {{ $t(data ? 'RebindPhone' : 'CreateNewPatient') }}
      </ElButton>

      <ElButton
        v-show="hasPatientFromOtherClinic && !data"
        type="primary"
        :loading="loading.attach"
        :disabled="loading.attach"
        @click="attachPatient">
        {{ $t('Base.Attach') }}
      </ElButton>
    </div>

    <PhoneConfirmModal
      v-model="isOpenPhoneConfirmModal"
      :phone="this.patient.phone"
      append-to-body
      @action="checkPhoneForRebinding" />
  </ElForm>
</template>

<script src="./index.js" />

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="./index.locales.json" />
