export const TEMPLATES_PATH_PREFIX = '/templates';
export const APPOINTMENTS_PATH_PREFIX = '/appointments';
export const INPATIENT_CARE_PATH_PREFIX = '/inpatient-care';
export const CASH_PATH_PREFIX = '/cash';
export const CASH_REGISTER_PATH_PREFIX = '/cash-register';
export const BOOKKEEPING_PATH_PREFIX = '/bookkeeping';
export const USERS_PATH_PREFIX = '/users';
export const WAREHOUSE_PATH_PREFIX = '/warehouse';
export const PATIENTS_PATH_PREFIX = '/patients';

export const AUTH_PATH_PREFIX = '/auth';

export const ROUTE_PATH = {
  root: '/',
  notFound: '/404',
  noAccess: '/403',
  contingent: '/contingent',
  appointmentSchedule: '/appointment-schedule',
  auth: {
    login: `${AUTH_PATH_PREFIX}/login`,
    forgotPassword: `${AUTH_PATH_PREFIX}/forgot-password`,
    waitingConfirm: `${AUTH_PATH_PREFIX}/waiting-confirm`,
  },
  cash: CASH_PATH_PREFIX,
  cashRegister: {
    invoices: `${CASH_REGISTER_PATH_PREFIX}/invoices`,
    depositList: `${CASH_REGISTER_PATH_PREFIX}/deposits`,
  },
  bookkeeping: {
    salaryCalcList: `${BOOKKEEPING_PATH_PREFIX}/salary-calc-list`,
    outcomes: `${BOOKKEEPING_PATH_PREFIX}/outcomes`,
    services: `${BOOKKEEPING_PATH_PREFIX}/services`,
    refSource: `${BOOKKEEPING_PATH_PREFIX}/referral-source`,
    refAccrual: `${BOOKKEEPING_PATH_PREFIX}/referral-accrual`,
    refCommission: {
      rawPath: `${BOOKKEEPING_PATH_PREFIX}/referral-commission/:id`,
      byId: (id: number | string) => `${BOOKKEEPING_PATH_PREFIX}/referral-commission/${id}`,
    },
    employees: {
      list: `${BOOKKEEPING_PATH_PREFIX}/employees`,
      employee: {
        rawPath: `${BOOKKEEPING_PATH_PREFIX}/employees/:id`,
        byId: (id: number | string) => `${BOOKKEEPING_PATH_PREFIX}/employees/${id}`,
      },
    },
  },
  users: {
    doctors: `${USERS_PATH_PREFIX}/doctors`,
    doctor: {
      rawPath: `${USERS_PATH_PREFIX}/doctors/:id`,
      byId: (id: number | string) => `${USERS_PATH_PREFIX}/doctors/${id}`,
    },
    employees: `${USERS_PATH_PREFIX}/employees`,
    user: {
      rawPath: `/user/:id`,
      byId: (id: number | string) => `/user/${id}`,
    },
  },
  patients: {
    profiles: {
      rawPath: `${PATIENTS_PATH_PREFIX}/:id`,
      byId: (id: number | string) => `${PATIENTS_PATH_PREFIX}/${id}`,
    },
    ambulatoryCards: {
      rawPath: `${PATIENTS_PATH_PREFIX}/:id/ambulatory-card`,
      byId: (id: number | string) => `${PATIENTS_PATH_PREFIX}/${id}/ambulatory-card`,
    },
    medicalCard: {
      rawPath: `${PATIENTS_PATH_PREFIX}/:id/medical-card`,
      byId: (id: number | string) => `${PATIENTS_PATH_PREFIX}/${id}/medical-card`,
    },
    checkup: {
      rawPath: `${PATIENTS_PATH_PREFIX}/:id/checkup`,
      byId: (id: number | string) => `${PATIENTS_PATH_PREFIX}/${id}/checkup`,
    },
  },
  appointments: {
    activeList: {
      rawPath: `${APPOINTMENTS_PATH_PREFIX}/active`,
      withQuery: () => `${APPOINTMENTS_PATH_PREFIX}/active?status=waiting,in_progress,approved`,
    },
    providedList: {
      rawPath: `${APPOINTMENTS_PATH_PREFIX}/provided`,
      withQuery: () => `${APPOINTMENTS_PATH_PREFIX}/provided?status=provided`,
    },
    cancelledList: {
      rawPath: `${APPOINTMENTS_PATH_PREFIX}/cancelled`,
      withQuery: () => `${APPOINTMENTS_PATH_PREFIX}/cancelled?status=canceled`,
    },
    appointment: {
      rawPath: `${APPOINTMENTS_PATH_PREFIX}/:id/medical-card`,
      byId: (id: number | string) => `${APPOINTMENTS_PATH_PREFIX}/${id}/medical-card`,
    },
    inspectionCard: {
      rawPath: `${APPOINTMENTS_PATH_PREFIX}/:id/inspection-card`,
      byId: (id: number | string) => `${APPOINTMENTS_PATH_PREFIX}/${id}/inspection-card`,
    },
    defaultDefaultCard: {
      rawPath: `${APPOINTMENTS_PATH_PREFIX}/:id/default-card`,
      byId: (id: number | string) => `${APPOINTMENTS_PATH_PREFIX}/${id}/default-card`,
    },
  },
  inpatientCare: {
    list: INPATIENT_CARE_PATH_PREFIX,
    patient: {
      rawPath: `${INPATIENT_CARE_PATH_PREFIX}/:id`,
      byId: (id: number | string) => `${INPATIENT_CARE_PATH_PREFIX}/${id}`,
    },
  },
  statistic: {
    dashboard: '/statistic/dashboard',
  },
  laboratory: {
    orders: '/laboratory/orders',
    analyzes: '/laboratory/analyzes',
    referencesGroups: '/laboratory/references-groups',
    order: {
      rawPath: `/laboratory/orders/:id`,
      byId: (id: number | string) => `/laboratory/orders/${id}`,
    },
  },
  warehouse: {
    nomenclature: `${WAREHOUSE_PATH_PREFIX}/nomenclature`,
    warehouses: `${WAREHOUSE_PATH_PREFIX}/warehouses`,
    goodsStock: `${WAREHOUSE_PATH_PREFIX}/goods-stock`,
    suppliers: `${WAREHOUSE_PATH_PREFIX}/suppliers`,
    incomes: `${WAREHOUSE_PATH_PREFIX}/incomes`,
    outcomes: `${WAREHOUSE_PATH_PREFIX}/outcomes`,
  },
  templates: {
    checkups: `${TEMPLATES_PATH_PREFIX}/checkup-list`,
    checkupEditor: {
      rawPath: `${TEMPLATES_PATH_PREFIX}/checkups/:id`,
      byId: (id: number | string) => `${TEMPLATES_PATH_PREFIX}/checkups/${id}`,
    },
    CheckupHeaderTemplateList: `${TEMPLATES_PATH_PREFIX}/checkups-header-list`,
    checkupHeaderTemplate: {
      rawPath: `${TEMPLATES_PATH_PREFIX}/checkup-header`,
      byId: (id: number | string) => {
        return `${TEMPLATES_PATH_PREFIX}/checkup-header/${id}`;
      },
    },
  },
};
