<template>
  <VeeForm
    class="service-products-table-filling-form"
    as="ElForm"
    :validation-schema="validationSchema"
    v-slot="{ meta }"
    @submit="onSubmit">
    <div class="service-products-table-filling-form__field-group">
      <FormSearchSelectField
        field-name="product_id"
        field-object-name="product"
        :model-for-use="Product"
        :placeholder="$t('Income.SearchGoods')"
        :label="$t('Services.ExpendableMaterials')"
        show-create-option
        @create="$emit('product:create', $event)" />
      <!--      <FormTextField-->
      <!--        field-name="product.price"-->
      <!--        :placeholder="$t('Base.Price')"-->
      <!--        :in-column="false"-->
      <!--        mask="##########" />-->
      <FormNumberField field-name="count" :placeholder="$t('Base.Quantity')" :in-column="false" />
      <div />
    </div>
    <ElButton
      class="service-products-table-filling-form__add-button"
      type="primary"
      native-type="submit"
      :disabled="!meta.dirty"
      >{{ `+ ${$t('Base.Add')}` }}
    </ElButton>
  </VeeForm>
</template>

<script lang="ts">
export default {
  name: 'ServiceProductsTableFillingForm',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { SubmissionHandler, Form as VeeForm } from 'vee-validate';
import { object, number } from 'yup';

import { Product } from '@/models/warehouse/Product.model';
import { I18nService } from '~shared/lib';
import FormNumberField from '@/components/common/form/ui/FormNumberField/index.vue';
import FormSearchSelectField from '@/components/common/form/ui/FormSearchSelectField/index.vue';

const emit = defineEmits(['table:add', 'product:create']);
const MAX_AMOUNT = 9999999;

const validationSchema = object({
  product_id: number().nullable().required(I18nService.t('Validation.RequiredField')),
  count: number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .min(1, I18nService.t('Validation.GreaterZero'))
    .required(() => I18nService.t('Validation.RequiredField'))
    .test(
      'count',
      `${I18nService.t('Validation.MaxValue')} ${MAX_AMOUNT}`,
      (value) => value <= MAX_AMOUNT
    ),
  // product: object({
  //   price: string().nullable().required(I18nService.t('Validation.RequiredField')),
  // }),
});

const onSubmit: SubmissionHandler = async (values, actions) => {
  actions.resetForm();
  emit('table:add', values);
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/validation.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/ui.locales.json" />
