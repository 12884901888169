<template>
  <ElDialog
    class="treatment-modal"
    :model-value="modelValue"
    :title="$t('Base.SetTreatment')"
    @update:modelValue="$emit('update:modelValue')">
    <ElForm
      id="treatment-modal-form"
      class="treatment-modal-form"
      label-position="top"
      @submit.prevent="submitHandler">
      <UiDataModalGrid class="treatment-modal-grid">
        <template #info>
          <ElCard class="treatment-modal-grid__info-card" shadow="never">
            <template #header> {{ $t('Base.Info') }}</template>

            <ElFormItem :label="$t('Base.Naming')">
              <ElInput v-model="localTreatment.title" :placeholder="$t('Base.Naming')" required />
            </ElFormItem>

            <ElFormItem :label="$t('Base.Notes')">
              <ElInput v-model="localTreatment.description" type="textarea" :rows="3" />
            </ElFormItem>

            <ElFormItem class="treatment-modal__inline-form-item" :label="$t('Base.Patient')">
              {{ localTreatment.user?.name }}
            </ElFormItem>
          </ElCard>
        </template>

        <template #control>
          <ElCard shadow="never">
            <CreateTreatmentDrug @drug:create="createDrug" />
          </ElCard>
        </template>

        <template #content>
          <TreatmentDrugsTable
            :items="localTreatment.drugs"
            height="260px"
            max-height="260px"
            editable
            deletable
            @drug:update="updateDrug"
            @drug:remove="removeDrug" />
        </template>

        <template #footer>
          <ElCard class="treatment-modal-form__cost-part" shadow="never">
            <ElFormItem :label="$t('Base.Discount') + ' (%)'">
              <ElInput v-model="localTreatment.discount" type="number" min="0" max="100" />
            </ElFormItem>

            <ElFormItem :label="$t('Base.Total')">
              {{ formattedDiscountedAmount }}
            </ElFormItem>
          </ElCard>
        </template>
      </UiDataModalGrid>
    </ElForm>

    <template #footer>
      <ElButton type="primary" native-type="submit" form="treatment-modal-form" :loading="loading">
        {{ localTreatment.id ? $t('Base.Save') : $t('Base.Create') }}
      </ElButton>
    </template>
  </ElDialog>
</template>

<script>
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';

import { useSessionStore } from '~entities/session';
import { formatPrice } from '@/utils/price.util';
import { Treatment } from '@/models/Treatment.model';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { Appointment } from '@/models/appointment/Appointment.model';
import { User } from '@/models/User.model';
import CreateTreatmentDrug from '@/components/treatments/CreateOrEditTreatmentModal/CreateTreatmentDrug/index.vue';
import TreatmentDrugsTable from '@/components/treatments/TreatmentDrugsTable/index.vue';

export default {
  name: 'CreateOrEditTreatmentModal',
  components: { TreatmentDrugsTable, CreateTreatmentDrug },
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    treatment: [Treatment, Object],
    appointment: [Appointment, Object],
    user: [User, Object],
  },
  data() {
    return {
      /** @type {Treatment} */
      localTreatment: null,
      loading: false,
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    userAuth() {
      return this.sessionStore.user;
    },

    discountedAmount() {
      return Treatment.calculateDiscountedAmount(this.localTreatment);
    },

    formattedDiscountedAmount() {
      return formatPrice({ price: this.discountedAmount });
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.localTreatment = new Treatment(
          this.treatment
            ? cloneDeep(this.treatment)
            : {
                user_id: this.appointment?.patient_id ?? this.user?.id ?? null,
                user: this.appointment?.patient ?? this.user ?? null,
                appointment_id: this.appointment?.id ?? null,
                appointment: this.appointment ?? null,
                doctor_id: this.userAuth.doctor_id ?? null,
                doctor: this.userAuth.doctor ?? null,
              }
        );
      },
      immediate: true,
    },
  },

  methods: {
    async submitHandler() {
      if (this.loading) return;
      this.loading = true;

      try {
        const treatment = this.localTreatment.id
          ? await this.editTreatment()
          : await this.createTreatment();

        this.$notify({
          type: 'success',
          title: this.$t(`Notifications.Success${this.localTreatment.id ? 'Updated' : 'Created'}`),
        });
        this.$emit(
          'action',
          new GlobalModalInstance({
            name: this.localTreatment.id ? 'updated' : 'created',
            data: { treatment },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    async createTreatment() {
      const { treatment } = await Treatment.create(this.localTreatment);
      return treatment;
    },

    async editTreatment() {
      const { treatment } = await Treatment.update({
        id: this.localTreatment.id,
        payload: this.localTreatment,
      });
      return treatment;
    },

    createDrug(drug) {
      this.localTreatment.drugs.unshift(drug);
    },
    updateDrug(drug) {
      const index = this.localTreatment.drugs.findIndex((elem) => elem.uuid === drug.uuid);
      this.localTreatment.drugs[index] = drug;
    },
    removeDrug(drug) {
      const index = this.localTreatment.drugs.findIndex((elem) =>
        drug.uuid ? elem.uuid === drug.uuid : elem.id === drug.id
      );
      this.localTreatment.drugs[index].is_deleted = true;
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/patients.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
