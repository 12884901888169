<template>
  <MiFormItem
    :class="[
      'form-text-field',
      {
        'form-text-field_in-column': props.inColumn,
        'form-text-field_only-value': props.onlyValue && props.disabled,
      },
    ]"
    :error="props.errorMessage || errorMessage"
    :label="props.label"
    :required="props.required">
    <template v-if="props.errorMessage" #error>
      <slot name="message-content" />
    </template>
    <template v-if="isLabelShow" #label>
      <slot name="label">
        <div class="form-text-field__label-text">{{ label }}</div>
        <MiTooltip v-if="!!props.description" :description="props.description" />
      </slot>
    </template>
    <div v-if="props.onlyValue && props.disabled">
      <slot>{{ value }}</slot>
    </div>
    <MiInput
      v-else
      v-model="value"
      v-bind="$attrs"
      :validate-event="false"
      v-maska:[maskOptions]
      :disabled="props.disabled"
      :data-maska="dataMaska"
      :placeholder="props.placeholder || $t('Base.PleaseInput')"
      :autosize="props.autosize"
      :type="props.type">
      <template v-if="isAppendShow" #append>
        <slot name="append" />
      </template>
    </MiInput>
    <div v-if="isDeleteActionShow" class="form-text-field__delete-action">
      <slot name="delete-action" />
    </div>
  </MiFormItem>
</template>

<script lang="ts" setup>
import { InputInstance } from 'element-plus/es';
import { vMaska, MaskInputOptions } from 'maska';
import { useField } from 'vee-validate';
import { computed, useSlots } from 'vue';

import { MiTooltip, MiInput, MiFormItem } from '~shared/ui';

const props = withDefaults(
  defineProps<{
    inColumn?: boolean;
    onlyValue?: boolean;
    disabled?: boolean;
    required?: boolean;
    fieldName: string;
    label?: string;
    maskName?:
      | 'float-2.2'
      | 'float-3.2'
      | 'float-12.2'
      | 'int-12'
      | 'int-14'
      | 'int-12-with-minus'
      | 'float-12.2-with-minus'
      | 'phone-uz'
      | 'birthdate'
      | '';
    placeholder?: string;
    description?: string | null;
    dataMaska?: string | null;
    errorMessage?: string;
    type?: InputInstance['type'];
    autosize?: InputInstance['autosize'];
    maskaOptions?: MaskInputOptions;
  }>(),
  {
    inColumn: true,
    onlyValue: false,
    disabled: false,
    required: false,
    maskName: '',
    label: '',
    placeholder: '',
    description: '',
    dataMaska: null,
  }
);

// eslint-disable-next-line vue/no-setup-props-destructure
const { value, errorMessage, setValue } = useField<string | number | undefined>(props.fieldName);

const slots = useSlots();
const isAppendShow = computed(() => !!slots.append);
const isLabelShow = computed(() => !!props.label);
const isDeleteActionShow = computed(() => !!slots['delete-action'] && props.inColumn);

const maskaOptions = computed(() => {
  return props.maskaOptions;
});

const maskOptions: MaskInputOptions = {
  tokens: {
    D: { pattern: /\d/, multiple: true },
    d: { pattern: /\d/, optional: true },
    m: { pattern: /-/, optional: true },
  },
  ...maskaOptions.value,
};

const dataMaska = computed(() => {
  if (props.dataMaska) {
    return props.dataMaska;
  }

  switch (props.maskName) {
    case 'float-2.2':
      return '#d.dd';
    case 'float-3.2':
      return '#dd.dd';
    case 'float-12.2':
      return '#ddddddddddd.dd';
    case 'float-12.2-with-minus':
      return 'm#ddddddddddd.dd';
    case 'int-12':
      return '############';
    case 'int-14':
      return '##############';
    case 'int-12-with-minus':
      return 'm############';
    case 'phone-uz':
      return '+998#########';
    // case 'phone-uz':
    //   return '+ 998 (##) ###-##-##';
    case 'birthdate':
      return '##.##.####';
    default:
      return '';
  }
});

defineExpose({
  setValue,
});
</script>

<style lang="scss" src="./index.scss" />
