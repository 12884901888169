<template>
  <UiModelsAutocompleteSearch
    :model-value="modelValue"
    :search-query="query"
    :model-for-use="ReferencesGroup"
    :default-item="defaultItem"
    label="title"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)"
    @select="$emit('select', $event)">
    <template #default="{ item }">
      {{ `${item.title} ${item.start_age}-${item.end_age}` }}
    </template>
  </UiModelsAutocompleteSearch>
</template>

<script>
import { ReferencesGroup } from '@/models/laboratory/ReferencesGroup.model';

export default {
  name: 'SelectReferencesGroup',
  emits: ['update:modelValue', 'select'],
  props: {
    modelValue: Number,
    searchQuery: Object,
    defaultItem: [ReferencesGroup, Object],
  },

  computed: {
    query() {
      return {
        query_field: 'title',
        ...this.searchQuery,
      };
    },
  },

  setup: () => ({
    ReferencesGroup,
  }),
};
</script>
