<template>
  <ElCard class="appointment-card" shadow="never" v-bind="$attrs">
    <template #header>
      <router-link class="appointment-card__header appointment-card-header" :to="patientPageLink">
        <UiUserAvatarInfo
          class="appointment-card-header__patient"
          :user="appointment.patient"
          bold
          :secondary-field-key="isDoctor ? 'birthdate' : 'phone'" />

        <AppointmentStatusTag
          class="appointment-card-header__status"
          :status="appointment.status" />
      </router-link>
    </template>

    <div class="appointment-card-body">
      <div
        v-for="item in infoItems"
        :key="item.value"
        class="appointment-card-body__item appointment-card-body-item">
        <div class="appointment-card-body-item__title">{{ item.label }}:</div>
        <div class="appointment-card-body-item__value">{{ item.value }}</div>
      </div>

      <div class="appointment-card-body-item appointment-card-body-services-item">
        <div class="appointment-card-body-item__title">{{ $t('Base.Services') }}:</div>
        <div class="appointment-card-body-services-item__value">
          <div
            v-for="groupServiceWithCount in groupServicesWithFormattedCost"
            :key="groupServiceWithCount._id">
            <span class="appointment-card-body-item__title">
              {{ groupServiceWithCount.title }} -
            </span>
            <span class="appointment-card-body-item__value">
              {{ groupServiceWithCount.price }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <ElDivider />

    <div class="appointment-card-actions">
      <ElButton
        v-if="actionsOptions.callToReception.isShow"
        type="primary"
        yyyyrt
        :loading="loading[Appointment.enum.statuses.Waiting]"
        @click="callToReception">
        {{ $t('Base.CallToReception') }}
      </ElButton>
      <ElButton
        v-if="actionsOptions.start.isShow"
        type="primary"
        :loading="loading[Appointment.enum.statuses.InProgress]"
        @click="startReception">
        {{ $t('Appointments.StartAppointment') }}
      </ElButton>

      <ElButton
        v-if="actionsOptions.cancel.isShow"
        type="danger"
        plain
        :loading="loading[Appointment.enum.statuses.Canceled]"
        @click="$emit('status:update', Appointment.enum.statuses.Canceled)">
        {{ $t('Appointments.CancelReception') }}
      </ElButton>

      <ElButton
        v-if="actionsOptions.end.isShow"
        type="primary"
        :loading="loading[Appointment.enum.statuses.Provided]"
        @click="endReception">
        {{ $t('Appointments.EndReception') }}
      </ElButton>

      <ElButton
        v-if="actionsOptions.goToInspectionCard.isShow"
        type="primary"
        @click="goToInspectionCard">
        {{ $t('GoToDefaultInspectionCard') }}
      </ElButton>

      <ElButton
        v-if="actionsOptions.reOpen.isShow"
        type="primary"
        plain
        :loading="loading[Appointment.enum.statuses.Approved]"
        @click="$emit('status:update', Appointment.enum.statuses.Approved)">
        {{ $t('Appointments.ReOpen') }}
      </ElButton>

      <ElButton
        v-if="actionsOptions.edit.isShow"
        type="primary"
        plain
        @click="$emit('appointment:edit')">
        {{ $t('Appointments.EditReception') }}
      </ElButton>
    </div>
  </ElCard>
</template>

<script src="./index.js" />

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
