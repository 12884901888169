import { RouteRecordRaw } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { onlyUserHasRoleMiddleware, onlyUserIsAuthMiddleware } from '~shared/lib';

export const APPOINTMENT_SCHEDULE_ROUTE: RouteRecordRaw = {
  name: 'AppointmentSchedule',
  path: ROUTE_PATH.appointmentSchedule,
  component: () => import('./ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'registry', 'doctor']),
  ],
  meta: {
    title: 'Appointments.ReceptionCalendar',
  },
};
