{
  "en": {
    "User": {
      "Accounting": "Accounting",
      "ActivationKey": "Activation key",
      "AddChildren": "Add a child",
      "AddUser": "Add user",
      "Address": "Address",
      "Age": "Age",
      "Answer": "Answer",
      "AnswerOnComment": "Reply to comment",
      "AnswerToComment": "Reply to the comment",
      "AppointmentFee": "Appointment fee",
      "Birthdate": "Date of birth",
      "BloodTypeRhFactor": "Blood type and Rh factor",
      "Children": "Children",
      "ClinicEmployee": "Employee of the clinic",
      "ConsultationFee": "Consultation fee",
      "CountryCity": "Country, city",
      "Devices": "Devices",
      "Email": "Mail",
      "EmployeeActivityStatus": "Employee activity status",
      "EmployeeActivityStatusTip": "The activity status is displayed on the employee's profile page to determine their current availability status",
      "EmployeeInformation": "Information about the employee",
      "EmployeeLoginAndPassword": "Employee's login and password",
      "EmployeeSalary": "Employee's salary",
      "EmployeeSearch": "Employee search",
      "Employees": "Employees",
      "EndAge": "Final age",
      "FIOEmployee": "Employee's full name",
      "FeedbackByClinic": "Clinic review",
      "FeedbackByDoctor": "Doctor review",
      "FormatDate": "DD.MM.YYYY",
      "From": "From",
      "FullName": "Full name",
      "FullNameFormat": "Latin letters",
      "FullNameOrPhoneNumber": "Full name or phone number",
      "Gender": "Gender",
      "Genders": {
        "man": "Male",
        "null": "",
        "woman": "Female"
      },
      "GetStarted": "Beginning of work",
      "Height": "Height",
      "IsChildren": "Child",
      "Is_foreginer": "Foreginer",
      "NumberOfPatients": "Number of patients",
      "Parent": "Parent",
      "Password": "Password",
      "Phone": "Phone",
      "PhoneNumber": "Phone number",
      "PlaceResidence": "Place of residence",
      "PlaceTreatment": "Place of treatment",
      "PleaseInputAddress": "Enter the patient's address",
      "PleaseInputNameOrPhone": "Enter the number or full name",
      "PriceListCategory": "Price list category",
      "Role": "Role",
      "Roles": {
        "accountant": "Accountant",
        "admin": "Administrator",
        "director": "Director",
        "doctor": "Doctor",
        "laboratory": "Laboratory assistant",
        "manager": "Manager",
        "nurse": "Nurse",
        "patient": "Patient",
        "registry": "Registry",
        "warehouse": "Warehouse"
      },
      "ScheduleBlockInfo": "Click on the time to make changes",
      "SelectSpeciality": "Select speciality",
      "Speciality": "Speciality",
      "StartAge": "Starting age",
      "Status": "Статус",
      "Statuses": {
        "active": "Active",
        "not_active": "Inactive"
      },
      "StudyWork": "Study - work",
      "UpdateUser": "Update user",
      "User": "User",
      "UserCredentialsHint": "Please write down the employee's login and password as they are displayed only once",
      "Users": "Users",
      "WasReception": "Had an appointment",
      "Weight": "Weight",
      "WorkExperience": "Work experience",
      "WorkSchedule": "Work schedule"
    }
  },
  "ru": {
    "User": {
      "Accounting": "Бухгалтерия",
      "ActivationKey": "Ключ активации",
      "AddChildren": "Добавить ребёнка",
      "AddUser": "Добавить пользователя",
      "Address": "Адрес",
      "Age": "Возраст",
      "Answer": "Ответ",
      "AnswerOnComment": "Ответ на комментарий",
      "AnswerToComment": "Ответить на комментарий",
      "AppointmentFee": "Комиссия за назначение",
      "Birthdate": "Дата рождения",
      "BloodTypeRhFactor": "Группа крови и резус фактор",
      "Children": "Дети",
      "ClinicEmployee": "Сотрудник клиники",
      "ConsultationFee": "Комиссия за консультацию",
      "CountryCity": "Страна, город",
      "Devices": "Устройства",
      "Email": "Почта",
      "EmployeeActivityStatus": "Статус активности сотрудника",
      "EmployeeActivityStatusTip": "Статус активности отображается на странице профиля сотрудника для определения его текущего статуса доступности",
      "EmployeeInformation": "Информация о сотруднике",
      "EmployeeLoginAndPassword": "Логин и пароль сотрудника",
      "EmployeeSalary": "Оклад сотрудника",
      "EmployeeSearch": "Поиск сотрудников",
      "Employees": "Сотрудники",
      "EndAge": "Конечный возраст",
      "FIOEmployee": "ФИО сотрудника",
      "FeedbackByClinic": "Отзыв на клинику",
      "FeedbackByDoctor": "Отзыв на врача",
      "FormatDate": "ДД.ММ.ГГГГ",
      "From": "Откуда",
      "FullName": "ФИО",
      "FullNameFormat": "Латинские буквы",
      "FullNameOrPhoneNumber": "Ф.И.О. или номер телефона",
      "Gender": "Пол",
      "Genders": {
        "man": "Мужчина",
        "null": "",
        "woman": "Женщина"
      },
      "GetStarted": "Начало работы",
      "Height": "Рост",
      "IsChildren": "Ребёнок",
      "Is_foreginer": "Иностранец",
      "NumberOfPatients": "Кол-во пациентов",
      "Parent": "Родитель",
      "Password": "Пароль",
      "Phone": "Телефон",
      "PhoneNumber": "Номер телефона",
      "PlaceResidence": "Место проживания",
      "PlaceTreatment": "Место лечения",
      "PleaseInputAddress": "Введите адрес пациента",
      "PleaseInputNameOrPhone": "Введите номер или ФИО",
      "PriceListCategory": "Категория прайслиста",
      "Role": "Роль",
      "Roles": {
        "accountant": "Бухгалтер",
        "admin": "Администратор",
        "director": "Директор",
        "doctor": "Врач",
        "laboratory": "Лаборант",
        "manager": "Менеджер",
        "nurse": "Медсестра",
        "patient": "Пациент",
        "registry": "Регистратура",
        "warehouse": "Кладовщик"
      },
      "ScheduleBlockInfo": "Нажмите на время, чтобы внести изменения",
      "SelectSpeciality": "Выбрать специальность",
      "Speciality": "Специальность",
      "StartAge": "Стартовый возраст",
      "Status": "Статус",
      "Statuses": {
        "active": "Активный",
        "not_active": "Неактивный"
      },
      "StudyWork": "Учёба - работа",
      "UpdateUser": "Обновить пользователя",
      "User": "Пользователь",
      "UserCredentialsHint": "Пожалуйста, запишите логин и пароль сотрудника, так как они отображаются только один раз",
      "Users": "Пользователи",
      "WasReception": "Был на приеме",
      "Weight": "Вес",
      "WorkExperience": "Стаж работы",
      "WorkSchedule": "Режим работы",
      "WorkingHours": "График работы (часы)"
    }
  },
  "uz": {
    "User": {
      "Accounting": "Muhassabat",
      "ActivationKey": "Aktivizatsiya kaliti",
      "AddChildren": "Bolani qo'shish",
      "AddUser": "Foydalanuvchi qo'shish",
      "Address": "Manzil",
      "Age": "Yosh",
      "Answer": "Javob",
      "AnswerOnComment": "Izohga javob",
      "AnswerToComment": "Izohga javob bering",
      "AppointmentFee": "Tayinlash komissiyasi",
      "Birthdate": "Tug'ilgan sanasi",
      "BloodTypeRhFactor": "Qon guruhi va Rh omil",
      "Children": "Bolalar",
      "ClinicEmployee": "Klinikaning xodimi",
      "ConsultationFee": "Mashvarga komissiya",
      "CountryCity": "Mamlakat, shahri",
      "Devices": "Ko'rinuvchilar",
      "Email": "E-mail",
      "EmployeeActivityStatus": "Xodim faoliyat holati",
      "EmployeeActivityStatusTip": "Faoliyat holati hozirgi mavjudligi aniqlash uchun xodimning profil sahifasida ko'rsatiladi",
      "EmployeeInformation": "Xodim haqida ma'lumot",
      "EmployeeLoginAndPassword": "Hodimning login va paroli",
      "EmployeeSalary": "Xodimning maosh",
      "EmployeeSearch": "Hamkasblarni qidiring",
      "Employees": "Xodimlar",
      "EndAge": "Yosh tugashi",
      "FIOEmployee": "Xodimning to'liq ismi",
      "FeedbackByClinic": "Klinikaga sharh",
      "FeedbackByDoctor": "Doktorga sharh",
      "FormatDate": "KUN.OY.YIL",
      "From": "Qayerda",
      "FullName": "F.I.Sh",
      "FullNameFormat": "Lotin harflari",
      "FullNameOrPhoneNumber": "To'liq ism yoki telefon raqami",
      "Gender": "Jinsi",
      "Genders": {
        "man": "Erkak",
        "woman": "Ayol"
      },
      "GetStarted": "Ish boshi",
      "Height": "Boyi",
      "IsChildren": "Yosh bola",
      "Is_foreginer": "Chet ellik",
      "NumberOfPatients": "Kasallar soni",
      "Parent": "Ota-onasi",
      "Password": "Parol",
      "Phone": "Telefon raqami",
      "PhoneNumber": "Telefon raqami",
      "PlaceResidence": "Yashash joyi",
      "PlaceTreatment": "Davolash joyi",
      "PleaseInputAddress": "Yashah joyni kiriting",
      "PleaseInputNameOrPhone": "Telefon raqamini yoki FiSh kiriting",
      "PriceListCategory": "Narx ro'yxati kategoriyasi",
      "Role": "Rol",
      "Roles": {
        "accountant": "Buxgalter",
        "admin": "Administrator",
        "director": "Director",
        "doctor": "Shifokor",
        "laboratory": "Laborant",
        "manager": "Menejer",
        "nurse": "Hamshira",
        "patient": "Bemor",
        "registry": "Registratura",
        "warehouse": "Omborchi"
      },
      "ScheduleBlockInfo": "Joyga tiklang, o'zgarishlarni kiriting",
      "SelectSpeciality": "Ixtisoslikni tanlang",
      "Speciality": "Ixtisos",
      "StartAge": "Yosh boshlanishi",
      "Status": "Status",
      "Statuses": {
        "active": "Faol",
        "not_active": "Faol emas"
      },
      "StudyWork": "O'qish - ishlash",
      "UpdateUser": "Foydalanuvchini yangilash",
      "User": "Foydalanuvchi",
      "UserCredentialsHint": "Iltimos, xodimning login va parolini yozib oling, chunki ular faqat bir martta ko'rsatiladi",
      "Users": "Foydalanuvchilar",
      "WasReception": "Qabulga kelgan",
      "Weight": "Vazni",
      "WorkExperience": "Ish tajribasi",
      "WorkSchedule": "Ish tartibi"
    }
  }
}
