{
  "en": {
    "PatientPaySum": "The patient paid",
    "PatientRefundSum": "Returned to the patient"
  },
  "ru": {
    "PatientPaySum": "Заплатил пациент",
    "PatientRefundSum": "Возвращается пациенту"
  },
  "uz": {
    "PatientPaySum": "Bemor to'laydi",
    "PatientRefundSum": "Bemorga qaytadi"
  }
}