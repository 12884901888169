export const CHART_DEFAULT_SERIES = [{ data: [] }];

export const CHART_DEFAULT_OPTIONS = {
  chart: {
    height: 200,
    type: 'bar',
    toolbar: { show: false },
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      columnWidth: '30%',
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    },
  },
  dataLabels: { enabled: false },

  xaxis: {
    categories: [],
    position: 'bottom',
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
    },
    tooltip: { enabled: true },
  },
  yaxis: {
    axisBorder: { show: false },
    axisTicks: { show: false },
    labels: { show: true },
  },
};
