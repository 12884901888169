{
  "en": {
    "ActivationKey": "Activation key",
    "Copy": "Copy"
  },
  "ru": {
    "ActivationKey": "Ключ активации",
    "Copy": "Копировать"
  },
  "uz": {
    "ActivationKey": "Aktivizatsiya kaliti",
    "Copy": "Nusxalash"
  }
}
