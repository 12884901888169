<template>
  <ElAvatar
    class="mi-avatar"
    :shape="shape"
    :size="size"
    fit="fill"
    :src="src"
    :alt="alt"
    @error="loadImgError = true" />
</template>

<script setup lang="ts">
import { ElAvatar, AvatarInstance } from 'element-plus/es';
import { computed, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    size?: AvatarInstance['size'];
    shape?: AvatarInstance['shape'];
    src?: AvatarInstance['src'];
    alt?: string;
  }>(),
  {
    alt: 'Avatar',
    size: 'default',
    shape: 'circle',
  }
);

const loadImgError = ref(false);

const src = computed(() =>
  props.src && !loadImgError.value ? props.src : '/src/assets/images/user-neutral.png'
);
</script>

<style lang="scss" src="./index.scss" />
