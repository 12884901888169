<template>
  <MainLayout content-class="v-appointment-content" :loading="loading.get" fix-height>
    <RouterView
      v-if="appointment"
      v-model:appointment="appointment"
      v-model:patientCardItems="patientCardItems"
      v-model:patientAmbulatoryCardPageLink="patientAmbulatoryCardPageLink"
      :loading="loading"
      @status:update="updateStatus"
      @appointment:edit="editAppointment"
      @appointment:provide="provideAppointment">
    </RouterView>
  </MainLayout>
</template>

<script>
import axios from 'axios';

import { APPOINTMENT_ROUTE } from '@/router/appointments.routes';
import { DEFAULT_GET_DATA_INTERVAL, ROUTE_PATH } from '~shared/config';
import { User } from '@/models/User.model';
import { Appointment } from '@/models/appointment/Appointment.model';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { insertRouteParams } from '@/utils/router.utils';
import { MainLayout } from '~widgets/layouts';
import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import SelectTreatmentModal from '@/components/appointments/SelectTreatmentModal/index.vue';
import { useSessionStore } from '~entities/session';

export default {
  name: 'VAppointment',
  components: { MainLayout },
  props: {
    id: [Number, String],
    patientId: [Number, String],
  },
  data() {
    return {
      loading: {
        get: false,
        [Appointment.enum.statuses.Created]: false,
        [Appointment.enum.statuses.Waiting]: false,
        [Appointment.enum.statuses.Canceled]: false,
        [Appointment.enum.statuses.InProgress]: false,
        [Appointment.enum.statuses.Approved]: false,
        [Appointment.enum.statuses.Provided]: false,
      },
      hasStatusLoadingState: false,

      /** @type {Appointment} */
      appointment: null,
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },

    isDoctor() {
      return this.user.role === User.enum.roles.Doctor;
    },

    watchers() {
      return {
        id: this.id,
        isInspectionCardRoute:
          this.$route.name === APPOINTMENT_ROUTE.childrenMap.APPOINTMENT_ROUTE_INSPECTION_CARD.name,
        isRecommendationRoute:
          this.$route.name === APPOINTMENT_ROUTE.childrenMap.APPOINTMENT_ROUTE_RECOMMENDATION.name,
      };
    },

    patientAmbulatoryCardPageLink() {
      return insertRouteParams({
        path: PATIENT_ROUTE.childrenMap.PATIENT_ROUTE_AMBULATORY_CARD._fullPath,
        params: {
          id: this.appointment.patient_id,
        },
      });
    },

    patientCardItems() {
      const servicesText = this.appointment.services.map((service) => service.title).join(',\n');

      return [
        {
          label: this.$t('User.Birthdate'),
          value: this.appointment.patient.birthdate,
        },
        {
          label: this.$t('Base.Services'),
          value: servicesText,
        },
        {
          label: this.$t('Appointments.StartDate'),
          value: this.appointment.start_at,
        },
      ];
    },
  },
  watch: {
    watchers: {
      handler(value) {
        clearInterval(this.timerId);
        if (!value.isInspectionCardRoute && !value.isRecommendationRoute) {
          this.timerId = setInterval(this.getAppointment, DEFAULT_GET_DATA_INTERVAL);
        }
        this.getAppointment();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async getAppointment() {
      this.loading.get = true;

      const { data } = await Appointment.findOneById(this.id);
      this.appointment = new Appointment(data.data);
      // Save the appointment in the store
      this.$store.dispatch('appointment/setAppointment', this.appointment);

      this.loading.get = false;
    },

    async editAppointment() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditAppointmentModal,
        payload: {
          id: this.appointment.id,
        },
      });

      if (action instanceof GlobalModalClose) return;
      // TODO нужно отрефакторить
      // this.appointment = action.data.appointment;
      await this.getAppointment();
    },

    /**
     * @param {string} status
     * @param {object} options
     * @param {boolean} options.forceUpdate проигнорирует бизнес логику и обновит статус
     * @return {Promise<void>}
     */
    async updateStatus(status, options = {}) {
      if (this.hasStatusLoadingState) return;
      this.loading[status] = true;
      this.hasStatusLoadingState = true;

      try {
        if (
          !options.forceUpdate &&
          this.isDoctor &&
          status === Appointment.enum.statuses.Provided
        ) {
          await this.startDoctorProvideFlow();
        } else {
          const { data } = await Appointment.updateStatus({
            id: this.appointment.id,
            status: status,
          });

          this.appointment = data.data;
          this.$notify({ type: 'success', title: this.$i18n.t('Notifications.SuccessUpdated') });
          this.redirectIfNeeded(status);

          if (this.isDoctor && status === Appointment.enum.statuses.InProgress) {
            this.startDoctorProvideFlow();
            this.sessionStore.setActiveAppointmentForCurrentUser({
              appointmentId: this.appointment.id,
            });
          }

          if (
            this.isDoctor &&
            [Appointment.enum.statuses.Provided, Appointment.enum.statuses.Canceled].includes(
              status
            )
          ) {
            this.sessionStore.closeActiveAppointmentForCurrentUser();
          }
        }
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading[status] = false;
      this.hasStatusLoadingState = false;
    },

    redirectIfNeeded(status) {
      if ([Appointment.enum.statuses.Canceled].includes(status)) {
        this.$router.push(ROUTE_PATH.appointments.activeList.withQuery());
      }
    },

    async startDoctorProvideFlow() {
      this.startFullProvideFlow();
      /*      if (
        !this.appointment.patient.has_treatment ||
        this.appointment.inspection_card?.type === InspectionCard.enum.types.Default
      )
        return this.startFullProvideFlow();
      if (this.appointment.treatment_id) return this.startTreatmentProvideFlow();

      const action = await this.$store.dispatch(
        'modalAndDrawer/openModal',
        SelectAppointmentInspectionTypeModal
      );

      if (action.name === Appointment.enum.inspectionTypes.Full) await this.startFullProvideFlow();
      if (action.name === Appointment.enum.inspectionTypes.Treatment)
        await this.startTreatmentProvideFlow();*/
    },

    async startFullProvideFlow() {
      this.$router.push(ROUTE_PATH.appointments.appointment.byId(this.appointment.id));
    },
    async startTreatmentProvideFlow() {
      const success = await this.selectTreatment();
      if (!success) return;

      this.$router.push(ROUTE_PATH.appointments.appointment.byId(this.appointment.id));
    },

    /**
     * Возращает
     *   true - если успешно выбрано лечение,
     *   false - модалка закрылась и нужно приостановить текщий flow
     * @return {Promise<boolean>}
     */
    async selectTreatment() {
      if (this.appointment.treatment_id) return true;

      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: SelectTreatmentModal,
        payload: {
          appointment: this.appointment,
        },
      });
      if (!(action instanceof GlobalModalClose)) {
        this.appointment = action.data.appointment;
      }

      return !(action instanceof GlobalModalClose);
    },

    async provideAppointment() {
      await this.updateStatus(Appointment.enum.statuses.Provided, { forceUpdate: true });
      this.startAfterProvideFlow();
    },

    async startAfterProvideFlow() {
      this.$router.push(ROUTE_PATH.appointments.activeList.withQuery());
    },
  },

  unmounted() {
    setTimeout(() => clearInterval(this.timerId));
  },

  setup: () => ({
    timerId: null,
    User,
    Appointment,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
