<template>
  <ul class="employee-schedule-list">
    <li class="employee-schedule-list__item" v-for="item in scheduleList" :key="item.day">
      {{ item.day }}: {{ item.time }}
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { prepareWorkingTime } from '../../lib/prepareWorkingTime';

import { WorkingTimeDto } from '~shared/api';

const props = defineProps<{
  workingTime?: WorkingTimeDto;
}>();

const scheduleList = computed(() => prepareWorkingTime(props.workingTime));
</script>

<style lang="scss" src="./index.scss"></style>
