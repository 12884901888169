import { useQuery } from './useQuery';

import { DEFAULT_PER_PAGE, PER_PAGE } from '~shared/config';

type UsePerPageOptions = {
  field?: string;
};

export function usePerPage(
  defaultValue: number | undefined = undefined,
  options: UsePerPageOptions = {}
) {
  return useQuery({
    field: options.field ?? PER_PAGE,
    defaultValue: defaultValue ?? DEFAULT_PER_PAGE,
    valueIsNumber: true,
  });
}
