import { GetPriceListsResponseData } from '../types';

import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';
import { joinQueryParams } from '~shared/lib';

const urlPath = 'g2g/price_lists';
// const urlPath = 'b2b/price_lists';

export const getList = async (query: QueryParams = {}) => {
  const response = await apiService.api.instance.get<GetPriceListsResponseData>(
    joinQueryParams({
      url: urlPath,
      query: query,
    })
  );
  return {
    ...response.data,
  };
};
