<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="article" :label="$t('Nomenclature.VendorCode')" />
    <ElTableColumn prop="title" :label="$t('Nomenclature.NameOfProduct')" />
    <ElTableColumn prop="count" :label="$t('Nomenclature.Qty')" />
    <ElTableColumn prop="warehouse.title" :label="$t('Income.Warehouse')" />
    <ElTableColumn prop="next_income_date" :label="$t('Nomenclature.NextDeliveryDate')" />
  </UiTableWithPagination>
</template>
<script lang="ts">
import { PropType } from 'vue';

import { WarehouseDto } from '@/types/api';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

export default {
  name: 'GoodsStockTable',
  components: {
    UiTableWithPagination,
  },
  emits: ['update:page', 'update:perPage', 'modal:open'],
  props: {
    loading: { type: Boolean, required: true },
    items: { type: Array as PropType<WarehouseDto[]>, required: true },
    page: { type: Number, required: true },
    perPage: { type: Number, required: true },
    total: { type: Number, required: true },
  },
};
</script>

<style lang="scss" scoped></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
