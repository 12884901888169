{
  "en": {
    "Laboratory": {
      "Analysis": {
        "AllAnalyzes": "All analyzes",
        "Analysis": "Analysis",
        "Analyzes": "Analyses",
        "Category": "Category",
        "CreateAnalysis": "Create Analysis",
        "ValueType": "Value type",
        "ValueTypes": {
          "boolean": "Boolean",
          "default": "Text field",
          "file": "File",
          "textarea": "Long text"
        }
      },
      "AssignAnalyzes": "Assign analyzes",
      "Biomaterial": {
        "AddBiomaterial": "Add biomaterial",
        "Biomaterial": "Biomaterial",
        "Statuses": {
          "assembled": "Assembled",
          "canceled": "Canceled",
          "confirmed": "Confirmed",
          "not_assembled": "Not assembled",
          "not_confirmed": "Not confirmed"
        }
      },
      "CompleteSampling": "Complete Fence",
      "EndValue": "End value",
      "Order": {
        "Order": "Request",
        "Statuses": {
          "assemble": "Take away",
          "awaiting_assemble": "Awaiting collection",
          "awaiting_confirm": "Awaiting confirmation",
          "canceled": "Canceled",
          "created": "Created",
          "not_confirm": "Not confirm",
          "ready": "Done"
        }
      },
      "RePickReferencesGroup": "Choose ref. group",
      "ReferencesGroup": "Ref. group",
      "ReferencesGroups": "Ref. groups",
      "ReferencesInterval": "Ref. interval",
      "ReferencesValue": "Ref. value",
      "ReferencesValues": "Ref. values",
      "Results": "Results",
      "SamplingAt": "Sampling at",
      "SamplingBiomaterial": "Sampling biomaterial",
      "StartValue": "Start value",
      "StudiesResults": "Studies results",
      "Study": {
        "AddStudy": "Add study",
        "Study": "Study",
        "ValueNegative": "Negative",
        "ValuePositive": "Positive"
      }
    }
  },
  "ru": {
    "Laboratory": {
      "Analysis": {
        "AllAnalyzes": "Все анализы",
        "Analysis": "Анализ",
        "Analyzes": "Анализы",
        "Category": "Категория",
        "CreateAnalysis": "Создать анализ",
        "ValueType": "Тип значения",
        "ValueTypes": {
          "boolean": "Логическое",
          "default": "Текстовое поле",
          "file": "Файл",
          "textarea": "Длинный текст"
        }
      },
      "AssignAnalyzes": "Назначить анализы",
      "Biomaterial": {
        "AddBiomaterial": "Добавить биоматериал",
        "Biomaterial": "Биоматериал",
        "Statuses": {
          "assembled": "Забран",
          "canceled": "Отменён",
          "confirmed": "Подтверждён",
          "not_assembled": "Не забран",
          "not_confirmed": "Не подтверждён"
        }
      },
      "CompleteSampling": "Завершить забор",
      "EndValue": "Конечное значение",
      "Order": {
        "Order": "Заявка",
        "Statuses": {
          "assemble": "Забирают",
          "awaiting_assemble": "Ожидание сбора",
          "awaiting_confirm": "Ожидает подтверждения",
          "canceled": "Отменён",
          "created": "Создан",
          "not_confirm": "Не подтвеждён",
          "ready": "Готово"
        }
      },
      "RePickReferencesGroup": "Подобрать реф. группу",
      "ReferencesGroup": "Реф. группа",
      "ReferencesGroups": "Реф. группы",
      "ReferencesInterval": "Реф. интервал",
      "ReferencesValue": "Реф. значение",
      "ReferencesValues": "Реф. значения",
      "Results": "Результаты",
      "SamplingAt": "Дата забора",
      "SamplingBiomaterial": "Забор биоматериала",
      "StartValue": "Начальное значение",
      "StudiesResults": "Результаты исследований",
      "Study": {
        "AddStudy": "Добавить исследование",
        "Study": "Исследование",
        "ValueNegative": "Отрицательно",
        "ValuePositive": "Положительно"
      }
    }
  },
  "uz": {
    "Laboratory": {
      "Analysis": {
        "AllAnalyzes": "Barcha tahlillar",
        "Analysis": "Tahlil",
        "Analyzes": "Analizlar",
        "Category": "Kategoriya",
        "CreateAnalysis": "Analizni yaratish",
        "ValueType": "Qiymat turi",
        "ValueTypes": {
          "boolean": "Mantiqiy",
          "default": "Matn maydoni",
          "file": "Fayl",
          "textarea": "Uzun matn"
        }
      },
      "AssignAnalyzes": "Tahlillarni tayinlash",
      "Biomaterial": {
        "AddBiomaterial": "Biomaterial qoshish",
        "Biomaterial": "Biomaterial",
        "Statuses": {
          "assembled": "Olingan",
          "canceled": "Bekor qilingan",
          "confirmed": "Tasdiqlangan",
          "not_assembled": "Olinmagan",
          "not_confirmed": "Tasdiqlanmagan"
        }
      },
      "CompleteSampling": "Biomateriallarni olishni tugatish",
      "EndValue": "Tugash qiymati",
      "Order": {
        "Order": " So'rov",
        "Statuses": {
          "assemble": "yig'ilmoqda",
          "awaiting_assemble": "Toplam kutilmoqda",
          "awaiting_confirm": "Tasdiqlash kutilmoqda",
          "canceled": "Bekor qilindi",
          "created": "Yaratilgan",
          "not_confirm": "Tasdiqlanmagan",
          "ready": "Tayor"
        }
      },
      "RePickReferencesGroup": "Ref. guruhni tanlash",
      "ReferencesGroup": "Ref. guruh",
      "ReferencesGroups": "Ref. guruhlar",
      "ReferencesInterval": "Ref. interval",
      "ReferencesValue": "Ref. qiymati",
      "ReferencesValues": "Ref. qiymatlar",
      "Results": "Natijalar",
      "SamplingAt": "Yig'ish sanasi",
      "SamplingBiomaterial": "Biomaterial olish",
      "StartValue": "Boshlang'ich qiymat",
      "StudiesResults": "Tahlil natijalari",
      "Study": {
        "AddStudy": "Tahlil qo‘shish",
        "Study": "Tadqiqotlar",
        "ValueNegative": "Salbiy",
        "ValuePositive": "Ijobiy"
      }
    }
  }
}
