<template>
  <MiSimpleTable class="salary-details-table" :data="tableData" :loading="false" height="100%">
    <ElTableColumn prop="type" :label="$t('Bookkeeping.OperationName')">
      <template #default="{ row }: { row: SalaryDetails }">
        {{ (row.type && I18nService.t(`Bookkeeping.SalaryDetailType.${row.type}`)) ?? row.title }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="title" :label="$t('Base.Service')">
      <template #default="{ row }: { row: SalaryDetails }">
        {{ row.title ?? '' }}
      </template>
    </ElTableColumn>
    <ElTableColumn prop="count" :label="$t('Bookkeeping.Qty')" />
    <ElTableColumn prop="amount" :label="$t('Bookkeeping.AccrualAmount')">
      <template #default="{ row }: { row: SalaryDetails }">
        <span
          :class="{
            'salary-details-table__minus': row.typeOperation === 'minus',
            'salary-details-table__plus': row.typeOperation === 'plus',
          }"
          >{{ row.amount }}</span
        >
      </template>
    </ElTableColumn>
    <ElTableColumn prop="typeOperation" :label="$t('Bookkeeping.Operation')">
      <template #default="{ row }: { row: SalaryDetails }">
        {{ row.typeOperation ? $t(`Bookkeeping.OperationType.${row.typeOperation}`) : '' }}
      </template>
    </ElTableColumn>
    <ElTableColumn :label="$t('Base.Actions')">
      <template #default="{ row }: { row: SalaryDetails }">
        <MiButton @click="removeOperation(row)" type="primary" text :disabled="disabled">
          <template #icon>
            <MiIcon icon="CLOSE" />
          </template>
        </MiButton>
      </template>
    </ElTableColumn>
  </MiSimpleTable>
</template>
<script lang="ts" setup>
import { I18nService } from '~shared/lib';
import { MiButton, MiIcon, MiSimpleTable } from '~shared/ui';
import { SalaryDetails } from '~shared/types';

defineProps<{
  tableData: SalaryDetails[];
  disabled?: boolean;
}>();

const emits = defineEmits<{
  removeOperation: [event: SalaryDetails];
}>();

const removeOperation = (row: SalaryDetails) => {
  emits('removeOperation', row);
};
</script>

<style lang="scss" src="./index.scss"></style>
