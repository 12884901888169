{
  "en": {
    "Treatments": {
      "CloseTreatment": "Complete treatment",
      "DrugOrProcedure": "Drug or procedure",
      "DrugsAndProcedures": "Drugs and Procedures",
      "Duration": "Duration",
      "Statuses": {
        "closed": "Completed",
        "created": "Open"
      }
    }
  },
  "ru": {
    "Treatments": {
      "CloseTreatment": "Завершить лечение",
      "DrugOrProcedure": "Препарат или процедура",
      "DrugsAndProcedures": "Препараты и процедуры",
      "Duration": "Продолжительность",
      "Statuses": {
        "closed": "Завершён",
        "created": "Открытый"
      }
    }
  },
  "uz": {
    "Treatments": {
      "CloseTreatment": "Davolashni tugadish",
      "DrugOrProcedure": "Dori-darmon yoki muolaja",
      "DrugsAndProcedures": "Dori-darmonlar va muolajalar",
      "Duration": "Davomiyligi",
      "Statuses": {
        "closed": "Bajarildi",
        "created": "Ochiq"
      }
    }
  }
}