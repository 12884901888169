import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const USERS_PATH_PREFIX = '/users';

export const USERS_ROUTE = {
  name: 'USERS',
  path: `${USERS_PATH_PREFIX}/employees`,
  component: 'VUsers',
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['manager', 'director', 'accountant']),
  ],
  meta: {
    title: 'User.Users',
  },
};

export const USER_ROUTE = {
  name: 'USER',
  path: '/user/:id/:referrerId?',
  component: 'VUser',
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['manager', 'director', 'accountant']),
  ],
  meta: {
    title: 'User.User',
  },
  props: true,
  childrenMap: {
    DEVICES: {
      name: 'DEVICES',
      path: 'devices',
      _fullPath: `/user/:id/devices`,
      component: 'VUserDevices',
      beforeEnter: [
        onlyUserIsAuthMiddleware,
        onlyUserHasRoleMiddleware(['manager', 'director', 'accountant']),
      ],
      meta: {
        title: 'User.Devices',
      },
      props: true,
    },
    SALARY: {
      name: 'SALARY',
      path: 'salary',
      _fullPath: `/user/:id/salary`,
      component: 'VUserSalary',
      beforeEnter: [
        onlyUserIsAuthMiddleware,
        onlyUserHasRoleMiddleware(['manager', 'director', 'accountant']),
      ],
      meta: {
        title: 'User.Accounting',
      },
      props: true,
    },
  },
};

export const routes = [USERS_ROUTE, USER_ROUTE];
