<template>
  <ElFormItem class="ui-switch" :label="props.label">
    <ElSwitch
      v-model="value"
      v-bind="$attrs"
      :active-value="props.activeValue"
      :inactive-value="props.inactiveValue"
      :active-color="props.activeColor"
      :inactive-color="props.inactiveColor"
      :active-text="props.activeText"
      :inactive-text="props.inactiveText"
      :validate-event="false" />
  </ElFormItem>
</template>

<script lang="ts">
export default {
  name: 'FormSwitchField',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { ElSwitch } from 'element-plus';

const props = withDefaults(
  defineProps<{
    fieldName: string;
    label?: string;
    activeValue?: string | number | boolean;
    inactiveValue?: string | number | boolean;
    activeText?: string;
    inactiveText?: string;
    activeColor?: string;
    inactiveColor?: string;
  }>(),
  {
    label: '',
    activeValue: true,
    inactiveValue: false,
    activeText: undefined,
    inactiveText: undefined,
    activeColor: undefined,
    inactiveColor: undefined,
  }
);

// eslint-disable-next-line vue/no-setup-props-destructure
const { value } = useField<boolean | string | number | undefined>(props.fieldName);
</script>

<style lang="scss" src="./index.scss" />
