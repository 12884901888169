<template>
  <PrinterDocument
    class="invoice-printer-check"
    :content-blocks="contentBlocks"
    hide-title
    hide-clinic-name
    hide-address
    hide-watermark
    ref="document">
    <template #header>
      <div class="invoice-printer-check__header">
        <img
          class="invoice-printer-check__header-logo"
          :src="user?.clinic?.logo?.link"
          alt="clinic logo" />
        <div class="invoice-printer-check__header-inner">
          <div>{{ invoice.user?.humId }}</div>
          <div>{{ $t('Invoices.YourNumberInLine') }}</div>
        </div>
      </div>
    </template>
    <template #append-content>
      <QrCode :payload="{ data: qrPayload }" />
      <div class="invoice-printer-check__company-title">{{ $t('CompanyTitle') }}</div>
      <img
        class="invoice-printer-check__company-logo"
        src="@/assets/images/invoice-check-logo.svg"
        alt="logo" />
    </template>
  </PrinterDocument>
</template>

<script>
import { formatPrice } from '@/utils/price.util';
import { Invoice } from '@/models/Invoice.model';
import { PrinterDocumentContentBlock as ContentBlock } from '@/models/client/PrinterDocumentContentBlock.model';
import PrinterDocument from '@/components/printer/PrinterDocument/index.vue';
import QrCode from '@/components/QrCode/index.vue';
import { useSessionStore } from '~entities/session';

export default {
  name: 'InvoicePrinterCheck',
  components: { QrCode, PrinterDocument },
  props: {
    invoice: [Invoice, Object],
    qrPayload: String,
  },
  data() {
    return {
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },

    contentBlocks() {
      return [
        {
          label: this.$t('Base.Clinic'),
          value: this.user.clinic.title,
        },
        {
          label: this.$t('DateAndTime.Date'),
          value: this.invoice.created_at?.split(' ')[0],
        },
        {
          label: this.$t('Base.Patient'),
          value: this.invoice.user?.name,
        },
        {
          label: this.$t('User.Birthdate'),
          value: this.invoice.user?.birthdate,
        },
        {
          label: this.$t('Base.Invoice'),
          value: '#' + this.invoice.id,
        },
        {
          label: this.$t('DateAndTime.Time'),
          value: this.invoice.created_at?.split(' ')[1],
        },
        {
          label: this.$t('Creator'),
          value: this.invoice.creator?.name,
          withDivider: true,
        },
        ...(this.invoice.appointments.length
          ? this.invoice.appointments
              .map((appointment, index) => {
                const paymentSubjects = this.invoice.payment_subjects.filter(
                  (f) => f.appointment_id === appointment.id
                );

                return [
                  {
                    type: ContentBlock.enum.types.Table,
                    label: `${this.$t('Appointment')} №${index + 1}`,
                    headers: [
                      { prop: 'title', label: this.$t('Base.Services') },
                      { prop: 'price', label: this.$t('Base.Price') },
                    ],
                    class: 'invoice-printer-check__content-block-table',
                    value: paymentSubjects.map((payment) => ({
                      title: payment.subject?.title,
                      price: `${formatPrice({
                        price: payment.subject?.price,
                      })} ${payment.count > 1 ? `(${payment.count})` : ''}`,
                    })),
                  },
                  {
                    label: this.$t('Base.Doctor'),
                    value: appointment.doctor.name,
                  },
                  {
                    label: this.$t('Base.Cabinet'),
                    value: appointment.doctor.cabinet,
                  },
                  {
                    label: this.$t('Appointment'),
                    value: appointment.start_at,
                    withDivider: true,
                  },
                ];
              })
              .reduce((acc, current) => [...acc, ...current], [])
          : []),
        ...(this.invoice.appointments.length === 0
          ? [
              {
                type: ContentBlock.enum.types.Table,
                headers: [
                  { prop: 'title', label: this.$t('Base.Services') },
                  { prop: 'price', label: this.$t('Base.Price') },
                ],
                class: 'invoice-printer-check__content-block-table',
                value: this.invoice.payment_subjects.map((payment) => ({
                  title: payment.subject?.title,
                  price: `${formatPrice({
                    price: payment.subject?.price,
                  })} ${payment.count > 1 ? `(${payment.count})` : ''}`,
                })),
              },
            ]
          : []),
        {
          label: this.$t('Base.Total'),
          value: formatPrice({ price: this.invoice.discounted_amount }) + ' ' + this.$t('Base.Sum'),
          class: 'invoice-printer-check__content-block-with-total-amount',
        },
        {
          label: this.$t('Base.Discount'),
          value: (this.invoice.discount ?? 0) + '%',
          withDivider: true,
        },
        {
          label: this.$t('Paid'),
          value: `${formatPrice({
            price: this.invoice.discounted_amount - this.invoice.left_pay,
          })} ${this.$t('Base.Sum')}`,
          class: 'invoice-printer-check__content-block-with-total-amount',
        },
      ];
    },
  },

  methods: {
    print() {
      this.$refs.document.print();
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
