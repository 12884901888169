import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware } from '~shared/lib';

export const NOT_FOUND_ROUTE = {
  name: 'NotFound',
  path: ROUTE_PATH.notFound,
  component: () => import('./ui/NotFound'),
  beforeEnter: [onlyUserIsAuthMiddleware],
  meta: {
    title: '404',
  },
};
