<template>
  <ElCard
    class="appointment-sources-statistic-card"
    v-loading="loading"
    :header="$t('Statistic.AppointmentSources')"
    :shadow="shadow">
    <div class="appointment-sources-statistic-card__content">
      <Vue3Apexcharts
        class="appointment-sources-statistic-card__chart"
        type="donut"
        width="280"
        :options="chartOptions"
        :series="series"
        ref="chart" />

      <div class="appointment-sources-statistic-card__info appointment-sources-statistic-card-info">
        <div class="appointment-sources-statistic-card-info__value">{{ totalAppointments }}</div>
        <div class="appointment-sources-statistic-card-info__label">
          {{ $t(`Statistic.TotalNumberOfPatients`) }}
        </div>
      </div>
    </div>

    <div class="appointment-sources-statistic-card__legend">
      <div class="appointment-sources-statistic-card__legend-label">
        {{ $t(`Statistic.Quantity`) }}
      </div>
      <div class="appointment-sources-statistic-card__legend-icon-wrapper">
        <div
          class="appointment-sources-statistic-card__legend-icon appointment-sources-statistic-card__legend-icon_zordoc"></div>
      </div>
      <div class="appointment-sources-statistic-card__legend-text">ZorDoc</div>
      <div class="appointment-sources-statistic-card__legend-amount">{{ series[0] }}</div>

      <div class="appointment-sources-statistic-card__legend-icon-wrapper">
        <div
          class="appointment-sources-statistic-card__legend-icon appointment-sources-statistic-card__legend-icon_phone"></div>
      </div>
      <div class="appointment-sources-statistic-card__legend-text">
        {{ $t(`Statistic.Phone`) }}
      </div>
      <div class="appointment-sources-statistic-card__legend-amount">{{ series[1] }}</div>

      <div class="appointment-sources-statistic-card__legend-icon-wrapper">
        <div
          class="appointment-sources-statistic-card__legend-icon appointment-sources-statistic-card__legend-icon_site"></div>
      </div>
      <div class="appointment-sources-statistic-card__legend-text">
        {{ $t(`Statistic.PersonalSite`) }}
      </div>
      <div class="appointment-sources-statistic-card__legend-amount">{{ series[2] }}</div>
    </div>
  </ElCard>
</template>

<script>
import Vue3Apexcharts from 'vue3-apexcharts';

export default {
  name: 'AppointmentSourcesStatisticCard',
  components: { Vue3Apexcharts },
  props: {
    /** @type {Array<string>} period - Array<DATE_FORMAT> */
    period: Array,
    shadow: {
      type: String,
      default: 'never',
    },
  },

  data() {
    return {
      value: null,
      percentage: null,
      loading: false,
      series: [2890, 8190, 2190],
    };
  },

  computed: {
    totalAppointments() {
      return this.series.reduce((acc, curr) => acc + curr, 0);
    },

    chartOptions() {
      return {
        chart: {
          width: 280,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: 90,
            endAngle: -270,
            donut: {
              size: '80%',
              background: 'transparent',
            },
          },
        },
        colors: ['#54F7E3', '#FBA556', '#1780FB'],
        labels: ['ZorDoc', this.$t(`Statistic.Phone`), this.$t(`Statistic.PersonalSite`)],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        fill: {
          type: 'gradient',
        },
      };
    },
  },

  watch: {
    period: {
      handler() {
        this.getData();
      },
      immediate: true,
    },
    series: {
      handler(value) {
        this.$refs.chart.updateSeries(value);
      },
    },
    chartOptions: {
      handler(value) {
        this.$refs.chart.updateOptions(value);
      },
      deep: true,
    },
  },

  methods: {
    async getData() {
      //TODO
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/statistic.locales.json" />
