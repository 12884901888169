import { I18nService } from '~shared/lib';

export function thousandsSeparator(
  value: string | number | null | undefined,
  params: {
    delimiter?: string;
  } = {
    delimiter: ' ',
  }
) {
  return (value ?? 0)
    .toString()
    .split(/(?=(?:\d{3})+(?:\.|$))/g)
    .join(params.delimiter);
}

export function formatFullnameToShort(value?: string | null) {
  return value
    ? value
        .split(' ')
        .map((word, index) => (index === 0 ? `${word} ` : `${word[0]?.toLocaleUpperCase()}.`))
        .join('')
    : '';
}

const cyrillicToEngMap: Record<string, string> = {
  'й': 'q',
  'ц': 'w',
  'у': 'e',
  'к': 'r',
  'е': 't',
  'н': 'y',
  'г': 'u',
  'ш': 'i',
  'щ': 'o',
  'з': 'p',
  'х': '[',
  'ъ': ']',
  'Х': '{',
  'Ъ': '}',
  // \: `\\`,
  'ф': 'a',
  'ы': 's',
  'в': 'd',
  'а': 'f',
  'п': 'g',
  'р': 'h',
  'о': 'j',
  'л': 'k',
  'д': 'l',
  'ж': ';',
  'Ж': ':',
  'э': "'",
  'Э': '"',
  'я': 'z',
  'ч': 'x',
  'с': 'c',
  'м': 'v',
  'и': 'b',
  'т': 'n',
  'ь': 'm',
  'б': ',',
  'Б': '<',
  'ю': '.',
  'Ю': '>',
  '.': '/',
  ',': '?',

  // numbers chars
  '!': '!',
  '"': '@',
  '№': '#',
  ';': '$',
  '%': '%',
  ':': '^',
  '?': '&',
  '*': '*',
  '(': '(',
  ')': ')',
  '_': '_',
  '+': '=',
};
export function cyrillicToEng(text: string) {
  return text
    .split('')
    .map(function (char) {
      return cyrillicToEngMap[char] || cyrillicToEngMap[char.toLowerCase()] || char;
    })
    .join('');
}

export function prepareTokenForBracelet(patientName: string): never | string {
  const isTextOnCyrillic = patientName.match(/[а-яА-Я]/gim);

  const latinText = isTextOnCyrillic ? cyrillicToEng(patientName) : patientName;

  const matches = Array.from(latinText.matchAll(/\?oneTimeToken=(?<token>[^&]*)/gim));
  const token = matches[0]?.groups?.token ?? null;

  if (!token) throw new Error(I18nService.t('Base.InvalidQrCode'));

  return token;
}
