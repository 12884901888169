<template>
  <ElForm class="create-treatment-drug" label-position="top" @submit.prevent="createDrug">
    <div class="create-treatment-drug__part create-treatment-drug-part">
      <ElInput v-model="drug.title" :placeholder="$t('Treatments.DrugOrProcedure')" required />
    </div>

    <div class="create-treatment-drug__part create-treatment-drug-part">
      <ElInput
        v-model.number="drug.price"
        :placeholder="$t('Base.Price')"
        type="number"
        min="0"
        required />
      <ElInput
        v-model.number="drug.count"
        :placeholder="$t('Base.QuantityDays')"
        type="number"
        min="1"
        required />

      <ElButton class="create-treatment-drug__submit" type="primary" native-type="submit">
        {{ $t('Base.Add') }}
      </ElButton>
    </div>
  </ElForm>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';

import { Treatment } from '@/models/Treatment.model';
import { TreatmentDrug } from '@/models/TreatmentDrug.model';

export default {
  name: 'CreateTreatmentDrug',
  emits: ['drug:create'],
  props: {
    treatment: [Treatment, Object],
  },
  data() {
    return {
      drug: new TreatmentDrug(),
    };
  },

  methods: {
    createDrug() {
      this.$emit('drug:create', cloneDeep(this.drug));
      this.reset();
    },

    reset() {
      this.drug = new TreatmentDrug();
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/treatments.locales.json" />
