<template>
  <div class="v-app-ins-card">
    <PatientCardRow :patient="appointment.patient" :items="patientCardItems">
      <template #actions>
        <router-link :to="patientAmbulatoryCardPageLink">
          <ElButton type="primary"> {{ $t('Base.AmbulatoryCard') }}</ElButton>
        </router-link>
      </template>
    </PatientCardRow>

    <InspectionCardComponent
      :appointment="appointment"
      :readonly="readonly"
      :is-provided="isProvided"
      @update:appointment="$emit('update:appointment', $event)"
      @appointment:provide="$emit('appointment:provide')"
      @appointment:set:diagnosis="$emit('appointment:set:diagnosis')" />
  </div>
</template>

<script>
import { APPOINTMENT_ROUTE } from '@/router/appointments.routes';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { NOT_REDIRECT } from '~shared/config';
import { insertRouteParams } from '@/utils/router.utils';
import { Appointment } from '@/models/appointment/Appointment.model';
import InspectionCardComponent from '@/components/appointments/InspectionCard/index.vue';
import PatientCardRow from '@/components/patients/PatientCardRow/index.vue';
import { useSessionStore } from '~entities/session';

export default {
  name: 'VAppointmentInspectionCard',
  emits: ['update:appointment', 'appointment:provide', 'appointment:set:diagnosis'],
  components: { InspectionCardComponent, PatientCardRow },
  props: {
    appointment: [Appointment, Object],
    patientCardItems: Array,
    patientAmbulatoryCardPageLink: String,
  },
  data() {
    return {
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },
    readonly() {
      return (
        this.appointment.doctor_id !== this.user.doctor_id ||
        this.appointment.status === Appointment.enum.statuses.Canceled
      );
    },
    isProvided() {
      return this.appointment.status === Appointment.enum.statuses.Provided;
    },

    patientPageLink() {
      return insertRouteParams({
        path: PATIENT_ROUTE.path,
        params: {
          id: this.appointment.patient_id,
        },
      });
    },
  },
  watch: {
    'appointment.status': {
      handler() {
        this.redirectToAppointmentDefaultCardIfNeed();
      },
      immediate: true,
    },
  },

  methods: {
    goToAppointmentDefaultCard() {
      this.$router.push({
        name: APPOINTMENT_ROUTE.childrenMap.APPOINTMENT_ROUTE_DEFAULT_CARD.name,
        params: { id: this.appointment.id },
        query: { [NOT_REDIRECT]: 1 },
      });
    },

    redirectToAppointmentDefaultCardIfNeed() {
      if (
        this.appointment.status &&
        ![Appointment.enum.statuses.InProgress, Appointment.enum.statuses.Provided].includes(
          this.appointment.status
        )
      ) {
        this.goToAppointmentDefaultCard();
      }
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="./index.locales.json" />
