<template>
  <MiButton
    :class="['scan-patient-bracelet', { 'scan-patient-bracelet_only-icon': onlyIcon }]"
    type="primary"
    plain
    :disabled="disabled"
    @click="startScan">
    <template #icon> <MiIcon icon="SCAN" /> </template>
    <div v-if="!onlyIcon">{{ text }}</div>
    <ScanModal
      ref="scanModal"
      :model-value="isModalOpen"
      :text="isLoading ? $t('Base.Loading') : null"
      @scan:success="scanHandler"
      @update:model-value="endScan" />
  </MiButton>
</template>

<script setup lang="ts">
import { computed, shallowRef } from 'vue';

import { ScanModal } from '../index';

import { I18nService } from '~shared/lib';
import { MiButton, MiIcon } from '~shared/ui';
import { misB2BApi, PatientFullDto } from '~shared/api';

withDefaults(
  defineProps<{
    disabled?: boolean;
    onlyIcon?: boolean;
  }>(),
  {
    disabled: false,
    onlyIcon: false,
  }
);

const emit = defineEmits<{
  'scan:success': [patient: PatientFullDto];
}>();

const isScanning = shallowRef(false);
const isLoading = shallowRef(false);
const isModalOpen = shallowRef(false);

const text = computed(() => {
  if (isLoading.value) return I18nService.t('Base.Loading');
  if (isScanning.value) return I18nService.t('Base.Scanning');

  return I18nService.t('Base.ScanBracelet');
});

const startScan = () => {
  if (isScanning.value) return;
  isScanning.value = true;
  isModalOpen.value = true;
};

const scanHandler = async (patineName: string) => {
  if (isLoading.value) return;
  isLoading.value = true;

  const patient = await misB2BApi.patientsTemp.getByBracelet(patineName);
  if (patient?.data) {
    emit('scan:success', patient.data);
    endScan();
  }
  isLoading.value = false;
};

const endScan = () => {
  isScanning.value = false;
  isLoading.value = false;
  isModalOpen.value = false;
};
</script>

<style lang="scss" src="./index.scss" />
