{
  "en": {
    "Doctors": {
      "ChooseSpecialty": "Choose a specialty",
      "CompletedAppointments": "Completed appointments",
      "Doctor": "Doctor",
      "Doctors": "Doctors",
      "FindingDoctor": "Finding a doctor",
      "NumberReceptions": "Number of receptions",
      "RecordingTime": "Recording on time"
    }
  },
  "ru": {
    "Doctors": {
      "ChooseSpecialty": "Выбрать специальность",
      "CompletedAppointments": "Завершенные приемы",
      "Doctor": "Врач",
      "Doctors": "Врачи",
      "FindingDoctor": "Поиск врача",
      "NumberReceptions": "Кол-во приемов",
      "RecordingTime": "Запись на время"
    }
  },
  "uz": {
    "Doctors": {
      "ChooseSpecialty": "Mutaxassislikni tanlang",
      "CompletedAppointments": "Tugallangan harakatlar",
      "Doctor": "Doktor",
      "Doctors": "Doktorlar",
      "FindingDoctor": "Shifokor topish",
      "NumberReceptions": "Qabullar soni",
      "RecordingTime": "O'z vaqtida yozib olish"
    }
  }
}