import { GlobalModalAndDrawerPayload } from '../types';

export class GlobalModalAndDrawer {
  name = '';
  data = {};
  _type = '';
  constructor(payload: GlobalModalAndDrawerPayload) {
    this.name = payload?.name ?? '';
    this.data = payload?.data ?? {};
    this._type = payload?.type ?? '';
  }
}
