{
  "en": {
    "PatientInfo": "Patient Information",
    "Services": "Services"
  },
  "ru": {
    "PatientInfo": "Информация пациента",
    "Services": "Услуги"
  },
  "uz": {
    "PatientInfo": "Bemor malumoti",
    "Services": "Xizmatlar"
  }
}