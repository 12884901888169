<template>
  <ElDialog
    class="create-or-edit-hospital-modal"
    :title="$t('Hospital.Hospital')"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElForm
      id="create-or-edit-hospital-modal-form"
      class="create-or-edit-hospital-modal__form"
      label-position="top"
      @submit.prevent="submit">
      <ElCard :header="$t('Base.Patient')" shadow="never" v-loading="loading.hospital">
        <PatientsSearchSelectDataBlock
          v-model="hospital.user_id"
          :default-item="hospital?.user"
          :disabled="!!hospital.id"
          required
          create-permission
          ref="patient" />
      </ElCard>

      <ElCard :header="$t('Hospital.Hospital')" shadow="never" v-loading="loading.hospital">
        <ElFormItem :label="$t('Hospital.Department')">
          <UiModelsAutocompleteSearch
            v-model="hospital.department_id"
            :model-for-use="Department"
            label="title"
            :default-item="hospital.department"
            required
            @select="hospital.department = $event" />
        </ElFormItem>

        <ElFormItem :label="$t('Hospital.Chamber')">
          <UiModelsAutocompleteSearch
            v-model="hospital.chamber_id"
            :model-for-use="Chamber"
            :default-item="hospital.chamber"
            label="number"
            :search-query="{
              department_id: hospital.department_id,
              free: true,
            }"
            :disabled="!hospital.department_id"
            required
            @select="hospital.chamber = $event" />
        </ElFormItem>

        <ElFormItem :label="$t('Base.Doctor')">
          <UiModelsAutocompleteSearch
            v-model="hospital.doctor_id"
            :model-for-use="Doctor"
            :default-item="hospital.doctor"
            required
            @select="hospital.doctor = $event" />
        </ElFormItem>

        <ElFormItem :label="$t('DateAndTime.DaysCount')">
          <ElInput v-model.number="hospital.count_days" type="number" required />
        </ElFormItem>
      </ElCard>
    </ElForm>

    <template #footer>
      <ElButton
        type="primary"
        native-type="submit"
        form="create-or-edit-hospital-modal-form"
        :loading="loading.form">
        {{ hospital.id ? $t('Base.Save') : $t('Base.Create') }}
      </ElButton>
    </template>
  </ElDialog>
</template>

<script src="./index.js"></script>
<style lang="scss" src="./index.scss"></style>
