{
  "en": {
    "Invoices": {
      "Statuses": {
        "canceled": "Cancelled",
        "not_paid": "Not paid",
        "overpay": "Overpayment",
        "paid": "Paid",
        "partially_paid": "Part paid",
        "refund": "Refund"
      }
    }
  },
  "ru": {
    "Invoices": {
      "Statuses": {
        "canceled": "Отменён",
        "not_paid": "Не оплачено",
        "overpay": "Переплата",
        "paid": "Оплачен",
        "partially_paid": "Часть оплачена",
        "refund": "Возврат"
      }
    }
  },
  "uz": {
    "Invoices": {
      "Statuses": {
        "canceled": "Bekor qilingan",
        "not_paid": "To'lanmagan",
        "overpay": "Ortiqcha",
        "paid": "To'langan",
        "partially_paid": "Qismi to'langan",
        "refund": "Qaytarilgan"
      }
    }
  }
}
