<template>
  <div class="patients-search-select-data-block">
    <PatientsSearchSelect
      v-show="!patientPart.show && !hideSelect"
      class="patients-search-select-data-block__search"
      :placeholder="$t('User.PleaseInputNameOrPhone')"
      :model-value="modelValue"
      :search-query="query"
      :required="!patientPart.show && required"
      :disabled="disabled"
      :default-item="defaultItem"
      :show-create-option="createPermission"
      ref="select"
      @update:model-value="$emit('update:modelValue', $event)"
      @select="selectHandler"
      @create="startCreatePatientFlow"
      @getItems="$emit('getItems')" />

    <div v-show="!patientPart.show" class="patients-search-select-data-block__content">
      <ElFormItem :label="$t('User.FullName')">
        {{ patient?.name || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.Birthdate')">
        {{ patient?.birthdate || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.Phone')">
        {{ patient?.phone || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.Gender')">
        {{ patient?.id && patient?.gender ? $t(`User.Genders.${patient?.gender}`) : '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.PlaceResidence')">
        {{ patient?.place_residence || '-' }}
      </ElFormItem>

      <ElFormItem :label="$t('User.PriceListCategory')">
        {{ patient?.price_list?.title || '-' }}
      </ElFormItem>

      <slot name="content-append"></slot>
    </div>

    <CreateOrEditPatient
      v-if="patientPart.show"
      class="create-or-edit-appointment-modal__create-patient"
      :appointment="appointment"
      @reference:update="$emit('reference:update', $event)"
      :name-or-phone="patientPart.nameOrPhone"
      disable-default-action
      ref="create_or_edit_patient"
      @action="insertPatient"
      @foundPatient="$emit('foundPatient')"
      @reattachPatient="$emit('reattachPatient')" />
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
