/* eslint-disable @typescript-eslint/no-explicit-any */
import { createI18n } from 'vue-i18n';

import { DEFAULT_LOCALE, FALLBACK_LOCALE, LOCALES } from '~shared/config';

const generateLocalesEmptyObject = () => {
  const locales: Record<string, any> = {};
  Object.keys(LOCALES).forEach((key) => (locales[key] = {}));

  return locales;
};

const loadGlobalLocales = () => {
  const newLocalesModules = import.meta.glob<true, string, any>(
    '~shared/locales/**/*.locales.json',
    {
      eager: true,
    }
  );
  const oldLocalesModules = import.meta.glob<true, string, any>('@/locales/**/*.locales.json', {
    eager: true,
  });

  const localesModules = {
    ...newLocalesModules,
    ...oldLocalesModules,
  };

  const locales = generateLocalesEmptyObject();

  for (const moduleKey in localesModules) {
    const module: Record<string, any> = localesModules[moduleKey].default;

    Object.keys(module).forEach((langKey) => {
      locales[langKey] = {
        ...locales[langKey],
        ...module[langKey],
      };
    });
  }

  return locales;
};

export const I18nPlugin = createI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  messages: loadGlobalLocales(),
});
