import { apiService } from './base';
import * as oldMethods from './methods';

import { withErrorHandler } from '~shared/lib';

const methods = {
  ...apiService.api,
  ...oldMethods,
};

const misB2BApi = withErrorHandler(methods) as typeof methods;

export { apiService, misB2BApi };
export * from './api.generated';
