// TODO: нужно рефакторить
import NProgress from 'nprogress';
import { createRouter, createWebHistory } from 'vue-router';

import { useSessionStore } from '~entities/session';
import { LOGIN_ROUTE } from '~pages/LoginPage';
import { NOT_FOUND_ROUTE } from '~pages/NotFound';
import { NO_ACCESS_ROUTE } from '~pages/NoAccess';
import { FORGOT_PASSWORD_ROUTE } from '~pages/ForgotPassword';
import { WAITING_CONFIRM_ROUTE } from '~pages/WaitingConfirm';
import { PATIENT_MEDICAL_CARD_ROUTE } from '~pages/PatientMedicalCard';
import { APPOINTMENT_SCHEDULE_ROUTE } from '~pages/AppointmentSchedule';
import { APPOINTMENT_ROUTE } from '~pages/Appointment';
import {
  SALARY_CALC_LIST,
  REFERRAL_SOURCE_LIST_ROUTE,
  REFERRAL_ACCRUAL_LIST_ROUTE,
  REFERRAL_COMMISSION_LIST_ROUTE,
} from '~pages/bookkeeping';
import { INVOICE_LIST_ROUTE, DEPOSIT_LIST_ROUTE } from '~pages/cashRegister';
import {
  CHECKUP_TEMPLATE_EDITOR_ROUTE,
  CHECKUP_TEMPLATE_HEADER_LIST_ROUTE,
  CHECKUP_TEMPLATE_HEADER_ROUTE,
  CHECKUP_TEMPLATE_LIST_ROUTE,
} from '~pages/checkupTemplate';
import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware } from '~shared/lib';
import { I18nService, amplitudeService } from '~shared/lib';
// FSD
import { setComponentInRoutesByViewsFolder } from '@/utils/router.utils';

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      beforeEnter: [onlyUserIsAuthMiddleware, _redirectCurrentPageByUserRole],
    },
    LOGIN_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    WAITING_CONFIRM_ROUTE,
    SALARY_CALC_LIST,
    APPOINTMENT_SCHEDULE_ROUTE,
    REFERRAL_SOURCE_LIST_ROUTE,
    REFERRAL_ACCRUAL_LIST_ROUTE,
    REFERRAL_COMMISSION_LIST_ROUTE,
    PATIENT_MEDICAL_CARD_ROUTE,
    APPOINTMENT_ROUTE,
    CHECKUP_TEMPLATE_HEADER_LIST_ROUTE,
    CHECKUP_TEMPLATE_HEADER_ROUTE,
    CHECKUP_TEMPLATE_LIST_ROUTE,
    CHECKUP_TEMPLATE_EDITOR_ROUTE,
    INVOICE_LIST_ROUTE,
    DEPOSIT_LIST_ROUTE,
    NOT_FOUND_ROUTE,
    NO_ACCESS_ROUTE,

    ...setComponentInRoutesByViewsFolder({
      routes: [
        ..._getRoutes(),

        {
          path: '/:pathMatch(.*)*',
          redirect: ROUTE_PATH.notFound,
        },
      ],
    } as never),
  ],
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' };
  },
});

router.beforeEach((to, _from, next) => {
  if (to.meta.title) {
    document.title = I18nService.t(to.meta.title as string);
  } else {
    document.title = 'zordoc';
  }
  next();
});
router.beforeResolve((to, _from, next) => {
  if (to.path) {
    NProgress.start();
  }
  next();
});
router.afterEach((to, from, failure) => {
  if (!failure && to.path !== from.path) amplitudeService.logPageVisit(to);
  NProgress.done();
});

// Assets

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _redirectCurrentPageByUserRole(_to: any, _from: any, next: (arg: string) => any) {
  const { userRole } = useSessionStore();

  switch (userRole) {
    case 'manager':
    case 'registry':
    case 'doctor':
      return next(ROUTE_PATH.appointments.activeList.withQuery());
    case 'director':
      return next(ROUTE_PATH.statistic.dashboard);
    case 'laboratory':
      return next(ROUTE_PATH.laboratory.orders);
    case 'warehouse':
      return next(ROUTE_PATH.warehouse.nomenclature);
    case 'accountant':
      return next(ROUTE_PATH.bookkeeping.salaryCalcList);
    default:
      return next(ROUTE_PATH.root);
  }
}

function _getRoutes() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const routesModules = import.meta.glob<true, string, any>('@/router/**/*.routes.js', {
    eager: true,
  });
  return Object.keys(routesModules).reduce(
    (acc, key) => [...acc, ...routesModules[key].routes],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [] as any[]
  );
}
