<template>
  <ElDialog
    width="480px"
    class="warehouse-crud-modal"
    :title="isNewWarehouse ? $t('Warehouse.AddWarehouse') : $t('Warehouse.EditWarehouse')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm
      class="warehouse-crud-form"
      as="ElForm"
      :validation-schema="validationSchema"
      :initial-values="initialValues"
      v-slot="{ meta }"
      @submit="onSubmit">
      <FieldGroupWrapper>
        <FormTextField
          :label="$t('Warehouse.Warehouse')"
          field-name="title"
          required
          maxlength="255" />
        <FormTextField
          :label="$t('Warehouse.Address')"
          field-name="address"
          required
          maxlength="255" />
        <FormTextField
          :label="$t('Nomenclature.Notes')"
          field-name="description"
          type="textarea"
          :rows="4"
          maxlength="255" />
      </FieldGroupWrapper>
      <FormActionsWrapper>
        <ElButton
          v-if="isNewWarehouse"
          type="primary"
          native-type="submit"
          :disabled="!meta.dirty"
          :loading="loading.save"
          >{{ `+ ${$t('Base.Add')}` }}
        </ElButton>
        <ElButton
          v-if="!isNewWarehouse"
          type="primary"
          native-type="submit"
          :disabled="!meta.dirty"
          :loading="loading.save"
          >{{ $t('Base.Save') }}
        </ElButton>
        <ElPopconfirm
          v-if="!isNewWarehouse"
          :title="$t('Ui.PopConfirm.DefaultTitle')"
          @confirm="onDeleteButtonClick">
          <template #reference>
            <ElButton type="primary" plain :loading="loading.delete">
              {{ $t('Base.Delete') }}
            </ElButton>
          </template>
        </ElPopconfirm>
      </FormActionsWrapper>
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { SubmissionHandler, Form as VeeForm } from 'vee-validate';
import { object, string } from 'yup';
import { PropType, reactive, ref, computed } from 'vue';
import { ElNotification } from 'element-plus';
import axios from 'axios';

import { I18nService } from '~shared/lib';
import { Warehouse } from '@/models/warehouse/Warehouse.model';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';

export default {
  name: 'WarehouseCrudModal',
  components: { VeeForm, FormTextField, FieldGroupWrapper, FormActionsWrapper },
  emits: ['update:modelValue', 'action'],
  props: {
    formData: { type: Object as PropType<Warehouse>, default: () => ({ ...new Warehouse() }) },
  },
  setup(props, { emit }) {
    const store = useStore();
    // eslint-disable-next-line vue/no-setup-props-destructure
    const initialValues = ref({ ...props.formData });
    const isNewWarehouse = computed(() => !initialValues.value.id);

    const loading = reactive({
      save: false,
      delete: false,
    });

    const validationSchema = object({
      title: string().required(I18nService.t('Validation.RequiredField')),
      address: string().required(I18nService.t('Validation.RequiredField')),
      description: string().nullable(),
    });

    const closeModal = () => {
      emit('update:modelValue');
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit: SubmissionHandler<any> = async (values) => {
      loading.save = true;
      try {
        if (values.id) {
          const { data } = await Warehouse.updateItem(values.id, values);
          await store.dispatch('warehouses/editItem', data.data);
        } else {
          const { data } = await Warehouse.createItem(values);
          await store.dispatch('warehouses/createItem', data.data);
        }
      } catch (err) {
        ElNotification({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
        });
      } finally {
        closeModal();
        loading.save = false;
      }
    };

    const onDeleteButtonClick = async () => {
      loading.delete = true;
      try {
        if (typeof initialValues.value.id === 'number') {
          await Warehouse.deleteItem(initialValues.value.id);
          await store.dispatch('nomenclature/deleteItem', initialValues.value);
        }
      } catch (err) {
        ElNotification({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
        });
      } finally {
        closeModal();
        loading.delete = false;
      }
    };

    return {
      isNewWarehouse,
      initialValues,
      loading,
      validationSchema,
      onSubmit,
      onDeleteButtonClick,
    };
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/validation.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/ui.locales.json" />
