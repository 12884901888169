{
  "en": {
    "Ui": {
      "PopConfirm": {
        "DefaultTitle": "Confirm action",
        "DeleteTitle": "Confirm deletion"
      }
    }
  },
  "ru": {
    "Ui": {
      "PopConfirm": {
        "DefaultTitle": "Подтвердите действие",
        "DeleteTitle": "Подтвердите удаление"
      }
    }
  },
  "uz": {
    "Ui": {
      "PopConfirm": {
        "DefaultTitle": "Tasdiqlang",
        "DeleteTitle": "Ochirishni tasdiqlang"
      }
    }
  }
}