<template>
  <MainLayout content-class="v-doctor-content" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem :to="{ path: `${USERS_PATH_PREFIX}/doctors` }"
        >{{ $t('Base.Doctors') }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem>{{ doctor?.name ?? '' }}</ElBreadcrumbItem>
    </ElBreadcrumb>

    <PersonCard :person="doctor" :items="doctorItems" content-grid>
      <template #actions v-if="isUserDirecor">
        <ElButton type="primary" @click="onClickPersonModal"
          >{{ $t('Accounting.DetailedInformation') }}
        </ElButton>
      </template>
      <template #value>
        <ScheduleList :working-time="doctor?.user?.working_time" />
      </template>
    </PersonCard>

    <ElCard class="v-doctor-content__actions" shadow="never">
      <ElDatePicker
        class="v-doctor-content__item"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <div class="v-doctor-content__btns v-doctor-content__item" v-if="isUserManager">
        <router-link :to="deviceLink">
          <ElButton type="primary" plain>
            <template #icon>
              <MiIcon icon="DEVICE" />
            </template>
            {{ $t('User.Devices') }}
          </ElButton>
        </router-link>

        <!--        <router-link :to="salaryLink">-->
        <!--          <ElButton type="primary">{{ $t('User.Accounting') }}</ElButton>-->
        <!--        </router-link>-->
      </div>
    </ElCard>

    <DoctorProfileStatistic v-if="$route.name === 'DOCTOR'" :doctor-id="id" :period="date.value" />
    <RouterView />
  </MainLayout>
</template>

<script>
import axios from 'axios';
import { mapActions, useStore } from 'vuex';
import noop from 'lodash.noop';

import { MiIcon } from '~shared/ui';
import { Doctor } from '@/models/Doctor.model';
import { DATE_FORMAT } from '~shared/config';
import { useDatePeriod } from '~shared/lib';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { DOCTOR_ROUTE, USERS_PATH_PREFIX } from '@/router/doctors.routes';
import { insertRouteParams } from '@/utils/router.utils';
import { useGetDataByInterval } from '~shared/lib';
import { Device } from '@/models/Device.model';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { User } from '@/models/User.model';
import { MainLayout } from '~widgets/layouts';
import DoctorProfileStatistic from '@/components/doctors/DoctorProfileStatistic/index.vue';
import PersonCard from '@/components/PersonCard/index.vue';
import EmployeeModal from '@/components/accounting/modals/EmployeeModal/index.vue';
import ScheduleList from '@/components/common/ScheduleList/index.vue';
import { useSessionStore } from '~entities/session';

export default {
  name: 'VDoctor',
  components: {
    ScheduleList,
    MainLayout,
    PersonCard,
    DoctorProfileStatistic,
    MiIcon,
  },
  props: {
    id: [Number, String],
  },
  setup: () => ({
    getDevicesByInterval: noop,
    date: useDatePeriod(getMonthPeriod()),
    DATE_FORMAT,
    store: useStore(),
  }),
  data() {
    return {
      /** @type {Doctor} */
      doctor: {},
      DOCTOR_ROUTE,
      USERS_PATH_PREFIX,
      loading: { doctor: false, devices: false },
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    user() {
      return this.sessionStore.user;
    },

    isUserDirecor() {
      return this.user.role === User.enum.roles.Director;
    },

    isUserManager() {
      return (
        this.user.role === User.enum.roles.Director || this.user.role == User.enum.roles.Manager
      );
    },

    doctorItems() {
      return [
        {
          label: this.$t('Base.DoctorFullName2'),
          value: this.doctor?.name,
        },
        {
          label: this.$t('User.Birthdate'),
          value: this.doctor?.user?.birthdate,
        },
        {
          label: this.$t('User.Address'),
          value: this.doctor?.user?.address,
        },
        {
          label: this.$t('User.Schedule'),
          value: '...',
          custom: true,
        },
        {
          label: this.$t('User.Speciality'),
          value: this.doctor?.specialties
            ? `${this.doctor.specialties.map((item) => item.title).join(', ')}`
            : null,
        },
        {
          label: this.$t('User.SalaryAmount'),
          value: this.doctor?.user?.salary,
        },
        {
          label: this.$t('User.PhoneNumber'),
          value: this.doctor?.user?.phone,
        },
      ];
    },
    deviceLink() {
      return insertRouteParams({
        path: DOCTOR_ROUTE.childrenMap.DEVICES._fullPath,
        params: { id: this.id },
      });
    },
    // salaryLink() {
    //   return insertRouteParams({
    //     path: DOCTOR_ROUTE.childrenMap.SALARY._fullPath,
    //     params: { id: this.id },
    //   });
    // },
  },

  watch: {
    id: {
      async handler() {
        await this.getDoctor();
      },
      immediate: true,
    },
    queryWatchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          getData: this.getDevicesByInterval,
          resetPage: this.page.reset,
        });
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setDevicesData: 'devices/setData',
      updateDevice: 'devices/editItem',
    }),

    async updateDeviceStatus(payload) {
      try {
        const { data } = await Device.updateStatus(payload.id, payload.status);
        this.updateDevice(data.data);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
    },

    async getDoctor() {
      this.loading.doctor = true;
      try {
        const { data } = await Doctor.findOneById(this.id);
        this.doctor = data.data;
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
      this.loading.doctor = false;
    },

    async onClickPersonModal() {
      const action = await this.store.dispatch('modalAndDrawer/openModal', {
        component: EmployeeModal,
        payload: {
          userId: this.doctor.user.id,
        },
      });
      if (!(action instanceof GlobalModalClose)) {
        this.getDoctor();
      }
    },
  },
  mounted() {
    this.getDevicesByInterval = useGetDataByInterval(this.getDevices);
    this.getDevicesByInterval();
  },
};
</script>

<style lang="scss" src="./index.scss" />
