import cloneDeep from 'lodash.clonedeep';
import { createPinia, PiniaPluginContext } from 'pinia';

export function storeReset({ store }: PiniaPluginContext) {
  const initialState = cloneDeep(store.$state);
  store.$reset = () => store.$patch(cloneDeep(initialState));
}

export const store = createPinia();
store.use(storeReset);
