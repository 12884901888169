<template>
  <FormSearchSelect
    class="invoice-modal__service-search"
    field-name="subject_id"
    field-object-name="subject"
    :placeholder="$t('Appointments.SelectServices')"
    required
    clearable
    :disabled="readOnly || !selectedUser"
    :query="{ user_id: selectedUser }"
    :fetch-data="misB2BApi.servicesTemp.getGroupList" />
  <FormTextField
    field-name="subjectCount"
    class="invoice-modal__service-count"
    :placeholder="$t('Base.Quantity')"
    type="number"
    required
    :disabled="readOnly || !selectedUser"
    min="1" />
  <MiButton
    @click="handleAddService"
    type="primary"
    native-type="submit"
    :disabled="readOnly || !selectedUser">
    {{ $t('Base.Add') }}
  </MiButton>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import { number, object } from 'yup';

import { I18nService } from '~shared/lib';
import { FormSearchSelect, FormTextField } from '~shared/ui/form';
import { MiButton } from '~shared/ui';
import { misB2BApi, ServiceGroupDto } from '~shared/api';

defineProps<{
  readOnly: boolean;
  selectedUser: number | null;
}>();

const emit = defineEmits<{
  addService: [subject: ServiceGroupDto, subjectCount: number];
}>();

const validationSchema = object({
  subject_id: number()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value))
    .required(I18nService.t('Validation.RequiredField')),
  subjectCount: number()
    .nullable()
    .min(1)
    .transform((value) => (isNaN(value) ? null : value))
    .required(I18nService.t('Validation.RequiredField')),
});

const { handleSubmit, resetForm } = useForm<{
  subject: ServiceGroupDto;
  subject_id: number;
  subjectCount: number;
}>({
  initialValues: {
    subjectCount: 1,
  },
  validationSchema: validationSchema,
});

const handleAddService = handleSubmit(
  (values) => {
    emit('addService', values.subject, values.subjectCount);
    resetForm();
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss" />
