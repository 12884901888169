{
  "en": {
    "Transactions": {
      "PaymentType": "Payment type",
      "PaymentTypes": {
        "card": "Card",
        "cash": "Cash",
        "click": "Click",
        "deposit": "Deposit",
        "discount": "Discount",
        "online": "Transfer",
        "payme": "PayMe",
        "uncollected": ".",
        "uzum": "Uzum"
      },
      "Types": {
        "discount": "Discount",
        "pay_in": "Top-up",
        "pay_out": "Refund"
      }
    }
  },
  "ru": {
    "Transactions": {
      "PaymentType": "Тип оплаты",
      "PaymentTypes": {
        "card": "Карта",
        "cash": "Наличные",
        "click": "Click",
        "deposit": "Депозит",
        "discount": "Скидка",
        "online": "Перевод",
        "payme": "PayMe",
        "uncollected": ".",
        "uzum": "Uzum"
      },
      "Types": {
        "discount": "Скидка",
        "pay_in": "Пополнение",
        "pay_out": "Возврат"
      }
    }
  },
  "uz": {
    "PaymentType": "To'lov turi",
    "Transactions": {
      "PaymentTypes": {
        "card": "Karta",
        "cash": "Naqd",
        "click": "Click",
        "deposit": "Depozit",
        "discount": "Chegirma",
        "online": "Otkazma",
        "payme": "PayMe",
        "uncollected": ".",
        "uzum": "Uzum"
      },
      "Types": {
        "discount": "Chegirma",
        "pay_in": "To‘ldirish",
        "pay_out": "Qaytarish"
      }
    }
  }
}