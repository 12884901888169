import { AppointmentFullResourceTemp, AppointmentSourceShort } from '../types/appointments';

import { AppointmentStatusEnum, apiService } from '~shared/api';
import { MetaDto } from '@/types/api';
import { QueryParams } from '~shared/types';
import { joinQueryParams } from '~shared/lib';

const urlPath = '/appointments';

// TODO: типизация
export const getById = async (id: number | string) => {
  const response = await apiService.api.instance.get<{
    data: AppointmentFullResourceTemp;
    meta: MetaDto;
  }>(`${urlPath}/${id}`);

  return {
    ...response.data,
  };
};

// TODO: типизация
export const updateStatus = async (id: number | string, status: AppointmentStatusEnum) => {
  const response = await apiService.api.instance.put(`${urlPath}/${id}/${status}`);

  return {
    ...response.data,
  };
};

export const getSourceList = async (query: QueryParams = {}) => {
  const response = await apiService.api.instance.get<{
    data: AppointmentSourceShort[];
    meta: MetaDto;
  }>(
    joinQueryParams({
      url: `${urlPath}/source`,
      query: query,
    })
  );

  return {
    ...response.data,
  };
};
