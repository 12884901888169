<template>
  <ElDialog
    width="515px"
    class="single-outcome-modal"
    :title="$t('Outcome.Add')"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)">
    <VeeForm
      class="single-outcome-form"
      as="ElForm"
      :validation-schema="validationSchema"
      :initial-values="initialValues"
      v-slot="{ meta }"
      @submit="onSubmit">
      <FieldGroupWrapper>
        <FormTextField
          :label="$t('Outcome.Name')"
          field-name="title"
          :placeholder="$t('Outcome.NamePlaceholder')"
          required />
      </FieldGroupWrapper>
      <FormActionsWrapper>
        <ElButton type="primary" native-type="submit" :disabled="!meta.dirty"
          >{{ $t('Outcome.AddType') }}
        </ElButton>
      </FormActionsWrapper>
    </VeeForm>
  </ElDialog>
</template>

<script lang="ts">
export default {
  name: 'OutcomeTypeModal',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script setup lang="ts">
import { ref } from 'vue';
import { SubmissionHandler, Form as VeeForm } from 'vee-validate';
import { object, string } from 'yup';

import { I18nService } from '~shared/lib';
import { Expense } from '@/models/accounting/Expense.model';
import { AnyObject } from '@/types/common';
import FieldGroupWrapper from '@/components/common/form/FieldGroupWrapper/index.vue';
import FormTextField from '@/components/common/form/ui/FormTextField/index.vue';
import FormActionsWrapper from '@/components/common/form/FormActionsWrapper/index.vue';

const emit = defineEmits(['update:modelValue', 'addOutcome']);

const closeModal = () => {
  emit('update:modelValue');
};

const validationSchema = object({
  title: string().required(I18nService.t('Validation.RequiredField')),
});

type initialValuesType = {
  title: string;
};
const initialValues = ref<initialValuesType>({
  title: '',
});
const onSubmit: SubmissionHandler = async (values: AnyObject) => {
  const result = await Expense.createExpenseType({
    title: values.title,
  });

  if (result) {
    emit('addOutcome', result.data);
  }
  closeModal();
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/accounting.locales.json" />
