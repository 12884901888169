<template>
  <MiSelect v-bind="$props" v-model="value">
    <ElOption
      v-for="option in options"
      :key="option.value"
      :label="option.label"
      :value="option.value">
      <slot v-if="$slots.option" :item="option" name="option" />
    </ElOption>
  </MiSelect>
</template>

<script setup lang="ts" generic="T">
import { computed } from 'vue';
import { ISelectProps } from 'element-plus';

import { MiSelect } from '~shared/ui';

type Props = {
  options: { label: string; value: string | number; disabled?: boolean }[];
} & Partial<ISelectProps>;

const props = withDefaults(defineProps<Props>(), {
  reserveKeyword: true,
  teleported: true,
  persistent: true,
  validationEvent: true,
});

const emit = defineEmits<{
  'update:modelValue': [e: Props['modelValue']];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val: Props['modelValue']) {
    emit('update:modelValue', val);
  },
});

defineSlots<{
  option(props: { item: Props['options'][number] }): never;
}>();
</script>

<style lang="scss" src="./index.scss" />
