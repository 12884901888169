import { RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { onlyUserNotAuthMiddleware, destroyJivoChat } from '~shared/lib';

const AUTH_ROUTE_NAME = 'AuthLogin';
const destroyChat = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.name === AUTH_ROUTE_NAME) {
    destroyJivoChat();
  }
  next();
};

export const LOGIN_ROUTE: RouteRecordRaw = {
  name: AUTH_ROUTE_NAME,
  path: ROUTE_PATH.auth.login,
  component: () => import('./ui'),
  beforeEnter: [onlyUserNotAuthMiddleware, destroyChat],
  meta: {
    title: 'Base.Authorization',
  },
};
