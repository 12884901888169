<template>
  <ElTag :type="typeByStatus[status]" effect="dark" round>
    {{ $t(`Laboratory.Biomaterial.Statuses.${status}`) }}
  </ElTag>
</template>

<script>
import { Biomaterial } from '@/models/laboratory/Biomaterial.model';

export default {
  name: 'BiomaterialStatusTag',
  props: {
    status: String,
  },

  computed: {
    typeByStatus() {
      return {
        [Biomaterial.enum.statuses.NotAssembled]: 'info',
        [Biomaterial.enum.statuses.Assembled]: '',
        [Biomaterial.enum.statuses.NotConfirmed]: 'warning',
        [Biomaterial.enum.statuses.Confirmed]: 'success',
        [Biomaterial.enum.statuses.Canceled]: 'danger',
      };
    },
  },
};
</script>

<i18n src="@/locales/laboratory.locales.json" />
