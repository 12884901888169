<template>
  <MainLayout> Settings </MainLayout>
</template>

<script>
import { MainLayout } from '~widgets/layouts';

export default {
  name: 'VSettings',
  components: { MainLayout },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
