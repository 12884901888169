<template>
  <MiCard class="invoice-modal__total-amount-card" shadow="never">
    <MiSimpleTable :data="summaryAmount" :loading="false">
      <ElTableColumn align="center" prop="discounted_amount" :label="$t('CashRegister.Paid')">
        <template #default="{ row }">
          {{ thousandsSeparator(row.discounted_amount) }} {{ $t('Base.Sum') }}
        </template>
      </ElTableColumn>
      <ElTableColumn align="center" prop="left_pay" :label="$t('CashRegister.Remainder')">
        <template #default="{ row }">
          {{ thousandsSeparator(row.left_pay) }} {{ $t('Base.Sum') }}
        </template>
      </ElTableColumn>
    </MiSimpleTable>
  </MiCard>
</template>

<script setup lang="ts">
import { useFieldValue } from 'vee-validate';
import { computed } from 'vue';

import { InvoiceFullDto } from '~shared/api';
import { thousandsSeparator } from '~shared/lib';
import { MiSimpleTable, MiCard } from '~shared/ui/';

const currentInvoice = computed(() => {
  return useFieldValue<InvoiceFullDto>('currentInvoice').value;
});

const summaryAmount = computed(() => {
  return [
    {
      left_pay: currentInvoice.value.left_pay,
      discounted_amount:
        (currentInvoice.value.discounted_amount || 0) - (currentInvoice.value.left_pay || 0),
    },
  ];
});
</script>

<style src="./index.scss" />
