import { ElNotification } from 'element-plus';

import {
  HeaderTemplateDto,
  CreateHeaderTemplatePayload,
  RemoveHeaderTemplatePayload,
  GetHeaderTemplateByIdPayload,
  UpdateHeaderTemplatePayload,
  MetaDto,
} from '../types';

import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'header-templates';

export const getList = async (query: QueryParams = {}) => {
  try {
    const { data } = await apiService.api.instance.get<{
      data: HeaderTemplateDto[];
      meta: MetaDto;
    }>(
      joinQueryParams({
        url: urlPath,
        query,
      })
    );
    return data;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getById = async ({ id }: GetHeaderTemplateByIdPayload) => {
  try {
    if (!id) {
      throw new Error('HeaderTemplate id is not provided');
    }

    const { data } = await apiService.api.instance.get<{ data: HeaderTemplateDto }>(
      `${urlPath}/${id}`
    );
    return data;
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const create = async (payload: CreateHeaderTemplatePayload) => {
  try {
    return await apiService.api.instance.post<{ data: HeaderTemplateDto }>(
      `${urlPath}/create`,
      payload
    );
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
    throw err;
  }
};

export const update = async (payload: UpdateHeaderTemplatePayload) => {
  try {
    if (!payload.id) {
      throw new Error('HeaderTemplate id is not provided');
    }

    return await apiService.api.instance.put<{ data: HeaderTemplateDto }>(
      `${urlPath}/${payload.id}/update`,
      payload
    );
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
    throw err;
  }
};

export const remove = async ({ id }: RemoveHeaderTemplatePayload) => {
  try {
    if (!id) {
      throw new Error('HeaderTemplate id is not provided');
    }
    return await apiService.api.instance.delete(`${urlPath}/${id}/delete`);
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
    throw err;
  }
};
