import sortBy from 'lodash.sortby';
import isEqual from 'lodash.isequal';

import { PER_PAGE, SEARCH } from '~shared/config';

/**
 * @param {string} path vue-router Route.path
 * @param {object} params params for replace
 */
export function insertRouteParams({ path, params }) {
  return path.replace(/:[a-zA-Z]*/gim, (str) => {
    const paramKey = str.split(':')[1];
    return params[paramKey];
  });
}

/**
 * Подставляет компоненты в объект опций роута
 * Создаёт children роуты исходя из childrenMap
 *
 * @param {Object} params
 * @param {Array<VueRouteOptions>} params.routes
 * @return {Array<VueRouteOtions>}
 */
/**
 * Подставляет компоненты в объект опций роута
 * Создаёт children роуты исходя из childrenMap
 *
 * @param {Array<VueRouteOptions>} routes
 * @return {Array<VueRouteOtions>}
 */
export function setComponentInRoutesByViewsFolder({ routes }) {
  const views = import.meta.glob('@/views/**/*.vue', { eager: true });
  const components = {};
  for (const key in views) {
    const component = views[key].default;
    components[component.name] = component;
  }

  const insertComponentsInRoutes = (routes) => {
    return routes.map((route) => {
      const component = components[route.component];
      const children = insertComponentsInRoutes(
        createChildrenRoutesByChildrenMap(route.childrenMap || {})
      );

      return {
        ...route,
        component,
        children,
      };
    });
  };

  return insertComponentsInRoutes(routes);
}

/**
 * Преобразует объект с именнованными роутами в обычный массив, который необходим для vue-router
 *
 * @param {object} childrenMap
 * @return {Array<object>}
 */
export function createChildrenRoutesByChildrenMap(childrenMap) {
  return Object.keys(childrenMap).map((key) => childrenMap[key]);
}

// TODO: придумать другое название и вынести - так как пригодилось не только при сравнении query
/**
 * Функция для сравненния двух query объектов, выполнения необходимых действий сброса некоторых ключей и дальнейшего получения данных
 *
 * @param {object} params
 * @param {object|array} params.query массив или объект с примитвными типами
 * @param {object|array} params.oldQuery
 * @param {Array<string>} [params.fieldsForResetPage = [per_page, search]] ключи в query, при изменение которых надо сбросить страницу
 * @param {function} [params.resetPage] функция для сброса текущей страницы при необходимости
 * @param {function} params.getData функция для получения данных
 * @return {number|undefined} может вернуть счётчик от вызова setTimeout
 */
export function compareQueriesThenLoadData({
  query,
  oldQuery,
  fieldsForResetPage = [],
  resetPage,
  getData,
}) {
  const defaultFieldsForReset = [PER_PAGE, SEARCH];
  const _fieldsForResetPage = [...defaultFieldsForReset, ...fieldsForResetPage];
  const queryKeys = Object.keys(query);

  const checkIsEqualArrays = (arr1, arr2) => {
    return isEqual(sortBy(arr1), sortBy(arr2));
  };

  for (let i = 0; i < queryKeys.length; i++) {
    if (
      query &&
      oldQuery &&
      Array.isArray(query[_fieldsForResetPage[i]]) &&
      Array.isArray(oldQuery[_fieldsForResetPage[i]]) &&
      !checkIsEqualArrays(query[_fieldsForResetPage[i]], oldQuery[_fieldsForResetPage[i]])
    ) {
      if (resetPage) resetPage();
      return setTimeout(() => getData());
    }

    if (
      query &&
      oldQuery &&
      (!Array.isArray(query[_fieldsForResetPage[i]]) ||
        !Array.isArray(oldQuery[_fieldsForResetPage[i]])) &&
      query[_fieldsForResetPage[i]] !== oldQuery[_fieldsForResetPage[i]]
    ) {
      if (resetPage) resetPage();
      return setTimeout(() => getData());
    }

    if (
      !oldQuery ||
      ((!Array.isArray(query[queryKeys[i]]) || !Array.isArray(oldQuery[queryKeys[i]])) &&
        query[queryKeys[i]] !== oldQuery[queryKeys[i]])
    ) {
      return getData();
    }
  }
}
