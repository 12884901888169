import { GetServiceGroupListResponseData } from '~shared/api';
import { apiService } from '~shared/api';
import { joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';

const urlPath = '/services';

export const getGroupList = async (query: QueryParams = {}) => {
  const response = await apiService.api.instance.get<GetServiceGroupListResponseData>(
    joinQueryParams({
      url: `${urlPath}/group/list`,
      query,
    })
  );
  return {
    ...response.data,
  };
};
