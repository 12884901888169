<template>
  <MiFormItem
    :class="[
      'form-info-field',
      {
        'form-info-field_in-column': props.inColumn,
        'form-info-field_only-value': props.onlyValue && props.disabled,
      },
    ]"
    :error="errorMessage"
    :label="props.label"
    :required="props.required">
    <template v-if="isLabelShow" #label>
      <slot name="label">
        <div class="form-info-field__label-text">{{ props.label }}</div>
        <MiTooltip v-if="!!props.description" :description="props.description" />
        <div v-if="isLabelActionsShow" class="form-info-field__label-actions">
          <slot name="label-actions" />
        </div>
      </slot>
    </template>
    <div class="form-info-field-info">
      <slot>{{ formattedValue }}</slot>
    </div>
  </MiFormItem>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { computed, useSlots } from 'vue';

import { MiTooltip, MiFormItem } from '~shared/ui';
import { I18nService } from '~shared/lib';
import { formatPrice } from '@/utils/price.util';

const props = withDefaults(
  defineProps<{
    inColumn?: boolean;
    onlyValue?: boolean;
    disabled?: boolean;
    fieldName: string;
    label?: string;
    required?: boolean;
    pattern?: string;
    placeholder?: string;
    description?: string;
    format?: 'price' | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatter?: (value?: any) => string | undefined;
  }>(),
  {
    inColumn: true,
    onlyValue: false,
    disabled: false,
    label: '',
    required: false,
    pattern: '',
    placeholder: '',
    description: '',
    format: null,
  }
);

// eslint-disable-next-line vue/no-setup-props-destructure
const { value, errorMessage } = useField<string | number | undefined>(props.fieldName);

const formattedValue = computed(() => {
  if (props.formatter) {
    return props.formatter(value.value) ?? '';
  }

  if (props.format === 'price') {
    return `${formatPrice({ price: value.value })} ${I18nService.t('Base.Sum')}`;
  }

  return value.value;
});

const slots = useSlots();
const isLabelShow = computed(() => !!props.label);
const isLabelActionsShow = computed(() => !!slots['label-actions'] && props.inColumn);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
