import { Patient } from '@/models/Patient.model';
import PatientsSearchSelect from '@/components/patients/PatientsSearchSelect/index.vue';
import CreateOrEditPatient from '@/components/patients/CreateOrEditPatientModal/CreateOrEditPatient/index.vue';
import { Appointment } from '@/models/appointment/Appointment.model';

/**
 * Компонент для поиска, отображения и создания пациента.
 * Логика создания пациента в компоненте CreateOrEditPatient
 * Для создания - снаружи нужно вызывать методы `validate`, `isNewPatientFlow`, 'createPatient`
 */
export default {
  name: 'PatientsSearchSelectDataBlock',
  components: { PatientsSearchSelect, CreateOrEditPatient },
  emits: [
    'update:modelValue',
    'select',
    'reference:update',
    'create',
    'foundPatient',
    'reattachPatient',
    'getItems',
  ],
  props: {
    modelValue: Number,
    defaultItem: [Patient, Object],
    required: Boolean,
    disabled: Boolean,
    searchQuery: Object,
    createPermission: Boolean,
    hideSelect: Boolean,
    appointment: {
      type: [Appointment, Object],
    },
  },
  data() {
    return {
      patient: null,

      patientPart: {
        show: false,
        nameOrPhone: null,
        newPatient: null,
      },
    };
  },
  computed: {
    query() {
      return {
        query_field: ['name', 'phone'],
        ...(this.searchQuery ?? {}),
      };
    },
  },

  watch: {
    defaultItem: {
      handler(value) {
        this.patient = value ?? new Patient();
      },
      immediate: true,
    },
  },

  methods: {
    selectHandler(data) {
      this.patient = data;
      this.$emit('select', data);
    },

    validate() {
      return this.$refs.create_or_edit_patient.customValidate();
    },
    isNewPatientFlow() {
      return this.patientPart.show;
    },
    async createPatient() {
      return this.$refs.create_or_edit_patient.createPatient();
    },
    insertPatient(action) {
      this.$emit('update:modelValue', action.data.patient.id);
      this.patientPart.show = false;
      this.selectHandler(action.data.patient);
    },

    startCreatePatientFlow(query) {
      this.blur();
      this.patientPart.show = true;
      this.$emit('create');
      this.$nextTick(() => (this.patientPart.nameOrPhone = query));
    },

    focus() {
      setTimeout(() => this.$refs.select.focus());
    },
    blur() {
      setTimeout(() => this.$refs.select.blur());
    },
  },
};
