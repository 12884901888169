export class AppointmentServiceGroupWithCount {
  /**
   * @param {object} [payload]
   * @param {number|string} payload.uuid
   * @param {number} payload.group_service_id
   * @param {ServiceGroup|object} payload.group_service
   * @param {number} payload.count
   */
  constructor(payload) {
    this._id = payload?.uuid ?? Math.random();
    this.group_service_id = payload?.group_service_id ?? null;
    this.group_service = payload?.group_service ?? null;
    this.count = payload?.count ?? 1;
  }
}
