import { ElNotification } from 'element-plus';

import {
  GetDepositListByUserResponseData,
  GetDepositListResponseData,
  InvoiceFullDto,
} from '~shared/api';
import { getApiErrorMessage, joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';

const urlPath = 'deposits';

export const getList = async (query: QueryParams = {}) => {
  try {
    const response = await apiService.api.instance.get<GetDepositListResponseData>(
      joinQueryParams({
        url: `${urlPath}/list`,
        query,
      })
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const changeAmount = async (userId: number | string, amount: number) => {
  try {
    const response = await apiService.api.instance.post<{ data: InvoiceFullDto }>(
      `${urlPath}/${userId}/change`,
      {
        amount,
      }
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};

export const getListByUserId = async (id: string | number) => {
  try {
    const response = await apiService.api.instance.get<GetDepositListByUserResponseData>(
      `${urlPath}/details/${id}`
    );
    return {
      ...response.data,
    };
  } catch (err) {
    ElNotification({
      type: 'error',
      title: getApiErrorMessage(err),
    });
  }
};
