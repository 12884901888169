<template>
  <ElConfigProvider size="large" :locale="lang">
    <RouterView />
    <GlobalModalAndDrawer />
  </ElConfigProvider>

  <MiSvgSprite />
</template>

<script lang="ts" setup>
import { onBeforeMount, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { ElConfigProvider } from 'element-plus';
import { Language } from 'element-plus/lib/locale';
import { RouterView } from 'vue-router';
import { storeToRefs } from 'pinia';
import ru from 'element-plus/lib/locale/lang/ru';
import en from 'element-plus/lib/locale/lang/en';
import uz from 'element-plus/lib/locale/lang/uz-uz';
import { Locale } from 'vue-i18n';

import { redirectMe, useSessionStore } from '~entities/session';
import { User } from '@/models/User.model';
import { InspectionCardTemplate } from '@/models/InspectionCardTemplate.model';
import { GlobalModalAndDrawer } from '~widgets/GlobalModalAndDrawer';
import { API_LOGOUT_EMIT } from '~shared/config';
import { MiSvgSprite } from '~shared/ui';
import {
  EmitterService,
  DeviceService,
  I18nService,
  authStorageService,
  amplitudeService,
} from '~shared/lib';

const storeVuex = useStore();
const sessionStore = useSessionStore();
const { userRole } = storeToRefs(sessionStore);
const { logout, checkAndSetUserAndTokenFromClientStorage, setUser } = sessionStore;

const addGlobalEventListeners = () => {
  EmitterService.on(API_LOGOUT_EMIT, logout);
};

const langByLocale: Record<Locale, Language> = {
  ru,
  en,
  uz,
};

const lang = computed(() => {
  const locale = I18nService.getLocale();
  return langByLocale[locale];
});

const getMe = async () => {
  const accessToken = authStorageService.getAccessToken();

  if (!accessToken) {
    return;
  }

  const response = await User.me();

  if (response?.data?.data?.clinic?.redirect_to_dmed) {
    redirectMe();
  }

  if (response?.user) {
    setUser(response.user);
  }
};

const getDoctorTemplates = async () => {
  const response = await InspectionCardTemplate.getItems({ per_page: 5 });

  if (response?.data) {
    storeVuex.dispatch('templates/setData', {
      items: response.data.data,
      total: response.meta.total,
    });
  }
};

onBeforeMount(async () => {
  // Проверяем наличие deviceId в localStorage, записываем в headers, генерируем новый если отсутствует, сохраняем в localStorage
  DeviceService.checkAndSetDeviceId();

  I18nService.setLocaleFromStorage();
  checkAndSetUserAndTokenFromClientStorage();

  // TODO: добавить проверку на существование сохранённого пользователя
  await getMe();

  addGlobalEventListeners();

  amplitudeService.init(authStorageService.getUser()?.id);
});

onMounted(() => {
  if (userRole.value === 'doctor') {
    getDoctorTemplates();
  }
});
</script>

<style src="./index.scss" lang="scss" />
