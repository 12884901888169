<template>
  <MainLayout content-class="v-hospital" :loading="loading.hospital">
    <template v-if="!!hospital?.id">
      <!--  Patient  -->
      <PatientCardRow :patient="hospital?.user" :items="patientItems">
        <template #actions>
          <RouterLink :to="ambulatoryCardPageLink">
            <ElButton type="primary">
              {{ $t('Base.AmbulatoryCard') }}
            </ElButton>
          </RouterLink>
        </template>
      </PatientCardRow>

      <!--  Hospital Card  -->
      <LayoutContentHeader :title="$t('Hospital.HospitalTreatment')">
        <template #append> <HospitalStatusTag :status="hospital.status" /> </template>

        <template #actions>
          <ElButton
            v-show="actionsVisible.cancel"
            type="danger"
            plain
            :loading="loading.cancel"
            @click="cancel">
            {{ $t('Base.Cancel') }}
          </ElButton>

          <ElButton v-show="actionsVisible.edit" type="primary" plain @click="edit">
            {{ $t('Base.Edit') }}
          </ElButton>

          <ElButton
            v-show="actionsVisible.close"
            type="primary"
            :loading="loading.close"
            @click="close">
            {{ $t('Base.Close') }}
          </ElButton>
        </template>
      </LayoutContentHeader>
      <HospitalCard v-model:hospital="hospital" />

      <!--  Invoices  -->
      <LayoutContentHeader :title="$t('Base.Invoices')">
        <template #actions>
          <ElButton
            v-show="actionsVisible.bill"
            type="primary"
            plain
            :loading="loading.invoice"
            @click="bill">
            {{ $t('Invoices.Bill') }}
          </ElButton>
        </template>
      </LayoutContentHeader>
      <InvoicesTable
        :items="hospital.invoices"
        :page="1"
        :per-page="hospital.invoice_ids.length || 1"
        :total="hospital.invoice_ids.length" />

      <!--  Treatments  -->
      <LayoutContentHeader :title="$t('Base.TreatmentJournal')">
        <template #actions>
          <ElButton v-show="actionsVisible.treatment" type="primary" plain @click="addTreatment">
            {{ $t('Base.Add') }}
          </ElButton>
        </template>
      </LayoutContentHeader>
      <TreatmentsTable
        :items="hospital.treatments"
        :page="1"
        :per-page="hospital.treatment_ids.length || 1"
        :total="hospital.treatment_ids.length"
        @item:update="updateTreatment" />
    </template>
  </MainLayout>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss"></style>
