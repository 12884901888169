<template>
  <ElForm
    class="recommendation-form"
    label-position="top"
    v-loading="loading.save"
    @submit.prevent="submitHandler">
    <ElCard
      class="recommendation-form__content"
      :header="$t('Recommendations.AssignToPatient')"
      shadow="never">
      <ServicesByCategoryList />

      <div class="recommendation-form__assigned-services assigned-services">
        <div class="assigned-services__header">{{ $t('Recommendations.Appointed') }}</div>
        <div class="assigned-services__items">
          <div class="assigned-services__item" v-for="item in appointedServices" :key="item.id">
            <ElCheckbox
              :label="item.title"
              v-model="item.checked"
              size="large"
              @change="(checked) => onChangeAppointedServices(item, checked)" />
          </div>
        </div>
      </div>
    </ElCard>
    <div class="recommendation-form__actions">
      <ElButton type="primary" native-type="submit" :disabled="isSaveDisabled">
        {{ $t('Recommendations.AssignToPatient') }}</ElButton
      >
    </div>
  </ElForm>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import isEqual from 'lodash.isequal';

// eslint-disable-next-line import/no-internal-modules
import ServicesByCategoryList from './ServicesByCategoryList/index.vue';

import { Appointment } from '@/models/appointment/Appointment.model';
import { ServiceGroup } from '@/models/ServiceGroup.model.js';
import { ROUTE_PATH } from '~shared/config';

export default {
  name: 'RecommendationForm',
  components: { ServicesByCategoryList },
  props: {
    appointment: Object,
  },
  data() {
    return {
      loading: { save: false },
      recommendationId: this.appointment.recommendation?.id ?? null,
      prevAppointedServices: this.appointment.recommendation
        ? this.appointment.recommendation.group_services.map((item) => ({
            ...item,
            checked: item.checked ?? true,
          }))
        : [],
    };
  },
  computed: {
    ...mapState({
      appointedServices: (state) => state.recommendations.appointedServices,
    }),

    isSaveDisabled() {
      const ids = this.appointedServices.map((item) => item.id).sort();
      const prevIds = this.prevAppointedServices.map((item) => item.id).sort();
      return this.appointedServices.length === 0 || isEqual(ids, prevIds);
    },
  },
  methods: {
    ...mapActions({
      setAppointedServices: 'recommendations/setAppointedServices',
      updateAppointedServices: 'recommendations/updateAppointedServices',
    }),

    onChangeAppointedServices(item, checked) {
      this.updateAppointedServices([{ ...item, checked }]);
    },

    async submitHandler() {
      this.loading.save = true;

      try {
        const { data } = this.recommendationId
          ? await Appointment.recommendationUpdate(this.recommendationId, {
              group_service_ids: this.appointedServices.map((item) => item.id),
            })
          : await Appointment.recommendationCreate({
              appointment_id: this.appointment.id,
              user_id: this.appointment.patient_id,
              group_service_ids: this.appointedServices.map((item) => item.id),
            });

        this.recommendationId = data.data.id;
        this.setAppointedServices(data.data.group_services);

        this.$router.push(ROUTE_PATH.appointments.appointment.byId(this.appointment.id));
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      } finally {
        this.loading.save = false;
      }
    },
  },
  setup: () => ({
    ServiceGroup,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
