{
  "en": {
    "GenKey": "Generate key"
  },
  "ru": {
    "GenKey": "Сгенерировать ключ"
  },
  "uz": {
    "GenKey": "Kalitni yarating"
  }
}