const DEVICE_ID_KEY = 'device_id';

export class DeviceService {
  static setDeviceId(payload: string) {
    localStorage.setItem(DEVICE_ID_KEY, payload);
  }

  static getDeviceId() {
    return localStorage.getItem(DEVICE_ID_KEY);
  }

  static removeDeviceId() {
    localStorage.removeItem(DEVICE_ID_KEY);
  }

  static checkAndSetDeviceId() {
    let deviceId = this.getDeviceId();
    if (!deviceId) {
      deviceId = self.crypto.randomUUID();
      this.setDeviceId(deviceId);
    }
  }
}
