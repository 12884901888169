{
  "en": {
    "Views": {
      "Appointments": {
        "Title": "Appointments"
      },
      "Dashboard": {
        "Title": "Dashboard"
      },
      "LaboratoryOrders": {
        "Title": "Orders"
      },
      "Queue": {
        "Title": "Queue"
      },
      "Services": {
        "Title": "Services"
      },
      "StatisticDashboard": {
        "Title": "Dashboard"
      }
    }
  },
  "ru": {
    "Views": {
      "Appointments": {
        "Title": "Таблица записей"
      },
      "Dashboard": {
        "Title": "Календарь приемов"
      },
      "LaboratoryOrders": {
        "Title": "Заявки"
      },
      "Queue": {
        "Title": "Таблица записей"
      },
      "Services": {
        "Title": "Услуги"
      },
      "StatisticDashboard": {
        "Title": "Дашборд"
      }
    }
  },
  "uz": {
    "Views": {
      "Appointments": {
        "Title": "Qabullar jadvali"
      },
      "Dashboard": {
        "Title": "Qabullar taqvimi"
      },
      "LaboratoryOrders": {
        "Title": "So'rovlar"
      },
      "Queue": {
        "Title": "Qabullar jadvali"
      },
      "Services": {
        "Title": "Xizmatlar"
      },
      "StatisticDashboard": {
        "Title": "Dashboard"
      }
    }
  }
}