import { ACCESS_TOKEN_KEY, DEVICE_ID_KEY, REFRESH_TOKEN_KEY } from '~shared/config';

export const redirectMe = () => {
  const redirectURL = new URL(import.meta.env.VITE_REDIRECT_URL);

  redirectURL.searchParams.append('accessToken', localStorage.getItem(ACCESS_TOKEN_KEY) ?? '');
  redirectURL.searchParams.append('refreshToken', localStorage.getItem(REFRESH_TOKEN_KEY) ?? '');
  redirectURL.searchParams.append('deviceId', localStorage.getItem(DEVICE_ID_KEY) ?? '');

  window.location.replace(redirectURL);
};
