<template>
  <MiConfirmModal
    class="payment-confirm-modal"
    v-model="isModalVisible"
    :modal-title="$t('Common.Confirmation')"
    :btn-title="$t('Common.Confirm')"
    footer-align="right">
    <div class="payment-confirm-modal__body">
      <p class="payment-confirm-modal__payment-info">
        {{ $t('Bookkeeping.PaymentConfirmation') }}
      </p>
      <span class="payment-confirm-modal__amount"
        >{{ amount }} {{ $t('Common.Sum').toLocaleLowerCase() }}</span
      >
    </div>
  </MiConfirmModal>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { MiConfirmModal } from '~shared/ui';

const props = defineProps<{
  amount: string | number;
  modelValue: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [e: boolean];
}>();

const isModalVisible = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});
</script>

<style src="./index.scss" />
