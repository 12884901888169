<template>
  <ElTag :type="typeByStatus[status]" effect="dark" round>
    {{ $t(`Laboratory.Order.Statuses.${status}`) }}
  </ElTag>
</template>

<script>
import { Order } from '@/models/laboratory/Order.model';

export default {
  name: 'OrderStatusTag',
  props: {
    status: String,
  },

  computed: {
    typeByStatus() {
      return {
        [Order.enum.statuses.Created]: '',
        [Order.enum.statuses.AwaitingAssemble]: 'info',
        [Order.enum.statuses.NotConfirm]: 'warning',
        [Order.enum.statuses.Ready]: 'success',
        [Order.enum.statuses.Canceled]: 'danger',
      };
    },
  },
};
</script>

<i18n src="@/locales/laboratory.locales.json" />
