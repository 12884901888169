<template>
  <MiSelect
    ref="refMiSearchSelect"
    class="mi-search-select__select"
    popper-class="mi-search-select__popper"
    v-model="value"
    filterable
    remote-show-suffix
    remote
    reserve-keyword
    :validate-event="false"
    :remote-method="debouncedGetItems"
    :loading="loading"
    :placeholder="placeholder || $t('Base.PleaseInput')"
    :no-data-text="$t('Base.NoData')"
    :disabled="disabled"
    :multiple="multiple"
    @change="selectHandler"
    @visible-change="handleSelectVisibilityChange">
    <template #prefix>
      <MiIcon icon="SEARCH" class="mi-search-select__icon" />
    </template>

    <ElOption
      class="mi-search-select__option"
      v-for="item in items"
      :key="item[valueKey]"
      :label="item[labelKey]"
      :value="item[valueKey]">
      <slot name="option" :item="item" />
    </ElOption>

    <template #empty>
      <div class="mi-search-select-empty">
        <div class="mi-search-select-empty__text">
          {{ loading ? 'Loading' : $t('Base.NoData') }}
        </div>

        <MiButton
          v-if="showCreateOption"
          v-show="!loading"
          class="form-search-select-empty__create"
          type="primary"
          size="default"
          @click="createItem">
          <template #icon>
            <MiIcon icon="PLUS" />
          </template>
          {{ $t('Base.Create') }}
        </MiButton>
      </div>
    </template>
  </MiSelect>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { cloneDeep, debounce } from 'lodash';

import { AnyObject, QueryParams } from '~shared/types';
import { MiIcon, MiSelect, MiButton } from '~shared/ui';

type Props = {
  fetchData?: (query?: QueryParams) => Promise<{ data?: AnyObject[] } | undefined>;
  modelValue: string | string[] | number | number[] | undefined;
  modelObjectValue: AnyObject | AnyObject[] | undefined | null;
  showCreateOption?: boolean;
  labelKey?: string;
  valueKey?: string;
  placeholder?: string;
  query?: QueryParams;
  disabled?: boolean;
  multiple?: boolean;
  abortableFetchData?: () => [
    (payload?: QueryParams) => Promise<{ data: AnyObject[] } | undefined>,
    () => void,
  ];
};

const props = withDefaults(defineProps<Props>(), {
  showCreateOption: false,
  labelKey: 'title',
  valueKey: 'id',
  placeholder: '',
  query: () => ({}),
});

const emit = defineEmits<{
  'update:modelValue': [e: Props['modelValue']];
  'update:modelObjectValue': [e: Props['modelObjectValue']];
  'createOption': [e: string];
}>();

defineSlots<{
  option(props: { item: AnyObject }): never;
}>();

const loading = ref(false);

const items = ref<AnyObject[]>([]);

const refMiSearchSelect = ref<InstanceType<typeof MiSelect> | null>(null);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const objectValue = computed({
  get() {
    return props.modelObjectValue;
  },
  set(val) {
    emit('update:modelObjectValue', val);
  },
});

const searchQuery = computed(() => refMiSearchSelect.value?.query);

let currentAbortController: (() => void) | null = null;

const createItem = () => {
  if (refMiSearchSelect.value) {
    refMiSearchSelect.value.query = '';
    return emit('createOption', searchQuery.value as string);
  }
};
const getItems = async () => {
  loading.value = true;

  let response;

  if (props.abortableFetchData) {
    if (typeof currentAbortController === 'function') {
      currentAbortController();
    }
    const [fetchData, cancel] = props.abortableFetchData();

    currentAbortController = cancel;
    response = await fetchData({
      ...props.query,
      page: 1,
      per_page: 30,
      search: searchQuery.value,
    });
  } else if (props.fetchData) {
    response = await props.fetchData({
      ...props.query,
      page: 1,
      per_page: 30,
      search: searchQuery.value,
    });
  }

  if (objectValue.value && response?.data) {
    const arrayOfValue = (
      Array.isArray(objectValue.value) ? objectValue.value : [objectValue.value]
    ).filter((f) => !!f[props.valueKey]);
    const ids = arrayOfValue.map((item) => item[props.valueKey]);
    const newItems = response.data.filter((f: AnyObject) => {
      return !ids.includes(f[props.valueKey]);
    });
    items.value = [...cloneDeep(arrayOfValue), ...newItems];
  } else {
    items.value = response?.data ?? [];
  }

  loading.value = false;
};

const handleSelectVisibilityChange = (isOpen: boolean) => {
  if (isOpen) {
    getItems();
  }
};

const selectHandler = (eventValue: string | number | string[] | number[]) => {
  let result: AnyObject | AnyObject[] | undefined;

  if (Array.isArray(eventValue)) {
    result = eventValue.map((item) => {
      return items.value.find((f) => f[props.valueKey] === item);
    });
  } else {
    result = items.value.find((f) => f[props.valueKey] === eventValue);
  }
  const cloneResult = cloneDeep(result);
  objectValue.value = cloneResult;
};

const debouncedGetItems = debounce(getItems, 500);

watch(
  () => value.value,
  (v) => {
    if (!v) {
      objectValue.value = undefined;
    }
  }
);

watch(
  () => objectValue.value,
  (newValue) => {
    if (newValue) {
      const arrayOfValue = (Array.isArray(newValue) ? newValue : [newValue]).filter(
        (f) => !!f[props.valueKey]
      );
      const ids = arrayOfValue.map((item) => item[props.valueKey]);
      const newItems = items.value.filter((f) => {
        return !ids.includes(f[props.valueKey]);
      });

      items.value = [...cloneDeep(arrayOfValue), ...newItems];
    } else {
      items.value = [];
    }
  },
  { immediate: true, deep: true }
);
</script>

<style lang="scss" src="./index.scss" />
