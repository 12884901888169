<template>
  <div class="invoice-modal-services-table">
    <MiSimpleTable :loading="false" :height="editMode ? '200px' : '400px'" :data="paymentSubjects">
      <ElTableColumn prop="value.subject.title" :label="$t('Base.Naming')" />
      <ElTableColumn prop="value.price" :label="$t('Base.Price')" />
      <ElTableColumn prop="value.count" :label="$t('Base.Quantity')">
        <template #default="{ row, $index }">
          <span v-if="readOnly">{{ row.value.count }}</span>
          <ElInput
            v-else
            @update:modelValue="handleChangeCount(Number($event), $index)"
            :model-value="row.value.count"
            type="number"
            min="1" />
        </template>
      </ElTableColumn>
      <ElTableColumn :label="$t('Common.Actions')" prop="actions">
        <template #default="{ $index }">
          <MiButton :disabled="readOnly" type="danger" text @click="handleRemove($index)">
            {{ $t('Base.Delete') }}
          </MiButton>
        </template>
      </ElTableColumn>
      <template #empty>
        <div class="invoice-modal-services-table__no-result-img"></div>
        <span>{{ $t('Common.NoData') }}</span>
      </template>
    </MiSimpleTable>
  </div>
</template>

<script setup lang="ts">
import { useFieldArray } from 'vee-validate';

import { PaymentSubjectDto } from '~shared/api';
import { MiSimpleTable, MiButton } from '~shared/ui/';

defineProps<{
  editMode: boolean;
  readOnly: boolean;
}>();

const {
  fields: paymentSubjects,
  update,
  remove,
} = useFieldArray<PaymentSubjectDto>('currentInvoice.payment_subjects');

const handleChangeCount = (newCount: number, index: number) => {
  const clonedPaymentSubject = paymentSubjects.value?.[index]?.value;
  if (clonedPaymentSubject) {
    update(index, { ...clonedPaymentSubject, count: newCount });
  }
};
const handleRemove = (index: number) => {
  remove(index);
};
</script>

<style src="./index.scss" />
