<template>
  <MainLayout content-class="v-users-content" fix-height>
    <LayoutContentHeader>
      <form @submit.prevent="throttleSearch">
        <ElInput
          v-model="search.value"
          :placeholder="$t('Base.Search')"
          clearable
          class="v-users-content-header__user-search">
          <template #prefix>
            <MiIcon icon="SEARCH" />
          </template>
        </ElInput>
      </form>

      <SelectUserRole class="v-users-content-header__role-select" v-model="role.value" />

      <!--      Status-->
      <UiSelect
        :options="statusOptions"
        v-model="statusSelect.value"
        :placeholder="$t('Base.Status')" />

      <!-- Specialties -->
      <UiModelsAutocompleteSearch
        class="v-doctors-content-header__specialties"
        :model-value="specialty.value.id"
        :model-for-use="Specialty"
        label="title"
        :default-item="specialty.value.id ? specialty.value : null"
        :placeholder="$t('Doctors.ChooseSpecialty')"
        clearable
        @select="specialty.value = $event" />

      <template #actions>
        <ElButton type="primary" @click="addUser">
          {{ $t('User.AddUser') }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <UsersTable
      class="v-users-content__table"
      :items="items"
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :total="total"
      @activateKey:generate="generateKey"
      @edit-profile="editProfile" />
  </MainLayout>
</template>

<script lang="ts">
export default {
  name: 'VUsers',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import throttle from 'lodash.throttle';

import { MiIcon } from '~shared/ui';
import { usePerPage, usePage, useModel, useQuery, useGetDataByInterval } from '~shared/lib';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { User } from '@/models/User.model';
import { I18nService } from '~shared/lib';
import { EmployeeShortDto } from '@/types/api';
import { Specialty } from '@/models/Specialty.model';
import UsersTable from '@/components/users/UsersTable/index.vue';
import ShowActivationKeyModal from '@/components/users/ShowActivationKeyModal/index.vue';
import SelectUserRole from '@/components/users/SelectUserRole/index.vue';
import { MainLayout } from '~widgets/layouts';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import UiSelect from '@/components/ui/UiSelect/index.vue';
import UiModelsAutocompleteSearch from '@/components/ui/UiModelsAutocompleteSearch/index.vue';
import EmployeeModal from '@/components/accounting/modals/EmployeeModal/index.vue';
import UserCredentialsModal from '@/components/accounting/modals/UserCredentialsModal/index.vue';

const store = useStore();
const perPage = usePerPage();
const page = usePage();
const role = useQuery({ field: 'role', valueIsArray: true });
const search = useQuery({ field: 'user' });
const statusSelect = useQuery({ field: 'is_working' });
const specialty = useModel({
  fieldNames: {
    id: 'specialty_id',
    name: 'specialty_name',
  },
});

const query = computed(() => ({
  per_page: perPage.value,
  page: page.value,
  query_field: ['name'],
  query_type: 'ILIKE',
  role: role.value,
  search: search.value,
  is_working: statusSelect.value,
  specialty_ids: specialty.value.id,
}));

const loading = computed(() => store.state.users.loading);
const items = computed(() => store.state.users.data);
const total = computed(() => store.state.users.total);
const statusOptions = computed(() => {
  return [
    {
      label: I18nService.t('Accounting.Active'),
      value: 'true',
    },
    {
      label: I18nService.t('Accounting.Inactive'),
      value: 'false',
    },
  ];
});

const generateKey = async (payload: EmployeeShortDto) => {
  const result = await User.getKey(payload.id);

  if (result?.data) {
    store.dispatch('modalAndDrawer/openModal', {
      component: ShowActivationKeyModal,
      payload: {
        data: { activationKey: result.data.key_activating },
      },
    });
  }
};

const editProfile = async (payload: EmployeeShortDto) => {
  const action = await store.dispatch('modalAndDrawer/openModal', {
    component: EmployeeModal,
    payload: {
      userId: payload.id,
    },
  });
};

const getUsers = async () => {
  store.dispatch('users/setLoading', true);
  const { data } = await User.find<EmployeeShortDto[]>(query.value);

  const dataParse = data.data.map((item) => {
    const titlesSpecialty = item.doctor
      ? item.doctor.specialties.map((doc) => doc.title).join(', ')
      : '';

    return {
      ...item,
      specialty: titlesSpecialty,
      status: item.is_working ? 'active' : 'not_active',
    };
  });
  store.dispatch('users/setData', {
    items: dataParse,
    total: +data.meta.total,
    overwriteDataState: true,
  });

  store.dispatch('users/setLoading', false);
};

//TODO after backend implementation
const addUser = async () => {
  const action = await store.dispatch('modalAndDrawer/openModal', {
    component: EmployeeModal,
  });

  const { email, key_activating, password } = action.data;
  if (action.name === 'created') {
    await getUsers();
    store.dispatch('modalAndDrawer/openModal', {
      component: UserCredentialsModal,
      payload: {
        formData: { email: email, password: password, key_activating: key_activating },
      },
    });
  }
};

const throttleSearch = throttle(getUsers, 300);
const throttleSearchByInterval = useGetDataByInterval(throttleSearch);

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: throttleSearchByInterval,
      resetPage: page.reset,
      fieldsForResetPage: ['name', 'role'],
    });
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/accounting.locales.json" />
<i18n src="@/locales/doctors.locales.json" />
