{
  "en": {
    "CodeRepeat": "Send code again",
    "GoToAppointments": "Return to records",
    "SendedCode": "Confirmation code sent to patient",
    "Success": "You have successfully changed the number!",
    "Waiting": "You can resend the code  ",
    "WriteCode": "Enter code"
  },
  "ru": {
    "CodeRepeat": "Отправить код заново",
    "GoToAppointments": "Вернуться к записям",
    "SendedCode": "Пациенту выслан код подтверждения",
    "Success": "Вы успешно поменяли номер!",
    "Waiting": "Повторно можете отправить код через ",
    "WriteCode": "Введите код"
  },
  "uz": {
    "CodeRepeat": "Kodni qayta yuboring",
    "GoToAppointments": "Xabarlarga qaytish",
    "SendedCode": "Bemorga tasdiqlash kodi yuborildi",
    "Success": "Siz raqamingizni muvaffaqiyatli o'zgartirdingiz!",
    "Waiting": "kodni qayta yuborishingiz uchun",
    "WriteCode": "Kodni kiriting"
  }
}