<template>
  <MainLayout content-class="v-template">
    <form class="v-template-form" @submit.prevent="updateTemplate">
      <ElInput v-model="template.title" />
      <ElButton type="primary" native-type="submit" :loading="loading.form">
        {{ $t('Base.Save') }}
      </ElButton>
    </form>

    <TinyEditor v-if="isTinyEditor" v-model:content="tinyData" />

    <EditorCard
      v-if="!isTinyEditor && template.basic_data.length"
      :data="template.basic_data[0]"
      :readonly="false"
      :min-height="300"
      @update:data="updateEditorHandler"
      @change="changeEditorHandler" />

    <div v-if="!isTinyEditor" class="v-template-actions v-template__actions">
      <ElButton text @click="print">
        <template #icon>
          <MiIcon icon="PRINTER" />
        </template>
        {{ $t('Base.Print') }}
      </ElButton>
    </div>

    <PrinterDocument v-if="!isTinyEditor" ref="printerDocument">
      <EditorCard :data="template.basic_data[0]" readonly ref="printerEditorCard" />
    </PrinterDocument>
  </MainLayout>
</template>

<script src="./index.js"></script>
<style lang="scss" src="./index.scss"></style>

<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
