import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const CASH_ROUTE = {
  name: 'CASH',
  path: '/cash',
  component: 'VCash',
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['manager', 'director', 'doctor', 'accountant']),
  ],
  meta: {
    title: 'Base.CashRegister',
  },
};

export const routes = [CASH_ROUTE];
