{
  "en": {
    "Devices": {
      "Device": "Device",
      "Ip": "IP address",
      "CreatedAt": "Date and time",
      "Location": "Location",
      "EndSession": "End session",
      "Reject": "Reject",
      "Unlock": "Unlock"
    }
  },
  "ru": {
    "Devices": {
      "Device": "Устройство",
      "Ip": "IP-адрес",
      "CreatedAt": "Дата и время",
      "Location": "Локация",
      "EndSession": "Завершить сеанс",
      "Reject": "Отклонить",
      "Unlock": "Разблокировать"
    }
  },
  "uz": {
    "Devices": {
      "Device": "Qurilma",
      "Ip": "IP manzili",
      "CreatedAt": "Sana va vaqt",
      "Location": "Joylashuvi",
      "EndSession": "Seansni tugatish",
      "Reject": "Rad etish",
      "Unlock": "Qulfni ochish"
    }
  }
}