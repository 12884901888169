<template>
  <MiCard shadow="never">
    <MiHorizInfoField label="Фамилия">{{
      splitedName.lastName ? splitedName.lastName : '-'
    }}</MiHorizInfoField>
    <MiHorizInfoField label="Имя">{{
      splitedName.firstName ? splitedName.firstName : '-'
    }}</MiHorizInfoField>
    <MiHorizInfoField label="Отчество"
      >{{ splitedName.middleName ? splitedName.middleName : '-' }}
    </MiHorizInfoField>
    <MiHorizInfoField label="ZorDoc ID">{{
      patient?.humId ? patient.humId : '-'
    }}</MiHorizInfoField>
    <MiHorizInfoField :label="$t('User.PhoneNumber')">{{
      patient?.phone ? patient.phone : '-'
    }}</MiHorizInfoField>
    <slot :patient="patient" name="append"></slot>
  </MiCard>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { PatientShortDto } from '~shared/api';
import { MiCard, MiHorizInfoField } from '~shared/ui';

const props = defineProps<{
  patient?: PatientShortDto;
}>();

const splitedName = computed(() => {
  if (!props.patient?.name) {
    return {};
  }

  const [last_name, first_name, middle_name] = props.patient.name.split(' ');
  return {
    lastName: last_name,
    firstName: first_name,
    middleName: middle_name,
  };
});
</script>

<style scoped></style>
