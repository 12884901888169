import { ElNotification } from 'element-plus';

import { apiService } from '~shared/api';
import { MetaDto } from '@/types/api';
import { QueryParams } from '@/types/common';
import { getApiErrorMessage, mergeOrCreateQuery } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';

export class DiseaseCode {
  static modelName = 'code';
  static tableName = 'codes';

  static async find(query: QueryParams) {
    try {
      const response = await apiService.api.instance.get<{ data: never[]; meta: MetaDto }>(
        mergeOrCreateQuery({
          url: `${this.tableName}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return { response, data: response.data };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
}
