import { RouteLocationNormalized } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { amplitudeService } from '~shared/lib';

export const logPageVisit = (to: RouteLocationNormalized) => {
  switch (to.matched?.[0]?.path || to.path) {
    case ROUTE_PATH.bookkeeping.services:
      amplitudeService.logEvent('view_services_list');
      break;
    case ROUTE_PATH.bookkeeping.salaryCalcList:
      amplitudeService.logEvent('view_salary_list');
      break;
    case ROUTE_PATH.users.doctor.rawPath || ROUTE_PATH.users.doctor.byId(to.params.id as string):
      amplitudeService.logEvent('view_doctors_dashboard');
      break;
    case ROUTE_PATH.appointmentSchedule:
      amplitudeService.logEvent('view_appointment_callendar');
      break;
    case ROUTE_PATH.laboratory.orders:
      amplitudeService.logEvent('laboratory_orders.view');
      break;
    case ROUTE_PATH.laboratory.order.rawPath:
      // Included
      break;
    default:
      amplitudeService.logEvent(to.matched?.[0]?.path || to.path);
      break;
  }
};
