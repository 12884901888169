export const setItemSafely = (key: string, payload: unknown): void => {
  if (typeof payload === 'string') {
    localStorage.setItem(key, payload);
  } else {
    localStorage.setItem(key, JSON.stringify(payload));
  }
};

export const getParsedItem = (key: string): string | null | unknown => {
  try {
    return JSON.parse(localStorage.getItem(key) ?? '');
  } catch {
    return localStorage.getItem(key);
  }
};
