<template>
  <MainLayout content-class="v-patient-content">
    <RouterView
      v-if="patient"
      v-model:patient="patient"
      :appointments="appointments"
      :appointment="appointment"
      :treatments="treatments"
      :loading="loading"
      :invoices="invoices"
      :orders="orders"
      :hospital="hospital"
      @invoice:create="createInvoice"
      @hospital:create="createHospital"
      @appointment:create="createAppointment"
      @treatment:create="createTreatment"
      @treatment:updated="updateTreatment"
      @patient:createChildren="createChildren">
    </RouterView>
  </MainLayout>
</template>

<script>
import { mapState } from 'vuex';

import { Patient } from '@/models/Patient.model';
import { Appointment } from '@/models/appointment/Appointment.model';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { Treatment } from '@/models/Treatment.model';
import { Invoice } from '@/models/Invoice.model';
import { Order } from '@/models/laboratory/Order.model';
import { User } from '@/models/User.model';
import { Hospital } from '@/models/hospital/Hospital.model';
import { MainLayout } from '~widgets/layouts';
import CreateOrEditPatientModal from '@/components/patients/CreateOrEditPatientModal/index.vue';
import CreateOrEditAppointmentModal from '@/components/appointments/CreateOrEditAppointmentModal/index.vue';
import CreateOrEditTreatmentModal from '@/components/treatments/CreateOrEditTreatmentModal/index.vue';
import CreateOrEditOrPayInvoiceModal from '@/components/invoices/CreateOrEditOrPayInvoiceModal/index.vue';
import CreateOrEditHospitalModal from '@/components/hospitals/CreateOrEditHospitalModal/index.vue';
import { useSessionStore } from '~entities/session';

export default {
  name: 'VPatient',
  components: { MainLayout },
  props: {
    id: [Number, String],
    appointment: [Appointment, Object],
  },
  data() {
    return {
      /** @type Array<Appointment> */
      appointments: [],
      /** @type Patient */
      patient: null,
      /**
       * @typedef {object} VPatientLoading
       * @property {boolean} profile
       * @property {boolean} appointments
       * @property {boolean} treatments
       * @property {boolean} invoices
       * @property {boolean} orders
       */
      loading: {
        profile: false,
        appointments: false,
        treatments: false,
        invoices: false,
        orders: false,
      },
      invoices: [],
      orders: [],
      hospital: [],
      perPageLength: 5,
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    ...mapState({
      treatments: (state) => state.treatments.data,
    }),
    user() {
      return this.sessionStore.user;
    },
    isChildren() {
      return !!this.patient.parent_id;
    },
  },
  watch: {
    id: {
      async handler() {
        await this.getUser();
        this.getAppointments();
        this.getPatinetHospital();
        if (this.user.role === User.enum.roles.Doctor) this.getAmbulatoryCard();
        if (this.user.role !== User.enum.roles.Doctor) this.getPatientInvoices();

        if (this.user.role === User.enum.roles.Doctor) this.getTreatmentByUserId();
        if (this.user.role !== User.enum.roles.Doctor) this.getPatientInvoices();
        this.getPatientOrders();
      },
      immediate: true,
    },
  },

  methods: {
    async getUser() {
      this.loading.profile = true;

      const { data } = await Patient.findOneById(this.id);
      this.patient = data.data;

      this.loading.profile = false;
    },

    async getAmbulatoryCard() {
      this.loading.profile = true;

      const { data } = await Patient.getAmbulatoryCardByPatientId(this.id);
      this.patient = {
        ...this.patient,
        ambulatory_card: data.data,
      };

      this.loading.profile = false;
    },

    async getAppointments() {
      this.loading.appointments = true;

      const { data } = await Appointment.find({
        query_type: 'EQUALS',
        search: this.patient.id,
        query_field: 'user_id',
        page: 1,
        per_page: this.perPageLength,
      });
      this.appointments = data.data;
      this.loading.appointments = false;
    },

    async getPatientInvoices() {
      this.loading.invoices = true;
      const { data } = await Invoice.find({
        user_id: this.id,
        per_page: this.perPageLength,
      });

      this.invoices = data.data;
      this.loading.invoices = false;
    },

    async getPatinetHospital() {
      this.loading.hospital = true;
      const { data } = await Hospital.find({
        user_id: this.id,
      });

      this.hospital = data.data;
      this.loading.hospital = false;
    },

    async getPatientOrders() {
      this.loading.orders = true;
      const { data } = await Order.find({
        user_id: this.id,
        per_page: this.perPageLength,
      });

      this.orders = data.data;
      this.loading.orders = false;
    },
    async getTreatmentByUserId() {
      this.loading.treatments = true;

      const { data } = await Treatment.findByUserId(this.id);

      this.$store.dispatch('treatments/setData', { items: data.data, overwriteDataState: true });
      this.loading.treatments = false;
    },

    createAppointment() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditAppointmentModal,
        payload: {
          patient: this.patient,
        },
      });
    },

    createInvoice() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditOrPayInvoiceModal,
        payload: {},
      });
    },

    createHospital() {
      this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditHospitalModal,
        payload: {},
      });
    },

    async createTreatment() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditTreatmentModal,
        payload: {
          user: this.patient,
        },
      });

      if (action instanceof GlobalModalClose) return;
      this.$store.dispatch('treatments/createItem', action.data.treatment);
    },

    updateTreatment(treatment) {
      this.$store.dispatch('treatments/editItem', treatment);
    },

    async editPatient() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditPatientModal,
        payload: { data: this.patient },
      });

      if (action instanceof GlobalModalClose) return;
      this.patient = action.data.patient;
    },

    async createChildren() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditPatientModal,
        payload: {
          data: new Patient({ parent: this.patient, parent_id: this.patient.id }),
          disableDefaultAction: true,
        },
      });

      if (action instanceof GlobalModalClose) return;
      await this.getUser();
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="./index.locales.json" />
