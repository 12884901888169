<template>
  <MainLayout content-class="v-references-groups">
    <LayoutContentHeader>
      <template #actions>
        <ElButton type="primary" @click="create">
          {{ $t('Base.Create') }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <ReferencesGroupsTable
      :data="items"
      :total="total"
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      @item:update="editItem" />
  </MainLayout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import noop from 'lodash.noop';

import { usePage, usePerPage } from '~shared/lib';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { useGetDataByInterval } from '~shared/lib';
import { ReferencesGroup } from '@/models/laboratory/ReferencesGroup.model';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { MainLayout } from '~widgets/layouts';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import ReferencesGroupsTable from '@/components/laboratory/referencesGroups/ReferencesGroupsTable/index.vue';
import CreateOrEditReferencesGroupModal from '@/components/laboratory/referencesGroups/CreateOrEditReferencesGroupModal/index.vue';

export default {
  name: 'VReferencesGroups',
  components: { ReferencesGroupsTable, LayoutContentHeader, MainLayout },
  setup: () => ({
    page: usePage(),
    perPage: usePerPage(),

    getDataByInterval: noop,
  }),
  computed: {
    ...mapState({
      items: (state) => state.referencesGroups.data,
      total: (state) => state.referencesGroups.total,
      loading: (state) => state.referencesGroups.loading,
    }),

    queryWatchers() {
      return {
        page: this.page.value,
        per_page: this.perPage.value,
      };
    },
  },
  watch: {
    queryWatchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          resetPage: this.page.reset,
          getData: this.getDataByInterval,
        });
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      setData: 'referencesGroups/setData',
      setLoading: 'referencesGroups/setLoading',
      createItem: 'referencesGroups/createItem',
      editItem: 'referencesGroups/editItem',
      removeItem: 'referencesGroups/removeItem',
    }),

    async getData() {
      this.setLoading(true);

      const { data } = await ReferencesGroup.find(this.queryWatchers);
      this.setData({
        items: data.data,
        total: data.meta.total,
        overwriteDataState: true,
      });

      this.setLoading(false);
    },

    async create() {
      const action = await this.$store.dispatch(
        'modalAndDrawer/openModal',
        CreateOrEditReferencesGroupModal
      );
      if (!(action instanceof GlobalModalClose)) this.createItem(action.data.referencesGroup);
    },
  },

  mounted() {
    this.getDataByInterval = useGetDataByInterval(this.getData);
    this.getDataByInterval();
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
