import { ClinicShortDto, ClinicShortResource } from '~shared/api';
import { CLINIC_KEY } from '~shared/config';
import { localStorageUtils } from '~shared/lib';

export const setClinic = (clinic: ClinicShortResource | ClinicShortDto) => {
  localStorageUtils.setItemSafely(CLINIC_KEY, clinic);
};

export const getClinic = (): ClinicShortResource | ClinicShortDto | null => {
  return <ClinicShortResource | ClinicShortDto | null>localStorageUtils.getParsedItem(CLINIC_KEY);
};

export const removeClinic = () => {
  localStorage.removeItem(CLINIC_KEY);
};
