<template>
  <MiTag size="small" :type="statusType">
    {{ $t(`Bookkeeping.SalaryStatuses.${status}`) }}
  </MiTag>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { TagProps } from 'element-plus';

import { SalaryStatus } from '~shared/api';
import { MiTag } from '~shared/ui';

const props = defineProps<{
  status: SalaryStatus;
}>();

const statusType = computed((): TagProps['type'] =>
  props.status === 'paid' ? 'success' : 'danger'
);
</script>
