<template>
  <PrinterDocument class="biomaterial-bar-code-printer-document" hide-header ref="printerDocument">
    <div class="biomaterial-bar-code-printer-document__patient-name">{{ patient.name }}</div>
    <div class="hospital-bar-code-printer-document__patient-birthdate">{{ patient.birthdate }}</div>
    <svg class="biomaterial-bar-code-printer-document__bar-code" ref="barCode"></svg>
  </PrinterDocument>
</template>

<script>
import JsBarcode from 'jsbarcode';

import { BIOMATERIAL_BAR_CODE_HEIGHT } from '~shared/config';
import { Biomaterial } from '@/models/laboratory/Biomaterial.model';
import { User } from '@/models/User.model';
import { Patient } from '@/models/Patient.model';
import PrinterDocument from '@/components/printer/PrinterDocument/index.vue';

export default {
  name: 'BiomaterialBarCodePrinterDocument',
  components: { PrinterDocument },
  props: {
    biomaterial: [Biomaterial, Object],
    patient: [User, Patient, Object],
  },
  watch: {
    biomaterial: {
      handler(value) {
        setTimeout(() =>
          JsBarcode(this.$refs.barCode, value.id, {
            height: BIOMATERIAL_BAR_CODE_HEIGHT,
            displayValue: true,
          })
        );
      },
      immediate: true,
    },
  },

  methods: {
    print() {
      this.$refs.printerDocument.print();
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
