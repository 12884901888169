<template>
  <div>
    <span v-show="!editable">{{ valueString ?? '...' }}</span>

    <!--  Default  -->
    <ElInput
      v-show="valueTypesBlockIsShow.default"
      :model-value="modelValue"
      :disabled="!editable"
      @update:model-value="$emit('update:model-value', $event)"
      @change="$emit('change')" />

    <!--  Textarea  -->
    <ElInput
      v-show="valueTypesBlockIsShow.textarea"
      :model-value="modelValue"
      type="textarea"
      :rows="2"
      :disabled="!editable"
      @update:model-value="$emit('update:modelValue', $event)"
      @change="$emit('change')" />

    <!--  File  -->
    <UiUpload
      v-show="valueTypesBlockIsShow.file"
      :files="study.value_file_id ? [study.value_file] : []"
      :disabled="!editable"
      :text="study.value_file_id ? $t('Base.Replace') : null"
      @file:add="addFileHandler"
      @file:delete="deleteFileHandler" />

    <!--  Boolean select  -->
    <ElSelect
      v-show="editable && study.value_type === Analysis.enum.valueTypes.Boolean"
      :model-value="modelValue"
      @change="selectHandler">
      <ElOption
        v-for="option in booleanOption"
        :key="option.value"
        :label="option.label"
        :value="option.value" />
    </ElSelect>
  </div>
</template>

<script>
import { Study } from '@/models/laboratory/Study.model';
import { Analysis } from '@/models/laboratory/Analysis.model';
import UiUpload from '@/components/ui/UiUpload/index.vue';

export default {
  name: 'StudyValue',
  components: { UiUpload },
  emits: ['update:study', 'update:modelValue', 'change'],
  props: {
    study: [Study, Object],
    editable: Boolean,
    modelValue: String,
  },
  computed: {
    valueTypesBlockIsShow() {
      return {
        default: this.editable && this.study.value_type === Analysis.enum.valueTypes.Default,
        boolean: this.editable && this.study.value_type === Analysis.enum.valueTypes.Boolean,
        textarea: this.editable && this.study.value_type === Analysis.enum.valueTypes.Textarea,
        file: this.editable && this.study.value_type === Analysis.enum.valueTypes.File,
      };
    },

    booleanOption() {
      return [
        {
          label: this.$t('Laboratory.Study.ValueNegative'),
          value: '0',
        },
        {
          label: this.$t('Laboratory.Study.ValuePositive'),
          value: '1',
        },
      ];
    },

    valueString() {
      return Study.getValueString(this.study);
    },
  },

  methods: {
    selectHandler(value) {
      this.$emit('update:modelValue', value);
      setTimeout(() => this.$emit('change'));
    },

    addFileHandler(file) {
      this.$emit('update:study', {
        ...this.study,
        value_file_id: file.id,
        value_file: file,
      });
    },
    deleteFileHandler() {
      this.$emit('update:study', {
        ...this.study,
        value_file_id: null,
        value_file: null,
      });
    },
  },

  setup: () => ({ Analysis }),
};
</script>

<style lang="scss" src="./index.scss" />
