import { LocaleIdKey } from '~shared/types';
import { DEFAULT_LOCALE } from '~shared/config';
import { localStorageUtils } from '~shared/lib';
import { ProfileFullResource } from '~shared/api';

const widgetIdMappingByLocale: Record<LocaleIdKey, string> = {
  ru: 'nzbdppi3rz',
  en: 'TsI41Oabx8',
  uz: 'TJRyxHeBhN',
};

export const getJivoChatWidgetId = () => {
  const locale = localStorageUtils.getParsedItem('locale') as LocaleIdKey;
  return widgetIdMappingByLocale[locale ?? DEFAULT_LOCALE];
};

export const destroyJivoChat = () => {
  let isDestroyed = false;

  if (window.jivo_api) {
    window.jivo_destroy();
  } else {
    window.jivo_onLoadCallback = () => {
      !isDestroyed && window.jivo_destroy();
      isDestroyed = true;
    };
  }
};

export const initJivoChat = (user: ProfileFullResource | null) => {
  window.jivo_api && window.jivo_init();
  window.jivo_api.setContactInfo({
    name: user?.name,
    email: user?.email,
    phone: user?.phone,
    description: `clinic_id: ${user?.clinic_id}`,
  });
};
