<template>
  <ElSelect class="mi-select" v-bind="$props" ref="refElSelect" v-model="value">
    <template v-if="$slots.prefix" #prefix>
      <slot name="prefix" />
    </template>
    <template v-if="$slots.empty" #empty>
      <slot name="empty" />
    </template>
    <slot />
  </ElSelect>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { ElSelect, ISelectProps } from 'element-plus';

type Props = Partial<ISelectProps>;

const props = withDefaults(defineProps<Props>(), {
  reserveKeyword: true,
  teleported: true,
  persistent: true,
  validationEvent: true,
});

const emit = defineEmits<{
  'update:modelValue': [e: Props['modelValue']];
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit('update:modelValue', val);
  },
});

const refElSelect = ref<InstanceType<typeof ElSelect> | null>(null);

const query = computed(() => refElSelect.value?.query);

defineExpose({
  query,
});
</script>

<style lang="scss" src="./index.scss" />
