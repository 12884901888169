import { computed, reactive } from 'vue';

import { useQuery } from './useQuery';

type UseModelParams = {
  defaultValue?: {
    id: string;
    name: string;
  };
  fieldNames: {
    id: string;
    name: string;
  };
  modelKeys?: {
    valueKey?: string;
    labelKey?: string;
  };
};

const enums = {
  DEFAULT_VALUE_KEY: 'id',
  DEFAULT_LABEL_KEY: 'title',
};

export function useModel(params: UseModelParams) {
  const id = useQuery({
    field: params.fieldNames.id,
    defaultValue: params.defaultValue?.id ?? undefined,
    valueIsNumber: true,
  });
  const name = useQuery({
    field: params.fieldNames.name,
    defaultValue: params.defaultValue?.name ?? undefined,
  });

  const reset = () => {
    name.reset();
    setTimeout(() => id.reset());
  };

  return reactive({
    value: computed({
      get() {
        return {
          [params.modelKeys?.valueKey ?? enums.DEFAULT_VALUE_KEY]: id.value,
          [params.modelKeys?.labelKey ?? enums.DEFAULT_LABEL_KEY]: name.value,
        };
      },
      set(value) {
        if (!value) return reset();
        name.value = value[params.modelKeys?.labelKey ?? enums.DEFAULT_LABEL_KEY];
        setTimeout(() => (id.value = value.id));
      },
    }),

    reset: reset,
  });
}
