<template>
  <MiSelectSearch
    class="patients-search-select__component"
    label-key="title"
    v-bind="$attrs"
    v-model="id"
    :placeholder="$t('Base.ChooseClinic')"
    :model-object-value="currentClinic"
    :fetch-data="misB2BApi.clinicTemp.getList"
    @update:modelObjectValue="handleSelect" />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { misB2BApi, ClinicShortDto } from '~shared/api';
import { clinicStorageService } from '~shared/lib';
import { AnyObject } from '~shared/types';
import { MiSelectSearch } from '~shared/ui';

const currentClinic = ref(clinicStorageService.getClinic());
const router = useRouter();

const handleSelect = (data: AnyObject | null | undefined) => {
  if (data?.id) {
    clinicStorageService.setClinic(data as ClinicShortDto);
  } else {
    clinicStorageService.removeClinic();
  }

  router.go(0);
};

const id = ref(currentClinic.value?.id);
</script>
