<template>
  <ElDialog
    class="favorites-modal"
    width="770px"
    :title="$t('Recommendations.FavoriteServices')"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)">
    <FavoritesForm />
  </ElDialog>
</template>

<script>
import FavoritesForm from '@/components/appointments/FavoritesForm/index.vue';

export default {
  name: 'FavoritesModal',
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
  },
  components: { FavoritesForm },
};
</script>

<style lang="scss" src="./index.scss"></style>
