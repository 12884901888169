import axios from 'axios';
import { markRaw } from 'vue';

// eslint-disable-next-line import/no-internal-modules
import CreateAppointmentSubjectOrServiceGroupWithCount from './CreateAppointmentSubjectOrServiceGroupWithCount/index.vue';
// eslint-disable-next-line import/no-internal-modules
import AppointmentSubjectsOrServiceGroupWithCountTable from './AppointmentSubjectsOrServiceGroupWithcountTable/index.vue';
// eslint-disable-next-line import/no-internal-modules
import SelectReference from '../SelectReference/index.vue';

import { formatPrice } from '@/utils/price.util';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import { Appointment } from '@/models/appointment/Appointment.model';
import { User } from '@/models/User.model';
import { Patient } from '@/models/Patient.model';
import { Doctor } from '@/models/Doctor.model';
import { Service } from '@/models/Service.model';
import { ServiceGroup } from '@/models/ServiceGroup.model';
import CreateOrEditPatient from '@/components/patients/CreateOrEditPatientModal/CreateOrEditPatient/index.vue';
import PatientsSearchSelectDataBlock from '@/components/patients/PatientsSearchSelectDataBlock/index.vue';
import { useSessionStore } from '~entities/session';
import { amplitudeService } from '~shared/lib';
import { ROUTE_PATH } from '~shared/config';
import { misB2BApi } from '~shared/api';
import { InvoiceModal } from '~features/InvoiceModal';

// TODO: написать документацию по бизнес логике
// TODO: отрефакторить, слишком разросся
export default {
  name: 'CreateOrEditAppointmentModal',
  components: {
    CreateOrEditPatient,
    PatientsSearchSelectDataBlock,
    CreateAppointmentSubjectOrServiceGroupWithCount,
    AppointmentSubjectsOrServiceGroupWithCountTable,
    SelectReference,
  },
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    id: [Number, String],
    patient: [Patient, User, Object],
    initialData: {
      type: [Appointment, Object],
      default: {},
    },
    disableDefaultAction: Boolean, // отключаем дефолтное поведение после создания//
    setDefaultMyDoctor: {
      // для автоматического выбора врача, если мы с аккаунта врача
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      /** @type Appointment */
      appointment: new Appointment(),
      loading: {
        appointment: false,
        form: false,
      },

      patientPart: {
        show: false,
        nameOrPhone: null,
        newPatient: null,
      },
      sessionStore: useSessionStore(),
    };
  },
  computed: {
    isPatientSelected() {
      return Boolean(this.appointment.patient_id);
    },
    user() {
      return this.sessionStore.user;
    },

    isNotDoctor() {
      return this.user.role !== User.enum.roles.Doctor;
    },

    permissions() {
      return {
        createPatient: [User.enum.roles.Manager, User.enum.roles.Laboratory].includes(
          this.user.role
        ),
      };
    },
    patientsOptions() {
      return {
        searchQuery: {},
      };
    },

    cost() {
      return this.appointment.id
        ? this.appointment.group_services_with_count.reduce(
            (acc, item) => acc + item.count * item.group_service.price,
            0
          )
        : this.appointment.appointments.reduce((acc, elem) => {
            const servicesCost = elem.group_services_with_count.reduce(
              (acc, groupServiceWithCount) =>
                acc + groupServiceWithCount.group_service.price * groupServiceWithCount.count,
              0
            );

            return acc + servicesCost;
          }, 0);
    },
    discountedCost() {
      return formatPrice({
        price:
          this.appointment.discount > 0
            ? this.cost - this.cost * (this.appointment.discount / 100)
            : this.cost,
      });
    },
  },

  watch: {
    'modelValue': {
      handler() {
        this.appointment = new Appointment({
          ...this.initialData,
          patient_id: this.patient?.id || null,
          reference_id: this.user.referrer_id,
          reference: {
            id: this.user.referrer_id,
            title: this.user.name,
          },
        });
      },
      immediate: true,
    },

    'id': {
      handler(value) {
        if (value) this.getAppointment();
      },
      immediate: true,
    },

    'appointment.patient_id': {
      async handler(value) {
        if (value) {
          await this.getPatientReferredBy();
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getPatientReferredBy() {
      if (!this.isNotDoctor) return;
      try {
        const response = await misB2BApi.patientsTemp.getReferredBy(this.appointment.patient_id);
        if (response?.data) {
          this.appointment.reference_id = response.data.id;
          this.appointment.reference = {
            id: response.data.id,
            title: response.data.title,
          };
        }
      } catch (e) {
        console.error(e);
        if (e?.response?.status === 404) {
          this.appointment.reference_id = null;
          this.appointment.reference = null;
        }
      }
    },

    async getAppointment() {
      this.loading.appointment = true;
      const { appointment } = await Appointment.findOneById(this.id);
      this.appointment = new Appointment(appointment);
      this.loading.appointment = false;
    },

    async submitHandler() {
      if (!this.validate() || this.loading.form) return;
      this.loading.form = true;

      try {
        const { appointment, appointments, invoice } = this.appointment.id
          ? await this.editAppointment()
          : await this.createAppointment();

        this.$notify({
          type: 'success',
          title: this.$i18n.t(
            `Notifications.Success${this.appointment.id ? 'Updated' : 'Created'}`
          ),
        });
        this.$emit(
          'action',
          new GlobalModalInstance({
            name: this.appointment.id ? 'updated' : 'created',
            data: { appointment, appointments, invoice },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.form = false;
    },

    async createAppointment() {
      amplitudeService.logEvent('modal_create_appointment.create', {
        doctor_ids: this.appointment?.appointments.map((appointment) => appointment.doctor_id),
        patient_id: this.appointment?.patient_id,
      });

      if (this.$refs.patient.isNewPatientFlow()) {
        const patient = await this.$refs.patient.createPatient();
        this.appointment.patient_id = patient.id;
        this.appointment.patient = patient;
      }

      const { data } = await Appointment.create(this.appointment);

      if (!this.disableDefaultAction) {
        setTimeout(() => {
          this.$router.push(ROUTE_PATH.appointments.activeList.withQuery());
          this.openInvoiceModalAfterUpdateIfNeeded(data.data.invoice);
        });
      }
      return { appointments: data.data.appointments, invoice: data.data.invoice };
    },

    async editAppointment() {
      const { data } = await Appointment.update({
        id: this.appointment.id,
        payload: this.appointment,
      });
      if (!this.disableDefaultAction) {
        setTimeout(() => {
          this.openInvoiceModalAfterUpdateIfNeeded(data.data.invoice);
        });
      }
      return { appointment: data.appointment, invoice: data.data.invoice };
    },

    openInvoiceModalAfterUpdateIfNeeded(invoice) {
      if (this.user.role === User.enum.roles.Manager) {
        setTimeout(() => {
          this.$store.dispatch('modalAndDrawer/openModal', {
            component: InvoiceModal,
            payload: {
              invoiceId: invoice.id,
            },
          });
        });
      }
    },

    /**
     * Доролнительная проверка
     * @return {boolean} если успешно true
     */
    validate() {
      const isValidatePatient = this.$refs.patient.isNewPatientFlow()
        ? this.$refs.patient.validate()
        : true;
      if (!isValidatePatient) return false;

      if (this.appointment.id) {
        if (!this.appointment.doctor_id) {
          this.$notify({ type: 'error', title: this.$t('Appointments.SelectDoctor') });
          return false;
        }
        if (!this.appointment.group_service_ids?.length) {
          this.$notify({ type: 'error', title: this.$t('Appointments.SelectServices') });
          return false;
        }

        return true;
      }

      if (!this.appointment.appointments.length) {
        this.$notify({
          type: 'error',
          title: this.$t('NotAppointmentsError'),
        });
        return false;
      }

      return true;
    },

    /** @param {AppointmentSubject|AppointmentServiceGroupWithCount|object} subject */
    addSubject(subject) {
      amplitudeService.logEvent('modal_create_appointment.add_appointment', {
        appointment_time_type: subject.start_at ? 'on_time' : 'queue',
      });

      const field = this.isAppointmentSubject(subject)
        ? 'appointments'
        : 'group_services_with_count';
      this.appointment[field].push(subject);

      if (!this.isAppointmentSubject(subject)) {
        this.appointment.group_service_ids.push(subject.group_service.id);
        this.appointment.group_services.push(subject.group_service);
      }
    },

    /** @param {AppointmentSubject|AppointmentServiceGroupWithCount|object} subject */
    updateSubject(subject) {
      const field = this.isAppointmentSubject(subject)
        ? 'appointments'
        : 'group_services_with_count';

      const index = this.appointment[field].findIndex((elem) => elem._id === subject._id);
      this.appointment[field][index] = subject;
    },

    /** @param {AppointmentSubject|AppointmentServiceGroupWithCount|object} subject */
    removeSubject(subject, type) {
      if (type === 'appointment') {
        amplitudeService.logEvent('modal_create_appointment.remove_appointment');
      } else {
        amplitudeService.logEvent('modal_create_appointment.remove_service');
      }

      const field = this.isAppointmentSubject(subject)
        ? 'appointments'
        : 'group_services_with_count';
      this.appointment[field] = this.appointment[field].filter((elem) => elem._id !== subject._id);

      if (!this.isAppointmentSubject(subject)) {
        this.appointment.group_service_ids = this.appointment.group_service_ids.filter(
          (elem) => elem !== subject.group_service.id
        );
        this.appointment.group_services = this.appointment.group_services.filter(
          (elem) => elem.id !== subject.group_service.id
        );
      }
    },

    insertPatient(action) {
      this.appointment.patient = action.data.patient;
      this.appointment.patient_id = action.data.patient.id;
    },

    /**
     * @param {AppointmentSubject|AppointmentServiceGroupWithCount} subject
     * @return {boolean}
     */
    isAppointmentSubject(subject) {
      return Object.hasOwn(subject, 'group_services_with_count');
    },

    sendStatisticStartCreatePatient() {
      amplitudeService.logEvent('modal_create_appointment.create_patient');
    },

    sendStatisticFoundPatient() {
      amplitudeService.logEvent('modal_create_appointment.found_patient_by_phone');
    },

    sendStatisticReattachPatient() {
      amplitudeService.logEvent('modal_create_appointment.reattach_patient_phone');
    },

    sendStatisticSelectSlot() {
      amplitudeService.logEvent('modal_create_appointment.select_on_time');
    },

    handleSelectSource($event) {
      this.appointment.reference = $event;
      amplitudeService.logEvent('modal_create_appointment.select_source');
    },

    sendStatisticSelectPatient() {
      amplitudeService.logEvent('modal_create_appointment.select_patient');
    },

    sendStatisticGSearchPatients() {
      amplitudeService.logEvent('modal_create_appointment.search_patient');
    },
  },

  // other
  setup: () => ({
    Patient: markRaw(Patient),
    Doctor: markRaw(Doctor),
    Service: markRaw(Service),
    ServiceGroup: markRaw(ServiceGroup),
  }),
};
