<template>
  <ElSelect
    class="select-analysis-value-type"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)">
    <ElOption
      v-for="option in options"
      :key="option.value"
      :label="option.label"
      :value="option.value" />
  </ElSelect>
</template>

<script>
import { Analysis } from '@/models/laboratory/Analysis.model';

export default {
  name: 'SelectAnalysisValueType',
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
  },
  computed: {
    options() {
      return [
        {
          label: this.$t(`Laboratory.Analysis.ValueTypes.${Analysis.enum.valueTypes.Default}`),
          value: Analysis.enum.valueTypes.Default,
        },
        {
          label: this.$t(`Laboratory.Analysis.ValueTypes.${Analysis.enum.valueTypes.Textarea}`),
          value: Analysis.enum.valueTypes.Textarea,
        },
        {
          label: this.$t(`Laboratory.Analysis.ValueTypes.${Analysis.enum.valueTypes.Boolean}`),
          value: Analysis.enum.valueTypes.Boolean,
        },
        {
          label: this.$t(`Laboratory.Analysis.ValueTypes.${Analysis.enum.valueTypes.File}`),
          value: Analysis.enum.valueTypes.File,
        },
      ];
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
