import { CRUDModel } from '@/models/CRUD.model';
import { apiService } from '~shared/api';

/**
 * @class InspectionCard
 * @extends CRUDModel
 */
export class InspectionCard extends CRUDModel {
  static modelName = 'card';
  static tableName = 'cards';

  /**
   * @typedef {object} InspectionCardConstructorPayload
   * @property {string} type
   * @property {string} status
   * @property {Array<InspectionCardCategory|InspectionCardBlock|object>} basic_data
   * @property {Array<string>} disease_code_codes
   * @property {Array<DiseaseCode>} disease_codes
   * @property {number} appointment_id
   * @property {number} user_id
   * @property {User} user
   * @property {Array<number>} template_ids
   * @property {Array<InspectionCardTemplate>} templates
   * @property {Date|string} created_at
   * @property {string} title
   * @property {object} template
   * @property {number} template_id
   */
  /** @param {InspectionCardConstructorPayload|object} [payload] */
  constructor(payload) {
    super(payload);

    this.type = payload?.type || null;
    this.status = payload?.status || null;
    this.basic_data = payload?.basic_data ?? [];
    this.disease_code_codes = payload?.disease_code_codes ?? [];
    this.disease_codes = payload?.disease_codes ?? [];

    this.appointment_id = payload?.appointment_id ?? null;
    this.user_id = payload?.user_id ?? null;
    this.user = payload?.user ?? null;
    this.template_ids = payload?.template_ids ?? [];
    this.templates = payload?.templates ?? [];
    this.created_at = payload?.created_at ?? null;
    this.template = payload?.template ?? null;
    this.title = payload?.template?.title ?? '';
    this.template_id = payload?.template_id ?? null;
  }

  static async draft(id) {
    const response = await apiService.api.instance.put(`${this.tableName}/${id}/draft`);
    return { response, data: response.data };
  }

  static async close(id) {
    const response = await apiService.api.instance.put(`${this.tableName}/${id}/closed`);
    return { response, data: response.data };
  }

  static enum = {
    types: {
      Default: 'default', // old realization
      Editor: 'editor', // use @editorjs/editorjs
      Tiny: 'tiny_mce',
    },
    statuses: {
      Created: 'created',
      Draft: 'draft',
      Closed: 'closed',
    },
  };
}
