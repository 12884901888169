<template>
  <ElDialog
    width="480px"
    class="show-activation-key-modal"
    :title="$t('ActivationKey')"
    v-bind="$attrs">
    <div class="show-activation-key-modal__wrapper">
      <div class="show-activation-key-modal__content">
        {{ data.activationKey }}
      </div>
      <div class="show-activation-key-modal__actions">
        <ElButton
          class="show-activation-key-modal__actions-copy-button"
          v-show="!!data.activationKey"
          type="primary"
          @click="copyText">
          {{ $t('Copy') }}
        </ElButton>
      </div>
    </div>
  </ElDialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ShowActivationKeyModal',
  props: {
    data: [Object],
    disableDefaultAction: Boolean, // отключаем дефолтное поведение после создания
  },
  methods: {
    copyText() {
      try {
        navigator.clipboard.writeText(this.data.activationKey);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="./index.locales.json" />
