<template>
  <SalaryTable :id="id" />
</template>

<script lang="ts">
export default {
  name: 'VUserSalary',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import SalaryTable from '@/components/employees/tables/SalaryTable/Index.vue';

defineProps<{
  id: string;
}>();
</script>
