import { object, string, number, boolean } from 'yup';

import { I18nService } from '~shared/lib';

export const validationSchema = object().shape({
  name: string()
    .trim()
    .nullable()
    .required(() => I18nService.t('Validation.RequiredField')),
  role: string()
    .trim()
    .nullable()
    .required(() => I18nService.t('Validation.RequiredField')),
  // TODO: fix this specialties
  // doctor: object()
  //   .nullable()
  //   .shape({
  //     specialties: array().when(['role'], (fields, schema) => {
  //       if (fields[0] === 'doctor') {
  //         return schema.min(1, I18nService.t('Validation.RequiredField'));
  //       }
  //       return schema;
  //     }),
  //   }),
  birthdate: string().trim().nullable(),
  phone: string()
    .trim()
    .nullable()
    .required(() => I18nService.t('Validation.RequiredField')),
  address: string().trim().nullable(),
  salary: number()
    .transform((value) => (isNaN(value) ? null : value))
    .required(() => I18nService.t('Validation.RequiredField')),
  doctor_recommendation_commission: string()
    .trim()
    .nullable()
    .required(() => I18nService.t('Validation.RequiredField')),
  appointment_commission: string()
    .trim()
    .nullable()
    .required(() => I18nService.t('Validation.RequiredField')),
  is_working: boolean(),
});
