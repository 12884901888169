import { QueryParams } from '~shared/types';
import { joinQueryParams } from '~shared/lib';
import { apiService, MetaDto, EmployeeFullDto } from '~shared/api';
import { DEFAULT_QUERY_TYPE } from '~shared/config';

const urlPath = '/users';

export const getList = async (query: QueryParams = {}) => {
  const response = await apiService.api.instance.get<{
    data: EmployeeFullDto[];
    meta: MetaDto;
  }>(
    joinQueryParams({
      url: urlPath,
      query: {
        ...{
          query_field: ['name'],
          query_type: DEFAULT_QUERY_TYPE,
          query_operator: 'AND',
        },
        ...query,
      },
    })
  );
  return {
    ...response.data,
  };
};

export const getById = async (id: number) => {
  const response = await apiService.api.instance.get<{ data: EmployeeFullDto }>(`${urlPath}/${id}`);
  return {
    ...response.data,
  };
};
