<template>
  <MainLayout content-class="v-treatment" fix-height :loading="loading.treatment">
    <LayoutContentHeader>
      <ElPageHeader class="v-patient-treatment-header" :title="$t('Base.Back')" @back="back">
        <template #content> {{ $t('Base.TreatmentJournal') }} </template>
      </ElPageHeader>
    </LayoutContentHeader>

    <template v-if="treatment">
      <TreatmentCard
        v-if="treatment"
        :data="treatment"
        :loading="loading.treatment"
        @treatment:update="treatment = $event" />

      <LayoutContentHeader :title="$t('Treatments.DrugsAndProcedures')">
        <template #actions>
          <ElButton
            v-if="treatment.status !== Treatment.enum.statuses.Closed"
            type="primary"
            plain
            @click="edit">
            {{ $t('Base.Edit') }}
          </ElButton>
        </template>
      </LayoutContentHeader>
      <TreatmentDrugsTable
        v-loading="loading.drugs"
        :items="treatment.drugs"
        :editable="treatment.status !== Treatment.enum.statuses.Closed"
        :deletable="treatment.status !== Treatment.enum.statuses.Closed"
        @drug:update="updateDrug"
        @drug:change="updateTreatment"
        @drug:remove="deleteDrug" />

      <LayoutContentHeader :title="$t('Appointments.Appointments')">
        <template #actions>
          <ElButton
            v-if="treatment.status !== Treatment.enum.statuses.Closed"
            type="primary"
            plain
            :loading="loading.appointment"
            @click="createAppointment">
            {{ $t('Appointments.CreateAppointment') }}
          </ElButton>
        </template>
      </LayoutContentHeader>
      <AppointmentsByTreatmentTable :data="treatment.appointments" :loading="loading.treatment" />

      <div class="v-treatment__actions">
        <ElButton
          v-if="treatment.status !== Treatment.enum.statuses.Closed"
          type="primary"
          plain
          :loading="loading.close"
          @click="closeTreatment">
          {{ $t('Treatments.CloseTreatment') }}
        </ElButton>

        <ElButton type="primary" :loading="loading.bill" @click="billTreatment">
          {{ $t('Invoices.Bill') }}
        </ElButton>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import axios from 'axios';

import { Treatment } from '@/models/Treatment.model';
import { Appointment } from '@/models/appointment/Appointment.model';
import { insertRouteParams } from '@/utils/router.utils';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import TreatmentCard from '@/components/treatments/TreatmentCard/index.vue';
import AppointmentsByTreatmentTable from '@/components/treatments/AppointmentsByTreatmentTable/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import { MainLayout } from '~widgets/layouts';
import TreatmentDrugsTable from '@/components/treatments/TreatmentDrugsTable/index.vue';
import CreateOrEditTreatmentModal from '@/components/treatments/CreateOrEditTreatmentModal/index.vue';
import CreateOrEditOrPayInvoiceModal from '@/components/invoices/CreateOrEditOrPayInvoiceModal/index.vue';
import { ROUTE_PATH } from '~shared/config';

export default {
  name: 'VTreatment',
  components: {
    TreatmentDrugsTable,
    LayoutContentHeader,
    MainLayout,
    TreatmentCard,
    AppointmentsByTreatmentTable,
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      loading: {
        treatment: false,
        update: false,
        close: false,
        appointment: false,
        bill: false,
      },
      /** @type {Treatment} */
      treatment: null,
      /** @type {Array<Appointment>} */
      receptions: [],
    };
  },
  computed: {
    patientPageLink() {
      return insertRouteParams({
        path: PATIENT_ROUTE.path,
        params: { id: this.treatment.user_id },
      });
    },
  },

  watch: {
    id: {
      async handler() {
        await this.getTreatment();
      },
      immediate: true,
    },
  },

  methods: {
    async getTreatment() {
      this.loading.treatment = true;

      const { data } = await Treatment.findById(this.id);

      this.treatment = data.data;
      this.loading.treatment = false;
    },

    async edit() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditTreatmentModal,
        payload: {
          treatment: this.treatment,
        },
      });

      if (!(action instanceof GlobalModalClose)) this.treatment = action.data.treatment;
    },

    async updateTreatment() {
      this.loading.treatment = true;

      try {
        await Treatment.update({
          id: this.treatment.id,
          payload: this.treatment,
        });
        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessUpdated') });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.treatment = false;
    },

    updateDrug(drug) {
      const index = this.treatment.drugs.findIndex((elem) => elem.uuid === drug.uuid);
      this.treatment.drugs[index] = drug;
    },

    async deleteDrug(drug) {
      this.loading.drugs = true;
      const index = this.treatment.drugs.findIndex((elem) => elem.uuid === drug.uuid);

      try {
        this.treatment.drugs[index].is_deleted = true;

        await Treatment.update({
          id: this.treatment.id,
          payload: this.treatment,
        });

        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessDeleted') });
      } catch (err) {
        this.treatment.drugs[index].is_deleted = false;

        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.drugs = false;
    },

    async createAppointment() {
      this.loading.appointment = true;

      try {
        const { data } = await Appointment.createByTreatment(this.treatment.id);

        this.$router.push(ROUTE_PATH.appointments.appointment.byId(data.data.appointments[0].id));
        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessCreated') });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.appointment = false;
    },

    async billTreatment() {
      this.loading.bill = true;

      try {
        const { invoice } = await Treatment.getInvoiceByTreatmentId(this.treatment.id);
        await this.$store.dispatch('modalAndDrawer/openModal', {
          component: CreateOrEditOrPayInvoiceModal,
          payload: {
            id: invoice.id,
          },
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.bill = false;
    },

    async closeTreatment() {
      this.loading.close = true;

      try {
        const { treatment } = await Treatment.close(this.treatment.id);
        this.treatment = treatment;

        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessUpdated') });
        this.billTreatment();
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.close = false;
    },

    back() {
      this.$router.go(-1);
    },
  },

  setup: () => ({ Treatment }),
};
</script>

<style lang="scss" src="./index.scss" />
