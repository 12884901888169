{
  "en": {
    "Contingent": {
      "Actions": "Actions",
      "AddContactInfo": "Add contact",
      "AddPatient": "Add patient",
      "AddRegistration": "Add to registration",
      "Birthdate": "Date of birth",
      "Comment": "Comment",
      "ContactPerson": "The contact person",
      "Contingent": "Contingent",
      "ContingentCategory": "Contingent category",
      "DRegistration": "D registration",
      "DegreeDisability": "Degree of disability",
      "DegreeDisabilityOptions": {
        "first_group": "I category",
        "second_group": "II category",
        "third_group": "III category"
      },
      "DeregistrationDate": "Deregistration date",
      "Description": "Description",
      "Disability": "Disability",
      "DocumentTypeName": {
        "accreditation": "??Аккредитация МИД",
        "birth_certificate": "??Свидетельство о рождении",
        "foreign_passport": "??Паспорт иностранного гражданина",
        "id_card": "??ID карта",
        "uz_passport": "??Паспорт РУз"
      },
      "DownloadExcelFile": "Скачать Excel файл",
      "EditingPatientInfo": "Editing patient information",
      "HealthGroup": "Health group",
      "ICD": "ICD",
      "Kinship": "Kinship",
      "MedCardNumber": "Medcard number",
      "Medcard": "Medcard",
      "OpenMedCard": "??Перейти в медкарту",
      "OpenProfile": "Open profile",
      "Patient": "Patient",
      "PatientGroups": {
        "first_group": "1st group of patients",
        "fourth_group": "4th group of patients",
        "second_group": "2nd group of patients",
        "third_group": "3rd group of patients"
      },
      "PatientProfile": "Patient profile",
      "PatientRegistration": "Patient registration",
      "PhoneNumber": "Phone number",
      "PriceListCategory": "Price list category",
      "Profile": "Profile",
      "RegistrationICD": "Make a diagnosis with ICD",
      "RelationShip": {
        "child": "Child",
        "parent": "Parent",
        "spouse": "Spouse"
      },
      "State": "State",
      "Status": {
        "fifth": "V категория",
        "fourth": "IV категория",
        "one": "I категория",
        "third": "III категория",
        "two": "II категория"
      },
      "Telegram": "Telegram",
      "ThereIs": "There is",
      "ThereIsNot": "There is not"
    }
  },
  "ru": {
    "Contingent": {
      "Actions": "Действия",
      "AddContactInfo": "Добавить контакт",
      "AddPatient": "Добавить пациента",
      "AddRegistration": "Добавить в учет",
      "Birthdate": "Дата рождения",
      "Comment": "Комментарий",
      "ContactPerson": "Контактное лицо",
      "Contingent": "Контингент",
      "ContingentCategory": "Категория контингента",
      "DRegistration": "Д учет",
      "DegreeDisability": "Степень инвалидности",
      "DegreeDisabilityOptions": {
        "first_group": "I категория",
        "second_group": "II категория",
        "third_group": "III категория"
      },
      "DeregistrationDate": "Дата снятия",
      "Description": "Заметки",
      "Disability": "Инвалидность",
      "DocumentTypeName": {
        "accreditation": "Аккредитация МИД",
        "birth_certificate": "Свидетельство о рождении",
        "foreign_passport": "Паспорт иностранного гражданина",
        "id_card": "ID карта",
        "uz_passport": "Паспорт РУз"
      },
      "EditingPatientInfo": "Редактирование информации о пациенте",
      "HealthGroup": "Группа здоровья",
      "ICD": "МКБ",
      "Kinship": "Родственная связь",
      "MedCardNumber": "Номер медкарты",
      "Medcard": "Медкарта",
      "OpenMedCard": "Перейти в медкарту",
      "OpenProfile": "Перейти в профиль",
      "Patient": "Пациент",
      "PatientGroups": {
        "first_group": "I - Здоровые",
        "fourth_group": "IV - Высокий риск",
        "second_group": "II - Низкий риск",
        "third_group": "III - Средний риск"
      },
      "PatientProfile": "Профиль пациента",
      "PatientRegistration": "Регистрация пациента",
      "PhoneNumber": "Номер телефона",
      "PriceListCategory": "Категория прайслиста",
      "Profile": "Профиль",
      "RegistrationICD": "Поставить диагноз по МКБ",
      "RelationShip": {
        "child": "Ребенок",
        "parent": "Родитель",
        "spouse": "Супруг"
      },
      "State": "Состояние",
      "Status": {
        "fifth": "V категория",
        "fourth": "IV категория",
        "one": "I категория",
        "third": "III категория",
        "two": "II категория"
      },
      "Telegram": "Телеграм",
      "ThereIs": "Есть",
      "ThereIsNot": "Нет"
    }
  },
  "uz": {
    "Contingent": {
      "Actions": "??Действия",
      "AddPatient": "??Добавить пациента",
      "AddRegistration": "??Hisobga qo'shish",
      "Birthdate": "??Дата рождения",
      "Comment": "Izoh",
      "ContactPerson": "??Контактное лицо",
      "Contingent": "??Contingent",
      "ContingentCategory": "??Категория контингента",
      "DRegistration": "??Д учет",
      "DegreeDisability": "Nogironlik darajasi",
      "DegreeDisabilityOptions": {
        "first_group": "I daraja",
        "second_group": "II daraja",
        "third_group": "III daraja"
      },
      "DeregistrationDate": "??Chiqarish sanasi",
      "Description": "??Контактное лицо",
      "Disability": "??Инвалидность",
      "DocumentTypeName": {
        "accreditation": "??Аккредитация МИД",
        "birth_certificate": "??Свидетельство о рождении",
        "foreign_passport": "??Паспорт иностранного гражданина",
        "id_card": "??ID карта",
        "uz_passport": "??Паспорт РУз"
      },
      "EditingPatientInfo": "Bemor haqidagi ma'lumotlarni tahrirlash",
      "HealthGroup": "??Группа здоровья",
      "ICD": "??МКБ",
      "Kinship": "??Родственная связь",
      "MedCardNumber": "??Номер медкарты",
      "Medcard": "??Медкарта",
      "OpenMedCard": "??Перейти в медкарту",
      "OpenProfile": "??Перейти в профиль",
      "Patient": "??Пациент",
      "PatientGroups": {
        "first_group": "I-guruh bemorlar",
        "fourth_group": "IV-guruh bemorlar",
        "second_group": "II-guruh bemorlar",
        "third_group": "III-guruh bemorlar"
      },
      "PatientProfile": "??Профиль пациента",
      "PatientRegistration": "Bemorni ro'yxatga olish",
      "PhoneNumber": "??Номер телефона",
      "PriceListCategory": "Narxlar toifasi",
      "Profile": "Profil",
      "RegistrationICD": "MKB bo'yicha tashxis qo'yish",
      "RelationShip": {
        "child": "??Ребенок",
        "parent": "??Родитель",
        "spouse": "??Супруг"
      },
      "State": "??Состояние",
      "Status": {
        "fifth": "??V категория",
        "fourth": "??IV категория",
        "one": "??I категория",
        "third": "??III категория",
        "two": "??II категория"
      },
      "Telegram": "??Телеграм",
      "ThereIs": "??Есть",
      "ThereIsNot": "??Нет"
    }
  }
}
