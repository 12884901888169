import { CRUDModel } from '@/models/CRUD.model';
import { User } from '@/models/User.model';

export class ReferencesGroup extends CRUDModel {
  static modelName = 'referencesGroup';
  static tableName = 'references/groups';

  /**
   * @param {object} payload
   * @param {string} payload.title
   * @param {string} payload.title_ru
   * @param {string} payload.title_uz
   * @param {User.enum.genders.MAN|User.enum.genders.Woman} payload.gender
   * @param {number} payload.start_age
   * @param {number} payload.end_age
   */
  constructor(payload) {
    super(payload);

    this.title = payload?.title ?? null;
    this.title_ru = payload?.title_ru ?? null;
    this.title_uz = payload?.title_uz ?? null;
    this.gender = payload?.gender ?? User.enum.genders.MAN;
    this.start_age = payload?.start_age ?? null;
    this.end_age = payload?.end_age ?? null;
  }
}
