<template>
  <ElCard
    class="patients-separation-statistic-card"
    :header="$t('Base.Patients')"
    :shadow="shadow"
    v-loading="loading">
    <Vue3Apexcharts type="pie" :options="chartOptions" :series="series" ref="chart" />
  </ElCard>
</template>

<script>
import Vue3Apexcharts from 'vue3-apexcharts';

import { User } from '@/models/User.model';
import { Patient } from '@/models/Patient.model';

export default {
  name: 'PatientsSeparationStatisticCard',
  components: { Vue3Apexcharts },
  props: {
    shadow: {
      type: String,
      default: 'never',
    },
  },
  data() {
    return {
      loading: false,
      series: [50, 50],
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [
          this.$t(`User.Genders.${User.enum.genders.MAN}`),
          this.$t(`User.Genders.${User.enum.genders.Woman}`),
        ],
        responsive: [
          {
            // breakpoint: 480,
            options: {
              chart: {},
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };
    },
  },

  watch: {
    series: {
      handler(value) {
        this.$refs.chart.updateSeries(value);
      },
    },
    chartOptions: {
      handler(value) {
        this.$refs.chart.updateOptions(value);
      },
      deep: true,
    },
  },

  methods: {
    async getData() {
      this.loading = true;

      const { data } = await Patient.getSeparationStatistic();
      this.series = [data[User.enum.genders.MAN], data[User.enum.genders.Woman]];
      this.loading = false;
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
