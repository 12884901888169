{
  "en": {
    "Placeholder": "Select the status"
  },
  "ru": {
    "Placeholder": "Выберите статус"
  },
  "uz": {
    "Placeholder": "Statusni tanlang"
  }
}