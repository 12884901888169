<template>
  <MainLayout content-class="v-doctors-content" fix-height>
    <LayoutContentHeader class="v-doctors-content-header">
      <!-- Date -->
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <!-- Specialties -->
      <UiModelsAutocompleteSearch
        class="v-doctors-content-header__specialties"
        :model-value="specialty.value.id"
        :model-for-use="Specialty"
        label="title"
        :default-item="specialty.value.id ? specialty.value : null"
        :placeholder="$t('Doctors.ChooseSpecialty')"
        clearable
        @select="specialty.value = $event" />

      <!-- SearchDoctors -->
      <ElInput
        v-model="search.value"
        class="v-doctors-content-header__search"
        clearable
        :placeholder="$t('Doctors.FindingDoctor')">
        <template #prefix>
          <MiIcon icon="SEARCH" />
        </template>
        <!--        <template #append>-->
        <!--          <ElButton type="primary" plain native-type="submit">-->
        <!--            {{ $t('Base.Search') }}-->
        <!--          </ElButton>-->
        <!--        </template>-->
      </ElInput>

      <!-- AddDoctor -->
      <!-- //TODO -->
      <!-- <template #actions>
        <ElButton type="primary" @click="createDoctor">
          <template #icon>
            <MiIcon icon="PLUS" />
          </template>
          Добавить врача
        </ElButton>
      </template> -->
    </LayoutContentHeader>
    <DoctorsTable
      class="v-doctors-content__table"
      :data="items"
      :loading="loading"
      :total="total"
      v-model:page="page.value"
      v-model:per-page="perPage.value" />
  </MainLayout>
</template>

<script>
import axios from 'axios';
import { mapActions, mapState } from 'vuex';

import { MiIcon } from '~shared/ui';
import { useDatePeriod, usePage, usePerPage, useModel, useQuery } from '~shared/lib';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { Doctor } from '@/models/Doctor.model';
import { getMonthPeriod, ISOStringToDateAppFormat } from '@/utils/dateAndTime.utils';
import { DATE_FORMAT } from '~shared/config';
import { Specialty } from '@/models/Specialty.model';
import { MainLayout } from '~widgets/layouts';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import DoctorsTable from '@/components/doctors/DoctorsTable/index.vue';

export default {
  name: 'VDoctors',
  components: { DoctorsTable, LayoutContentHeader, MainLayout, MiIcon },
  setup: () => ({
    perPage: usePerPage(),
    page: usePage(),
    search: useQuery({ field: 'user' }),
    startAt: useQuery({
      field: 'start_at',
      defaultValue: ISOStringToDateAppFormat(new Date().toISOString()),
    }),
    endAt: useQuery({
      field: 'end_at',
      defaultValue: ISOStringToDateAppFormat(new Date().toISOString()),
    }),
    Specialty,
    date: useDatePeriod(getMonthPeriod()),
    DATE_FORMAT,
    specialty: useModel({
      fieldNames: {
        id: 'specialty_id',
        name: 'specialty_name',
      },
    }),
  }),

  data() {
    return {};
  },

  watch: {
    queryWatchers: {
      handler(value, oldValue) {
        compareQueriesThenLoadData({
          query: value,
          oldQuery: oldValue,
          getData: this.getDoctors,
          resetPage: this.page.reset,
        });
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    ...mapState({
      loading: (state) => state.doctors.loading,
      items: (state) => state.doctors.data,
      total: (state) => state.doctors.total,
    }),
    queryWatchers() {
      return {
        page: this.page.value,
        per_page: this.perPage.value,
        start_at: this.date.value[0],
        end_at: this.date.value[1],
        specialty_ids: this.specialty.value.id ?? undefined,
        search: this.search.value,
        query_field: ['name'],
        query_type: 'ILIKE',
      };
    },
  },

  methods: {
    ...mapActions({
      setLoading: 'doctors/setLoading',
      setData: 'doctors/setData',
    }),

    async getDoctors() {
      this.setLoading(true);
      try {
        const { data } = await Doctor.getDoctors(this.queryWatchers);
        this.setData({
          items: data.data,
          total: +data.meta.total,
          overwriteDataState: true,
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
      this.setLoading(false);
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
