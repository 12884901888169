import axios, { AxiosError } from 'axios';

export function mergeOrCreateQuery({
  url,
  query,
}: {
  url: string;
  query: Record<string, string | string[] | number | boolean>;
}) {
  const [urlExcludeQuery, inlineQueries] = url.split('?');

  const queries = new URLSearchParams(
    `${
      (inlineQueries || '') +
      Object.keys(query).reduce((acc, key) => acc + `&${key}=${query[key]}`, '')
    }`
  );

  return `${urlExcludeQuery}?${queries.toString()}`;
}

export const getApiErrorMessage = (err: unknown) => {
  let message = String(err);
  if (axios.isAxiosError(err)) {
    const error = err as AxiosError<{ message?: string }>;
    if (error.response?.data?.message) {
      message = error.response?.data?.message;
    } else {
      message = err.message;
    }
  }
  return message;
};
