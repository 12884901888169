<template>
  <ElTag :type="typeByStatus[status]">
    {{ $t(`Appointments.Statuses.${status}`) }}
  </ElTag>
</template>

<script>
import { Appointment } from '@/models/appointment/Appointment.model';

export default {
  name: 'AppointmentStatusTag',
  props: {
    status: String,
  },
  computed: {
    typeByStatus() {
      return {
        [Appointment.enum.statuses.Created]: '',
        [Appointment.enum.statuses.Approved]: 'warning',
        [Appointment.enum.statuses.Waiting]: 'info',
        [Appointment.enum.statuses.InProgress]: '',
        [Appointment.enum.statuses.Provided]: 'success',
        [Appointment.enum.statuses.Canceled]: 'danger',
      };
    },
  },
};
</script>
