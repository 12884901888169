{
  "en": {
    "Appointments": {
      "ActiveAppointments": "Active",
      "AddAppointment": "Add appointment",
      "AllAppointments": "All appointments",
      "Appointment": "Appointment",
      "AppointmentStatus": "Appointment status",
      "Appointments": "Appointments",
      "AppointmentsTc": "{n} records | {n} record | {n} records",
      "CallToReception": "Call for an appointment",
      "InviteToReception": "Invite for an appointment",
      "CancelReception": "Cancel recording",
      "ClickButton": "Click on the button",
      "Cancelled": "Cancelled",
      "ClosedAppointments": "Closed",
      "CreateAppointment": "Create Recording",
      "EditAppointment": "Edit appointment",
      "EditReception": "Edit Reception",
      "EndReception": "End reception",
      "GoToAppointment": "Back to reception",
      "InspectionCard": {
        "Anomnes": "Anamnesis",
        "AnomnesLife": "Anamnesis life",
        "GeneralState": "General condition",
        "LocalStatus": "Local status",
        "Operations": "Operations",
        "PlanObservation": "Survey Plan",
        "PreDiagnosis": "Preliminary diagnosis",
        "Recommendations": "Recommendations",
        "Сomplaints": "Complaints"
      },
      "InspectionTypes": {
        "full": "Full examination",
        "treatment": "Examination of the treatment diary"
      },
      "InvitePatient": "Invite a patient",
      "LiveQueue": "Live queue",
      "OnTopPanelInvitePatientAppointment": "on the top panel to invite the patient to the appointment",
      "PatientAppointment": "Patient appointment",
      "PatientCome": "Patient appeared",
      "PatientNotCome": "Patient did not appear",
      "PhonePatient": "Patient's phone",
      "Provided": "Completed",
      "ReOpen": "Reopen",
      "ReceptionCalendar": "Reception calendar",
      "RecordOnTime": "On time",
      "RecordingSource": "Record Source",
      "SaveTemplate": "Save as template",
      "SelectDiagnosis": "Select Diagnosis",
      "SelectDoctor": "Select Doctor",
      "SelectIDC": "Select IDC",
      "SelectRecordingSource": "Select recording source",
      "SelectReferralDoctor": "Select a referral doctor",
      "SelectServices": "Select services",
      "SelectStatus": "Select status",
      "SetControlAppointment": "Assign control appointment",
      "SetExamination": "Assign examination",
      "StartAppointment": "Start appointment",
      "StartDate": "Date of appointment",
      "StartDateAndTime": "Time and date of reception",
      "StartTime": "Time of reception",
      "Status": "Status",
      "Statuses": {
        "Status": "Statuses",
        "approved": "In queue",
        "canceled": "Canceled",
        "created": "Created",
        "in_progress": "In progress",
        "provided": "Finished",
        "waiting": "Invited"
      },
      "ToBeginExaminationInvitePatient": "To start the examination, invite the patient",
      "ToDiagnose": "Diagnose",
      "StatusModalTitle": {
        "Invite" : "To begin the examination, invite the patient",
        "Start" : "Start the examination if the patient appears",
        "End" : "Complete the appointment"
      },
      "StatusModalText": {
        "Invite" : "At the moment, the patient is in queue for your appointment, click on the “Invite” button so that he receives a notification in the queue monitor",
        "Start" : "The patient comes to see you, as soon as he comes to the office, it is necessary to begin the examination",
        "End" : "Are you sure you want to end the appointment? Check that all issues have been discussed."
      },
      "Types": {
        "doctor": "Doctor",
        "service": "Service"
      }
    }
  },
  "ru": {
    "Appointments": {
      "ActiveAppointments": "Активные",
      "AddAppointment": "Добавить назначения",
      "AllAppointments": "Все записи",
      "Appointment": "Прием",
      "AppointmentStatus": "Статус приема",
      "Appointments": "Приемы",
      "AppointmentsTc": "{n} записей | {n} запись | {n} записей",
      "CallToReception": "Вызвать на прием",
      "InviteToReception": "Пригласить на прием",
      "CancelReception": "Отменить запись",
      "ClickButton": "Нажмите на кнопку",
      "Cancelled": "Отмененные",
      "ClosedAppointments": "Закрытые",
      "CreateAppointment": "Создать запись",
      "EditAppointment": "Редактриовать назначения",
      "EditReception": "Редактировать прием",
      "EndReception": "Завершить прием",
      "GoToAppointment": "Вернуться к приёму",
      "InspectionCard": {
        "Anomnes": "Анамнез",
        "AnomnesLife": "Анамнез жизни",
        "GeneralState": "Общее состояние",
        "LocalStatus": "Локальный статус",
        "Operations": "Операции",
        "PlanObservation": "План обследования",
        "PreDiagnosis": "Предворительный диагноз",
        "Recommendations": "Рекомендации",
        "Сomplaints": "Жалобы"
      },
      "InspectionTypes": {
        "full": "Полный осмотр",
        "treatment": "Осмотр дневника лечения"
      },
      "InvitePatient": "Вызвать пациента",
      "LiveQueue": "Живая очередь",
      "OnTopPanelInvitePatientAppointment": "на верхней плашке, чтобы пригласить пациента на прием",
      "PatientAppointment": "Прием пациента",
      "PatientCome": "Пациент явился",
      "PatientNotCome": "Пациент не явился",
      "PhonePatient": "Телефон пациента",
      "Provided": "Завершенные",
      "ReOpen": "Переоткрыть",
      "ReceptionCalendar": "Календарь приемов",
      "RecordOnTime": "На время",
      "RecordingSource": "Источник записи",
      "SaveTemplate": "Сохранить как шаблон",
      "SelectDiagnosis": "Выберите диагноз",
      "SelectDoctor": "Выберите доктора",
      "SelectIDC": "Выбрать IDC",
      "SelectRecordingSource": "Выберите источник записи",
      "SelectReferralDoctor": "Выберите реферального доктора",
      "SelectServices": "Выберите услуги",
      "SelectStatus": "Выберите статус",
      "SetControlAppointment": "Назначить контрольный приём",
      "SetExamination": "Назначить обследование",
      "StartAppointment": "Начать приём",
      "StartDate": "Дата приёма",
      "StartDateAndTime": "Время и дата приёма",
      "StartTime": "Время приёма",
      "Status": "Статус",
      "Statuses": {
        "Status": "Статусы",
        "approved": "В очереди",
        "canceled": "Отменён",
        "created": "Создан",
        "in_progress": "На приеме",
        "provided": "Завершен",
        "waiting": "Приглашен"
      },
      "ToBeginExaminationInvitePatient": "Чтобы начать осмотр, пригласите пациента",
      "ToDiagnose": "Поставить диагноз",
      "StatusModalTitle": {
        "Invite" : "Чтобы начать осмотр, пригласите пациента  ",
        "Start" : "Начните осмотр, если пациент явился ",
        "End" : "Завершите прием"
      },
      "StatusModalText": {
        "Invite" : "На данный момент пациент стоит в очереди к вам на прием, нажмите на кнопку “Пригласить” , чтобы он получил уведомление в мониторе очереди",
        "Start" : "Пациент идет к вам на прием, как только он явился в кабинет , необходимо начать осмотр",
        "End" : "Вы уверены, что хотите завершить прием? Проверьте, все ли вопросы обсуждены"
      },
      "Types": {
        "doctor": "Врач",
        "service": "Услуга"
      }
    }
  },
  "uz": {
    "Appointments": {
      "ActiveAppointments": "Aktiv",
      "AddAppointment": "Uchrashuv qo'shish",
      "AllAppointments": "Barcha uchrashuvlar",
      "Appointment": "Uchrashuvlar",
      "AppointmentStatus": "Qabul holati",
      "Appointments": "Qabulga yozuvlar",
      "AppointmentsTc": "{n} yozuvlar | {n} yozuv | {n} yozuvlar",
      "CallToReception": "Qabul qilish",
      "InviteToReception": "Taklif  qilish",
      "CancelReception": "Qabulga yozuvni bekor qilish",
      "Cancelled": "Bekor qilingan",
      "ClickButton": "Tugmani bosing",
      "ClosedAppointments": "Yopiq",
      "CreateAppointment": "Qabulga yozuvni yaratish",
      "EditAppointment": "Uchrashuvlarni tahrirlash",
      "EditReception": "Qabulga yozuvni tahrirlash",
      "EndReception": "Qabulni yakunlash",
      "GoToAppointment": "Qabulga qaytish",
      "InspectionCard": {
        "Anomnes": "Anamnez",
        "AnomnesLife": "Hayotning anamnezi",
        "GeneralState": "Umumiy holat",
        "LocalStatus": "Mahalliy holat",
        "Operations": "Operatsiyalar",
        "PlanObservation": "So'rov rejasi",
        "PreDiagnosis": "Dastlabki tashxis",
        "Recommendations": "Tavsiyalar",
        "Сomplaints": "Shikoyatlar"
      },
      "InspectionTypes": {
        "full": "To'liq tekshirish",
        "treatment": "Davolash jurnalini ko'rib chiqish"
      },
      "InvitePatient": "Вызвать пациента",
      "LiveQueue": "Jonli navbat",
      "OnTopPanelInvitePatientAppointment": "yuqori panelida bemorni qabulga chaqiring",
      "PatientAppointment": "Bemorni tayinlash",
      "PatientCome": "Bemor tashrif buyurdi",
      "PatientNotCome": "Bemor tashrif buyurmadi",
      "PhonePatient": "Bemorning telefon raqami",
      "Provided": "Bajarildi",
      "ReOpen": "Qayta ochish",
      "ReceptionCalendar": "Qabullar kalendar",
      "RecordOnTime": "Vaqtga",
      "RecordingSource": "Qabulga yozuv manbai",
      "SaveTemplate": "Shablon sifatida saqlash",
      "SelectDiagnosis": "Diagnozni tanlang",
      "SelectDoctor": "Shifokorni tanlang",
      "SelectIDC": "IDC ni tanlang",
      "SelectRecordingSource": "Qabulga yozuv manbasini tanlang",
      "SelectReferralDoctor": "??Выберите реферального доктора??",
      "SelectServices": "Xizmatlarni tanlang",
      "SelectStatus": "Statusni tanlang",
      "SetControlAppointment": "Nazorat uchrashuvini o'rnatish",
      "SetExamination": "Tekshiruvni rejalashtirish",
      "StartAppointment": "Qabulni boshlash",
      "StartDate": "Qabul sanansi",
      "StartDateAndTime": "Qabul sanasi va vaqti",
      "StartTime": "Qabul vaqti",
      "Status": "Holati",
      "Statuses": {
        "Status": "Statuslar",
        "approved": "Navbatda",
        "canceled": "Bekor qilindi",
        "created": "Yaratildi",
        "in_progress": "Jarayonda",
        "provided": "Yakunlangan",
        "waiting": "Taklif qilingan"
      },
      "ToBeginExaminationInvitePatient": "Tekshirishni boshlash uchun bemorni chaqiring",
      "ToDiagnose": "Tashxis qo'yish",
      "StatusModalTitle": {
        "Invite" : "Qabulni boshlash uchun bemorni taklif qiling",
        "Start" : "Agar bemor paydo bo'lsa, qabulni boshlang",
        "End" : "Qabulni yakunlang"
      },
      "StatusModalText": {
        "Invite" : "Ayni paytda bemor sizning uchrashuvingizga navbatda turibdi, u navbat monitorida bildirishnoma olishi uchun \"Taklif qilish\" tugmasini bosing.",
        "Start" : "Bemor sizni ko'rish uchun keladi, u kabinetga kelishi bilanoq qabulni boshlash kerak",
        "End" : "Uchrashuvni tugatmoqchimisiz? Barcha masalalar muhokama qilinganligini tekshiring."
      },
      "Types": {
        "doctor": "Shifokor",
        "service": "Xizmat"
      }
    }
  }
}
