<template>
  <ElCard
    class="appointment-by-time-statistic-card"
    v-loading="loading"
    :header="$t('Statistic.AppointmentByTime')"
    :shadow="shadow">
    <div class="appointment-by-time-statistic-card__content">
      <div class="appointment-by-time-statistic-card__info appointment-by-time-statistic-card-info">
        <div class="appointment-by-time-statistic-card-info__value">{{ valueString }}</div>
        <div
          :class="[
            'appointment-by-time-statistic-card-info__percentage',
            percentageDirectionClass,
          ]">
          <div class="appointment-by-time-statistic-card-info__percentage-icon">
            <MiIcon :icon="isPercentageIncrease ? 'INCREASE_PERCENTAGE' : 'DECREASE_PERCENTAGE'" />
          </div>
          <div class="appointment-by-time-statistic-card-info__percentage-value">
            {{ percentageString }}
          </div>
        </div>
      </div>

      <Vue3Apexcharts
        class="appointment-by-time-statistic-card__chart"
        type="pie"
        width="190"
        :options="chartOptions"
        :series="series"
        ref="chart" />
    </div>

    <div class="appointment-by-time-statistic-card__legend">
      <div class="appointment-by-time-statistic-card__legend-item">
        <div class="appointment-by-time-statistic-card__legend-icon"></div>
        <div class="appointment-by-time-statistic-card__legend-text">
          {{ $t(`Statistic.AppointmentByTime`) }}
        </div>
      </div>
      <div class="appointment-by-time-statistic-card__legend-item">
        <div class="appointment-by-time-statistic-card__legend-icon"></div>
        <div class="appointment-by-time-statistic-card__legend-text">
          {{ $t(`Statistic.LiveQueuing`) }}
        </div>
      </div>
    </div>
  </ElCard>
</template>

<script>
import Vue3Apexcharts from 'vue3-apexcharts';

import { MiIcon } from '~shared/ui';

export default {
  name: 'AppointmentsOnTimeStatisticCard',
  components: { Vue3Apexcharts, MiIcon },
  props: {
    shadow: {
      type: String,
      default: 'never',
    },
    value: Number,
    percentage: Number,
    loading: Boolean,
  },
  computed: {
    valueNum() {
      if (this.value === null || this.value === undefined) return 0;
      return Number.isInteger(this.value) ? this.value : Number(this.value.toFixed(2));
    },

    percentageNum() {
      if (this.percentage === null || this.percentage === undefined) return 0;
      return this.percentage === 0 ? this.percentage : Number(this.percentage.toFixed(2));
    },

    valueString() {
      return `${this.valueNum}%`;
    },

    percentageString() {
      return (this.percentageNum > 0 ? `+${this.percentageNum}` : this.percentageNum) + '%';
    },

    isPercentageIncrease() {
      return this.percentageNum >= 0;
    },

    percentageDirectionClass() {
      return `appointment-by-time-statistic-card-info__percentage_${
        this.isPercentageIncrease ? 'success' : 'danger'
      }`;
    },

    series() {
      return [this.valueNum, 100 - this.valueNum];
    },

    chartOptions() {
      return {
        chart: {
          width: 190,
          type: 'pie',
        },
        plotOptions: {
          pie: {
            startAngle: 120,
            endAngle: -240,
          },
        },
        colors: ['#BA84FF', '#EF7AEA'],
        labels: [this.$t(`Statistic.AppointmentByTime`), this.$t(`Statistic.LiveQueuing`)],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        fill: {
          type: 'gradient',
        },
      };
    },
  },
  watch: {
    series: {
      handler(value) {
        this.$refs.chart.updateSeries(value);
      },
    },
    chartOptions: {
      handler(value) {
        this.$refs.chart.updateOptions(value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/statistic.locales.json" />
