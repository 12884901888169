<template>
  <FormSelect
    :options="operationTypes"
    field-name="typeOperation"
    field-object-name="typeOperationObject"
    :label="$t('Bookkeeping.Operation')"
    :placeholder="$t('Bookkeeping.SelectOperation')"
    :disabled="disabled"
    required />
  <FormTextField
    field-name="title"
    :disabled="disabled"
    :label="$t('Bookkeeping.OperationName')"
    :placeholder="$t('Bookkeeping.EnterNameOperation')"
    required />
  <FormTextField
    field-name="count"
    :disabled="disabled"
    mask-name="int-12"
    :label="$t('Bookkeeping.Qty')"
    :placeholder="$t('Bookkeeping.EnterNumberTransactions')"
    required />
  <FormTextField
    field-name="amount"
    :disabled="disabled"
    mask-name="int-12"
    :label="$t('Bookkeeping.TransactionAmount')"
    :placeholder="$t('Bookkeeping.EnterTransactionAmount')"
    required />
  <MiButton
    class="salary-details-add__btn"
    type="primary"
    @click="addOperation"
    :disabled="submitDisabled"
    >{{ $t('Base.Add') }}</MiButton
  >
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { object, string } from 'yup';
import { useForm } from 'vee-validate';

import { FormSelect, FormTextField } from '~shared/ui/form';
import { MiButton } from '~shared/ui';
import { OperationType, SalaryDetails } from '~shared/types';
import { I18nService } from '~shared/lib';

defineProps<{
  disabled?: boolean;
}>();

const emits = defineEmits<{
  addOperation: [event: SalaryDetails];
}>();

const operationTypes = computed<{ id: OperationType; title: string }[]>(() => {
  return [
    { id: 'plus', title: I18nService.t('Bookkeeping.OperationType.plus') },
    { id: 'minus', title: I18nService.t('Bookkeeping.OperationType.minus') },
  ];
});

const validationSchema = object({
  typeOperation: string().required(I18nService.t('Validation.RequiredField')),
  title: string().required(I18nService.t('Validation.RequiredField')),
  count: string().required(I18nService.t('Validation.RequiredField')),
  amount: string().required(I18nService.t('Validation.RequiredField')),
});

const {
  handleSubmit: submitOperation,
  meta,
  isSubmitting,
  errors,
  resetForm,
} = useForm<{
  reason_type: string | null;
  reason_id: number | null;
  typeOperation: OperationType;
  title: string;
  count: number;
  amount: number;
}>({
  validationSchema,
});

const submitDisabled = computed(
  () => Object.keys(errors.value).length > 0 || !meta.value.dirty || isSubmitting.value
);

const addOperation = submitOperation(
  async (values) => {
    emits('addOperation', values);
    resetForm();
  },
  (ctx) => {
    // eslint-disable-next-line no-console
    console.log('invalid submit', ctx.errors);
  }
);
</script>

<style lang="scss" src="./index.scss"></style>
