import axios from 'axios';
import { defineAsyncComponent } from 'vue';
import debounce from 'lodash.debounce';

import { Order } from '@/models/laboratory/Order.model';
import { Biomaterial } from '@/models/laboratory/Biomaterial.model';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import BiomaterialStatusTag from '@/components/laboratory/BiomaterialStatusTag/index.vue';
import StudiesTable from '@/components/laboratory/studies/StudiesTable/index.vue';
import SampleBiomaterialModal from '@/components/laboratory/SampleBiomaterialModal/index.vue';
import CreateStudyModal from '@/components/laboratory/studies/CreateStudyModal/index.vue';
import EditorCard from '@/components/EditorCard/index.vue';
import { MiIcon } from '@/shared/ui';
import { amplitudeService } from '@/shared/lib';
const TinyEditor = defineAsyncComponent(() => import('@/components/common/TinyEditor/index.vue'));

export default {
  name: 'OrderBiomaterialDataActionsCard',
  emits: [
    'update:order',
    'update:studys',
    'study:create',
    'study:update',
    'study:update:api',
    'study:delete',
    'biomaterial:update',
    'biomaterial:delete',
    'editor:update',
    'editor:change',
  ],
  components: { StudiesTable, BiomaterialStatusTag, EditorCard, MiIcon, TinyEditor },
  props: {
    order: [Order, Object],
    biomaterial: [Biomaterial, Object],
    /** @type {Array<Study|object>} studys */
    studys: Array,
    editable: Boolean,

    shadow: {
      type: String,
      default: 'never',
    },
  },
  data() {
    return {
      loading: {
        confirm: false,
        cancelConfirm: false,
        referencesGroup: false,
        delete: false,
      },
    };
  },
  computed: {
    biomaterialTitle() {
      return Biomaterial.getBiomaterialTitleWithAnalysisCategory(this.biomaterial);
    },

    isCardWithStudiesTable() {
      return !this.biomaterial.basic_data && Array.isArray(this.studys) && this.studys.length > 0;
    },

    isCardWithBasicData() {
      return !!this.biomaterial.basic_data;
    },
    isCardTinyMCE() {
      return this.biomaterial.type === 'tiny_mce';
    },
    isCardEditor() {
      return this.biomaterial.type === 'editor';
    },

    biomaterialStatus() {
      if (this.biomaterial.sampling_at && this.biomaterial.confirmed_at) {
        return Biomaterial.enum.statuses.Confirmed;
      }
      if (this.biomaterial.sampling_at && !this.biomaterial.confirmed_at) {
        return Biomaterial.enum.statuses.NotConfirmed;
      }
      if (!this.biomaterial.sampling_at) {
        return Biomaterial.enum.statuses.NotAssembled;
      }
    },

    actionsOptions() {
      return {
        sampling: {
          isShow:
            this.editable &&
            [
              Biomaterial.enum.statuses.NotAssembled,
              Biomaterial.enum.statuses.NotConfirmed,
            ].includes(this.biomaterialStatus),
          text:
            this.biomaterialStatus === Biomaterial.enum.statuses.NotConfirmed
              ? this.$t('Base.Print')
              : this.$t('Laboratory.SamplingBiomaterial'),
          plain: this.biomaterialStatus === Biomaterial.enum.statuses.NotConfirmed,
        },
        cancelConfirm: {
          isShow: this.editable && this.biomaterialStatus === Biomaterial.enum.statuses.Confirmed,
        },
        confirm: {
          isShow:
            this.editable && this.biomaterialStatus === Biomaterial.enum.statuses.NotConfirmed,
        },
        createStudy: {
          isShow:
            this.editable &&
            !this.biomaterial.basic_data &&
            this.biomaterialStatus === Biomaterial.enum.statuses.NotConfirmed,
        },
        remove: {
          isShow: this.editable && ![Order.enum.statuses.Canceled].includes(this.order.status),
        },
      };
    },

    studiesIsEditable() {
      return this.editable && this.biomaterialStatus === Biomaterial.enum.statuses.NotConfirmed;
    },
  },

  methods: {
    async sampleBiomaterial() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: SampleBiomaterialModal,
        payload: {
          biomaterial: this.biomaterial,
          patient: this.order.user,
          status: this.biomaterialStatus,
          orderId: this.order.id,
        },
      });

      if (!(action instanceof GlobalModalClose)) {
        this.sendAmplitudeFinishCollecting(this.biomaterial.id, this.order.id, this.order.user.id);
        this.$emit('biomaterial:update', action.data.biomaterial);
      }
    },

    async confirm() {
      this.loading.confirm = true;

      try {
        const { data } = await Biomaterial.updateStatusByBiomaterialId({
          id: this.biomaterial.id,
          status: Biomaterial.enum.statuses.Confirmed,
        });

        this.sendAmplitudeApprove(this.biomaterial.id, this.order.id, this.order.user.id);

        this.$notify({
          type: 'success',
          title: this.$t('Notifications.SuccessUpdated'),
        });
        this.$emit('biomaterial:update', data.data);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.confirm = false;
    },

    async cancelConfirm() {
      this.loading.cancelConfirm = true;

      try {
        const { data } = await Biomaterial.updateStatusByBiomaterialId({
          id: this.biomaterial.id,
          status: Biomaterial.enum.statuses.NotConfirmed,
        });

        this.$notify({
          type: 'success',
          title: this.$t('Notifications.SuccessUpdated'),
        });
        this.$emit('biomaterial:update', data.data);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.cancelConfirm = false;
    },

    async createStudy() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateStudyModal,
        payload: {
          biomaterialType: this.biomaterial.biomaterial_type,
          data: {
            biomaterial_id: this.biomaterial.id,
            order_id: this.order.id,
          },
        },
      });

      if (!(action instanceof GlobalModalClose)) {
        this.sendAmplitudeAddStudy();
        this.$emit('study:create', action.data.study);
      }
    },

    async deleteBiomaterial() {
      this.loading.delete = true;

      try {
        await Biomaterial.delete({ id: this.biomaterial.id });
        this.$emit('biomaterial:delete');
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.delete = false;
    },

    sendAmplitudeFinishCollecting(biomaterialId, orderId, patientId) {
      amplitudeService.logEvent('laboratory_order.finish_collecting', {
        biomaterial_id: biomaterialId,
        order_id: orderId,
        patient_id: patientId,
      });
    },

    sendAmplitudeApprove(biomaterialId, orderId, patientId) {
      amplitudeService.logEvent('laboratory_order.approve', {
        biomaterial_id: biomaterialId,
        order_id: orderId,
        patient_id: patientId,
      });
    },

    sendAmplitudeAddStudy() {
      amplitudeService.logEvent('laboratory_order.add_study');
    },
  },
  watch: {
    'biomaterial.basic_data': debounce(function (basicData) {
      this.$emit('editor:change', basicData);
    }, 500),
  },
};
