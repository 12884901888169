// Функции для работы с множествами

/**
 * Возвращает разность множеств
 * @param {Array< Array<number|string|boolean|null> >} sets
 * @return {Array<number|string|boolean|null>}
 */
export function getSetsDifference({ sets }) {
  const difference = new Set();

  sets.forEach((set, index) => {
    const otherSets = [...sets.slice(0, index), ...sets.slice(index + 1)];
    const otherSetsElems = otherSets.reduce((acc, set) => {
      return [...acc, ...set];
    }, []);

    set.forEach((elem) => {
      const hasInOther = otherSetsElems.includes(elem);
      if (!hasInOther) difference.add(elem);
    });
  });

  return Array.from(difference);
}
