{
  "en": {
    "Placeholder": "Select role"
  },
  "ru": {
    "Placeholder": "Выберите роль"
  },
  "uz": {
    "Placeholder": "Rol tanlang"
  }
}