export function cyrillicToEng(text) {
  return text
    .split('')
    .map(function (char) {
      return cyrillicToEngMap[char] || cyrillicToEngMap[char.toLowerCase()] || char;
    })
    .join('');
}

const cyrillicToEngMap = {
  'й': 'q',
  'ц': 'w',
  'у': 'e',
  'к': 'r',
  'е': 't',
  'н': 'y',
  'г': 'u',
  'ш': 'i',
  'щ': 'o',
  'з': 'p',
  'х': '[',
  'ъ': ']',
  'Х': '{',
  'Ъ': '}',
  // \: `\\`,
  'ф': 'a',
  'ы': 's',
  'в': 'd',
  'а': 'f',
  'п': 'g',
  'р': 'h',
  'о': 'j',
  'л': 'k',
  'д': 'l',
  'ж': ';',
  'Ж': ':',
  'э': "'",
  'Э': '"',
  'я': 'z',
  'ч': 'x',
  'с': 'c',
  'м': 'v',
  'и': 'b',
  'т': 'n',
  'ь': 'm',
  'б': ',',
  'Б': '<',
  'ю': '.',
  'Ю': '>',
  '.': '/',
  ',': '?',

  // numbers chars
  '!': '!',
  '"': '@',
  '№': '#',
  ';': '$',
  '%': '%',
  ':': '^',
  '?': '&',
  '*': '*',
  '(': '(',
  ')': ')',
  '_': '_',
  '+': '=',
};
