import noop from 'lodash.noop';
import { ref, onUnmounted } from 'vue';

import { DEFAULT_GET_DATA_INTERVAL, USER_KEY } from '~shared/config';

export function useGetDataByInterval(callback = noop, interval = DEFAULT_GET_DATA_INTERVAL) {
  const user = localStorage.getItem(USER_KEY);

  const getDataTimerId = ref<string | number | NodeJS.Timeout | undefined>();

  const clearTimer = () => clearTimeout(getDataTimerId.value);
  const getData = function () {
    if (!user) {
      return;
    }

    callback();

    if (getDataTimerId.value) clearTimer();
    getDataTimerId.value = setInterval(callback, interval);
  };

  onUnmounted(() =>
    setTimeout(() => {
      clearTimer();
      getDataTimerId.value = undefined;
    })
  );

  return getData;
}
