{
  "en": {
    "Editor": {
    }
  },
  "ru": {
    "Editor": {
      "ui": {
        "blockTunes": {
          "toggler": {
            "Click to tune": "Нажмите, чтобы настроить",
            "or drag to move": "или перетащите"
          }
        },
        "inlineToolbar": {
          "converter": {
            "Convert to": "Конвертировать в"
          }
        },
        "toolbar": {
          "toolbox": {
            "Add": "Добавить"
          }
        }
      },
      "toolNames": {
        "Text": "Параграф",
        "Heading": "Заголовок",
        "List": "Список",
        "Warning": "Примечание",
        "Checklist": "Чеклист",
        "Quote": "Цитата",
        "Code": "Код",
        "Delimiter": "Разделитель",
        "Raw HTML": "HTML-фрагмент",
        "Table": "Таблица",
        "Link": "Ссылка",
        "Marker": "Маркер",
        "Bold": "Полужирный",
        "Image": "Изображение",
        "Italic": "Курсив",
        "InlineCode": "Моноширинный",
        "Columns": "Колонки"
      },
      "blockTunes": {
        "delete": {
          "Delete": "Удалить"
        },
        "moveUp": {
          "Move up": "Переместить вверх"
        },
        "moveDown": {
          "Move down": "Переместить вниз"
        },
        "CallOut": "Акцентировать",
        "Citation": "Цитата",
        "Details": "Подробности"
      }
    }
  },
  "uz": {
    "Editor": {
      "ui": {
        "blockTunes": {
          "toggler": {
            "Click to tune": "Moslashtirish uchun bosing",
            "or drag to move": "yoki torting"
          }
        },
        "inlineToolbar": {
          "converter": {
            "Convert to": "O'zgartirish"
          }
        },
        "toolbar": {
          "toolbox": {
            "Add": "Qo'shish"
          }
        }
      },
      "toolNames": {
        "Text": "Matn",
        "Heading": "Sarlavha",
        "List": "Ro'yxat",
        "Warning": "Eslatma",
        "Checklist": "Tekshirish ro'yxati",
        "Quote": "Iqtibos",
        "Code": "Kod",
        "Delimiter": "Boluvchi belgi",
        "Raw HTML": "HTML",
        "Table": "Jadval",
        "Link": "Havola",
        "Marker": "Belgilash",
        "Bold": "Qalin",
        "Image": "Rasm",
        "Italic": "Kursiv",
        "InlineCode": "Tekst kod",
        "Columns": "Ustunlari"
      },
      "blockTunes": {
        "delete": {
          "Delete": "O'chirish"
        },
        "moveUp": {
          "Move up": "Yuqoriga ko'tarish"
        },
        "moveDown": {
          "Move down": "Pastga ko'tarish"
        },
        "CallOut": "Ta’kidlab o‘tish",
        "Citation": "Iqtibos",
        "Details": "Tafsilotlar"
      }
    }
  }
}
