<template>
  <RouterLink
    v-if="showActiveAppointmentPageLink"
    :to="ROUTE_PATH.appointments.appointment.byId(activeAppointmentId)">
    <MiButton type="primary">
      <template #icon>
        <MiIcon icon="CHEVRON_LEFT" />
      </template>
      {{ $t('Appointments.GoToAppointment') }}
    </MiButton>
  </RouterLink>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { useSessionStore } from '~entities/session';
import { ROUTE_PATH } from '~shared/config';
import { MiButton, MiIcon } from '~shared/ui';

const sessionStore = useSessionStore();
const { appointmentDataForCurrentUser } = storeToRefs(sessionStore);

const showActiveAppointmentPageLink = computed(
  () => !!appointmentDataForCurrentUser.value?.isActiveAppointment
);

const activeAppointmentId = computed(() => {
  if (
    appointmentDataForCurrentUser.value.isActiveAppointment &&
    appointmentDataForCurrentUser.value.appointmentId !== null
  ) {
    return appointmentDataForCurrentUser.value.appointmentId;
  }

  return '';
});
</script>
