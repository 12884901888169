{
  "en": {
    "InputLabel": "Search for patients..."
  },
  "ru": {
    "InputLabel": "Поиск пациентов..."
  },
  "uz": {
    "InputLabel": "Bemorlarni topish..."
  }
}