<template>
  <MainLayout content-class="v-nomenclature-content" fix-height>
    <LayoutContentHeader>
      <template #actions>
        <ElButton type="primary" @click="onCreateButtonClick">
          {{ `+ ${$t('Product.CreateProduct')}` }}
        </ElButton>
      </template>
      <template #append>
        <ElInput
          v-model="searchProduct.value"
          class="v-nomenclature-content-input"
          :placeholder="$t('Income.SearchGoods')"
          @input="debouncedInput">
          <template #prefix>
            <MiIcon icon="SEARCH" />
          </template>
        </ElInput>
      </template>
    </LayoutContentHeader>
    <NomenclatureTable
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :items="items"
      :total="total"
      @modal:open="onEditButtonClick" />
  </MainLayout>
</template>

<script lang="ts">
export default {
  name: 'VNomenclature',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import axios from 'axios';
import debounce from 'lodash.debounce';
import { ref, computed, watch } from 'vue';
import { ElNotification } from 'element-plus';
import { useStore } from 'vuex';

import { MiIcon } from '~shared/ui';
import { Product } from '@/models/warehouse/Product.model';
import { I18nService } from '~shared/lib';
import { usePerPage, usePage, useQuery } from '~shared/lib';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { ProductDto } from '@/types/api';
import { MainLayout } from '~widgets/layouts';
import NomenclatureTable from '@/components/warehouse/tables/NomenclatureTable/index.vue';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import ProductCrudModal from '@/components/warehouse/modals/ProductCrudModal/index.vue';

const store = useStore();
const loading = ref(false);
const perPage = usePerPage();
const page = usePage();
const searchProduct = useQuery({ field: 'title' });
const debouncedSearchString = ref(searchProduct.value);

const total = computed(() => store.state.nomenclature.total);
const items = computed(() => store.state.nomenclature.data);
const query = computed(() => ({
  per_page: perPage.value,
  page: page.value,
  title: debouncedSearchString.value,
}));

const getProducts = async () => {
  loading.value = true;
  try {
    const { data } = await Product.getItems(query.value);
    store.dispatch('nomenclature/setData', {
      items: data.data,
      total: +data.meta.total,
      overwriteDataState: true,
    });
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  } finally {
    loading.value = false;
  }
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getProducts,
      resetPage: page.reset,
      fieldsForResetPage: ['title'],
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  await store.dispatch('modalAndDrawer/openModal', ProductCrudModal);
};

const onEditButtonClick = async (payload: ProductDto) => {
  await store.dispatch('modalAndDrawer/openModal', {
    component: ProductCrudModal,
    payload: {
      formData: payload,
    },
  });
};

const debouncedInput = debounce((value) => {
  debouncedSearchString.value = value;
}, 600);
</script>

<style lang="scss">
.v-nomenclature-content {
  &-input {
    width: 500px;
  }
}
</style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/warehouse.locales.json" />
