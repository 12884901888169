{
  "en": {
    "MedicalCard": {
      "AddHospital": "Add hospital",
      "Analyzes": "Analyzes",
      "Appointment": "Appointment",
      "Chronology": "Chronology",
      "CoursesOfTreatment": "Courses of treatment",
      "CurrentAppointment": "Current appointment",
      "Hospitalization": "Hospitalization",
      "MedicalCard": "Medical card",
      "Relatives": "Relatives",
      "Sightseeing": "Sightseeing",
      "Vaccination": "Vaccination"
    }
  },
  "ru": {
    "MedicalCard": {
      "AddHospital": "Добавить стационар",
      "Analyzes": "Анализы",
      "Appointment": "Прием",
      "Chronology": "Хронология",
      "CoursesOfTreatment": "Курсы лечений",
      "CurrentAppointment": "Текущий осмотр",
      "Hospitalization": "Стационар",
      "MedicalCard": "Медицинская карта",
      "Relatives": "Родственники",
      "Sightseeing": "Осмотры",
      "Vaccination": "Вакцинация"
    }
  },
  "uz": {
    "MedicalCard": {
      "AddHospital": "Statsionar qo'shish",
      "Analyzes": "Tahlillar",
      "Appointment": "Uchrashuvlar",
      "Chronology": "Xronologiya",
      "CoursesOfTreatment": "??Davolash kurslari",
      "CurrentAppointment": "Joriy qabul",
      "Hospitalization": "Kasalxonaga yotqizish",
      "MedicalCard": "Tibbiy karta",
      "Relatives": "Qarindoshlar",
      "Sightseeing": "??Ko'rik",
      "Vaccination": "Emlash"
    }
  }
}
