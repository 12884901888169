<template>
  <UiTableWithPagination
    :data="items"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)">
    <ElTableColumn prop="article" :label="$t('Nomenclature.VendorCode')" width="180px" />
    <ElTableColumn prop="title" :label="$t('Nomenclature.Name')" />
    <ElTableColumn prop="price" :label="$t('Nomenclature.SellingPrice')" width="180px" />
    <ElTableColumn prop="description" :label="$t('Nomenclature.Notes')" />
    <ElTableColumn :label="$t('Nomenclature.Actions')" width="180px">
      <template #default="{ row }">
        <ElButton size="small" type="primary" plain @click.stop="$emit('modal:open', row)"
          >{{ $t('Nomenclature.Edit') }}
        </ElButton>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script lang="ts">
import { PropType } from 'vue';

import { ProductDto } from '@/types/api';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';

export default {
  name: 'NomenclatureTable',
  components: {
    UiTableWithPagination,
  },
  emits: ['update:page', 'update:perPage', 'modal:open'],
  props: {
    loading: { type: Boolean, required: true },
    items: { type: Array as PropType<ProductDto[]>, required: true },
    page: { type: Number, required: true },
    perPage: { type: Number, required: true },
    total: { type: Number, required: true },
  },
};
</script>

<style lang="scss" scoped></style>
<i18n src="@/locales/warehouse.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
