<template>
  <ElCard
    :class="['mi-card', { 'mi-card_with-shadow': boxShadow }]"
    v-bind="$props"
    ref="elCard"
    shadow="never">
    <template v-if="$slots.header" #header>
      <slot name="header"></slot>
    </template>
    <slot></slot>
  </ElCard>
</template>

<script setup lang="ts">
import { ElCard, CardProps } from 'element-plus';
import { ref } from 'vue';

type Props = {
  boxShadow?: boolean;
} & Partial<CardProps>;

withDefaults(defineProps<Props>(), {
  boxShadow: true,
});

const elCard = ref<InstanceType<typeof ElCard> | null>(null);
</script>

<style lang="scss" src="./index.scss" />
