import axios from 'axios';

import { PATIENT_ROUTE } from '@/router/patients.routes';
import { HOSPITALS_ROUTE } from '@/router/hospital.routes';
import { insertRouteParams } from '@/utils/router.utils';
import { Hospital } from '@/models/hospital/Hospital.model';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { MainLayout } from '~widgets/layouts';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import PatientCardRow from '@/components/patients/PatientCardRow/index.vue';
import HospitalCard from '@/components/hospitals/HospitalCard/index.vue';
import InvoicesTable from '@/components/invoices/InvoicesTable/index.vue';
import TreatmentsTable from '@/components/treatments/TreatmentsTable/index.vue';
import HospitalStatusTag from '@/components/hospitals/HospitalStatusTag/index.vue';
import CreateOrEditHospitalModal from '@/components/hospitals/CreateOrEditHospitalModal/index.vue';
import CreateOrEditTreatmentModal from '@/components/treatments/CreateOrEditTreatmentModal/index.vue';

export default {
  name: 'VHospital',
  components: {
    MainLayout,
    LayoutContentHeader,
    PatientCardRow,
    HospitalCard,
    InvoicesTable,
    TreatmentsTable,
    HospitalStatusTag,
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      loading: {
        hospital: false,
        invoice: false,
        close: false,
        cancel: false,
      },
      /** @type {Hospital} hospital */
      hospital: new Hospital(),
    };
  },
  computed: {
    actionsVisible() {
      return {
        edit: this.hospital.status === Hospital.enum.statuses.IN_PROGRESS,
        close: this.hospital.status === Hospital.enum.statuses.IN_PROGRESS,
        cancel: this.hospital.status === Hospital.enum.statuses.IN_PROGRESS,
        bill: true,
        treatment: this.hospital.status === Hospital.enum.statuses.IN_PROGRESS,
      };
    },

    patientItems() {
      return [
        {
          label: this.$t('User.Phone'),
          value: this.hospital?.user?.phone,
        },
        {
          label: this.$t('User.Birthdate'),
          value: this.hospital?.user?.birthdate,
        },
        {
          label: this.$t('User.Gender'),
          value: this.hospital?.user?.gender
            ? this.$t(`User.Genders.${this.hospital.user.gender}`)
            : '...',
        },
      ];
    },

    ambulatoryCardPageLink() {
      return insertRouteParams({
        path: PATIENT_ROUTE.childrenMap.PATIENT_ROUTE_AMBULATORY_CARD._fullPath,
        params: { id: this.hospital?.user?.id },
      });
    },
  },
  watch: {
    id: {
      handler() {
        this.getHospital();
      },
      immediate: true,
    },
  },

  methods: {
    async getHospital() {
      this.loading.hospital = true;
      const { hospital } = await Hospital.findOneById(this.id);
      this.hospital = hospital;
      this.loading.hospital = false;
    },

    async edit() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditHospitalModal,
        payload: { id: this.id },
      });

      if (!(action instanceof GlobalModalClose)) this.hospital = action.data.hospital;
    },

    async bill() {
      if (this.loading.invoice) return;
      this.loading.invoice = true;

      try {
        const { invoice } = await Hospital.getInvoiceById(this.hospital.id);
        this.hospital.invoice_ids = [invoice.id];
        this.hospital.invoices = [invoice];
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading.invoice = false;
    },

    async addTreatment() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditTreatmentModal,
        payload: {
          user: this.hospital.user,
        },
      });

      if (!(action instanceof GlobalModalClose)) {
        this.hospital.treatment_ids.push(action.data.treatment.id);
        this.hospital.treatments.push(action.data.treatment);
      }
    },

    async updateTreatment(item) {
      const index = this.hospital.treatments.findIndex((elem) => elem.id == item.id);
      this.hospital.treatments = [
        ...this.hospital.treatments.slice(0, index),
        item,
        ...this.hospital.treatments.slice(index + 1),
      ];
    },

    /** @return {Promise<boolean>} false - error */
    async updateStatus(status) {
      try {
        await Hospital.updateStatusById(this.hospital.id, status);

        this.$notify({
          type: 'success',
          title: this.$t('Notifications.SuccessUpdated'),
        });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });

        return false;
      }

      return true;
    },

    async close() {
      if (this.loading.close) return;
      this.loading.close = true;

      const isUpdated = await this.updateStatus(Hospital.enum.statuses.COMPLETED);
      if (isUpdated) this.$router.push(HOSPITALS_ROUTE.path);

      this.loading.close = false;
    },

    async cancel() {
      if (this.loading.cancel) return;
      this.loading.cancel = true;

      const isUpdated = await this.updateStatus(Hospital.enum.statuses.CANCELED);
      if (isUpdated) this.$router.push(HOSPITALS_ROUTE.path);

      this.loading.cancel = false;
    },
  },
};
