<template>
  <ElCard class="hospital-card" :shadow="shadow">
    <div class="hospital-card-content" v-for="(elems, key) in items" :key="key">
      <div
        class="hospital-card-content__item hospital-card-content-item"
        v-for="elem in elems"
        :key="elem.value">
        <div class="hospital-card-content-item__label">{{ elem.label }}</div>
        <div class="hospital-card-content-item__value">{{ elem.value }}</div>
      </div>
    </div>
  </ElCard>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss"></style>
