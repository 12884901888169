<template>
  <MainLayout content-class="v-warehouses-content" fix-height>
    <LayoutContentHeader>
      <!-- Date -->
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <UiModelsAutocompleteSearch
        class="v-warehouses-content__products"
        v-model="productIds.value"
        :model-for-use="Product"
        method-name="getItems"
        label="title"
        value="id"
        :placeholder="$t('Income.SearchGoods')"
        multiple />

      <!-- Warehouse -->
      <UiModelsAutocompleteSearch
        class="v-hospital-header__department"
        :model-value="warehouse.value.id"
        :model-for-use="Warehouse"
        label="title"
        :default-item="warehouse.value.id ? warehouse.value : null"
        :placeholder="$t('Income.Warehouse')"
        clearable
        @select="warehouse.value = $event" />

      <template #actions>
        <ElButton type="primary" @click="onCreateButtonClick">
          {{ $t('TransferGoods.TransferGoods') }}
        </ElButton>
      </template>
    </LayoutContentHeader>
    <GoodsStockTable
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :items="items"
      :total="total" />
  </MainLayout>
</template>

<script lang="ts">
export default {
  name: 'VGoodsStock',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import axios from 'axios';
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import { ElNotification } from 'element-plus/es';

import { useDatePeriod, usePerPage, usePage, useModel, useQuery } from '~shared/lib';
import { I18nService } from '~shared/lib';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { DATE_FORMAT } from '~shared/config';
import { Warehouse } from '@/models/warehouse/Warehouse.model';
import { Product } from '@/models/warehouse/Product.model';
import { MainLayout } from '~widgets/layouts';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import GoodsStockTable from '@/components/warehouse/tables/GoodsStockTable/index.vue';
import TransferGoodsCrudModal from '@/components/warehouse/modals/TransferGoodsCrudModal/index.vue';

const store = useStore();
const loading = ref(false);
const perPage = usePerPage();
const page = usePage();
const warehouse = useModel({
  fieldNames: {
    id: 'warehouse_id',
    name: 'warehouse_name',
  },
});

const date = useDatePeriod(getMonthPeriod()); // TODO типизировать useDatePeriod
const productIds = useQuery({ field: 'product_ids', valueIsNumberArray: true });
const total = computed(() => store.state.goodsStock.total); // TODO типизировать store
const items = computed(() => store.state.goodsStock.data); // TODO типизировать store
const query = computed(() => ({
  per_page: perPage.value,
  page: page.value,
  start_at: date.value[0],
  end_at: date.value[1],
  warehouse_id: warehouse.value.id,
  product_ids: productIds.value,
}));

const getGoodsStock = async () => {
  loading.value = true;
  try {
    const { data } = await Product.getItemsWithCountByWarehouseId(query.value);
    store.dispatch('goodsStock/setData', {
      // TODO: передаю индекс чтобы не схлопывались строки с одинаковыми товарами, нужно переделать бэк
      items: data.data.map((item, index) => ({ ...item, id: index })),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      total: +data.meta.total,
      overwriteDataState: true,
    });
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  } finally {
    loading.value = false;
  }
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      // TODO типизировать compareQueriesThenLoadData
      query: value,
      oldQuery: oldValue,
      getData: getGoodsStock,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  await store.dispatch('modalAndDrawer/openModal', TransferGoodsCrudModal);
};
</script>

<style lang="scss">
.v-warehouses-content {
  .layout-content-header-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
