import { CRUDModel } from '@/models/CRUD.model';

export class ReferencesValue extends CRUDModel {
  static modelName = 'referencesValue';
  static tableName = 'references/values';

  /**
   * @param {object} payload
   * @param {number|string} payload.start_value
   * @param {number|string} payload.end_value
   * @param {string} payload.norm_text
   * @param {number} payload.references_group_id
   * @param {ReferencesGroup|object} payload.references_group
   * @param {number} payload.analysis_id
   * @param {Analysis} payload.analysis
   * @param {string} payload.created_at DATE_FORMAT
   */
  constructor(payload) {
    super(payload);

    this.start_value = payload?.start_value ?? null;
    this.end_value = payload?.end_value ?? null;
    this.norm_text = payload?.norm_text ?? null;
    this.references_group_id = payload?.references_group_id ?? null;
    this.references_group = payload?.references_group ?? null;
    this.analysis_id = payload?.analysis_id ?? null;
    this.analysis = payload?.analysis ?? null;
    this.created_at = payload?.created_at ?? null;
  }
}
