import { computed } from 'vue';

import { WorkingTimeDto } from '~shared/api';
import { I18nService } from '~shared/lib';
import { DayOfWeekEnum } from '~shared/types';

const daysOfWeek: DayOfWeekEnum[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const dayAbbreviations = computed((): Record<DayOfWeekEnum, string> => {
  return {
    monday: I18nService.t('Base.DaysOfWeek.Monday'),
    tuesday: I18nService.t('Base.DaysOfWeek.Tuesday'),
    wednesday: I18nService.t('Base.DaysOfWeek.Wednesday'),
    thursday: I18nService.t('Base.DaysOfWeek.Thursday'),
    friday: I18nService.t('Base.DaysOfWeek.Friday'),
    saturday: I18nService.t('Base.DaysOfWeek.Saturday'),
    sunday: I18nService.t('Base.DaysOfWeek.Sunday'),
  };
});

export const prepareWorkingTime = (workingTime: WorkingTimeDto | undefined) => {
  if (!workingTime) {
    return [];
  }

  const scheduleList: {
    day: string;
    time: string;
  }[] = [];

  if (workingTime) {
    daysOfWeek.forEach((day) => {
      const startTime = workingTime?.[`${day}_start` as keyof typeof workingTime];
      const endTime = workingTime?.[`${day}_end` as keyof typeof workingTime];

      const dayAbbreviation =
        dayAbbreviations.value[day.toLowerCase() as keyof typeof dayAbbreviations.value];
      const timeRange =
        startTime && endTime
          ? `${startTime} - ${endTime}`
          : I18nService.t('Base.DaysOfWeek.DayOff');

      scheduleList.push({
        day: dayAbbreviation ?? '...',
        time: timeRange,
      });
    });
  } else {
    scheduleList.push({
      day: '...',
      time: '...',
    });
  }

  return scheduleList;
};
