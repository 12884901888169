<template>
  <ElDialog
    class="create-biomaterial-modal"
    :model-value="modelValue"
    :title="$t('Laboratory.Biomaterial.AddBiomaterial')"
    @update:model-value="$emit('update:modelValue')">
    <ElForm id="create-biomaterial-modal-form" label-position="top" @submit.prevent="submitHandler">
      <ElFormItem :label="$t('Base.Naming')">
        <SelectBiomaterial v-model="biomaterialType" required />
      </ElFormItem>
    </ElForm>

    <template #footer>
      <ElButton
        type="primary"
        native-type="submit"
        form="create-biomaterial-modal-form"
        :loading="loading">
        {{ $t('Base.Create') }}
      </ElButton>
    </template>
  </ElDialog>
</template>

<script>
import axios from 'axios';

import { Biomaterial } from '@/models/laboratory/Biomaterial.model';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import SelectBiomaterial from '@/components/laboratory/SelectBiomaterialType/index.vue';

export default {
  name: 'CreateBiomaterialModal',
  components: { SelectBiomaterial },
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    orderId: [Number, String],
  },
  data() {
    return {
      loading: false,
      biomaterialType: null,
    };
  },
  methods: {
    async submitHandler() {
      this.loading = true;

      try {
        const { biomaterial } = await Biomaterial.create({
          biomaterial_type: this.biomaterialType,
          order_id: this.orderId,
        });

        this.$notify({
          type: 'success',
          title: this.$t('Notifications.SuccessCreated'),
        });
        this.$emit(
          'action',
          new GlobalModalInstance({
            name: 'created',
            data: { biomaterial },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" src="./index.scss"></style>
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
