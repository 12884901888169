<template>
  <div class="services-by-category">
    <div class="services-by-category__header">
      <div class="services-by-category__header-title">
        <slot name="header">{{ categoryData.title }} </slot>
      </div>
      <div class="services-by-category__header-actions">
        <slot name="actions"></slot>
      </div>
    </div>

    <div class="services-by-category__items">
      <div class="services-by-category__item" v-for="item in groupServices" :key="item.id">
        <ElCheckbox
          :label="item.title"
          v-model="item.checked"
          size="large"
          @change="(checked) => onChange(item, checked)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ServicesByCategory',
  props: {
    categoryData: { type: Object, defaultValue: {} },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      appointedServices: (state) => state.recommendations.appointedServices,
    }),

    groupServices() {
      return this.categoryData?.groupServices.map((item) => this.getIsChecked(item));
    },
  },
  methods: {
    ...mapActions({
      updateAppointedServices: 'recommendations/updateAppointedServices',
    }),

    onChange(item, checked) {
      this.updateAppointedServices([{ ...item, checked }]);
    },

    getIsChecked(payload) {
      const finded = this.appointedServices?.find((f) => f.id === payload.id);
      return { ...payload, checked: finded ? finded.checked : false };
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
