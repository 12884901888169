import { CRUDModel } from '@/models/CRUD.model';

export class ServiceCategory extends CRUDModel {
  static modelName = 'serviceCategory';
  static tableName = 'services/categories';

  constructor(payload) {
    super(payload);
    this.title = payload?.title ?? null;
    this.parent_id = payload?.parent_id ?? null;
  }
}
