import { useQuery } from './useQuery';

import { PAGE } from '~shared/config';

type UsePageOptions = {
  field?: string;
};

export function usePage(
  defaultValue: number | undefined = undefined,
  options: UsePageOptions = {}
) {
  return useQuery({
    field: options.field ?? PAGE,
    defaultValue: defaultValue ?? 1,
    valueIsNumber: true,
  });
}
