<template>
  <ElTable :data="itemsWithPayload">
    <ElTableColumn prop="analysis.title" :label="$t('Base.Naming')" />

    <ElTableColumn prop="value" :label="$t('Laboratory.Results')">
      <template #default="{ row }">
        <StudyValue
          :model-value="row.value"
          :study="row"
          :editable="editable"
          @update:model-value="updateValueHandler(row, $event)"
          @change="$emit('item:change', row)"
          @update:study="$emit('item:change', $event)" />
      </template>
    </ElTableColumn>

    <ElTableColumn prop="analysis.measure" :label="$t('Base.Measure')" />
    <ElTableColumn prop="references_group" :label="$t('Laboratory.ReferencesGroup')">
      <template #default="{ row }">
        <SelectReferencesGroup
          v-if="editable"
          :model-value="row.references_value?.references_group?.id"
          :default-item="row.references_value?.references_group"
          :search-query="{ study_id: row.id }"
          @select="selectReferencesGroup(row, $event)" />
        <span v-if="!editable">...</span>
      </template>
    </ElTableColumn>
    <ElTableColumn prop="_references_value" :label="$t('Laboratory.ReferencesInterval')" />

    <ElTableColumn v-if="editable" label="" width="70px">
      <template #default="{ row }">
        <ElButton
          type="danger"
          text
          :loading="loading && deletedItemId === row.id"
          @click="deleteItem(row)">
          <template #icon> <MiIcon icon="TRASH" /> </template>
        </ElButton>
      </template>
    </ElTableColumn>
  </ElTable>
</template>

<script>
import axios from 'axios';

import { Study } from '@/models/laboratory/Study.model';
import StudyValue from '@/components/laboratory/studies/StudyValue/index.vue';
import SelectReferencesGroup from '@/components/laboratory/referencesGroups/SelectReferencesGroup/index.vue';
import { MiIcon } from '~shared/ui';

export default {
  name: 'StudiesTable',
  components: { SelectReferencesGroup, StudyValue, MiIcon },
  emits: ['item:update', 'item:change', 'item:delete'],
  props: {
    items: Array,
    editable: Boolean,
  },
  data() {
    return {
      loading: false,
      deletedItemId: null,
    };
  },
  computed: {
    itemsWithPayload() {
      return this.items.map((elem) => {
        return {
          ...elem,
          _references_value: Study.getReferencesValueString(elem) ?? '...',
        };
      });
    },
  },

  methods: {
    updateValueHandler(study, value) {
      this.$emit('item:update', { ...study, value });
    },

    async deleteItem(study) {
      if (this.loading) return;
      this.loading = true;
      this.deletedItemId = study.id;

      try {
        await Study.delete({ id: study.id });
        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessDeleted') });
        this.$emit('item:delete', study);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.deletedItemId = null;
      this.loading = false;
    },

    selectReferencesGroup(study, referencesGroup) {
      this.$emit('item:change', {
        ...study,
        references_group_id: referencesGroup?.id,
        references_group: referencesGroup,
      });
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/laboratory.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
