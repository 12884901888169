import axios from 'axios';

import { insertRouteParams } from '@/utils/router.utils';
import { PATIENT_ROUTE } from '@/router/patients.routes';
import { PrinterService } from '@/services/printer.service';
import { Patient } from '@/models/Patient.model';
import { User } from '@/models/User.model';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { ISOStringToDateAppFormat } from '@/utils/dateAndTime.utils';
import { DiseaseCode } from '@/models/DiseaseCode.model';
import AppointmentsTable from '@/components/appointments/AppointmentsTable/index.vue';
import AnalyzesStatusesTable from '@/components/patients/AnalyzesStatusesTable/index.vue';
import PatientsTable from '@/components/patients/PatientsTable/index.vue';
import HospitalsTable from '@/components/hospitals/HospitalsTable/index.vue';
import TreatmentsTable from '@/components/treatments/TreatmentsTable/index.vue';
import PatientCardRow from '@/components/patients/PatientCardRow/index.vue';
import CreateOrEditPatientModal from '@/components/patients/CreateOrEditPatientModal/index.vue';
import InvoicesTable from '@/components/invoices/InvoicesTable/index.vue';
import OrdersTable from '@/components/laboratory/OrdersTable/index.vue';
import { useSessionStore } from '~entities/session';
import { ROUTE_PATH } from '~shared/config';

export default {
  name: 'VPatientDefault',
  emits: [
    'update:patient',
    'appointment:create',
    'patient:createChildren',
    'treatment:create',
    'treatment:updated',
    'hospital:create',
    'invoice:create',
  ],
  components: {
    AnalyzesStatusesTable,
    AppointmentsTable,
    PatientsTable,
    TreatmentsTable,
    PatientCardRow,
    InvoicesTable,
    OrdersTable,
    HospitalsTable,
  },
  props: {
    patient: [Patient, Object],
    /**@param {Array<invoices>} */
    invoices: [Array],

    /**@param {Array<hospital>} */
    hospital: Array,

    /**@param {Array<orders>} */
    orders: [Array],

    /** @param {Array<Appointment>} appointments */
    appointments: Array,
    /** @param {Array<Treatment>} treatments */
    treatments: Array,

    /** @type {VPatientLoading|object} loading */
    loading: Object,
  },
  data() {
    return {
      braceletLoading: false,
      sessionStore: useSessionStore(),
    };
  },

  setup: () => ({
    DiseaseCode,
  }),

  computed: {
    user() {
      return this.sessionStore.user;
    },

    isDirectorOrManager() {
      return (
        this.user.role === User.enum.roles.Director || this.user.role === User.enum.roles.Manager
      );
    },

    isDoctor() {
      return this.user.role === User.enum.roles.Doctor;
    },
    isManager() {
      return this.user.role === User.enum.roles.Manager;
    },
    patientItems() {
      return [
        {
          label: this.$t('User.Phone'),
          value: this.patient.phone,
        },
        {
          label: this.$t('User.Birthdate'),
          value: this.patient.birthdate,
        },
        {
          label: this.$t('User.Gender'),
          value: this.patient.gender ? this.$t('User.Genders.' + this.patient.gender) : null,
        },
        {
          label: this.$t('User.PlaceResidence'),
          value: this.patient.place_residence,
        },
        {
          label: this.$t('User.Children'),
          value: this.patient.childrens?.length > 0 ? this.$t('Base.Yes') : this.$t('Base.No'),
        },
      ];
    },
    patientAmbulatoryCardPageLink() {
      return insertRouteParams({
        path: PATIENT_ROUTE.childrenMap.PATIENT_ROUTE_AMBULATORY_CARD._fullPath,
        params: {
          id: this.patient.id,
        },
      });
    },
    excludeColumns() {
      return ['patient', 'start_at', 'end_at', 'patient.phone', 'status', 'actions', 'actions-eye'];
    },
  },

  methods: {
    goToCashPatient() {
      this.$router.push(
        `/cash/?user_name=${this.patient.name}&user_id=${
          this.patient.id
        }&start_at=01.01.20&end_at=${ISOStringToDateAppFormat(new Date().toISOString())}`
      );
    },

    goToAppointmentsPatient() {
      this.$router.push(
        `/appointments/?user_name=${this.patient.name}&user_id=${
          this.patient.id
        }&start_at=01.01.20&end_at=${ISOStringToDateAppFormat(new Date().toISOString())}`
      );
    },

    goToAnalysisPatient() {
      this.$router.push(
        `${ROUTE_PATH.laboratory.orders}?user_name=${this.patient.name}&user_id=${
          this.patient.id
        }&date_period=01.01.20,${ISOStringToDateAppFormat(new Date().toISOString())}`
      );
    },

    async editPatient() {
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: CreateOrEditPatientModal,
        payload: {
          data: this.patient,
        },
      });

      if (action instanceof GlobalModalClose) return;

      this.$emit('update:patient', action.data.patient);
    },

    async printBracelet() {
      if (this.braceletLoading) return;
      this.braceletLoading = true;

      try {
        await PrinterService.printBraceletByPatientId(this.patient.id);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.braceletLoading = false;
    },
  },
};
