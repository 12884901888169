{
  "en": {
    "Types": {
      "day": "Day",
      "month": "Month"
    }
  },
  "ru": {
    "Types": {
      "day": "День",
      "month": "Месяц"
    }
  },
  "uz": {
    "Types": {
      "day": "Kun",
      "month": "Oy"
    }
  }
}