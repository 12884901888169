{
  "en": {
    "Info": "The laboratory carries out constant quality control on the basis of the provisions and documents of the Quality Management System (QMS) developed in accordance with the recommendations of the International Organization for ISO Certification, WHO, Uzbek regulatory documents and recommendations of international scientific communities on clinical chemistry and hematology."
  },
  "ru": {
    "Info": "В лаборатории осуществляется постоянный контроль качества на основании положений и документов Системы менеджмента качества (СМК), разработанных в соответствии с рекомендациями Международной организации по сертификации ISO, ВОЗ, узбекистанскими нормативными документами и рекомендациями международных научных сообществ по клинической химии и гематологии."
  },
  "uz": {
    "Info": "Laboratoriya ISO, JSST sertifikatlashtirish boʻyicha xalqaro tashkilot tavsiyalari, Oʻzbekiston meʼyoriy hujjatlari va xalqaro ilmiy hamjamiyatlarning klinik kimyo boʻyicha tavsiyalariga muvofiq ishlab chiqilgan Sifat menejmenti tizimining (SMT) qoidalari va hujjatlari asosida doimiy sifat nazoratini amalga oshiradi. va gematologiya."
  }
}