<template>
  <MiCard class="invoice-modal__transactions-card" shadow="never">
    <MiSimpleTable height="150px" :data="currentInvoice.transactions" :loading="false">
      <ElTableColumn prop="type" :label="$t('Base.Type')">
        <template #default="{ row }">
          <!--  Иначе выскакивают предупреждения из-зa row.type === undefined, хотя такого быть не может  -->
          {{ row.type ? $t(`Transactions.Types.${row.type}`) : '' }}
        </template>
      </ElTableColumn>
      <ElTableColumn prop="amount" :label="$t('Base.SumCost')" />
      <ElTableColumn prop="created_at" :label="$t('DateAndTime.CreatedAt')" />
      <ElTableColumn prop="payment_type" :label="$t('Invoices.PaymentMethod')">
        <template #default="{ row }">
          {{ row.payment_type ? $t(`Transactions.PaymentTypes.${row.payment_type}`) : '' }}
        </template>
      </ElTableColumn>
    </MiSimpleTable>
  </MiCard>
</template>

<script setup lang="ts">
import { useFieldValue } from 'vee-validate';
import { computed } from 'vue';

import { InvoiceFullDto } from '~shared/api';
import { MiSimpleTable, MiCard } from '~shared/ui/';

const currentInvoice = computed(() => {
  return useFieldValue<InvoiceFullDto>('currentInvoice').value;
});
</script>

<style src="./index.scss" />
