export const ICON_NAME = {
  LOGOUT: 'LOGOUT',
  DASHBOARD: 'DASHBOARD',
  USER_TAG: 'USER_TAG',
  NOTE: 'NOTE',
  LOCK: 'LOCK',
  MESSAGE: 'MESSAGE',
  BOARD: 'BOARD',
  SEARCH: 'SEARCH',
  BELL: 'BELL',
  FILTER: 'FILTER',
  CHEVRON_DOWN: 'CHEVRON_DOWN',
  CHEVRON_LEFT: 'CHEVRON_LEFT',
  CHEVRON_RIGHT: 'CHEVRON_RIGHT',
  PLUS: 'PLUS',
  CLOSE: 'CLOSE',
  DOUBLE_CHECKER: 'DOUBLE_CHECKER',
  PROVIDED: 'PROVIDED',
  CANCELED_ROUND: 'CANCELED',
  TRASH: 'TRASH',
  RELOAD: 'RELOAD',
  BACK_ARROW: 'BACK_ARROW',
  DASHBOARD_NAV: 'DASHBOARD_NAV',
  TIMETABLE_NAV: 'TIMETABLE_NAV',
  CALENDAR_NAV: 'CALENDAR_NAV',
  PATIENTS_NAV: 'PATIENTS_NAV',
  LOGO_CIRCLE: 'LOGO_CIRCLE',
  RUSSIAN: 'RUSSIAN',
  ENGLISH: 'ENGLISH',
  NOTIFICATION: 'NOTIFICATION',
  UZBEKISTAN: 'UZBEKISTAN',
  SCAN: 'SCAN',
  PRINTER: 'PRINTER',
  WALLET_OUTLINE: 'WALLET_OUTLINE',
  MESSAGE_LOGIN: 'MESSAGE_LOGIN',
  LOCK_PASSWORD: 'LOCK_PASSWORD',
  EYE: 'EYE',
  VECTOR_TOP: 'VECTOR_TOP',
  VECTOR_BOT: 'VECTOR_BOT',
  CANCEL: 'CANCEL',
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  DOWNLOAD: 'DOWNLOAD',
  CASH_CHECK: 'CASH_CHECK',
  CASH_REFUND: 'CASH_REFUND',
  LIST_BOX_OUTLINE: 'LIST_BOX_OUTLINE',
  HOSPITAL: 'HOSPITAL',
  DATABASE: 'DATABASE',
  MEDICAL: 'MEDICAL',
  DECREASE_PERCENTAGE: 'DECREASE_PERCENTAGE',
  INCREASE_PERCENTAGE: 'INCREASE_PERCENTAGE',
  DOCTORS: 'DOCTORS',
  NOMENCLATURE: 'NOMENCLATURE',
  WAREHOUSES: 'WAREHOUSES',
  SUPPLIERS: 'SUPPLIERS',
  INCOME: 'INCOME',
  OUTCOME: 'OUTCOME',
  GOODS_STOCK: 'GOODS_STOCK',
  TOOLTIP: 'TOOLTIP',
  DOLLAR: 'DOLLAR',
  D: 'D',
  ARROW: 'ARROW',
  V: 'V',
  WARN: 'WARN',
  DEVICE: 'DEVICE',
  CHAT: 'CHAT',
  TELEGRAM: 'TELEGRAM',
  PATIENT_GROUPS: 'PATIENT_GROUPS',
  UPLOAD: 'UPLOAD',
  TEMPLATE_NAV: 'TEMPLATE_NAV',
  PAPER: 'PAPER',
  BOOKKEEPING: 'BOOKKEEPING',
};
