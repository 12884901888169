import { AxiosError } from 'axios';

import { Api } from './api.generated';

import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  REQUEST_TIMEOUT,
  TOKEN_TYPE,
  DEFAULT_LOCALE,
  ROUTE_PATH,
  REFRESH_TOKEN_PATH,
} from '~shared/config';
import {
  globalRouter,
  DeviceService,
  authStorageService,
  clinicStorageService,
  amplitudeService,
} from '~shared/lib';

class ApiService {
  isAlreadyFetchingAccessToken = false;

  subscribers: Array<(token: string) => void> = [];

  constructor(public api: Api<unknown>) {
    this.api.instance.interceptors.request.use(
      (config) => {
        const accessToken = authStorageService.getAccessToken() ?? '';
        const locale = authStorageService.getLocale() ?? DEFAULT_LOCALE;
        const clinicId = clinicStorageService.getClinic()?.id ?? '';

        config.headers.Authorization = `${TOKEN_TYPE} ${accessToken}`;
        config.headers['Lang'] = locale;
        config.headers['Clinic'] = clinicId;

        DeviceService.checkAndSetDeviceId();
        config.headers['Device-Id'] = DeviceService.getDeviceId();

        return config;
      },
      (error) => Promise.reject(error)
    );

    this.api.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (!(error instanceof AxiosError)) {
          return this.logout();
        }

        if (
          response &&
          response.status === 401 &&
          config.url !== REFRESH_TOKEN_PATH &&
          authStorageService.getRefreshToken()
        ) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken().then(
              (r) => {
                this.isAlreadyFetchingAccessToken = false;

                authStorageService.setAccessToken(r.data[ACCESS_TOKEN_KEY]);
                authStorageService.setRefreshToken(r.data[REFRESH_TOKEN_KEY]);
                this.setDefaultAccessToken(r.data[ACCESS_TOKEN_KEY]);

                this.onAccessTokenFetched(r.data[ACCESS_TOKEN_KEY]);
              },
              () => {
                this.logout();
              }
            );
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken: string) => {
              originalRequest.headers.Authorization = `${TOKEN_TYPE} ${accessToken}`;
              resolve(this.api.instance(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  private onAccessTokenFetched(accessToken: string) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken));
  }

  private addSubscriber(callback: (token: string) => void) {
    this.subscribers.push(callback);
  }

  public setDefaultLocale(locale: string) {
    this.api.instance.defaults.headers.common.Lang = locale;
  }

  private setDefaultAccessToken(accessToken: string) {
    this.api.instance.defaults.headers.common.Authorization = `${TOKEN_TYPE} ${accessToken}`;
  }

  public setDefaultDeviceId(deviceId: string) {
    this.api.instance.defaults.headers.common['Device-Id'] = deviceId;
  }

  public removeLocale = () => {
    delete this.api.instance.defaults.headers.common.Lang;
  };

  public removeToken = () => {
    delete this.api.instance.defaults.headers.common.Authorization;
  };

  private refreshToken() {
    return this.api.instance.post(REFRESH_TOKEN_PATH, {
      refresh_token: authStorageService.getRefreshToken(),
    });
  }

  private logout() {
    this.removeToken();
    authStorageService.removeTokens();
    authStorageService.removeUser();
    globalRouter?.router?.push(ROUTE_PATH.auth.login);
    amplitudeService.removeUser();
  }
}

const api = new Api({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: REQUEST_TIMEOUT,
});

export const apiService = new ApiService(api);
