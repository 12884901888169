import { AxiosResponse } from 'axios';
import { ElNotification } from 'element-plus';

import { getApiErrorMessage } from './utils';

import { ErrorResource } from '~shared/api';

const axiosWrapper = <
  T extends (...args: unknown[]) => Promise<
    AxiosResponse<{
      data: unknown | ErrorResource;
    }>
  >,
>(
  fn: T
) => {
  return async (...args: Parameters<T>) => {
    try {
      const response = await fn(...args);

      return response;
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withErrorHandler = <T extends Record<string, any>>(api: T) => {
  return (Object.keys(api) as [keyof T]).reduce((acc, moduleName) => {
    const apiModule = api[moduleName];

    if (!apiModule) {
      return acc;
    }

    return {
      ...acc,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [moduleName]: Object.entries<any>(apiModule).reduce(
        (moduleRequests, [fnName, fn]) => ({
          ...moduleRequests,
          [fnName]: axiosWrapper(fn),
        }),
        {} as T
      ),
    };
  }, {} as T);
};
