export default {
  name: 'recommendations',
  namespaced: true,
  state() {
    return {
      appointedServices: [],
      favorites: {
        groupServices: [],
        groupServiceIds: [],
      },
    };
  },

  mutations: {
    SET_APPOINTED_SERVICES: (state, payload) => {
      state.appointedServices = payload.map((item) => ({
        ...item,
        checked: item.checked ?? true,
      }));
    },

    UPDATE_APPOINTED_SERVICES: (state, payload) => {
      const ids = payload.map((item) => item.id);
      const filteredAppointServices = state.appointedServices.filter(
        (item) => !ids.includes(item.id)
      );
      state.appointedServices = filteredAppointServices.concat(
        payload
          .map((item) => ({
            ...item,
            checked: item.checked ?? true,
          }))
          .filter((item) => item.checked !== false)
      );
    },

    SET_FAVORITES: (state, payload) => {
      state.favorites = {
        groupServices: payload.group_services,
        groupServiceIds: payload.group_service_ids,
      };
    },
  },
  actions: {
    /**
     * @param commit
     * @param {Array<Object>} payload
     */
    setAppointedServices({ commit }, payload) {
      commit('SET_APPOINTED_SERVICES', payload);
    },

    /**
     * @param commit
     * @param {Array<Object>} payload
     */
    updateAppointedServices({ commit }, payload) {
      commit('UPDATE_APPOINTED_SERVICES', payload);
    },

    /**
     * @param commit
     * @param {Array<Object>} payload
     */
    setFavorites({ commit }, payload) {
      commit('SET_FAVORITES', payload);
    },
  },
};
