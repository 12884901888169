import { CRUDModel } from '@/models/CRUD.model';

export class Measure extends CRUDModel {
  static modelName = 'measure';
  static tableName = 'measures';

  /**
   * @param {object} payload
   * @param {string} payload.title
   */
  constructor(payload) {
    super(payload);
    this.title = payload?.title ?? null;
  }

  static async findForAnalysis(options) {
    return this.find({
      ...options,
      _url: `${this.tableName}/analysis`,
    });
  }
}
