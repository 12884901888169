<template>
  <div class="ui-data-modal-grid">
    <div class="ui-data-modal-grid__info">
      <slot name="info"></slot>
    </div>

    <div class="ui-data-modal-grid__header">
      <slot name="control"></slot>
    </div>
    <div class="ui-data-modal-grid__content">
      <slot name="content"></slot>
    </div>
    <div class="ui-data-modal-grid__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiDataModalGrid',
  props: {},
};
</script>

<style lang="scss" src="./index.scss" />
