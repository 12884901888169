import { ElNotification } from 'element-plus';

import { FeedbackFullDto, MetaDto, AnswerCreateDto } from '@/types/api';
import { QueryParams } from '@/types/common';
import { apiService } from '~shared/api';
import { mergeOrCreateQuery, getApiErrorMessage } from '@/utils/http.util';
import { deleteEmptyValueKeys } from '@/utils/object.util';

export class Feedback {
  static modelName = 'feedback';
  static tableName = 'feedbacks';

  static async getItems(query: QueryParams) {
    try {
      const response = await apiService.api.instance.get<{
        data: FeedbackFullDto[];
        meta: MetaDto;
      }>(
        mergeOrCreateQuery({
          url: `b2g/${this.tableName}`,
          query: deleteEmptyValueKeys(query),
        })
      );

      return {
        response: response,
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }

  static async createAnswer(id: number, payload: AnswerCreateDto) {
    try {
      const response = await apiService.api.instance.post<{ data: FeedbackFullDto }>(
        `b2g/${this.tableName}/${id}/response`,
        payload
      );

      return {
        data: response.data.data,
      };
    } catch (err) {
      ElNotification({
        type: 'error',
        title: getApiErrorMessage(err),
      });
    }
  }
}
