<template>
  <div class="patients-search">
    <div class="patients-search__input-wrapper">
      <ElInput v-model="searchString" :placeholder="$t('InputLabel')" @input="debounceInput">
        <template #prefix>
          <MiIcon icon="SEARCH" />
        </template>
      </ElInput>
    </div>
    <ScanPatientBracelet @scan:success="redirectToPatientByBracelet" />
    <PatientsSearchPopover
      v-show="isOpenPopover"
      class="patients-search__popover"
      :search="queryWord.value"
      :patients="items"
      :loading="loading" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import noop from 'lodash.noop';

// eslint-disable-next-line import/no-internal-modules
import PatientsSearchPopover from './PatientsSearchPopover/index.vue';

import { MiIcon } from '~shared/ui';
import { useSearch, amplitudeService } from '~shared/lib';
import { PATIENT_ROUTE, PATIENTS_ROUTE } from '@/router/patients.routes';
import { Patient } from '@/models/Patient.model';
import ScanPatientBracelet from '@/components/scanner/ScanPatientBracelet/index.vue';

export default {
  name: 'PatientsSearch',
  components: { ScanPatientBracelet, PatientsSearchPopover, MiIcon },

  setup: () => ({
    queryWord: useSearch(),
    debounceInput: noop,
  }),
  data() {
    return {
      isOpenPopover: false,
      isLoading: true,
      searchString: '',
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.patients.data,
      total: (state) => state.patients.total,
      loading: (state) => state.patients.loading,
    }),

    isDisabledByPatientsPages() {
      return this.$route.name === PATIENTS_ROUTE.name;
    },
  },
  watch: {
    'queryWord.value': {
      handler(value) {
        if (this.isDisabledByPatientsPages) return;
        if (value && value.length) {
          this.search(value);
        }
        if (value && !this.isOpenPopover) this.isOpenPopover = true;
        if ((!value || !value.length) && this.isOpenPopover) this.isOpenPopover = false;
      },
    },
  },

  methods: {
    ...mapActions({
      setLoading: 'patients/setLoading',
      setData: 'patients/setData',
    }),

    scanHandler() {
      this.$refs.elInput.focus();
    },

    async search(searchString) {
      if (this.isDisabledByPatientsPages || this.loading) return;
      this.setLoading(true);

      try {
        const { data } = await Patient.find({
          page: 1,
          per_page: 100,
          search: searchString,
          query_field: ['name', 'phone'],
          query_type: 'ILIKE',
          query_operator: 'OR',
        });
        this.setData({
          items: data.data,
          total: data.meta.total,
          overwriteDataState: true,
        });

        this.isOpenPopover = true;
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.setLoading(false);
    },

    redirectToPatientByBracelet({ patient }) {
      amplitudeService.logEvent('scan_patient_qr', {
        patient_id: patient.id,
      });

      this.$router.push({
        name: PATIENT_ROUTE.name,
        params: {
          id: patient.id,
        },
      });
    },
  },

  mounted() {
    this.debounceInput = debounce((value) => {
      this.queryWord.value = value;
    }, 600);
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="./index.locales.json" />
