<template>
  <MainLayout content-class="v-outcome-content" fix-height>
    <LayoutContentHeader>
      <!-- Date -->
      <ElDatePicker
        class="v-doctors-content-header__date"
        v-model="date.value"
        type="daterange"
        unlink-panels
        :format="DATE_FORMAT"
        :value-format="DATE_FORMAT"
        :start-placeholder="$t('DateAndTime.StartDate')"
        :end-placeholder="$t('DateAndTime.EndDate')" />

      <!-- Warehouse -->
      <UiModelsAutocompleteSearch
        class="v-hospital-header__department"
        :model-value="warehouse.value.id"
        :model-for-use="Warehouse"
        label="title"
        :default-item="warehouse.value"
        :placeholder="$t('Income.Warehouse')"
        clearable
        @select="warehouse.value = $event" />

      <!-- Type Outcome -->
      <!--      <UiModelsAutocompleteSearch-->
      <!--        class="v-hospital-header__department"-->
      <!--        :model-value="outcome.value"-->
      <!--        :model-for-use="Outcome"-->
      <!--        label="title"-->
      <!--        :default-item="outcome.value ? outcome.value : null"-->
      <!--        :placeholder="$t('Тип расхода')"-->
      <!--        clearable-->
      <!--        @select="outcome.value = $event" />-->

      <template #actions>
        <ElButton type="primary" @click="onCreateButtonClick">
          {{ `+ ${$t('Outcome.CreateOutcome')}` }}
        </ElButton>
      </template>
    </LayoutContentHeader>
    <OutcomesTable
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      :items="items"
      :total="total" />
  </MainLayout>
</template>

<script lang="ts">
export default {
  name: 'VOutcome',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import axios from 'axios';
import { ref, computed, watch } from 'vue';
import { ElNotification } from 'element-plus';
import { useStore } from 'vuex';

import { I18nService } from '~shared/lib';
import { useDatePeriod, usePerPage, usePage, useModel } from '~shared/lib';
import { compareQueriesThenLoadData } from '@/utils/router.utils';
import { Outcome } from '@/models/warehouse/Outcome.model';
import { DATE_FORMAT } from '~shared/config';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { Warehouse } from '@/models/warehouse/Warehouse.model';
import { MainLayout } from '~widgets/layouts';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import OutcomesTable from '@/components/warehouse/tables/OutcomesTable/index.vue';
import OutcomeCrudModal from '@/components/warehouse/modals/OutcomeCrudModal/index.vue';

const loading = ref(false);
const store = useStore();
const page = usePage();
const perPage = usePerPage();
const warehouse = useModel({
  fieldNames: {
    id: 'warehouse_id',
    name: 'warehouse_name',
  },
});

const date = useDatePeriod(getMonthPeriod());

const total = computed(() => store.state.outcomes.total);
const items = computed(() => store.state.outcomes.data);
const query = computed(() => ({
  page: page.value,
  per_page: perPage.value,
  start_at: date.value[0],
  end_at: date.value[1],
  // type: outcome.value,
  warehouse_id: warehouse.value.id,
}));

const getOutcomes = async () => {
  loading.value = true;
  try {
    const { data } = await Outcome.getItems(query.value);
    store.dispatch('outcomes/setData', {
      items: data.data,
      total: +data.meta.total,
      overwriteDataState: true,
    });
  } catch (err) {
    ElNotification({
      type: 'error',
      title: axios.isAxiosError(err) ? err.message : I18nService.t('Notifications.Error'),
    });
  } finally {
    loading.value = false;
  }
};

watch(
  query,
  (value, oldValue) => {
    compareQueriesThenLoadData({
      query: value,
      oldQuery: oldValue,
      getData: getOutcomes,
      resetPage: page.reset,
    });
  },
  { deep: true, immediate: true }
);

const onCreateButtonClick = async () => {
  await store.dispatch('modalAndDrawer/openModal', OutcomeCrudModal);
  await getOutcomes();
};
</script>

<style lang="scss"></style>
