<template>
  <div>
    <img v-if="isImage" :src="file.link" alt="image" />
    <span v-if="!isImage"> {{ file.filename }} </span>
  </div>
</template>

<script>
import { File as FileModel } from '@/models/File.model';

export default {
  name: 'PrinterDocumentContentBlockFile',
  props: {
    file: [FileModel, Object],
  },
  computed: {
    isImage() {
      return FileModel.isImage(this.file);
    },
  },
};
</script>
