import { AppLocalesDto } from '../types';

export const LOCALES: AppLocalesDto = {
  ru: {
    id: 'ru',
    label: 'Русский',
  },
  uz: {
    id: 'uz',
    label: 'Ozbekcha',
  },
  en: {
    id: 'en',
    label: 'English',
  },
};

export const DEFAULT_LOCALE = LOCALES.ru.id;
export const FALLBACK_LOCALE = LOCALES.ru.id;
