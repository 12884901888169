<template>
  <ElDialog
    :model-value="modelValue"
    :title="title"
    class="invoice-pay-or-refund-modal"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue')">
    <ElForm
      id="invoice-pay-or-refund"
      class="invoice-pay-or-refund-modal-content"
      label-position="top"
      @submit.prevent="submitHandler">
      <ElFormItem>
        <div class="invoice-pay-or-refund-modal__sum invoice-pay-or-refund-modal-sum">
          <span>{{ $t('Base.SumCost') }}: </span>
          <span class="invoice-pay-or-refund-modal-sum__value">
            {{ formattedCost }}
          </span>
        </div>
      </ElFormItem>

      <ElFormItem
        :label="$t(type === Transaction.enum.types.PayIn ? 'PatientPaySum' : 'PatientRefundSum')">
        <ElInput v-model.number="transaction.amount" type="number" required />
      </ElFormItem>

      <ElFormItem :label="$t('Invoices.PaymentMethod')">
        <ElRadioGroup v-model="transaction.payment_type">
          <ElRadio v-for="option in paymentTypes" :key="option.value" :label="option.value">
            {{ option.label }}
          </ElRadio>
        </ElRadioGroup>
      </ElFormItem>
    </ElForm>

    <template #footer>
      <ElButton type="primary" native-type="submit" form="invoice-pay-or-refund" :loading="loading">
        {{ $t(type === Transaction.enum.types.PayIn ? 'Base.Pay' : 'Base.Refund') }}
      </ElButton>
    </template>
  </ElDialog>
</template>

<script>
import axios from 'axios';

import { amplitudeService } from '~shared/lib';
import { Invoice } from '@/models/Invoice.model';
import { formatPrice } from '@/utils/price.util';
import { Transaction } from '@/models/Transaction.model';
import { GlobalModalInstance } from '~widgets/GlobalModalAndDrawer';
import {
  INVOICE_PAYED_ACTION,
  INVOICE_REFUND_ACTION,
} from '@/components/invoices/InvoicePayOrRefundModal/index.enum';

export default {
  name: 'InvoicePayOrRefundModal',
  emits: ['update:modelValue', 'action'],
  props: {
    modelValue: Boolean,
    invoice: [Invoice, Object],
    type: {
      type: String,
      default: Transaction.enum.types.PayIn,
      validator: (val) =>
        [Transaction.enum.types.PayIn, Transaction.enum.types.PayOut].includes(val),
    },
  },
  data() {
    return {
      /** @type {Transaction} transaction */
      transaction: null,
      loading: null,
      paymentTypes: [],
      paymentDrivers: this.paymentDrivers || [],
    };
  },

  computed: {
    title() {
      return this.$t(this.type === Transaction.enum.types.PayIn ? 'Base.Payment' : 'Base.Refund');
    },
    cost() {
      let amount = 0;
      if (this.type === Transaction.enum.types.PayIn) amount = this.invoice.left_pay;
      if (this.type === Transaction.enum.types.PayOut) {
        amount =
          this.invoice.status === Invoice.enum.statuses.Overpay
            ? Math.abs(this.invoice.left_pay)
            : this.invoice.discounted_amount - this.invoice.left_pay;
      }

      return amount;
    },
    formattedCost() {
      return `
        ${formatPrice({
          price: this.cost,
        })} ${this.$t('Base.Sum')}`;
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.transaction = new Transaction({
          amount: this.cost,
          type: this.type,
          invoice_id: this.invoice.id,
        });
      },
      immediate: true,
    },

    paymentDrivers: {
      handler(newPaymentDrivers) {
        if (newPaymentDrivers) {
          this.paymentTypes = newPaymentDrivers.map((driver) => ({
            label: this.$t(`Transactions.PaymentTypes.${driver}`),
            value: driver,
          }));
        }
      },
      deep: true,
    },
  },

  methods: {
    sendStatistics() {
      if (this.type === Transaction.enum.types.PayIn) {
        amplitudeService.logEvent('transaction_modal.pay', {
          transaction_type: this.type,
        });
      } else {
        amplitudeService.logEvent('refund_modal.refund', {
          transaction_type: this.type,
        });
      }
    },

    async submitHandler() {
      this.sendStatistics();

      if (this.loading) return;
      this.loading = true;

      try {
        const transaction = await this.createTransaction();
        const { data } = await Invoice.findOneById(this.transaction.invoice_id);

        this.$notify({
          type: 'success',
          title: this.$t(
            this.type === Transaction.enum.types.PayIn
              ? 'Invoices.SuccessPayed'
              : 'Invoices.SuccessRefunded'
          ),
        });

        this.$emit(
          'action',
          new GlobalModalInstance({
            name:
              this.type === Transaction.enum.types.PayIn
                ? INVOICE_PAYED_ACTION
                : INVOICE_REFUND_ACTION,
            data: {
              invoice: data.data,
              transaction: transaction,
            },
          })
        );
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.loading = false;
    },

    async createTransaction() {
      const { data } = await Transaction.create(this.transaction);
      return data.data;
    },

    async getPaymentDrivers() {
      const response = await Invoice.getPaymentDrivers();
      this.paymentDrivers = response.data;
    },
  },
  mounted() {
    this.getPaymentDrivers();
  },

  setup: () => ({
    Transaction,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
<i18n src="@/locales/invoices.locales.json" />
<i18n src="@/locales/transactions.locales.json" />
<i18n src="./index.locales.json" />
