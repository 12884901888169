<template>
  <UiTableWithPagination
    :data="itemsWithPayload"
    :loading="loading"
    :page="page"
    :per-page="perPage"
    :total="total"
    :default-sort="defaultSort"
    @row-click="editService"
    @sort-change="onSortChange"
    @update:page="$emit('update:page', $event)"
    @update:perPage="$emit('update:perPage', $event)">
    <ElTableColumn prop="id" label="№" width="100px" />
    <ElTableColumn prop="title" :label="$t('Base.Service')" />
    <ElTableColumn prop="sold_count" :label="$t('Base.Quantity')" width="200px" sortable="custom">
      <template #default="{ row }">
        <div class="services-table__column">
          {{ row.sold_count ? row.sold_count : 0 }}
          <div :class="['services-table__column-value', chooseClassCount(row)]">
            <MiIcon
              class="services-table__column-value-icon"
              :icon="row.sold_count_delta >= 0 ? 'INCREASE_PERCENTAGE' : 'DECREASE_PERCENTAGE'" />
            <div>{{ row.sold_count_delta ? row.sold_count_delta : 0 }}%</div>
          </div>
        </div>
      </template>
    </ElTableColumn>
    <ElTableColumn prop="sold_amount" :label="$t('Base.SumCost')" width="200px" sortable="custom">
      <template #default="{ row }">
        <div class="services-table__column">
          {{ row.sold_amount ? row.sold_amount : 0 }}
          <div :class="['services-table__column-value', chooseClassAmount(row)]">
            <MiIcon
              class="services-table__column-value-icon"
              :icon="row.sold_amount_delta >= 0 ? 'INCREASE_PERCENTAGE' : 'DECREASE_PERCENTAGE'" />
            <div>{{ row.sold_amount_delta ? row.sold_amount_delta : 0 }}%</div>
          </div>
        </div>
      </template>
    </ElTableColumn>
    <ElTableColumn prop="price" :label="$t('Base.Price')" />
    <ElTableColumn prop="actions" :label="$t('Base.Actions')" width="150px">
      <template #default="{ row }">
        <div class="services-table__actions">
          <ElButton type="primary" size="small" plain @click.stop="editService(row)">
            {{ $t('Base.Edit') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script>
import axios from 'axios';

import { MiIcon } from '~shared/ui';
import { amplitudeService } from '~shared/lib';
import { PAGE_SIZES } from '~shared/config';
import { formatPrice } from '@/utils/price.util';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import { ServiceGroup } from '@/models/ServiceGroup.model';
import UiTableWithPagination from '@/components/ui/UiTableWithPagination/index.vue';
import ServiceCrudModal from '@/components/accounting/modals/ServiceCrudModal/index.vue';

export default {
  name: 'ServicesTable',
  components: { UiTableWithPagination, MiIcon },
  emits: [
    'update:page',
    'update:perPage',
    'item:update',
    'item:delete',
    'sort-change',
    'table:reload',
  ],
  props: {
    /** @type {Array<ServiceGroup|object>} */
    items: Array,
    loading: Boolean,
    percentage: Number,
    total: [Number, String],
    page: [Number, String],
    perPage: [Number, String],
    defaultSort: Object,
  },
  data() {
    return {
      deleteLoading: false,
      deletingItemId: null,
    };
  },
  computed: {
    itemsWithPayload() {
      return this.items.map((elem) => ({
        ...elem,
        _price: formatPrice({ price: elem.price }),
      }));
    },
    hasItems() {
      return !!this.items.length;
    },
    pageCount() {
      return Math.ceil(this.total / this.perPage);
    },
    pageSizes() {
      return PAGE_SIZES;
    },
  },
  methods: {
    async editService(row) {
      amplitudeService.logEvent('open_modal_edit_service');
      const action = await this.$store.dispatch('modalAndDrawer/openModal', {
        component: ServiceCrudModal,
        payload: { id: row.id },
      });

      if (!(action instanceof GlobalModalClose)) {
        this.$emit('table:reload', action.data);
      }
    },

    chooseClassCount(row) {
      return `services-table__column-value_${
        this.getBooleanSoldCountDelta(row) ? 'success' : 'danger'
      }`;
    },

    chooseClassAmount(row) {
      return `services-table__column-value_${
        this.getBooleanSoldAmountDelta(row) ? 'success' : 'danger'
      }`;
    },

    getBooleanSoldCountDelta(row) {
      return row.sold_count_delta >= 0;
    },

    getBooleanSoldAmountDelta(row) {
      return row.sold_amount_delta >= 0;
    },

    onSortChange({ prop, order }) {
      this.$emit('sort-change', { prop, order });
    },
    async deleteService(row) {
      if (this.deleteLoading) return;
      this.deleteLoading = true;
      this.deletingItemId = row.id;

      try {
        await ServiceGroup.delete({ id: row.id });

        this.$emit('item:delete', row);
        this.$notify({ type: 'success', title: this.$t('Notifications.SuccessDeleted') });
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }

      this.deleteLoading = false;
      this.deletingItemId = null;
    },
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/notifications.locales.json" />
