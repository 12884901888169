<template>
  <MainLayout content-class="v-user-content" fix-height>
    <ElBreadcrumb separator="/">
      <ElBreadcrumbItem :to="{ path: `${USERS_PATH_PREFIX}/employees` }"
        >{{ $t('User.Employees') }}
      </ElBreadcrumbItem>
      <ElBreadcrumbItem>{{ user.name ?? '' }}</ElBreadcrumbItem>
    </ElBreadcrumb>

    <PersonCard :person="user" :items="userItems" content-grid>
      <template #actions>
        <ElButton type="primary" @click="isClickUserInfo"
          >{{ $t('Accounting.DetailedInformation') }}
        </ElButton>
      </template>
      <template #value>
        <ScheduleList :working-time="user?.working_time" />
      </template>
    </PersonCard>

    <MiTabs stretch v-model="activeTabName">
      <MiFilterBox class="v-user-content__filter">
        <template v-if="showReferrerFilter">
          <MiDatePicker
            v-model="date.value"
            type="daterange"
            unlink-panels
            :format="DATE_FORMAT"
            :value-format="DATE_FORMAT"
            :start-placeholder="$t('DateAndTime.StartDate')"
            :end-placeholder="$t('DateAndTime.EndDate')" />
          <MiSelectWithOption
            v-if="activeTabName === 'ref'"
            v-model="status.value"
            :options="statusOptions"
            clearable
            :placeholder="$t('Common.Status')" />
        </template>

        <template #actions>
          <MiButton v-if="showReferrerFilter" link>{{ $t('Common.DownloadExcelFile') }}</MiButton>
          <MiButton type="primary" @click="handleToAdvanceBtnClick">
            {{ $t('Bookkeeping.ToAdvance') }}
          </MiButton>
          <MiButton type="primary" @click="handleToSalaryBtnClick">
            {{ $t('Bookkeeping.CalculateSalary') }}
          </MiButton>
        </template>
      </MiFilterBox>
      <ElTabPane lazy v-if="referrerId" :label="$t('Referral.Commissions')" name="ref">
        <ReferralCommissionTable
          ref="refReferralCommissionTable"
          @invoice-changed="handleReferralCommissionTableInvoiceChanged"
          :current-referral-id="referrerId" />
      </ElTabPane>
      <ElTabPane lazy v-if="referrerId" :label="$t('Referral.UnpaidBills')" name="refNotPaid">
        <ReferralCommissionTable
          ref="refNotPaidReferralCommissionTable"
          @invoice-changed="handleNotPaidReferralCommissionTableInvoiceChanged"
          not-paid
          :current-referral-id="referrerId" />
      </ElTabPane>
      <ElTabPane :label="$t('User.Devices')" name="devices">
        <EmployeeDevicesTable :id="id" />
      </ElTabPane>
    </MiTabs>
    <AdvancePaymentModal
      v-if="advancePaymentModalVisible"
      :retriever="retriever"
      v-model:visible="advancePaymentModalVisible" />

    <SalaryCalculationModal
      v-if="salaryModalVisible"
      :retriever="retriever"
      v-model:modal-visible="salaryModalVisible"
      v-model:salary-id="modalSalaryId"
      @submit:successful="handleSuccessfulSalaryCalculationModal"
      receiver-type="user" />
  </MainLayout>
</template>

<script>
import axios from 'axios';
import { mapState, useStore } from 'vuex';
import noop from 'lodash.noop';

import { User } from '@/models/User.model';
import { insertRouteParams } from '@/utils/router.utils';
import { USERS_PATH_PREFIX, USER_ROUTE } from '@/router/users.routes';
import {
  usePerPage,
  usePage,
  amplitudeService,
  useDatePeriod,
  useQuery,
  I18nService,
  getMonthPeriod,
} from '~shared/lib';
import { MainLayout } from '~widgets/layouts';
import PersonCard from '@/components/PersonCard/index.vue';
import EmployeeModal from '@/components/accounting/modals/EmployeeModal/index.vue';
import ScheduleList from '@/components/common/ScheduleList/index.vue';
import { GlobalModalClose } from '~widgets/GlobalModalAndDrawer';
import EmployeeDevicesTable from '@/components/employees/tables/EmployeeDevicesTable/Index.vue';
import { ReferralCommissionTable } from '~widgets/bookkeeping/';
import { DATE_FORMAT } from '~shared/config';
import { MiTabs, MiDatePicker, MiFilterBox, MiSelectWithOption, MiButton } from '~shared/ui';
import { AdvancePaymentModal, SalaryCalculationModal } from '~features/bookkeeping';

export default {
  name: 'VUser',
  components: {
    SalaryCalculationModal,
    AdvancePaymentModal,
    MiButton,
    MiSelectWithOption,
    MiFilterBox,
    MiDatePicker,
    ReferralCommissionTable,
    EmployeeDevicesTable,
    ScheduleList,
    PersonCard,
    MainLayout,
    MiTabs,
  },
  props: {
    id: [Number, String],
    referrerId: [Number],
  },
  setup: () => ({
    getDevicesByInterval: noop,
    perPage: usePerPage(),
    page: usePage(),
    date: useDatePeriod(getMonthPeriod()),
    store: useStore(),
    status: useQuery({
      field: 'status',
    }),
    DATE_FORMAT,
  }),
  data() {
    return {
      /** @type {User} */
      USERS_PATH_PREFIX,
      user: {},
      loading: { user: false, devices: false },
      USER_ROUTE,
      activeTabName: 'devices',
      advancePaymentModalVisible: false,
      salaryModalVisible: false,
      modalSalaryId: null,
    };
  },
  computed: {
    deviceLink() {
      return insertRouteParams({
        path: USER_ROUTE.childrenMap.DEVICES._fullPath,
        params: { id: this.id },
      });
    },
    statusOptions() {
      return [
        { value: 'paid', label: I18nService.t('Bookkeeping.SalaryStatuses.paid') },
        { value: 'not_paid', label: I18nService.t('Bookkeeping.SalaryStatuses.not_paid') },
      ];
    },
    retriever() {
      return {
        id: this.user.id,
        title: this.user.name,
        name: this.user.name,
      };
    },
    showReferrerFilter() {
      return ['ref', 'refNotPaid'].includes(this.activeTabName);
    },
    // salaryLink() {
    //   return insertRouteParams({
    //     path: USER_ROUTE.childrenMap.SALARY._fullPath,
    //     params: { id: this.id },
    //   });
    // },
    ...mapState({
      devices: (state) => state.devices.data,
      total: (state) => state.devices.total,
    }),
    userItems() {
      return [
        {
          label: this.$t('Base.DoctorFullName2'),
          value: this.user.name,
        },
        {
          label: this.$t('User.Birthdate'),
          value: this.user.birthdate,
        },
        {
          label: this.$t('User.Address'),
          value: this.user.address,
        },
        {
          label: this.$t('User.Schedule'),
          value: '...',
          custom: true,
        },
        {
          label: this.$t('User.Speciality'),
          value: this.user.doctor?.specialties
            ? `${this.user.doctor.specialties.map((item) => item.title).join(', ')}`
            : null,
        },
        {
          label: this.$t('User.SalaryAmount'),
          value: this.user.salary,
        },
        {
          label: this.$t('User.PhoneNumber'),
          value: this.user.phone,
        },
      ];
    },
  },
  watch: {
    id: {
      async handler() {
        await this.getUser();
      },
      immediate: true,
    },
  },
  methods: {
    async getUser() {
      this.loading.user = true;
      try {
        const { data } = await User.findOneById(this.id);
        this.user = new User(data.data);
      } catch (err) {
        this.$notify({
          type: 'error',
          title: axios.isAxiosError(err) ? err.message : String(err),
        });
      }
      this.loading.user = false;
    },

    async isClickUserInfo() {
      const action = await this.store.dispatch('modalAndDrawer/openModal', {
        component: EmployeeModal,
        payload: {
          userId: this.user.id,
        },
      });
      if (!(action instanceof GlobalModalClose)) {
        this.getUser();
      }
    },

    handleToAdvanceBtnClick() {
      this.advancePaymentModalVisible = true;
      amplitudeService.logEvent('open_modal_create_salary_advance');
    },

    handleToSalaryBtnClick() {
      this.salaryModalVisible = true;
      amplitudeService.logEvent('open_modal_calculate_salary');
    },

    async handleSuccessfulSalaryCalculationModal() {
      await this.$refs.refReferralCommissionTable.getReferrerCommissionTable();
    },

    async handleReferralCommissionTableInvoiceChanged() {
      await this.$refs.refNotPaidReferralCommissionTable.getReferrerCommissionTable();
    },

    async handleNotPaidReferralCommissionTableInvoiceChanged() {
      await this.$refs.refReferralCommissionTable.getReferrerCommissionTable();
    },
  },
  mounted() {
    this.getDevicesByInterval();
  },
};
</script>

<style lang="scss" src="./index.scss" />
<i18n src="@/locales/base.locales.json" />
<i18n src="@/locales/user.locales.json" />
<i18n src="@/locales/accounting.locales.json" />
