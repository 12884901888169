<template>
  <div class="schedule">
    <h3 class="schedule__label">{{ $t('User.Schedule') }}</h3>
    <ul class="schedule__list">
      <li v-for="(day, index) in daysFormatted" :key="index">
        <ElButton class="schedule__item-btn" @click="openModal(day)">
          <span class="schedule__shortday">{{ day.label }} </span>
          <template v-if="day.start && day.end">{{ day.start }} - {{ day.end }}</template>
        </ElButton>
      </li>
    </ul>
    <span class="schedule__subtitle">{{ $t('User.ScheduleBlockInfo') }}</span>

    <ElDialog
      class="schedule__modal"
      v-model="nestedModalVisible"
      width="400px"
      :title="$t('User.WorkingHours')">
      <VeeForm as="ElForm">
        <ScheduleForm
          v-if="!!currentDay"
          :key="currentDay.name"
          :form-data="formData"
          @change:schedule="changeSchedule"
          @close:modal="$emit('update:modelValue')" />
      </VeeForm>
    </ElDialog>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ScheduleBlock',
  inheritAttrs: false,
  customOptions: {},
};
</script>
<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useField } from 'vee-validate';
import { Form as VeeForm } from 'vee-validate';

import { EmployeeFormData } from '@/models/User.model';
import ScheduleForm from '@/components/accounting/modals/EmployeeModal/ScheduleForm/Index.vue';
import { I18nService } from '~shared/lib';

type FormData = {
  start: string;
  end: string;
  checked: boolean;
};

type DaysOfWeek =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

type DaysMapType = Record<DaysOfWeek, string>;
type DayInfo = {
  label: string;
  start: string | null;
  end: string | null;
  name: string;
};
type EmployeeTimeKeyof = keyof EmployeeFormData['working_time'];

defineEmits(['update:modelValue']);

const { value: dayValue, setValue: setScheduleValue } =
  useField<EmployeeFormData['working_time']>('working_time');

const nestedModalVisible = ref(false);
const currentDay = ref<DayInfo | null>(null);
const daysMap: DaysMapType = {
  monday: I18nService.t('Base.DaysOfWeek.Monday'),
  tuesday: I18nService.t('Base.DaysOfWeek.Tuesday'),
  wednesday: I18nService.t('Base.DaysOfWeek.Wednesday'),
  thursday: I18nService.t('Base.DaysOfWeek.Thursday'),
  friday: I18nService.t('Base.DaysOfWeek.Friday'),
  saturday: I18nService.t('Base.DaysOfWeek.Saturday'),
  sunday: I18nService.t('Base.DaysOfWeek.Sunday'),
};

const formData = computed(() => {
  return currentDay.value
    ? {
        start: dayValue.value[`${currentDay.value.name}_start` as EmployeeTimeKeyof] ?? '',
        end: dayValue.value[`${currentDay.value.name}_end` as EmployeeTimeKeyof] ?? '',
        checked:
          !dayValue.value[`${currentDay.value.name}_start` as EmployeeTimeKeyof] ||
          !dayValue.value[`${currentDay.value.name}_end` as EmployeeTimeKeyof],
      }
    : {
        start: '',
        end: '',
        checked: false,
      };
});

const daysFormatted = computed<DayInfo[]>(() => {
  const keys = Object.keys(daysMap) as DaysOfWeek[];

  return keys.map((key) => {
    const label =
      !dayValue.value[`${key}_start` as EmployeeTimeKeyof] ||
      !dayValue.value[`${key}_end` as EmployeeTimeKeyof]
        ? I18nService.t('Base.DaysOfWeek.DayOff')
        : daysMap[key];

    return {
      start: dayValue.value[`${key}_start` as EmployeeTimeKeyof],
      end: dayValue.value[`${key}_end` as EmployeeTimeKeyof],
      label,
      name: key,
    };
  });
});

const changeSchedule = (value: FormData) => {
  if (currentDay.value) {
    const data = Object.assign(
      {},
      {
        ...dayValue.value,
        [`${currentDay.value.name}_start`]: value.checked ? null : value.start,
        [`${currentDay.value.name}_end`]: value.checked ? null : value.end,
      }
    );

    setScheduleValue(data);
  }

  closeModal();
};

const resetCurrentDay = () => {
  currentDay.value = null;
};

watch(nestedModalVisible, (val) => {
  if (!val) {
    resetCurrentDay();
  }
});
const openModal = (day: DayInfo) => {
  nestedModalVisible.value = true;
  currentDay.value = day;
};

const closeModal = () => {
  nestedModalVisible.value = false;
};
</script>

<style lang="scss" src="./index.scss"></style>

<i18n src="@/locales/accounting.locales.json" />
