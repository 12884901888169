<template>
  <EmployeeDevicesTable :id="id" />
</template>

<script lang="ts">
export default {
  name: 'VUserDevices',
  inheritAttrs: false,
  customOptions: {},
};
</script>

<script lang="ts" setup>
import EmployeeDevicesTable from '@/components/employees/tables/EmployeeDevicesTable/Index.vue';

defineProps<{
  id: string;
}>();
</script>

<style scoped></style>
