{
  "en": {
    "Patients": {
      "AddPatient": "Add Patient",
      "ClinicPatients": "Clinic patients",
      "EditPatient": "Change patient data",
      "HasTreatment": "On treatment",
      "Id": "ID",
      "MyPatients": "My patients",
      "NamePatient": "Patient name",
      "NoPatient": "Patient not found",
      "Patient": "Patient",
      "PatientAge": "Age",
      "PatientAlreadyExists": "The patient already exists",
      "PrintBracelet": "Print Bracelet",
      "SelectPatient": "Select a patient",
      "patientID": "Patient ID"
    }
  },
  "ru": {
    "Patients": {
      "AddPatient": "Добавить пациента",
      "ClinicPatients": "Пациенты клиники",
      "EditPatient": "Изменить данные пациента",
      "HasTreatment": "На лечении",
      "Id": "ID",
      "MyPatients": "Мои пациенты",
      "NamePatient": "Имя пациента",
      "NoPatient": "Пациен не найден",
      "Patient": "Пациент",
      "PatientAge": "Возраст",
      "PatientAlreadyExists": "Пациент уже существует",
      "PatientId": "ID Пациента",
      "PrintBracelet": "Распечатать браслет",
      "SelectPatient": "Выберите пациента"
    }
  },
  "uz": {
    "Patients": {
      "AddPatient": "Bemorni qo'shish",
      "ClinicPatients": "Klinikadagi bemorlar",
      "EditPatient": "Bemorning ma'lumotlarini o'zgartirish",
      "HasTreatment": "Davolanmoqda",
      "Id": "ID",
      "MyPatients": "Mening bemorlarim",
      "NamePatient": "Bemorning Ismi",
      "NoPatient": "Bemor topilmadi",
      "Patient": "Bemor",
      "PatientAge": "Bemornig yoshi",
      "PatientAlreadyExists": "Bemor mavjud",
      "PatientId": "ID - Bemorni",
      "PrintBracelet": "Braslet chiqarish",
      "SelectPatient": "Bemorni tanlang"
    }
  }
}