import * as amplitude from '@amplitude/analytics-browser';

import { AccountingEvents } from './accountingEvents.type';
export const init = (currentUser?: number) => {
  amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, String(currentUser), {
    minIdLength: 1,
    defaultTracking: false,
  });
};

export const setUser = (userId: number, clinicId?: number | string) => {
  const identifyEvent = new amplitude.Identify();
  amplitude.setUserId(String(userId));
  if (clinicId) {
    identifyEvent.set('clinic_id', clinicId);
    amplitude.identify(identifyEvent);
  }
};

export const removeUser = () => {
  amplitude.reset();
};

export const logEvent = (
  event: AccountingEvents | string,
  eventProperties?: Record<string, string | number>
) => {
  amplitude.track(event, eventProperties);
};

export { logPageVisit } from './logPageVisit';
