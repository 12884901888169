<template>
  <canvas class="qr-code" ref="container"></canvas>
</template>

<script>
import QrCode from 'qrcode';

export default {
  name: 'QrCode',
  props: {
    /**
     * @type {object} payload
     * @property {string} data
     * @property {void} toSJSIFunc
     * @property {string} errorCorrectionLevel
     */
    payload: {
      type: Object,
      default: () => ({
        data: '',
        toSJSIFunc: QrCode.toSJIS,
        errorCorrectionLevel: 'H',
      }),
    },
  },
  watch: {
    payload: {
      handler(value) {
        QrCode.toCanvas(this.$refs.container, value.data ?? 'test', {
          toSJISFunc: value.toSJSIFunc,
          errorCorrectionLevel: value.errorCorrectionLevel,
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
