import { QueryParams } from '@/types/common';

export function deleteEmptyValueKeys(object: QueryParams) {
  const returnedObject: Record<string, string | string[] | number | boolean> = {};

  Object.keys(object).forEach((key) => {
    const value = object[key];

    if (value === undefined || value === null) return;
    if (typeof value === 'string' && value.length === 0) return;
    if (Array.isArray(value) && value.length === 0) return;

    returnedObject[key] = value;
  });

  return returnedObject;
}
