import { GenericAbortSignal } from 'axios';

import { useAbortable } from '~shared/hooks/useAbortable';
import { joinQueryParams } from '~shared/lib';
import { QueryParams } from '~shared/types';
import { apiService, GetDoctorDirectoryListResponseData } from '~shared/api';

const urlPath = 'doctors';

export const getList = async (query: QueryParams = {}, signal?: GenericAbortSignal) => {
  const response = await apiService.api.instance.get<GetDoctorDirectoryListResponseData>(
    joinQueryParams({
      url: urlPath,
      query,
    }),
    {
      signal,
    }
  );
  return {
    ...response.data,
  };
};

export const abortableGetList = useAbortable(getList);
