import { QueryParams } from '~shared/types';
import { apiService } from '~shared/api';
import { MetaDto, ClinicShortDto } from '@/types/api';
import { joinQueryParams } from '~shared/lib';

const urlPath = '/clinics';

export const getList = async (query: QueryParams = {}) => {
  const response = await apiService.api.instance.get<{ data: ClinicShortDto[]; meta: MetaDto }>(
    joinQueryParams({
      url: urlPath,
      query: query,
    })
  );

  return {
    ...response.data,
  };
};
