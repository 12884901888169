<template>
  <MainLayout :roles="['admin']">
    <LayoutContentHeader>
      <ElPageHeader :title="$t('Base.Back')" :content="title" @back="backHandler"> </ElPageHeader>

      <template #actions>
        <ElButton type="primary" @click="create">
          {{ $t('Base.Create') }}
        </ElButton>
      </template>
    </LayoutContentHeader>

    <ReferencesValuesTable
      :data="items"
      :total="total"
      :loading="loading"
      v-model:page="page.value"
      v-model:per-page="perPage.value"
      @item:update="editItem"
      @item:delete="deleteItem" />
  </MainLayout>
</template>

<script src="./index.js"></script>
<style lang="scss" src="./index.scss" />
