{
  "en": {
    "Title": "scan"
  },
  "ru": {
    "Title": "Сканировать"
  },
  "uz": {
    "Title": "Skanerlash"
  }
}