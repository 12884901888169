import { RouteRecordRaw } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const SALARY_CALC_LIST: RouteRecordRaw = {
  name: 'SalaryCalcList',
  path: ROUTE_PATH.bookkeeping.salaryCalcList,
  component: () => import('./SalaryCalcList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'accountant'])],
  meta: {
    title: 'Bookkeeping.SalaryCalculation',
  },
};

export const REFERRAL_SOURCE_LIST_ROUTE = {
  name: 'ReferralSources',
  path: ROUTE_PATH.bookkeeping.refSource,
  component: () => import('./ReferralSourceList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'accountant', 'manager']),
  ],
  meta: {
    title: 'Bookkeeping.ReferralSources',
  },
};

export const REFERRAL_ACCRUAL_LIST_ROUTE = {
  name: 'ReferralAccrual',
  path: ROUTE_PATH.bookkeeping.refAccrual,
  component: () => import('./ReferralAccrualList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'accountant', 'manager']),
  ],
  meta: {
    title: 'Bookkeeping.ReferralAccruals',
  },
};

export const REFERRAL_COMMISSION_LIST_ROUTE = {
  name: 'ReferralCommission',
  path: ROUTE_PATH.bookkeeping.refCommission.rawPath,
  component: () => import('./ReferralCommissionList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'accountant', 'manager']),
  ],
  meta: {
    title: 'Bookkeeping.ReferralSources',
  },
};

export const SALARY_BY_EMPLOYEES_LIST_ROUTE = {
  name: 'EmployeeSalaryList',
  path: ROUTE_PATH.bookkeeping.employees.list,
  component: () => import('./SalaryByEmployeesList/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'accountant'])],
  meta: {
    title: 'Common.Employees',
  },
};

export const EMPLOYEE_PAYOUTS_ROUTE = {
  name: 'EmployeePayouts',
  path: ROUTE_PATH.bookkeeping.employees.employee.rawPath,
  component: () => import('./EmployeePayouts/ui'),
  beforeEnter: [onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware(['director', 'accountant'])],
  meta: {
    title: 'Common.Employees',
  },
  props: true,
};
