import { DATE_FORMAT } from '~shared/config';
import { useDatePeriod } from '~shared/lib';
import { getMonthPeriod } from '@/utils/dateAndTime.utils';
import { formatPrice } from '@/utils/price.util';
import { Invoice } from '@/models/Invoice.model';
import { Patient } from '@/models/Patient.model';
import { Appointment } from '@/models/appointment/Appointment.model';
import { Doctor } from '@/models/Doctor.model';
import { ServiceGroup } from '@/models/ServiceGroup.model';
import { Feedback as FeedbackModel } from '@/models/feedback/Feedback.model';
import { MainLayout } from '~widgets/layouts';
import LayoutContentHeader from '@/components/layouts/assets/LayoutContentHeader/index.vue';
import StatisticSimpleCard from '@/components/statistic/StatisticSimpleCard/index.vue';
import PatientsSeparationStatisticCard from '@/components/patients/PatientsSeparationStatisticCard/index.vue';
import ClinicWorkloadStatisticCard from '@/components/statistic/ClinicWorkloadStatisticCard/index.vue';
import AppointmentsOnTimeStatisticCard from '@/components/statistic/AppointmentsOnTimeStatisticCard/index.vue';
import AppointmentSourcesStatisticCard from '@/components/statistic/AppointmentSourcesStatisticCard/index.vue';
import CompletedAppointmentsStatisticCard from '@/components/statistic/CompletedAppointmentsStatisticCard/index.vue';
import PatientWaitingTimeStatisticCard from '@/components/statistic/PatientWaitingTimeStatisticCard/index.vue';
import Feedback from '@/components/common/Feedback/Index.vue';

// TODO: отрефакторить, слишком много логики в одном месте и описать возвращаемые типы из запросов на статистику в моделях
export default {
  name: 'VStatisticDashboard',
  components: {
    LayoutContentHeader,
    MainLayout,
    StatisticSimpleCard,
    PatientsSeparationStatisticCard,
    ClinicWorkloadStatisticCard,
    AppointmentsOnTimeStatisticCard,
    AppointmentSourcesStatisticCard,
    CompletedAppointmentsStatisticCard,
    PatientWaitingTimeStatisticCard,
    Feedback,
  },
  setup: () => ({
    date: useDatePeriod(getMonthPeriod()),
    DATE_FORMAT,
  }),

  data() {
    return {
      FeedbackModel,
      feedback: {
        data: [],
        loading: false,
      },
      invoicesIncome: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },
      patientsCount: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },
      appointmentsCount: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },
      repeatPatients: {
        loading: false,
        data: {
          value: null,
          percent_change: null,
          chart: null,
        },
      },

      topDoctors: {
        loading: false,
        data: {
          by_amount: [],
          by_count: [],
        },
      },
      topServices: {
        loading: false,
        data: {
          by_amount: [],
          by_count: [],
        },
      },

      appointmentsOnTime: {
        loading: false,
        data: {
          value: null,
          percentage: null,
        },
      },
      completedAppointments: {
        loading: false,
        data: {
          value: null,
          percentage: null,
        },
      },
      patientAverageWaiting: {
        loading: false,
        data: {
          timeWaiting: null,
          timeAppointment: null,
        },
      },
    };
  },

  computed: {
    simpleCards() {
      return [
        {
          title: this.$t('Statistic.Income'),
          loading: this.invoicesIncome.loading,
          value: this.invoicesIncome.data.value,
          percentage: this.invoicesIncome.data.percent_change,
          chart: this.invoicesIncome.data.chart,
          chartColor: '#73A2FE',
        },
        {
          title: this.$t('Base.Patients'),
          loading: this.patientsCount.loading,
          value: this.patientsCount.data.value,
          percentage: this.patientsCount.data.percent_change,
          chart: this.patientsCount.data.chart,
          chartColor: '#FE9F44',
        },
        {
          title: this.$t('Statistic.NumberOfAppointments'),
          loading: this.appointmentsCount.loading,
          value: this.appointmentsCount.data.value,
          percentage: this.appointmentsCount.data.percent_change,
          chart: this.appointmentsCount.data.chart,
          chartColor: '#FE44CA',
        },
        {
          title: this.$t('Invoices.RepeatPatients'),
          loading: this.repeatPatients.loading,
          value: this.repeatPatients.data.value,
          percentage: this.repeatPatients.data.percent_change,
          chart: this.repeatPatients.data.chart,
          chartColor: '#40CCBD',
          valueFormatter: (value) => (value ? `${value}%` : '0%'),
        },
      ];
    },

    topCards() {
      return [
        {
          title: this.$t('Statistic.TopDoctorsByAmount'),
          loading: this.topDoctors.loading,
          items: this.topDoctors.data.by_amount,
        },
        {
          title: this.$t('Statistic.TopDoctorsByCount'),
          loading: this.topDoctors.loading,
          items: this.topDoctors.data.by_count,
        },
        {
          title: this.$t('Statistic.TopServicesByAmount'),
          loading: this.topServices.loading,
          items: this.topServices.data.by_amount,
        },
        {
          title: this.$t('Statistic.TopServicesByCount'),
          loading: this.topServices.loading,
          items: this.topServices.data.by_count,
        },
      ];
    },
  },

  watch: {
    'date.value': {
      handler(value) {
        if (value) this.getData();
      },
      immediate: true,
    },
  },

  methods: {
    getData() {
      this.getInvoiceIncome();
      this.getPatientsCount();
      this.getAppointmentsCount();
      this.getRepeatPatients();

      this.getTopDoctors();
      this.getTopServices();

      this.getAppointmentsOnTime();
      this.getCompletedAppointments();
      this.getPatientAverageWaiting();
      this.getFeedbacks();
    },

    async getAppointmentsOnTime() {
      this.appointmentsOnTime.loading = true;

      const { data } = await Appointment.getOnTimeStatistic({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });

      this.appointmentsOnTime.data.value = data.value;
      this.appointmentsOnTime.data.percentage = data.percent_change;

      this.appointmentsOnTime.loading = false;
    },

    async getCompletedAppointments() {
      this.completedAppointments.loading = true;

      const { data } = await Appointment.getSuccessfulStatistic({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });

      this.completedAppointments.data.value = data.value;
      this.completedAppointments.data.percentage = data.percent_change;

      this.completedAppointments.loading = false;
    },

    async getPatientAverageWaiting() {
      this.patientAverageWaiting.loading = true;

      const { data } = await Appointment.getAverageWaitingStatistic({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });

      this.patientAverageWaiting.data.timeWaiting = data.time_waiting;
      this.patientAverageWaiting.data.timeAppointment = data.time_appointment;

      this.patientAverageWaiting.loading = false;
    },

    async getInvoiceIncome() {
      this.invoicesIncome.loading = true;

      const { data } = await Invoice.getIncomeByPeriod({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });

      const income = Math.ceil(data.value / 1e6);
      this.invoicesIncome.data = {
        ...data,
        value: formatPrice({ price: income }),
      };

      this.invoicesIncome.loading = false;
    },

    async getPatientsCount() {
      this.patientsCount.loading = true;

      const { data } = await Patient.getCountStatisticByPeriod({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });
      this.patientsCount.data = data;

      this.patientsCount.loading = false;
    },

    async getAppointmentsCount() {
      this.appointmentsCount.loading = true;

      const { data } = await Appointment.getCountStatistic({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });
      this.appointmentsCount.data = data;

      this.appointmentsCount.loading = false;
    },

    async getRepeatPatients() {
      this.repeatPatients.loading = true;

      const { data } = await Patient.getRepeatStatisticByPeriod({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });
      this.repeatPatients.data = data;

      this.repeatPatients.loading = false;
    },

    async getTopDoctors() {
      this.topDoctors.loading = true;

      const { data } = await Doctor.getTopByPeriod({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });
      this.topDoctors.data = {
        by_amount: data.by_amount.map((elem) => ({
          ...elem,
          value: formatPrice({ price: elem.value }),
        })),
        by_count: data.by_count,
      };

      this.topDoctors.loading = false;
    },

    async getTopServices() {
      this.topServices.loading = true;

      const { data } = await ServiceGroup.getTopByPeriod({
        startAt: this.date.value[0],
        endAt: this.date.value[1],
      });
      this.topServices.data = {
        by_amount: data.by_amount.map((elem) => ({
          ...elem,
          value: formatPrice({ price: elem.value }),
        })),
        by_count: data.by_count,
      };

      this.topServices.loading = false;
    },
    async getFeedbacks() {
      this.feedback.loading = true;
      const { data } = await FeedbackModel.getItems({
        start_at: this.date.value[0],
        end_at: this.date.value[1],
      });

      if (data?.length) {
        this.feedback.data = data;
      }

      this.feedback.loading = false;
    },
  },
};
