/**
 * @class TreatmentDrug
 */
export class TreatmentDrug {
  /**
   * @param {object} [payload]
   * @param {string|number} payload.uuid
   * @param {string} payload.title
   * @param {number} payload.price
   * @param {number} payload.count
   * @param {boolean} payload.is_deleted
   */
  constructor(payload) {
    this._id = Math.random(); // only for client
    this.uuid = payload?.uuid ?? null;
    this.title = payload?.title ?? null;
    this.price = payload?.price ?? null;
    this.count = payload?.count ?? 1;
    this.is_deleted = payload?.is_deleted ?? false;
  }
}
