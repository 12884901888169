import { RouteRecordRaw } from 'vue-router';

import { ROUTE_PATH } from '~shared/config';
import { onlyUserIsAuthMiddleware, onlyUserHasRoleMiddleware } from '~shared/lib';

export const DEPOSIT_LIST_ROUTE: RouteRecordRaw = {
  name: 'DepositList',
  path: ROUTE_PATH.cashRegister.depositList,
  component: () => import('./DepositList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['director', 'manager', 'accountant', 'doctor']),
  ],
  meta: {
    title: 'CashRegister.Deposit',
  },
};

export const INVOICE_LIST_ROUTE = {
  name: 'InvoiceList',
  path: ROUTE_PATH.cashRegister.invoices,
  component: () => import('./InvoiceList/ui'),
  beforeEnter: [
    onlyUserIsAuthMiddleware,
    onlyUserHasRoleMiddleware(['manager', 'director', 'accountant', 'doctor']),
  ],
  meta: {
    title: 'CashRegister.Invoices',
  },
};
