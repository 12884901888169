import { ProfileFullResource } from '../api';

import { localStorageUtils } from '~shared/lib';
import { ACCESS_TOKEN_KEY, LOCALE_KEY, REFRESH_TOKEN_KEY, USER_KEY } from '~shared/config';
import { LocaleIdKey } from '~shared/types';

class AuthStorageService {
  setAccessToken(token: string) {
    localStorageUtils.setItemSafely(ACCESS_TOKEN_KEY, token);
  }

  getAccessToken() {
    return <string | null>localStorageUtils.getParsedItem(ACCESS_TOKEN_KEY);
  }

  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  setRefreshToken(token: string) {
    localStorageUtils.setItemSafely(REFRESH_TOKEN_KEY, token);
  }

  getRefreshToken() {
    return <string | null>localStorageUtils.getParsedItem(REFRESH_TOKEN_KEY);
  }

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  setUser(user: ProfileFullResource) {
    localStorageUtils.setItemSafely(USER_KEY, user);
  }

  getUser(): ProfileFullResource | null {
    return <ProfileFullResource | null>localStorageUtils.getParsedItem(USER_KEY);
  }

  removeUser() {
    localStorage.removeItem(USER_KEY);
  }

  setLocale(lang: string) {
    localStorageUtils.setItemSafely(LOCALE_KEY, lang);
  }
  getLocale(): null | LocaleIdKey {
    return <LocaleIdKey | null>localStorageUtils.getParsedItem(LOCALE_KEY);
  }

  removeLocale() {
    localStorage.removeItem(LOCALE_KEY);
  }

  removeTokens() {
    this.removeAccessToken();
    this.removeRefreshToken();
  }
}

export const authStorageService = new AuthStorageService();
