<template>
  <ElInput
    class="ui-phone-input"
    :model-value="phone"
    :pattern="disableMask ? '.*' : '\\(\\d{2}\\)\\s\\d{3}-\\d{2}-\\d{2}'"
    :placeholder="disableMask ? '' : '(xx) xxx-xx-xx'"
    v-bind="$attrs"
    @update:model-value="updateModelValue">
    <template #prefix>
      <span class="ui-phone-prefix">{{ prefix }}</span>
    </template>
  </ElInput>
</template>

<script>
export default {
  name: 'UiPhoneInput',
  props: {
    modelValue: String,
    disableMask: Boolean,
  },
  computed: {
    prefix() {
      return this.disableMask ? this.defaultPrefix : this.localPrefix;
    },
    prefixRegex() {
      return this.disableMask ? this.defaultPrefixRegex : this.localPrefixRegex;
    },

    phone() {
      const phoneExcludePrefix = this.modelValue?.replace(this.prefix, '');

      if (this.disableMask) return phoneExcludePrefix;
      return phoneExcludePrefix?.replace(
        /^(\d{2})(\d{0,3})(\d{0,2})(\d{0,2})/gm,
        (str, code, group1, group2, group3) => {
          return `(${code}${group1 ? ') ' + group1 : ''}${group2 ? '-' + group2 : ''}${
            group3 ? '-' + group3 : ''
          }`;
        }
      );
    },
  },
  watch: {
    modelValue: {
      handler(value) {
        if (value && value.length && !value.match(this.prefixRegex)) {
          this.$emit('update:modelValue', this.prefix + value);
        }
      },
      immediate: true,
    },
  },

  methods: {
    updateModelValue(value) {
      const phone = this.disableMask
        ? this.defaultPrefix + value.replace(/[-\s()]/gm, '')
        : this.localPrefix + value.replace(/[-\s()]/gm, '').slice(0, 9);

      this.$emit('update:modelValue', phone);
    },
  },

  setup: () => ({
    defaultPrefix: '+',
    defaultPrefixRegex: /\+/gim,
    localPrefix: '+998',
    localPrefixRegex: /\+998/gim,
  }),
};
</script>

<style lang="scss" src="./index.scss" />
