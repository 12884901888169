import { object, string } from 'yup';

import { I18nService } from '~shared/lib';

export const validationSchema = object().shape({
  start: string()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .when(['checked'], (fields, schema) => {
      if (fields[0] !== true) {
        return schema.required(I18nService.t('Validation.RequiredField'));
      }
      return schema;
    }),
  end: string()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .when(['checked'], (fields, schema) => {
      if (fields[0] !== true) {
        return schema.required(I18nService.t('Validation.RequiredField'));
      }
      return schema;
    }),
});
