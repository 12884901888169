<template>
  <UiTableWithPagination
    table-class="appointments-table"
    :data="itemsWithPayload"
    :loading="loading || statusLoading"
    :page="page"
    :per-page="perPage"
    :total="total"
    @update:page="$emit('update:page', $event)"
    @update:per-page="$emit('update:perPage', $event)"
    @row-click="goToAppointment">
    <template #empty>
      <div class="appointments-table__empty">
        <span>{{ $t('Base.NoData') }}</span>
        <ElButton type="primary" @click="addAppointment">
          {{ $t('Appointments.CreateAppointment') }}
        </ElButton>
      </div>
    </template>
    <ElTableColumn width="140px" prop="patient.humId" label="ZorDoc ID" />

    <ElTableColumn
      v-if="columnsOptions['patient'].isShow"
      prop="patient.name"
      :label="$t('Base.Patient')"
      min-width="300px">
      <template #default="{ row }">
        <UiUserAvatarInfo :user="row.patient" show-has-treatment :bold="false" />
      </template>
    </ElTableColumn>

    <ElTableColumn
      v-if="columnsOptions['doctor'].isShow"
      prop="doctor.name"
      :label="$t('Appointments.Types.doctor')"
      min-width="150px" />

    <ElTableColumn
      v-if="columnsOptions['start_at'].isShow"
      width="140px"
      prop="start_at"
      :label="$t('DateAndTime.StartAt')" />
    <ElTableColumn
      v-if="columnsOptions['end_at'].isShow"
      width="100px"
      prop="_end_at"
      :label="$t('DateAndTime.EndAt')" />

    <ElTableColumn
      v-if="columnsOptions['patient.phone'].isShow"
      prop="patient.phone"
      :label="$t('Appointments.PhonePatient')"
      width="160px" />

    <ElTableColumn
      v-if="columnsOptions['payment'].isShow"
      :label="$t('Base.Payment')"
      width="140px">
      <template #default="{ row }">
        <InvoiceStatusTag v-if="row.invoice" :status="row.invoice.status"></InvoiceStatusTag>
      </template>
    </ElTableColumn>

    <ElTableColumn
      v-if="columnsOptions['status'].isShow"
      prop="status"
      :label="$t('Appointments.Statuses.Status')"
      width="140px">
      <template #default="{ row }">
        <AppointmentStatusTag :status="row.status" />
      </template>
    </ElTableColumn>

    <ElTableColumn
      v-if="columnsOptions['services'].isShow"
      prop="_services"
      :label="$t('Base.Services')"
      min-width="240px" />

    <ElTableColumn
      v-if="columnsOptions['created_at'].isShow"
      prop="created_at"
      :label="$t('DateAndTime.CreatedAt')"
      min-width="130px" />

    <ElTableColumn
      v-if="columnsOptions['actions'].isShow"
      prop="actions"
      :label="$t('Base.Actions')"
      min-width="400px">
      <template #default="{ row }">
        <div class="appointments-table__actions-row">
          <ElButton
            v-if="row.status === Appointment.enum.statuses.Approved"
            type="primary"
            @click.stop="updateAppointmentStatus(row, Appointment.enum.statuses.Waiting)">
            {{ $t('Appointments.InviteToReception') }}
          </ElButton>

          <ElButton
            v-else-if="row.status === Appointment.enum.statuses.Waiting"
            type="primary"
            @click.stop="updateAppointmentStatus(row, Appointment.enum.statuses.InProgress)">
            {{ $t('Appointments.StartAppointment') }}
          </ElButton>

          <ElButton
            v-else-if="row.status === Appointment.enum.statuses.InProgress"
            type="primary"
            @click.stop="updateAppointmentStatus(row, Appointment.enum.statuses.Provided)">
            {{ $t('Appointments.EndReception') }}
          </ElButton>

          <ElButton
            v-if="
              ![Appointment.enum.statuses.Canceled, Appointment.enum.statuses.Provided].includes(
                row.status
              )
            "
            type="danger"
            plain
            @click.stop="updateAppointmentStatus(row, Appointment.enum.statuses.Canceled)">
            {{ $t('Appointments.CancelReception') }}
          </ElButton>
        </div>
      </template>
    </ElTableColumn>
    <ElTableColumn
      v-if="!columnsOptions['actions-eye'].isShow"
      prop="actions"
      :label="$t('Base.Actions')"
      min-width="120px">
      <ElButton type="primary" text>
        <template #icon>
          <MiIcon icon="EYE" />
        </template>
        {{ $t('Base.View') }}
      </ElButton>
    </ElTableColumn>
  </UiTableWithPagination>
</template>

<script src="./index.js"></script>

<style lang="scss" src="./index.scss" />
